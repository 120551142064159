.Modal-Create-MSL .ant-modal-content{
    border-radius: 20px !important;
    padding: 22px 24px;
    position: absolute;
    width: 100%;
}
.Modal-Container-Text-Detail-Json .Modal-Container-Text-Main *{
    color: var(--green) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-regular) !important;
    display: flex;
    font-weight: var(--weight-bold) !important;
}
.Modal-Container-Text-Detail-Json .Modal-Container-Button-Main{
    display: flex !important;
    justify-content: center !important;
}
.Modal-Container-Text-Detail-Json .Modal-Container-Text-Main .ant-typography{
    margin-bottom: 0;
}
.Modal-Container-Text-Detail-Json .Modal-Container-Text-Main{
    display: flex;
    justify-content: center;
}
.Modal-Container-Text-Detail-Json .Modal-Container-Row-Key-Value{
    display: inline;
}
.Modal-Container-Text-Detail-Json .Modal-Container-Text-Description *{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-regular) !important;
    font-weight: var(--weight-regular) !important;
}

.Modal-Container-Text-Detail-Json .Modal-Container-Text-Description{
    margin: 13px 0px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: initial !important;
}

.Modal-Container-Text-Detail-Json *{
    display: flex !important;
    /* justify-content: center !important; */
}

.Modal-Close-Detail-Json-Table{
    border-radius: 100%;
    padding: 8px 10px;
    width: fit-content;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 700;
    justify-content: center;
    cursor: pointer;
}

.Modal-Close-Detail-Json-Table{
    background-color: #2AD295;
}

.Modal-Notification-Description-Table .Modal-Container-Text-Key{
    padding-left: 15px;
}