.Container-Incentiv-Program{
    position: relative;
}
.Container-Incentiv-Program .Container-Fitler-Actions{
    margin: 5px 0 10px 0;
    display: flex;
    justify-content: space-between;
    row-gap: 10px;
}

.Title-Section-Incentiv-Program{
    font-size: 12px !important;
    font-family: var(--font-family);
    color: var(--blue) !important;
}

.Container-Title-Section-Zone-Incentiv-Program{
    display: flex;
    justify-content: center;
    background-color: #FBF8F2;
    margin-bottom: 5px;
    align-items: center;
}

.Title-Section-Zone-Incentiv-Program{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    padding: 5px 0;
    color: var(--blue);
    
}

.Container-Incentiv-Program .Button-Actions{
    height: 25px !important;
    padding-left: 3px !important;
    padding-right: 3px  !important;
    display: flex !important;
    align-items : center !important;
    justify-content: center !important;
    border-radius : 8px !important;
    color: white !important;
    font-size :13px !important;
    font-family: 'Inter' !important;
    cursor: pointer !important;
}

.Container-Incentiv-Program .Button-Actions.Button-Save-Incentiv-Program{
    background: #FFC700 !important;
    border: 1px solid #FFC700 !important;
    min-width: 60px;
}

.Button-Actions.Button-Disabled{
    background: rgba(128, 128, 128, 0.305) !important;
    cursor: not-allowed !important;
    border: 1px solid rgba(128, 128, 128, 0.305) !important;
}

.Container-Incentiv-Program .Button-Actions.Button-Download-Incentiv-Program{    
    background: #2AD295 !important;
    border: 1px solid #2AD295 !important;
    width: 71px;
}

.DatePicker-Filter-Incentiven-Program .ant-picker-body{
    padding: 0 !important;
}
.DatePicker-Filter-Incentiven-Program ,
.DatePicker-Filter-Incentiven-Program .ant-picker-body,
.DatePicker-Filter-Incentiven-Program .ant-picker-header{
    width: 250px !important;
}

.DatePicker-Filter-Incentiven-Program .ant-picker-content td{
    width: 30px !important;
}
.DatePicker-Filter-Incentiven-Program .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}