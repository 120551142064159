.Collapse-Form-Master-Products .ant-collapse-header{
    color: var(--blue) !important;
    font-weight: var(--weight-semibold) !important;
}

.Collapse-Form-Master-Products.ant-collapse{
    border: 1px solid rgba(59, 114, 255, 0.4) !important;
}
.Collapse-Form-Master-Products .ant-collapse-item{
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
}


.Collapse-Form-Master-Products{
    margin-bottom: 4px !important;
    margin-top: 3px !important;
}