.Container-Users-Create{
    padding-top: 49px !important;
    padding-bottom: 15px;
}
.Container-Users-Create .Title-Create-User-New{
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: var(--weight-bold);
    line-height: 24.2px;
    color: var(--blue);
    margin-top: 3px;
    margin-bottom: 12px !important;
}
.Container-Users-Create .Create-User-Form-Item-Text .ant-form-item-label{
    display: flex;
    padding-bottom: 8px !important;
    margin-bottom: 0 !important;
}
.ant-form-vertical.Form-Container-User{
    margin-top: 0 !important;
}
.Create-User-Form-Item-Text .ant-form-item-label label{
    font-size: 11px !important;
    font-family: 'Inter' !important;
    color: var(--blue-elec) !important;
    line-height: 13.31px;
}
.Create-User-Form-Item-Text{
    width: 100% !important;
    margin-bottom: 12px !important;
}

.Create-User-Form-Item-Text .ant-picker{
    width: 100%;
    padding: 8px 12px;
}

.Create-User-Form-Item-Text .ant-picker:hover{
    border: 1px solid #D7DEF5 !important;
}

.Create-User-Form-Item-Text .ant-picker-focused{
    border-color: #D7DEF5 !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input > input{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    line-height: 22.54px;
    color: var(--blue) !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input > input::placeholder{
    color: rgb(1, 58, 129, .4) !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-suffix, .Create-User-Form-Item-Text .ant-picker .ant-picker-clear{
    display: none;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input:hover > input{
    border: none !important;
}
.Create-User-Form-Item-Text .ant-input, .Create-User-Form-Item-Text .ant-input-number{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    line-height: 22.54px;
    color: var(--blue) !important;
    border: 1px solid rgb(215, 222, 245, .5) !important;
    padding: 8px 12px 7px 12px;
}
.Create-User-Form-Item-Text .ant-input::placeholder{
    color: rgb(1, 58, 129, .4) !important;
}
.Create-User-Form-Item-Text .ant-input:hover, .Create-User-Form-Item-Text .ant-input:focus{
    box-shadow: none !important;
}
.Create-User-Form-Item-Text .ant-input-number{
    width: 100%;
}
.Create-User-Form-Item-Text .ant-input-number .ant-input-number-input{
    color: var(--blue) !important;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile{
    background-color: var(--blue-elec);
    border-color: var(--blue-elec) !important;
    padding: 8px 15px 7px 17px;
    cursor: pointer;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile.Input-Active-Type-Profile{
    padding-left: 12px;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile .ant-input-prefix{
    margin-inline-end: 3px;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile input{
    border: 0 !important;
    background-color: var(--blue-elec);
    color: var(--white) !important;
    line-height: 22.54px;
    cursor: pointer;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile input::placeholder{
    color: var(--white) !important;
    line-height: 22.54px;
}
.Create-User-Form-Item-Text .Create-User-Input-Password{
    box-shadow: none !important;
    border-color: rgb(215, 222, 245, .5) !important;
}
.Create-User-Input-Password .ant-input{
    border: none !important;
}
.Create-User-Form-Item-Text .Create-User-Input-Password .ant-input-suffix .ant-input-password-icon:hover{
    color: var(--blue-elec) !important;
}
.Form-Create-User-Col-Left{
    padding-right: 10px;
}
.Form-Create-User-Col-Right{
    padding-left: 10px;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox .ant-checkbox-inner{
    border: 2px solid rgb(41, 209, 147, .5) !important;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox-checked .ant-checkbox-inner::after{
    border-color:  #ffffff !important;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item .ant-checkbox-checked .ant-checkbox-inner{
    border: 2px solid #2AD295 !important;
    background-color: #2AD295 !important;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item .ant-checkbox-checked .ant-checkbox-inner::after{
    inset-inline-start: 16.5% !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-checked:after{
    border: none !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-wrapper-checked span{
    color: var(--blue);
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    margin-right: 30px !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-wrapper-disabled span:nth-child(2){
    font-size: 12px !important;
    color: rgba(1, 58, 129, 0.4) !important;
}
.Create-User-Container-Checkbox-Status{
    user-select: none;
}
.Create-User-Container-Checkbox-Status label{
    margin-right: 20px !important;
}
.Create-User-Form-Gender .ant-select-selector{
    border-color: rgb(176, 191, 236, .5) !important;
    box-shadow: none !important;
    padding: 0 12px !important;
}
.Create-User-Form-Gender .ant-select-selection-item{
    font-family: var(--font-family);
    font-size: 12px !important;
    line-height: 22.54px !important;
    color: var(--blue);
    display: flex;
    align-items: center;
}
.Create-User-Form-Gender .ant-select-selection-placeholder{
    font-family: var(--font-family);
    font-size: 12px !important;
    line-height: 22.54px !important;
    color: rgb(1, 58, 129, .4);
    display: flex;
    align-items: center;
}
.Dropdown-User-Form-Gender .ant-select-item-option-content{
    font-family: var(--font-family) !important;
    color: var(--blue) !important;
    font-size: 12px !important;
    line-height: 22.72px !important;
    font-weight: var(--weight-regular) !important;
}
.Dropdown-User-Form-Gender .ant-select-item-option-selected, .Dropdown-User-Form-Gender .ant-select-item-option-active{
    background-color: #EDF2FF !important;
    border-radius: 0 !important;
}
.Dropdown-User-Form-Gender .ant-select-item-option-selected .ant-select-item-option-content, .Dropdown-User-Form-Gender .ant-select-item-option-active .ant-select-item-option-content{
    color: var(--blue-elec) !important;
}
.Create-User-Form-Gender:hover .ant-select-selector, .Create-User-Form-Gender:focus .ant-select-selector{
    border-color: rgb(176, 191, 236, .5) !important;
    box-shadow: none !important;
}
.Create-User-Form-Gender .ant-select-arrow{
    inset-inline-end: 12px;
}
.Create-User-Form-Gender .Icon-Gender-Form{
    font-size: 13px;
    color: var(--blue-elec);
}
.Create-User-Container-Checkbox-Countries label{
    margin-inline-start: 0 !important;
}
.Create-User-Container-Checkbox-Countries label span:nth-child(2){
    padding-inline-start: 4px;
    padding-inline-end: 0;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-disabled .ant-checkbox-inner{
    background-color: var(--white) !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-wrapper :last-child{
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-regular) !important;
    line-height: 14.52px;
    padding-inline-start: 4px;
    padding-inline-end: 0;
}
.Create-User-Container-Checkbox-Status .ant-radio-inner{
    border: 2px solid var(--blue) !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-checked .ant-radio-inner{
    border: 2px solid #2AD295 !important;
    background-color: #ffffff !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-checked .ant-radio-inner::after{
    background-color: #2AD295 !important;
}
 .Create-User-Form-Container-Button{
    border-radius: 27px !important;
    padding: 11px 15px !important;
    font-family: var(--font-family) !important;
    font-weight: var(--weight-bold) !important;
    line-height: 14.52px;
    column-gap: 10px;
    font-size: 12px !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer;
    height: auto !important;
    overflow: hidden;
    position: relative;
}
.Create-User-Form-Container-Button .Spin-Loading{
    display: flex;
}
.Form-Button-Users{
    margin-bottom: 12px;
}
.Form-Button-Users .ant-form-item-control-input-content{
    margin-top: 52px;
}
.Create-User-Form-Button-Save{
    background-color: #2AD295 !important;
    color: #ffffff;
    border: 1px solid rgba(42, 210, 149, 0.4);
    margin-left: 20px !important;
    height: auto !important;
}

.Create-User-Form-Button-Cancel{
    background-color: #ffffff !important;
    border: 1px solid rgba(42, 210, 149, 0.4);
    color: #2AD295;
}

 .Create-User-Form-Button-Cancel:focus, 
 .Create-User-Form-Button-Cancel:hover{
    color: #2AD295 !important;
}

 .Create-User-Form-Button-Cancel span:first-child, .Create-User-Form-Button-Save .Text-Span-Save-User-First{
    transform: translate(0, 0);
    transition: .7s;
}

 .Create-User-Form-Button-Cancel .Text-Span-Cancel-Create-User, .Create-User-Form-Button-Save .Text-Span-Save-Create-User{
    position: absolute;
    transform: translate(0, 190%);
    transition: .7s;
}

 .Create-User-Form-Button-Cancel:hover span:first-child, .Create-User-Form-Button-Save:hover .Text-Span-Save-User-First{
    transform: translate(0, -190%);
    transition: .7s;
}
 .Create-User-Form-Button-Cancel:hover .Text-Span-Cancel-Create-User, .Create-User-Form-Button-Save:hover .Text-Span-Save-Create-User{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Create-User-Form-Item-Text.Check-Form-Country .ant-form-item-control{
    display: flex;
    justify-content: center;
}
.Create-User-Form-Item-Text.Radio-Form-State .ant-form-item-control{
    display: flex;
    justify-content: center;
    height: 40px;
}
.Create-User-Form-Item-Text .Profile-Type-Select-Type-Profile .ant-select-selector{
    height: 37px !important;
}
.Create-User-Form-Item-Text .Profile-Type-Select-Type-Profile .ant-select-selection-placeholder{
    line-height: 35px !important;
}
.Create-User-Form-Item-Text .Profile-Type-Select-Type-Profile .ant-select-selection-search-input{
    height: 35px !important;
}
.Create-User-Form-Item-Text .ant-picker{
    border: 1px solid #D7DEF5 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}
.Create-User-Form-Item-Text .ant-picker-status-error.ant-picker{
    border: 1px solid #D7DEF5 !important;
}
.Form-Container-User .Permissions-User-Modules{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: var(--blue);
    margin-top: 14px;
    margin-bottom: 8px;
}
.Form-User-Checkbox-Countries{
    margin-bottom: 8px !important;
}
.Form-Button-Users .ant-form-item-control-input-content{
    display: flex;
    justify-content: end;
}
.Form-User-Checkbox-Countries .ant-form-item-label label{
    font-family: var(--font-family) !important;
    color: var(--blue-elec) !important;
    font-size: 11px !important;
    line-height: 13.31px;
}
.Form-User-Checkbox-Countries .ant-form-item-label{
    padding-bottom: 8px !important;
    display: flex;
}
.Container-User-Modules .Header-Colapse-Create-Profile .Text-Header-Collapse-Create-Profile{
    color: rgb(1, 58, 129, .5) !important;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper.Submodule-Item{
    display: flex;
    align-items: center;
    padding-left: 12px !important;
    padding-bottom: 4px;
    padding-top: 4px;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper-in-form-item .ant-checkbox-inner, .Collapse-Create-Profile .ant-collapse-content .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
    box-shadow: none !important;
}
.Container-User-Modules .Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper.Submodule-Item span:nth-child(2):not(.ant-checkbox-inner){
    color: rgb(1, 58, 129, .5);
}
.Container-User-Modules .Collapse-Create-Profile .ant-collapse-content-box .Collapse-Submodule-Profile .ant-checkbox-wrapper span:nth-child(2):not(.ant-checkbox-inner){
    color: rgb(1, 58, 129, .5);
}
.Header-Colapse-Create-Profile.Check-Active-Module .Text-Header-Collapse-Create-Profile, .Collapse-Submodule-Profile .Header-Colapse-Create-Profile.Check-Active-Submodule .Text-Header-Collapse-Create-Profile{
    color: var(--blue-elec) !important;
}
.DatePicker-Form-Executives .ant-picker-body{
    padding: 0 !important;
}
.DatePicker-Form-Executives ,
.DatePicker-Form-Executives .ant-picker-body,
.DatePicker-Form-Executives .ant-picker-header{
    width: 250px !important;
}

.DatePicker-Form-Executives .ant-picker-content td{
    width: 30px !important;
}
.DatePicker-Form-Executives .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}
.Modal-Edit-User .ant-modal-content{
    padding: 22px 16px !important;
    border-radius: 20px !important;
}
.Modal-Edit-User .ant-modal-confirm-title{
    font-family: var(--font-family);
    font-weight: var(--weight-regular) !important;
    font-size: 14px !important;
    color: var(--green) !important;
    text-align: center;
    line-height: 14.52px !important;
}
.Modal-Edit-User .ant-modal-confirm-content{
    font-family: var(--font-family);
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    text-align: center;
    line-height: 16.94px !important;
}
.Modal-Edit-User .ant-modal-confirm-btns{
    display: flex;
    justify-content: center;
}
.Modal-Edit-User .Button-Cancel-Edit-User, .Modal-Edit-User .Button-Confirm-Edit-User{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    border-radius: 20px;
    height: auto !important;
    padding: 10px;
    box-shadow: none;
    display: flex !important;
    align-items: center !important;
    overflow: hidden;
    position: relative;
}
.Modal-Edit-User .Button-Cancel-Edit-User{
    font-weight: var(--weight-regular);
    border: 1px solid rgb(41, 209, 147, .4);
    color: var(--green);
}
.Modal-Edit-User .Button-Cancel-Edit-User:hover, .Modal-Edit-User .Button-Cancel-Edit-User:focus{
    border: 1px solid rgb(41, 209, 147, .4);
    color: var(--green);
}
.Modal-Edit-User .Button-Confirm-Edit-User{
    background-color: var(--green) !important;
    font-weight: var(--weight-bold);
    color: var(--white);
    margin-inline-start: 28px !important;
    border: 1px solid var(--green);
}
.Modal-Edit-User .Button-Confirm-Edit-User:hover, .Modal-Edit-User .Button-Confirm-Edit-User:focus{
    color: var(--white);
    border: 1px solid var(--green);
}

@media screen and (max-width: 991px) {
    .Form-Create-User-Col-Left{
        padding-right: 0;
    }
    .Form-Create-User-Col-Right{
        padding-left: 0;
    }
    .Create-User-Container-Checkbox-Countries .ant-checkbox-group-item{
        margin-right: 10px !important;
    }
}