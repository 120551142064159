.Item-Simple-Select-Global .ant-select-item{
    position: relative !important;
    min-height: 15px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.Item-Simple-Select-Global .ant-select-item-option{
    display: flex !important;
    align-items: center !important;
}
.Item-Simple-Select-Global .ant-select-item-option-content{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    line-height: 15px;
    color: var(--blue) !important;
    font-size: 10px !important;
}
.Simple-Select-Global .ant-select-selection-item{
    color: var(--blue) !important;
}

.Item-Simple-Select-Global .rc-virtual-list-scrollbar{
    width: 5px !important;
}

.Item-Simple-Select-Global .rc-virtual-list-scrollbar-thumb{
    background-color: var(--blue) !important;
}

.Simple-Select-Global .ant-select-selector{
    border-radius: 6px !important;
}

.Simple-Select-Global .ant-select-selection-placeholder{
    font-size: 13px !important;
}
.Simple-Select-Global .ant-select-selection-search-input{
    font-size: 12px !important;
}
.Simple-Select-Global {
    width: 90px !important;
}

@media screen and (min-width: 1242px) {
    .Simple-Select-Global{
        width: 100px !important;
    }
}