.Modal-Confirm-Delete-Products .ant-modal-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Modal-Confirm-Delete-Products .Container-Message-Delete-Products{
    overflow-y: scroll;
    max-height: 60vh;
}


.Modal-Confirm-Delete-Products .Container-Message-Delete-Products::-webkit-scrollbar{
    width: 8px;
}
.Modal-Confirm-Delete-Products .Container-Message-Delete-Products::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 6px;
}
.Modal-Confirm-Delete-Products .Container-Message-Delete-Products::-webkit-scrollbar-track{
    background-color: #FFFFFF;
    border-radius: 10px;
}

.Modal-Confirm-Delete-Products .ant-modal-content{
    padding: 30px 30px 20px 30px;
}

.Modal-Confirm-Delete-Products .Container-Message-Delete-Products{
    margin-bottom: 30px !important;
    color: var(--blue);
}
.Modal-Confirm-Delete-Products .Title-Confirm-Delete-Products{
    font-family: var(--font-family);
    color: var(--green);
    font-size: 14px;
    margin-bottom: 10px;
}