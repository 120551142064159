.Container-Channels-Executives .Box-Number-Clients{
    background-color: var(--blue-elec);
    color: #FFFFFF;
    width: 20px !important;
    height: 20px !important;
    border: 1px solid var(--blue-night);
    border-radius: 3px;
    font-family: var(--font-family);
}

.Container-Channels-Executives .Container-Box-Number-Clients{
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    align-items: center;
}

.Container-Channels-Executives .Table-Global .ProfileTypesFilterSelect-Container-Items{
    display: flex !important;
    justify-content: center !important;
}
.Container-Channels-Executives .Table-Global .ant-table-thead .ant-table-cell{
    padding: 0px !important;
    font-size: 11px !important;
}


.Container-Channels-Executives .ProfileTypesFilterSelect-Container-Items {
    margin-left: 0 !important;
}
.Container-Channels-Executives .Table-Global .ant-table-thead tr th:first-child{
    width: 40px !important;
}

.Container-Channels-Executives .Table-Global .ant-table-thead .ant-table-column-title{
    margin-left: 3px !important;
}

.Container-Channels-Executives .Table-Global{
    position: relative !important;
    top: -4px !important;
}

.Container-Channels-Executives .Table-Global .ant-table-tbody .ant-table-cell{
    padding: 2px 4px !important;
}

.Container-Channels-Executives .Table-Container-Actions-Global{
    padding-bottom: 10px !important;
}

.Container-Channels-Executives .Table-Global .ant-table-thead .ant-table-cell{
    padding: 6px 8px 3px 0px;
}


.PopOver-Box-Clients .ant-popover-inner{
    padding: 12px 12px 12px 16px;
    overflow-y: scroll;
    max-height: 80vh;
}

.PopOver-Box-Clients .ant-popover-inner::-webkit-scrollbar{
    width: 8px;
}
.PopOver-Box-Clients .ant-popover-inner::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 6px;
}
.PopOver-Box-Clients .ant-popover-inner::-webkit-scrollbar-track{
    background-color: #FFFFFF;
    border-radius: 10px;
}


.PopOver-Box-Clients .ant-checkbox-wrapper{
    margin-inline-start: 0 !important;
    font-size: 12px !important;
}

.PopOver-Box-Clients .ant-checkbox-checked .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.PopOver-Box-Clients .ant-checkbox-checked .ant-checkbox-inner {
    width: 13px !important;
    height: 13px !important;
    border-radius: 3px !important;
    background-color: var(--green) !important;
    border: 1px solid var(--green);
}

.PopOver-Box-Clients .ant-checkbox-inner {
    border: 2px solid #2AD29580 !important;
    width: 14px;
    height: 14px;
}

.Container-Channels-Executives .Table-Global .ant-table .ant-table-body .ant-table-cell {
    width: 40px !important;
}
.Container-Channels-Executives .Table-Global .ant-table th.ant-table-selection-column {
    display: block !important;
    width: 100% !important;
}
.Container-Channels-Executives .Table-Global .ant-table-tbody .ant-table-cell{
    padding: 0px 4px 0px 4px !important;
    font-size: 10px !important;
}
.Container-Channels-Executives .Table-Col-Container-Actions-Global .Simple-Select-Global{
    width: 120px !important;
}

.Select-Simple-Executive-Form .ant-select-selection-item{
    font-size: 11px;
    color: var(--blue) !important;
}
.Select-Simple-Executive-Form{
    width: 120px !important;
}

.Container-ShipTo-PopUp{
    display: flex;
    align-items: end;
    max-width: 300px;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
    line-height: 1.2;
}

.Title-Checkbox-PopUp-Channels-Executives.Container-Text-ShipTo{
    width: 200px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Title-Checkbox-PopUp-Channels-Executives{
    margin-right: 5px !important;
    font-size: 11px !important;
    font-family: var(--blue) !important;
    color: var(--blue) !important;
}

.Confirm-Action-Channel-Executive .ant-modal-content{
    padding: 18px 22px !important;
}
.Confirm-Action-Channel-Executive .ant-btn span{
    font-size: 13px !important;
}
.Confirm-Action-Channel-Executive .ant-modal-confirm-content{
    font-size: 13px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-semibold);
}
.Confirm-Action-Channel-Executive .ant-modal-confirm-title{
    font-size: 14px !important;
    color: var(--green) !important;
}