.Modal-Notification-User-Created .ant-modal-content{
    border-radius: 20px !important;
    padding: 22px 24px;
}

.Modal-Container-Text-User-Created .Modal-Container-Text-Main *{
    color: var(--green) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    font-weight: var(--weight-bold) !important;
}

.Modal-Container-Text-User-Created-Alert .Modal-Container-Text-Main *{
    color: #de444e !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    font-weight: var(--weight-bold) !important;
}
.Modal-Container-Text-User-Created-Alert .Modal-Container-Text-Main .ant-typography{
    margin-bottom: 0;
}
.Modal-Container-Text-User-Created .Modal-Container-Text-Main .ant-typography{
    margin-bottom: 0;
}
.Modal-Container-Text-User-Created .Modal-Container-Text-Description *{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 11px !important;
    font-weight: var(--weight-regular) !important;
    text-align: center;
    line-height: 1 !important;
}

.Modal-Container-Text-User-Created .Modal-Container-Text-Description .p{
    margin-bottom: 13px;
    text-align: center !important;
}
.Modal-Container-Text-User-Created-Alert .Modal-Container-Text-Description *{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 11px !important;
    font-weight: var(--weight-regular) !important;
    text-align: center;
    line-height: 1 !important;
}

.Modal-Container-Text-User-Created-Alert .Modal-Container-Text-Description .p{
    margin-bottom: 13px;
    text-align: center !important;
}

.Modal-Container-Text-User-Created *{
    display: flex !important;
    justify-content: center !important;
}

.Modal-Container-Text-User-Created-Alert *{
    display: flex !important;
    justify-content: center !important;
}

.Notification-Button-User-Created{
    border-radius: 100%;
    background-color: var(--green);
    padding: 10px 12px;
    width: fit-content;
    color: #ffffff;
    font-size: 11px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    justify-content: center;
    cursor: pointer;
}

.Notification-Button-User-Created-Alert{
    border-radius: 100%;
    background-color: #de444e;
    padding: 10px 12px;
    width: fit-content;
    color: #ffffff;
    font-size: 11px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    justify-content: center;
    cursor: pointer;
}