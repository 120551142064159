.Container-Selection-Rows-Channels-Executives .Title-Section-Selection-Rows{
    display: flex;
    justify-content: space-between;
    color: var(--green) !important;
    align-items: center;
    font-weight: var(--weight-bold);
    background-color: #FFFFFF !important;
    position: relative;
    width: 100%;
    z-index: 10;
    padding-bottom: 5px;
}

.Text-Checkbox-Selection-Rows{
    text-overflow: ellipsis !important;
    font-size: 10px !important;
    overflow: hidden !important;
    width: 100%;
    white-space: nowrap !important;
    font-family: var(--font-family);
    color: var(--blue) !important;
}

.Container-Checkbox-Channel-Executives .ant-checkbox-wrapper span:nth-child(2){
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

/*  */
.Container-Checkbox-Channel-Executives .ant-checkbox-wrapper {
    font-size: 12px !important;
}

.Container-Info-Channels-Executives .ant-checkbox-inner {
    border: 2px solid #2AD29580 !important;
    width: 14px;
    height: 14px;
}

.Container-Info-Channels-Executives .ant-checkbox-inner::after {
    font-size: 10px !important;
}

.Container-Info-Channels-Executives .ant-checkbox-checked .ant-checkbox-inner:hover{
    border-color: var(--green) !important;
}

.Container-Info-Channels-Executives .ant-checkbox-checked .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}

.Container-Info-Channels-Executives .ant-checkbox-checked .ant-checkbox-inner {
    width: 13px !important;
    height: 13px !important;
    border-radius: 3px !important;
    background-color: var(--green) !important;
    border: 1px solid var(--green);
}
/*  */
.Title-Section-Selection-Rows .ant-input {
    font-size: 12px !important;
    color: var(--blue);
}

.Container-Order-Column-Channels-Executives{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    margin: 0 5px 0 5px;
}
.Container-Order-Column-Channels-Executives span{
    height: 8px !important;
    display: flex !important;
    align-items: center !important;
}

.Title-Section-Selection-Rows {
    font-family: var(--font-family) !important;
    font-size: 11px !important;
    width: 100% !important;
    position: sticky !important;
    top: 0 !important;
}