@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&family=Source+Serif+Pro:ital,wght@1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&family=Source+Serif+Pro:ital,wght@1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&family=Source+Serif+Pro:ital,wght@1,600&display=swap');
:root{
    --font-family: 'Inter';
    --font-family-poppins: 'Poppins';
    --green: #2AD295;
    --blue: #013A81;
    --magenta: #FF1CDB;
    --blue-night: #1B2143;
    --blue-elec: #3B72FF;
    --light-blue: #EDF2FF;
    --yellow: #FFC700;
    --cream: #FBF8F2;
    --cream-light: #FFFDF9;
    --lead: #EFEBE6;
    --white: #ffffff;
    --black: #000000;
    --size-exbig: 80px;
    --size-big: 57.54px;
    --size-mediumbig: 28.38px;
    --size-medium: 22.42px;
    --size-regular: 14px;
    --size-small: 11.06px;
    --lineheight-big: 67.3px;
    --lineheight-mediumbig: 34.35px;
    --lineheight-medium: 27.14px;
    --lineheight-regular: 16.94px;
    --lineheight-small: 13.39px;
    --weight-bold: 700;
    --weight-semibold: 600;
    --weight-regular: 400;
}
html body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 100% !important;
}
.Container-Main{
    padding: 46px 60px 50px 60px;
}
@media screen and (max-width: 991px) {
    .Container-Main{
        padding: 60px 34px 50px 34px;
    }
}
@media screen and (max-width: 769px) {
    .Container-Main{
        padding: 60px 30px 50px 30px;
    }
}
@media screen and (max-width: 575px) {
    .Container-Main{
        padding: 60px 30px 50px 30px;
    }
}
.Container-Animacion-Circulo-Menu{
    width: 350vh;
    height: 350vh;
    background-color: red;
    border-radius: 100%; 
    position: absolute;
    z-index: 101;
    right: -130%;
    top: -100%;
    display: flex;
    place-content: center;
    align-items: center;
    animation: Animacion-Crecimiento-Circulo 1s;
}
@keyframes Animacion-Crecimiento-Circulo{
    0%{
        width: 0px;
        height: 0px;
    }
    100%{
        width: 350vh;
        height: 350vh;
    }
}
.Container-Animacion-Circulo-Cerrar-Menu{
    width: 0px;
    height: 0px;
    background-color: red;
    border-radius: 100%; 
    position: absolute;
    z-index: 101;
    right: -90%;
    top: -70%;
    display: flex;
    place-content: center;
    align-items: center;
    animation: Animacion-DeCrecimiento-Circulo 1s;
}
.ant-table-filter-dropdown .ant-dropdown-menu{
    padding: 4px 0 !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item{
    padding: 0 12px !important;
    border-radius: 0 !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content span,
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns button span{
    font-family: var(--font-family) !important;
    font-size: 12px !important;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns{
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content:hover span{
    color: var(--blue-elec) !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content span{
    color: var(--blue) !important;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary{
    background-color: var(--blue) !important;
}
.ant-table-filter-dropdown .ant-checkbox-checked .ant-checkbox-inner{
    border: var(--blue) !important;
    background-color: var(--blue) !important;
}
.ant-table-filter-dropdown .ant-checkbox-inner{
    border: 2px solid rgba(1, 58, 129, 0.4) !important;
    height: 14px !important;
    width: 14px !important;
}
@keyframes Animacion-DeCrecimiento-Circulo{
    0%{
        width: 350vh;
        height: 350vh;
    }
    100%{
        width: 0px;
        height: 0px;
    }
}
.Container-Animacion-Circulo-Menor-Menu{
    width: 90%;
    height: 90%;
    background-color: yellow;
    border-radius: 100%; 
    display: flex;
    place-content: center;
    align-items: center;
}
.Container-Animacion-Circulo-Sub-Menor-Menu{
    width: 80%;
    height: 80%;
    background-color: black;
    border-radius: 100%; 
}
.Menu-Text{
    font-family: var(--font-family), sans-serif;
    font-size: var(--size-regular);
}
.Arrow-Menu-Icon{
    font-size: 20px;
    position: absolute;
    right: 100px;
    transition: right 1s;
    color: var(--blue-elec);
}
.Container-Option-Menu:hover .Arrow-Menu-Icon{
    right: 60px;
    color: var(--white);
    transition: right 1s;
}
.Menu-Option-Selected .Arrow-Menu-Icon{
    color: var(--white);
}
.Menu-Container{
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
            user-select: none;
}
.Menu-Row-Option{
    color: var(--blue) !important;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--lead);
    cursor: pointer;
    width: 100%;
    padding: 0 29px;
}
.Menu-Row-Option:hover{
    color: var(--blue-elec) !important;
}
.Menu-Image{
    padding: 13px 13px 13px 0;
}
.Menu-Option-Selected{
    background: var(--blue-elec);

    transition: background-color 0.7s ease;
    color: var(--white) !important;
    
}
.Menu-Row-Option .Admin-Menu-Image, .Menu-Row-Option .Analytics-Menu-Image, .Menu-Row-Option .Download-Menu-Image, .Menu-Row-Option .Home-Menu-Image, .Menu-Row-Option .Profile-Menu-Image, .Menu-Row-Option .Sing-Out-Menu-Image, .Menu-Row-Option .Status-Menu-Image, .Menu-Row-Option .Upload-Menu-Image{
    width: 20px;
    background-size: 20px 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.Menu-Row-Option .Admin-Menu-Image{
    background-image: url('../../Assets/Images/Menu/AdminBlue.png');
}
.Menu-Row-Option:hover .Admin-Menu-Image, .Menu-Option-Selected .Admin-Menu-Image{
    background-image: url('../../Assets/Images/Menu/AdminSkyBlue.png');
}
.Menu-Row-Option .Analytics-Menu-Image{
    background-image: url('../../Assets/Images/Menu/AnalyticBlue.png');
}
.Menu-Row-Option:hover .Analytics-Menu-Image, .Menu-Option-Selected .Analytics-Menu-Image{
    background-image: url('../../Assets/Images/Menu/AnalyticSkyBlue.png');
}
.Menu-Row-Option .Download-Menu-Image{
    background-image: url('../../Assets/Images/Menu/DownloadBlue.png');
}
.Menu-Row-Option:hover .Download-Menu-Image, .Menu-Option-Selected .Download-Menu-Image{
    background-image: url('../../Assets/Images/Menu/DownloadSkyBlue.png');
}
.Menu-Row-Option .Home-Menu-Image{
    background-image: url('../../Assets/Images/Menu/HomeBlue.png');
}
.Menu-Row-Option:hover .Home-Menu-Image, .Menu-Option-Selected .Home-Menu-Image{
    background-image: url('../../Assets/Images/Menu/HomeSkyBlue.png');
}
.Menu-Row-Option .Profile-Menu-Image{
    background-image: url('../../Assets/Images/Menu/ProfileBlue.png');
}
.Menu-Row-Option:hover .Profile-Menu-Image, .Menu-Option-Selected .Profile-Menu-Image{
    background-image: url('../../Assets/Images/Menu/ProfileSkyBlue.png');
}
.Menu-Row-Option .Sing-Out-Menu-Image{
    background-image: url('../../Assets/Images/Menu/SignOutBlue.png');
}
.Menu-Row-Option:hover .Sing-Out-Menu-Image, .Menu-Option-Selected .Sing-Out-Menu-Image{
    background-image: url('../../Assets/Images/Menu/SignOutSkyBlue.png');
}
.Menu-Row-Option .Status-Menu-Image{
    background-image: url('../../Assets/Images/Menu/StatusBlue.png');
}
.Menu-Row-Option:hover .Status-Menu-Image, .Menu-Option-Selected .Status-Menu-Image{
    background-image: url('../../Assets/Images/Menu/StatusSkyBlue.png');
}
.Menu-Row-Option .Upload-Menu-Image{
    background-image: url('../../Assets/Images/Menu/UploadBlue.png');
}
.Menu-Row-Option:hover .Upload-Menu-Image, .Menu-Option-Selected .Upload-Menu-Image{
    background-image: url('../../Assets/Images/Menu/UploadSkyBlue.png');
}
.Top-Container-Main{
  position: relative;
  /* height: 68px; */
}
.Image-Top-Countries{
  width: 22px;
  height: 22px;
  margin: 2px;
  cursor: pointer;
  transition: 0.2s;
}
.Image-Top-Countries:hover{
  scale: 1.1;
}
.Flag-Top-Andes{
  animation: andes-flag 4s infinite linear;
}
.Underline-Image-Countrie-Selected-Top{
  display: flex;
  justify-content: center;
}
.Underline-Image-Countrie-Selected-Top span{
  width: 70%;
  height: 4px !important;
  background-color: var(--green);
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.Container-Countrie-Select-Top{
  display: flex; 
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
}
.Countrie-Select-Top{
  background: #E9EEFB;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: move-up-countrie-top .5s forwards;
  margin-top: 15px;
  cursor: pointer;
}
.Disable-Country-Select{
  opacity: 0.5 !important;
  pointer-events: none;
}
@keyframes move-up-countrie-top {
  from {
    top: 15px;
  }
  to {
    margin-top: 0px;
  }
}
.Countrie-Select-Top-Selected{
  background: #FC330A;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  animation: move-down-countrie-top .5s forwards;
  cursor: pointer;
}
.Prefix-Country-Selected{
  position: absolute;
  text-align: center;
  margin-left: 4px;
  margin-top: -35px;
  bottom: 34px !important;
  color: rgba(1, 58, 129, 0.4);
  font-size: 11px;
  font-weight: 700 !important;
  font-family: var(--font-family) !important;
}
@keyframes move-down-countrie-top {
  from {
    top: 0;
  }
  to {
    margin-top: 15px;
  }
}
.Container-Loading-Country{
  position: absolute;
  background-color: rgba(128, 128, 128, 0.4);
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
.Container-Country-Top{
  background-color: white;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Container-Round-Menu{
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
}
.Round-Menu-First, .Round-Menu-Second, .Round-Menu-Third , .Round-Menu-Forth{
    position: fixed;
    left: auto;
    top: -8em;
    right: -5em;
    bottom: auto;
    width: 20em;
    height: 20em;
    border-radius: 694.44em;
    transform: translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.Round-Menu-First{
    background-color: #FF1CDB;
    z-index: 10000;
    transition-duration: 1s;
    transition-delay: .9s;
}
.Round-Menu-Second{
    background-color: #013A81;
    z-index: 10001;
    transition-duration: 1s;
    transition-delay: .6s;
}
.Round-Menu-Third{
    background-color: #FDCA0F;
    z-index: 10002;
    transition-duration: 1s;
    transition-delay: .3s;
}
.Round-Menu-Forth{
    background-color: var(--white);
    z-index: 10002;
    transition-duration: 1s;
    transition-delay: 0s;
}
.Round-Active-First{
    transform: translate3d(0px, 0px, 0px) scale3d(20, 20, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition-duration: 1.5s;
    transition-delay: 0s;
}
.Round-Active-Second{
    transform: translate3d(0px, 0px, 0px) scale3d(20, 20, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition-duration: 1.5s;
    transition-delay: .3s;
}
.Round-Active-Third{
    transform: translate3d(0px, 0px, 0px) scale3d(20, 20, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition-duration: 1.5s;
    transition-delay: .6s;
}
.Round-Active-Forth{
  transform: translate3d(0px, 0px, 0px) scale3d(20, 20, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition-duration: 1.5s;
  transition-delay: .9s;
}
.Appear-Drawer{
    opacity: 0;
    animation: Appear-Drawer-Animation .5s 1s forwards;
}
.Disappear-Drawer{
    opacity: 1;
    animation: Disappear-Drawer-Animation .1s forwards;
}
@keyframes Appear-Drawer-Animation{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes Disappear-Drawer-Animation{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}
.Top-Container-Nav{
  position: fixed;
  z-index: 100;
  width: 100%;
  /* background: var(--white); */
}
.Menu-Top{
    background-color: rgb(255, 255, 255);
    opacity: .3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 4px 60px 4px 60px;
    z-index: 100000;
    width: 100%;
    transition: 0.3s;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
}
.Top-Container-Nav.active-nav-opacity .Menu-Top, .Menu-Top:hover{
  opacity: 1;
}
.Menu-Top-Logo{
    height: 24px;
    background-color: #D9D9D9;
    width: 150px;
    border-radius: 10px;
}
.Menu-Top-Logo-Margin{
  margin-left: 0px;
}
.Top-Container-Burger-Menu{
    border: 1px solid var(--blue);
    border-radius: 100%;
    top: 4px;
    right: 60px !important;
    height: 27px;
    width: 27px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    z-index: 100000 !important;
    position: fixed;
    background-color: var(--white);
    opacity: .3;
    transition: 0.5s;
}
.Top-Container-Burger-Menu:hover, .Top-Container-Burger-Menu.active-opacity{
  opacity: 1;
}
/* .Top-Container-Burger-Menu:hover + .Top-Container-Nav .Menu-Top{
  padding-top: 12px;
  padding-bottom: 12px;
} */
.Menu-User-Status-Menu{
  display: flex;
  align-items: center;
  position: fixed;
  right: 120px;
  z-index: 1001;
  top: 6px;
}
.Menu-User-Container-Icon{
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Menu-User-Icon{
  width: 17px;
  height: 17px;
}
.Menu-User-Name-Menu{
  font-family: var(--font-family);
  color: var(--blue);
  font-size: var(--size-regular);
}
.Menu-User-Name{
  display: none; 
}
.Menu-User-Full-Name{
  display: block;
}
.Menu-User-Icon-Inactive{
  height: 10px;
  width: 10px;
  background-color: #f8f32b;
  border-radius: 100%;
  animation: circleScale 0.5s infinite;
}
@keyframes circleScale {
	0% {transform: scale(1)  }
	50% { transform: scale(1.1) }
  100% { transform: scale(1) }
}
/* .Top-Container-Burger-Menu:hover.Top-Menu-Active + .Top-Container-Nav .Menu-Top{
  padding-top: 4px;
  padding-bottom: 4px;
}

.Top-Container-Burger-Menu:hover{
  background-color: var(--blue);
  height: 60px;
  width: 60px;
} */
.Top-Menu-Active{
  right: 28px !important;
  top: 15px;
}
.Top-Burger-Menu{
    position: absolute;
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);
    height: 13px;
    width: 10px;
    cursor: pointer;
}
.Top-Burger-Menu span{
    display: block;
    border-radius: 3px;    
    background: var(--blue);
    transition: all .1s;
    position: relative;
}
.Top-Burger-Menu span:nth-child(1){
  left: 2px;
}
.Top-Burger-Menu span:nth-child(2){
  left: 0;
}
.Top-Burger-Menu span:nth-child(3){
  left: -2px;
}
.Top-Container-Burger-Menu.Top-Menu-Active .Top-Burger-Menu span{
  left: 0;
}
.Top-Burger-Menu span::after{
  content: "";
  position: absolute;
  width: 6px;
  height: 3px;
  top: -1px;
  left: 0;
  background-color: var(--white);
  z-index: 1;
  animation: efecto-menu 2s ease-in-out infinite;
}
@keyframes efecto-menu{
  0%{
    left: 0;
  }
  40%{
    left: 12px;
    opacity: 0;
  }
  50%{
    left: 40px;
    opacity: 0;
  }
  100%{
    opacity: 0;
    left: 90px;
  }
}
/* .Top-Container-Burger-Menu:hover span{
    background: var(--white);
} */
.Top-Container-Burger-Menu .active span:nth-child(1) {
    animation: ease .3s top forwards;
    margin-top: -17px;
    height: 2px;
}
.Top-Container-Burger-Menu .not-active span:nth-child(1) {
    animation: ease .3s top-2 forwards;
    height: 2px;
    margin-top: -0.5px;
}
.Top-Container-Burger-Menu .active span:nth-child(2) {
    animation: ease .3s scaled forwards;
    margin-top: 19px;
    height: 2px;
}
.Top-Container-Burger-Menu .not-active span:nth-child(2) {
    animation: ease .3s scaled-2 forwards;
    margin-top: 4px;
    height: 2px;
}
.Top-Container-Burger-Menu .active span:nth-child(3) {
    animation: ease .3s bottom forwards;
    margin-top: 21px;
    height: 2px;
}
.Top-Container-Burger-Menu .not-active span:nth-child(3) {
    animation: ease .3s bottom-2 forwards;
    margin-top: 4px;
    height: 2px;
}
.Top-Close-Burger-Menu-Test{
    width: 16px;
    height: 16px;
}
.Col-Top-Select{
  display: flex;
  align-items: center;
  height: 50px;
  transition: .3s;
}
/* .Effect-Col-Top-Select{
  height: 56px;
} */
.Top-Select-Country{
    font-size: var(--size-regular);
    font-family: var(--font-family), sans-serif;
    font-weight: var(--weight-bold);
    display: flex;
    flex-direction: initial;
    width: 100%;
    padding: 10px 5px 0px 5px;
    -webkit-user-select: none;
            user-select: none;
}
.Top-Select-Country .Top-Select-Country-Name{
    margin: 0 10px;
}
.Top-Select-Country-Name div{
  cursor: pointer;
  color: var(--blue);
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Top-Container-Country-Name-No-Selected:hover{
  background: rgba(42, 210, 149, 0.4);
  border: 1px solid rgba(42, 210, 149, 0.4);
  color: var(--white);
}
.Top-Container-Country-Name-Active{
    background-color:var(--green);
    color: var(--white) !important;
}
.ant-drawer-body ul{
    border: none;
}
.ant-drawer-body{
    padding: 0 !important;
}
.ant-drawer-header{
    padding: 11px 0px !important;
    border-bottom: none!important;
}
.Menu-Container-Button-Logo{
  z-index: 1001 !important;
  display: flex;
  position: fixed;
  top: 7px;
  left: 60px;
  cursor: pointer;
}
@keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 22px;
      transform: rotate(0);
    }
    100% {
      top: 22px;
      transform: rotate(45deg);
    }
}
@keyframes top-2 {
    0% {
      top: 22px;
      transform: rotate(45deg);
    }
    50% {
      top: 22px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
}
@keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 22px;
      transform: rotate(-45deg);
    }
}
@keyframes bottom-2 {
    0% {
      bottom: 22px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
}
@keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
}
@keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}
@media screen and (max-width: 600px) {
  .ant-drawer-content-wrapper{
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .Menu-Top{
      padding: 3px 60px 3px 30px;
  }
  .Top-Container-Burger-Menu{
    right: 28px !important;
    top: 3px;
  }
  .Top-Menu-Active{
    top: 15px;
  }
  .Menu-Top-Logo-Margin{
    margin-left: 20px;
  }
}
@media screen and (max-width: 575px) {
  .Menu-Top{
      padding: 3px 60px 3px 20px;
  }
  .Top-Container-Burger-Menu{
    right: 18px !important;
    top: 3px;
  }
  .Top-Menu-Active{
    top: 15px;
  }

  .Menu-User-Name{
    display: block; 
  }
  
  .Menu-User-Full-Name{
    display: none;
  }
}
@media screen and (max-width: 331px) {


  .Menu-Top-Logo-Margin{
      margin-left: -2px;
  }
  .Menu-Top-Logo{
    transform: scale(0.9);
  }
  .Top-Container-Burger-Menu{
    top: 3px;
  }
  .Top-Menu-Active{
    top: 15px;
  }
}
.Container-Option-Menu{
  transition: background-color 0.5s; /* transición de color en 0.5 segundos */
}
.Container-Option-Menu::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  /* background: rgba(42, 210, 149, 0.5); color de fondo del efecto de llenado */
  /* background: #3B72FF; color de fondo del efecto de llenado */
  background: var(--yellow); /* color de fondo del efecto de llenado */
  transition: height 0.3s; /* transición de llenado en 0.5 segundos */
}
/* Estilo para la animación cuando se pasa el mouse */
.Container-Option-Menu:hover::before {
  height: 100%;
}
.Container-Option-Menu:hover .Menu-Image{
  z-index: 1;
}
.Menu-Image{
  width: 35px;
  height: 40px;
  /* background-color: red; */
  padding-top: 9px;
  /* display: flex;
  align-items: center; */
}
.Menu-Image img{
  position: absolute;
}
.Container-Option-Menu:hover .Menu-Text{
  color: white !important;
  z-index: 1;
}
.Container-Option-Menu::after{
    content: '';
    position: absolute;
    width: 1px;
    height: 4px;
    bottom: 0;
    left: 0;
    transition: width 1.5s;
}
.Container-Option-Menu:hover::after{
    background-color: var(--blue-elec);
    width: 100%;
}
.Menu-Image .Img-Blanco{
  opacity: 0;
}
.Container-Option-Menu:hover .Menu-Image .Img-Blanco{
  opacity: 1;
}
.Menu-Option-Selected .Img-Blanco {
  opacity: 1;
}
.Container-Menu{
  background-color: var(--white);
  width: 100%;
  height: 38px;
  position: fixed;
  z-index: 1000;
  top: 0;
  opacity: .5;
  transition: .5s;
  /* border-radius: 0 0 20px 20px; */
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  border-left: 2px solid #D9D9D9;
  border-right: 2px solid #D9D9D9;
}
.Container-Menu:hover{
  opacity: 1;
  transition: .5s;
}
.Perfil-Button-Back{
  height: 43px;
  width: 43px;
  border-radius: 100%;
  border: 1px solid rgba(59, 114, 255, 0.4);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.Perfil-Button-Back:hover{
  background: var(--blue);
  border: none;
}
@media screen and (max-width: 991px) {
  .Perfil-Button-Back{
    display: flex;
  }

}
.Container-Image-Gif{
    height: 100vh;
    background-image: url('../../Assets/Images/Login/LoginBackground.gif');
    background-size: 100vw auto;
    background-position-y: -4vh;
    background-repeat: no-repeat;
}
.Col-Form-Login{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Container-Login{
    background-color: var(--white);
    /* background-color: var(--blue); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 100px;
    /* height: 100%; */
}
.Container-Login .Container-Filter{
    position: absolute;
    top: 5%;
    display: none;
}
.Image-Filter-Login{
    width: 18px;
    height: 18px;
    margin-bottom: 4px;
    margin-right: 6px;
    border-radius: 50%;
}
.Container-Login .Filter-Country .ant-select-selector{
    background-color: var(--blue) !important;
    border: 1px solid var(--lead);
    height: 28px;
    border-radius: 12px;
    padding: 0 5px 0 8px;
}
.Container-Login .Filter-Country:hover .ant-select-selector, .Container-Login .Filter-Country.ant-select-focused .ant-select-selector{
    border: 1px solid var(--lead) !important;
}
.Container-Login .Filter-Country input{
    height: 26px;
}
.Container-Login .Filter-Country .ant-select-selection-item{
    color: var(--white);
    line-height: 25px;
}
.Container-Login .Filter-Country .ant-select-arrow{
    color: var(--white);
    font-size: 8px;
    right: 8px;
    margin-top: -5px;
}
.Container-Login .Title-Login{
    color: var(--blue) !important;
    /* color: var(--white) !important; */
    font-family: var(--font-family);
    font-size: var(--size-mediumbig);
    line-height: var(--lineheight-mediumbig);
}
.Container-Login .Image-Login{
    width: 100px;
    height: 100px;
    background-color: var(--white);
    border-radius: 50%;
    margin-bottom: 25px;
}
.Container-Login .Button-Grow-Login, .Container-Login .Button-Google-Login, .Container-Login .Button-Azure-Login{
    height: auto;
    border-radius: 30px;
    border: 0;
    font-family: var(--font-family);
    padding: 12px 0;
    width: 190px;
    margin-bottom: 15px;
    font-size: var(--size-regular);
    font-weight: 500;
    line-height: var(--lineheight-regular);
    text-align: start;
    display: flex;
    align-items: center;
    position: relative;
}
.Container-Login .Button-Grow-Login{
    background-color: var(--green);
    margin-bottom: 0;
    color: var(--white);
}
.Container-Login .Avatar-Button-Grow-Login,
.Container-Login .Avatar-Button-Google-Login,
.Container-Login .Avatar-Button-Azure-Login{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    
}
.Container-Login .Icon-Button-Google-Login{
    width: 17px !important;
    height: 17px !important;
    margin-top: 1px;
    margin-left: 1px;
}
.Button-Google-Login span, .Button-Azure-Login span, .Button-Grow-Login span{
    z-index: 1;
}
.Container-Login .Icon-Button-Azure-Login{
    width: 21px !important;
    height: 21px !important;
    margin-bottom: 2px;
}
.Container-Login .Button-Google-Login, .Container-Login .Button-Azure-Login{
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
}
.Container-Login .Button-Azure-Login{
    color: var(--white);
    background-color: var(--blue);
}
.Container-Login .Button-Google-Login{
    color: var(--white);
    background-color: var(--blue-elec);
}
.Container-Login .Button-Grow-Login{
    color: var(--white);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
}
.Container-Login .Button-Azure-Login .Circle-Button-Azure-Login,
.Container-Login .Button-Google-Login .Circle-Button-Google-Login,
.Container-Login .Button-Grow-Login .Circle-Button-Grow-Login{
    position: absolute;
    height: 360%;
    width: 100%;
    top: 100%;
    border-radius: 100%;
    transition: top 0.8s;
}
.Container-Login .Button-Azure-Login .Circle-Button-Azure-Login{
    background-color: var(--blue-night);
}
.Container-Login .Button-Google-Login .Circle-Button-Google-Login{
    background-color: #FFC700;
}
.Container-Login .Button-Grow-Login .Circle-Button-Grow-Login{
    background-color: var(--blue-elec);
}
.Container-Login .Button-Grow-Login:hover .Circle-Button-Grow-Login,
.Container-Login .Button-Google-Login:hover .Circle-Button-Google-Login,
.Container-Login .Button-Azure-Login:hover .Circle-Button-Azure-Login{
    top: -140%;
    z-index: 0;
}
.Container-Login .Button-Azure-Login:hover span{
    color: var(--white);
}
.Container-Login .Button-Grow-Login:focus,
.Container-Login .Button-Grow-Login:hover, 
.Container-Login .Button-Google-Login:focus, 
.Button-Google-Login:hover{
    color: var(--white) !important;
}
.Container-Login .Button-Azure-Login:focus, .Container-Login .Button-Azure-Login:hover{
    color: var(--blue) !important;
}
.Container-Login .Copyright-Login{
    position: absolute;
    margin: 0;
    color: var(--white);
    bottom: 5%;
    font-family: var(--font-family);
    display: none;
}
.Container-Login .Button-Grow-Login{
    position: relative;
    cursor: pointer;
}
.Container-Login .Button-Grow-Login .Text-Span{
    transform: translate(0, 0);
    transition: .7s;
    opacity: 1;
}
.Container-Login .Button-Grow-Login:hover .Text-Span{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.Container-Login .Button-Grow-Login .Text-Span-Grow-Login{
    position: absolute;
    transform: translate(0, 100%);
    right: 32px;
    opacity: 0;
    transition: .7s;
}
.Container-Login .Button-Grow-Login:hover .Text-Span-Grow-Login{
    transform: translate(0, 0) !important;
    transition: .7s !important;
    opacity: 1 !important;
}
.Container-Login .Container-Or{
    position: relative;
    margin-bottom: 15px;
    width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Container-Login .Text-Or{
    font-family: var(--font-family);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
    color: var(--white);
    margin-bottom: 0;
    background-color: var(--blue);
    padding: 0 8px;
    position: relative;
    z-index: 2;
}
.Container-Login .Container-Or::after{
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--white);
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    z-index: 1;
}
@media screen and (max-width: 991px) {
    .Container-Login .Container-Filter{
        display: block;
    }
    .Container-Login .Copyright-Login{
        display: block;
    }
}
.Container-Image-Gif{
    height: 100vh;
    background-image: url('../../Assets/Images/Login/Gif_login.gif');
    background-size: 100vw auto;
    background-position-y: -4vh;
    background-repeat: no-repeat;
}
.Col-Login-Grow{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Container-Login-Grow{
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 40px 45px;
    height: 458px;
}
.Container-Login-Grow .Perfil-Button-Back{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
}
.Container-Login-Grow .Perfil-Button-Back svg{
    width: 13px;
    height: 13px;
}
.Container-Login-Grow .Container-Filter{
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    /* height: 60px;
    width: 100%;
    background-color: red; */
}
.Image-Filter-Login-Grow{
    width: 22px;
    height: 22px;
    margin: 5px;
    cursor: pointer;
    border-radius: 100%;
    transition: 0.2s;
}
.Underline-Image-Filter-Login-Grow{
    display: flex;
    justify-content: center;
}
.Underline-Image-Filter-Login-Grow span{
    width: 70%;
    height: 4px !important;
    background-color: var(--green);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.Container-Filter-Country{
    display: flex;
}
.Container-Country-Select{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    position: relative;
}
.Country-Text{
    position: absolute;
    top: -7px;
    right: 0;
    left: 0;
    text-align: center;
    font-family: var(--font-family);
    color: rgb(1, 58, 129, .4);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    font-weight: var(--weight-bold);
}
.Country-Select-LoginGrow{
    background: #E9EEFB;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: move-up-countrie-logingrow .5s forwards;
    margin-top: 15px;
    cursor: pointer;
}
@keyframes move-up-countrie-logingrow {
    from {
      top: 15px;
    }
    to {
      margin-top: 0px;
    }
  }
.Country-Select-Selected-LoginGrow{
    background: #FC330A;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    animation: move-down-countrie-logingrow .5s forwards;
    cursor: pointer;
}
@keyframes move-down-countrie-logingrow {
    from {
        top: 0;
    }
    to {
        margin-top: 15px;
    }
}
.Container-Country-LoginGrow{
    background-color: white;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Image-Filter-Login-Grow:hover{
    scale: 1.1;
}
.Container-Login-Grow .Filter-Country .ant-select-selector{
    border: 1px solid var(--lead);
    height: 28px;
    border-radius: 12px;
    padding: 0 15px 0 13px;
}
.Container-Login-Grow .Filter-Country:hover .ant-select-selector, .Container-Login-Grow .Filter-Country.ant-select-focused .ant-select-selector{
    border: 1px solid var(--lead) !important;
}
.Container-Login-Grow .Filter-Country input{
    height: 26px;
}
.Container-Login-Grow .Filter-Country .ant-select-selection-item{
    color: var(--blue);
    line-height: 25px;
}
.Container-Login-Grow .Filter-Country .ant-select-arrow{
    color: var(--blue);
    font-size: 8px;
    right: 13px;
    margin-top: -5px;
}
.Input-Login{
    width: 280px;
}
.Container-Login-Grow .Input-Login span, .Container-Login-Grow .Input-LoginFocus span{
    color: rgb(176, 191, 236) !important;
}
.Input-Login.Input-Error{
    border-color: var(--magenta) !important;
}
.Input-Login:hover{
    border-color: var(--blue-elec) !important;
    border: 5px solid var(--blue-elec);
}
.Input-LoginFocus{
    width: 280px;
    border-color: var(--blue-elec) !important;
    border: 2px solid var(--blue-elec) !important;
    box-shadow: none !important;
}
.Input-LoginFocus.Input-Error{
    border-color: var(--magenta) !important;
}
.Label-Input-Login-Grow{
    position: absolute !important;
    top: 1rem !important;
    z-index: 1;
    pointer-events: none;
    padding: 0 25px;
    color: rgb(176, 191, 236);
    transition: top 0.2s;
    font-size: 12px !important;
    font-family: var(--font-family);
}
.Label-Input-Login-Grow-Focus{
    position: absolute;
    top: -0.8rem !important;
    left: 1.2rem;
    z-index: 1;
    color: var(--blue);
    font-weight: var(--weight-semibold);
    pointer-events: none;
    padding: 0 5px;
    background-color: #ffffff;
    transition: top 0.2s;
    font-size: 12px !important;
    /* font-size: var(--size-regular) !important; */
    font-family: var(--font-family);
}
.Container-Login-Grow .Title-Login-Grow{
    color: var(--blue);
    font-family: var(--font-family);
    margin-bottom: 24px;
    font-size: var(--size-mediumbig);
    line-height: var(--lineheight-mediumbig);
}
.Container-Login-Grow .Input-Login{
    padding: 12px 25px;
    border: 1px solid rgba(176, 191, 236, .5);
    border-radius: 8px;
}
.Container-Login-Grow .Input-Login{
    width: 320px;
}
.Container-Login-Grow .Input-LoginFocus{
    padding: 12px 25px;
    border: 1px solid rgba(176, 191, 236, .5);
    border-radius: 8px;
    width: 320px;
}
.Container-Login-Grow .Input-Login:focus, .Container-Login-Grow .Input-Login:hover{
    border: 1px solid rgba(176, 191, 236, .5);
    box-shadow: none !important;
}
.Container-Login-Grow .Input-Login.Input-Error:hover{
    border-color: var(--magenta) !important;
}
:where(.css-dev-only-do-not-override-1n7nwfa).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: rgba(176, 191, 236, .5);
    border-inline-end-width: 1px;
}
.Container-Login-Grow .Input-Login::placeholder, .Container-Login-Grow .Input-Login input::placeholder{
    color: rgba(1, 58, 129, .4) !important;
}
.Container-Login-Grow .Password-Incorrect{
    font-family: var(--font-family);
    color: var(--magenta);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
}
.Container-Login-Grow .Container-Button-Password{
    margin: 15px 0 20px 0 !important;
}
.Container-Login-Grow .Container-Button-Password .ant-form-item-control-input-content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 20px;
}
.Container-Login-Grow .Loading-Button-Form .ant-spin-blur{
    opacity: 1 !important;
}
.Container-Login-Grow .Loading-Button-Form .ant-spin-container::after{
    background-color: transparent;
}
.Container-Login-Grow .Password-Link{
    font-size: var(--size-small);
    color: var(--blue-elec);
    line-height: var(--lineheight-small);
    font-weight: var(--weight-bold);
    margin-bottom: 0;
}
.Container-Login-Grow .Container-Password-Link{
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 10px;
}
.Container-Login-Grow .Button-Login-Grow{
    background-color: #FFFFFF;
    height: auto;    
    border: 0;
    font-family: var(--font-family);
    padding: 14px 25px;
    font-size: var(--size-regular);
    font-weight: 500;
    line-height: var(--lineheight-regular);
    color: var(--white) ;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
}
.Container-Login-Grow .Button-Login-Grow:focus, .Container-Login-Grow .Button-Login-Grow:hover{
    color: var(--white)  !important;
}
.Container-Login-Grow .Button-Login-Grow span:first-child{
    transform: translate(0, 0);
    transition: .3s;
    opacity: 1;
    z-index: 2;
}
.Container-Login-Grow .Button-Login-Grow .Text-Span-Login-Grow{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    transition: .3s;
    z-index: 2;
    color: var(--white);
}
.Container-Login-Grow .Button-Login-Grow:hover span:first-child{
    transform: translate(0, -100%);
    transition: .3s;
    opacity: 0;
    z-index: 2;
    color: var(--white);
}
.Country-Flag-Andes-Login-Grow{
    animation: andes-flag 4s infinite linear;
}
@keyframes andes-flag {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}
.Button-Text-Color{
    color: #ffffff !important;
}
.Container-Login-Grow .Button-Login-Grow:hover .Text-Span-Login-Grow{
    transform: translate(0, 0) !important;
    transition-duration: .1s !important;
    transition-delay: 0.2s !important;
    opacity: 1 !important;
    z-index: 2;
}
.Button-Login-Grow .Button-Corner{
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    transition: width 0.4s;
}
/* .Button-Background-Login-Grow{
    background-color: var(--green) !important;
    margin: auto 0;
    z-index: 0;
    width: 0;
    height: 0;
    position: absolute;
    transition: 0.3s;
} */
.Button-Background-Login-Grow{
    background-color: var(--green) !important;
    margin: auto 0;
    z-index: 0;
    width: 90%;
    height: 70%;
    position: absolute;
    transition: 0.3s;
    border-radius: 21px;
}
.Button-Login-Grow:hover span{
    color: #ffffff;
}
.Button-Top-Left-Login-Grow{
    border-left: 1px solid var(--green) ;
    border-top: 1px solid var(--green) ;
    left: 0;
    top: 0;
}
.Button-Top-Right-Login-Grow{
    border-right: 1px solid var(--green) ;
    border-top: 1px solid var(--green) ;
    right: 0;
    top: 0;
}
.Button-Bottom-Right-Login-Grow{
    border-bottom: 1px solid var(--green) ;
    border-right: 1px solid var(--green) ;
    bottom: 0;
    right: 0;
}
.Button-Bottom-Left-Login-Grow{
    border-bottom: 1px solid var(--green) ;
    border-left: 1px solid var(--green) ;
    bottom: 0;
    left: 0;
}
.Container-Login-Grow .Button-Login-Grow:hover .Button-Top-Left-Login-Grow{
    width: 50%;
}
.Container-Login-Grow .Button-Login-Grow:hover .Button-Top-Right-Login-Grow{
    width: 50%;
}
.Container-Login-Grow .Button-Login-Grow:hover .Button-Bottom-Right-Login-Grow{
    width: 50%;
}
.Container-Login-Grow .Button-Login-Grow:hover .Button-Bottom-Left-Login-Grow{
    width: 50%;
}
.Container-Login-Grow .Container-Return-Login{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    color: var(--blue);
}
.Container-Login-Grow .Icon-Return-Login{
    font-size: 16px;
    color: #1677ff;
}
.Container-Login-Grow .Text-Return-Login{
    font-family: var(--font-family);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
    margin-bottom: 0;
    color: #1677ff;
}
@media screen and (max-width: 991px) {
    .Col-Image-Login-Grow{
        border-radius: 0 0 20px 20px;
        height: 55%;
    }
    .Col-Login-Grow{
        height: auto;
    }
    .Container-Login-Grow{
        justify-content: start;
    }
    .Container-Login-Grow .Container-Filter{
        position: initial;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 575px) {
    .Col-Image-Login-Grow{
        height: 40%;
    }
    .Col-Login-Grow{
        height: 60%;
    }
    .Container-Login-Grow{
        height: auto;
        padding: 20px 20px;
    }
    .Container-Login-Grow .Input-Login{
        padding: 12px 20px;
    }
    .Container-Login-Grow .Perfil-Button-Back{
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .Container-Login-Grow .Container-Button-Password .ant-form-item-control-input-content{
        column-gap: 10px;
    }
}
.Container-Image-Gif-Recover{
    height: 100vh;
    background-image: url('../../Assets/Images/Login/Gif_login.gif');
    background-size: 100vw auto;
    background-position-y: -4vh;
    background-repeat: no-repeat;
}
.Container-Recover-Password{
    display: flex;
    flex-direction: column;
    background-color: #FDCA0F;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border-radius: 0 20px 20px 0;
    height: 100%;
}
.Container-Recover-Password.View-Restore{
    background-color: #C4D5FF !important;
}
.Container-Recover-Password .Title-Recover-Password{
    color: var(--blue);
    font-family: var(--font-family);
    margin-bottom: 24px;
    font-size: var(--size-mediumbig);
    line-height: var(--lineheight-mediumbig);
    text-align: center;
}
.Container-Recover-Password .Input-Recover{
    padding: 14px 25px 14px 15px;
    /* width: 90% !important; */
    width: 300px !important;
    border: 1px solid var(--white);
    border-radius: 8px;
    background-color: #FDCA0F;
    color: var(--white);
    font-family: var(--font-family);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
}
.Container-Recover-Password .Input-Recover:focus{
    /* border: 1px solid var(--white) !important; */
    box-shadow: none !important;
}
.Container-Recover-Password .Input-Recover:hover{
    /* border: 1px solid var(--white); */
    box-shadow: none !important;
}
.Container-Recover-Password .Input-Recover::placeholder{
    color: var(--white);
    font-family: var(--font-family);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
}
.Container-Recover-Password .Input-Restore.Input-Restore-Error{
    border: 1px solid var(--magenta) !important;
}
.Container-Recover-Password .Input-Restore:focus{
    /* border: 1px solid var(--white) !important; */
    box-shadow: none !important;
}
.Container-Recover-Password .Input-Restore:hover{
    border: 1px solid var(--white);
    box-shadow: none !important;
}
.Container-Recover-Password .Input-Restore::placeholder{
    color: var(--white);
    font-family: var(--font-family);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
}
.Container-Recover-Password .Label-Input-Login-Grow{
    position: absolute !important;
    top: 0.9rem !important;
    z-index: 1;
    pointer-events: none;
    padding: 0 25px;
    color: var(--white);
    transition: top 0.2s;
    /* font-size: var(--size-regular) !important; */
    font-size: 12px !important;
    font-family: var(--font-family);
}
.Container-Recover-Password.View-Restore .Label-Input-Login-Grow{
    top: 0.7rem !important;
}
.Container-Recover-Password.View-Restore .Label-Input-Login-Grow{
    color: var(--blue) !important;
}
:where(.css-dev-only-do-not-override-1n7nwfa).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: rgba(176, 191, 236, .5);
    border-inline-end-width: 1px;
}
.Container-Recover-Password .Input-Login::placeholder, .Container-Recover-Password .Input-Login input::placeholder{
    color: rgba(1, 58, 129, .4) !important;
}
.Container-Recover-Password .Button-Login-Grow:hover span:first-child{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.Container-Recover-Password .Message-Recover, .Container-Recover-Password .Message-Recover-Responsive{
    font-family: var(--font-family);
    text-align: center;
    color: var(--blue);
    line-height: 17px;
}
.Container-Recover-Password .Message-Recover{
    margin-bottom: 2em;
}
.Container-Recover-Password .Message-Recover-Responsive{
    display: none;
}
.Container-Recover-Password .Container-Return-Login-Grow{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    color: var(--blue);
}
.Container-Recover-Password .Icon-Return-Login-Grow{
    font-size: 16px;
    color: var(--white);
}
.Container-Recover-Password .Text-Return-Login-Grow{
    font-family: var(--font-family);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
    font-weight: var(--weight-bold);
    margin-bottom: 0;
    color: var(--white);
}
.Container-Recover-Password .Button-Login-Grow{
    background-color: #FDCA0F;
    height: auto;    
    border: 0;
    font-family: var(--font-family);
    padding: 16px 25px;
    font-size: var(--size-regular);
    font-weight: 500;
    line-height: var(--lineheight-regular);
    color: var(--white) ;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    overflow-y: hidden;
}
.Container-Recover-Password.View-Restore .Button-Login-Grow{
    background-color: #C4D5FF;
}
.Container-Recover-Password .Button-Login-Grow:hover .Text-Span-Login-Grow{
    transform: translate(0, 0) !important;
    transition-duration: .1s !important;
    transition-delay: 0.2s !important;
    opacity: 1 !important;
    z-index: 2;
}
.Container-Recover-Password .Button-Login-Grow .Text-Span-Login-Grow{
    position: absolute;
    transform: translate(0, 30px);
    opacity: 0;
    transition: .3s;
    z-index: 2;
    color: var(--white);
}
.Container-Recover-Password .Button-Text-Color{
    color: #ffffff !important;
}
.Container-Recover-Password .Label-Input-Login-Grow-Focus{
    position: absolute;
    top: -0.7rem !important;
    left: 1.1rem;
    z-index: 1;
    color: var(--white);
    font-weight: var(--weight-semibold);
    pointer-events: none;
    padding: 0 5px;
    transition: top 0.2s;
    /* font-size: var(--size-regular) !important; */
    font-size: 12px !important;
    font-family: var(--font-family);
    /* background-color: transparent !important; */
}
.Container-Recover-Password .Input-Restore{
    padding: 8px 25px 8px 15px;
    width: 232px !important;
    border: 1px solid var(--blue) !important;
    border-radius: 8px;
    background-color: #C4D5FF !important;
    color: var(--blue) !important;
    font-family: var(--font-family);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
}
.Container-Recover-Password.View-Restore .Label-Input-Login-Grow-Focus{
    color: var(--blue) !important;
}
.Container-Recover-Password .Label-Input-Login-Grow-Focus.Color-Yellow{
    background-color: #FDCA0F;
}
.Container-Recover-Password .Label-Input-Login-Grow-Focus.Color-Blue{
    background-color: #A9D2FE;
}
.Container-Recover-Password .Label-Input-Login-Grow-Focus.Color-Purple{
    background-color: #AF88FD;
}
.Container-Recover-Password.View-Restore .Label-Input-Login-Grow-Focus{
    background-color: #C4D5FF !important;
}
.Container-Recover-Password .Button-Background-Login-Grow{
    background-color: var(--blue) !important;
    margin: auto 0;
    z-index: 0;
    width: 97%;
    height: 85%;
    position: absolute;
    transition: 0.3s;
    border-radius: 21px;
}
.Container-Recover-Password.View-Restore .Button-Background-Login-Grow{
    background-color: var(--blue-elec) !important;
}
.Container-Recover-Password .Button-Login-Grow .Button-Corner{
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    transition: width 0.4s;
}
/* .Container-Recover-Password .Button-Login-Grow:focus, .Container-Recover-Password .Button-Login-Grow:hover{
    color: var(--blue)  !important;
} */
.Container-Recover-Password .Button-Login-Grow:hover span{
    color: var(--white);
}
.Container-Recover-Password .Button-Top-Left-Login-Grow{
    border-left: 1px solid var(--blue) ;
    border-top: 1px solid var(--blue) ;
    left: 0;
    top: 0;
}
.Container-Recover-Password .Button-Top-Right-Login-Grow{
    border-right: 1px solid var(--blue) ;
    border-top: 1px solid var(--blue) ;
    right: 0;
    top: 0;
}
.Container-Recover-Password .Button-Bottom-Right-Login-Grow{
    border-bottom: 1px solid var(--blue) ;
    border-right: 1px solid var(--blue) ;
    bottom: 0;
    right: 0;
}
.Container-Recover-Password .Button-Bottom-Left-Login-Grow{
    border-bottom: 1px solid var(--blue) ;
    border-left: 1px solid var(--blue) ;
    bottom: 0;
    left: 0;
}
/* .Container-Recover-Password .Button-Login-Grow:focus, .Container-Recover-Password .Button-Login-Grow:hover{
    color: var(--blue)  !important;
} */
.Container-Recover-Password .Button-Login-Grow:hover .Button-Top-Left-Login-Grow{
    width: 50%;
}
.Container-Recover-Password .Button-Login-Grow:hover .Button-Top-Right-Login-Grow{
    width: 50%;
}
.Container-Recover-Password .Button-Login-Grow:hover .Button-Bottom-Right-Login-Grow{
    width: 50%;
}
.Container-Recover-Password .Button-Login-Grow:hover .Button-Bottom-Left-Login-Grow{
    width: 50%;
}
.Container-Recover-Password .Button-Login-Grow span:first-child{
    transform: translate(0, 0);
    transition: .3s;
    opacity: 1;
    z-index: 2;
}
.Container-Recover-Password .Button-Login-Grow:hover span:first-child{
    opacity: 0;
    transform: translate(0, -30px);
}
.View-Restore .Button-Top-Left-Login-Grow{
    border-left: 1px solid var(--blue-elec);
    border-top: 1px solid var(--blue-elec);
    left: 0;
    top: 0;
}
.View-Restore .Button-Top-Right-Login-Grow{
    border-right: 1px solid var(--blue-elec);
    border-top: 1px solid var(--blue-elec);
    right: 0;
    top: 0;
}
.View-Restore .Button-Bottom-Right-Login-Grow{
    border-bottom: 1px solid var(--blue-elec);
    border-right: 1px solid var(--blue-elec);
    bottom: 0;
    right: 0;
}
.View-Restore .Button-Bottom-Left-Login-Grow{
    border-bottom: 1px solid var(--blue-elec);
    border-left: 1px solid var(--blue-elec);
    bottom: 0;
    left: 0;
}
.View-Restore .Input-Restore .ant-input{
    background-color: #C4D5FF !important;
    display: flex !important;
    align-items: center !important;
}
.Button-Login-Grow.Recover-Button{
    width: 205px !important;
}
.Button-Login-Grow.Restore-Password{
    width: 150px !important;
}
@media screen and (max-width: 575px) {
    .Container-Recover-Password{
        padding: 20px 30px;
    }
    .Container-Recover-Password .Message-Recover{
        display: none;
    }
    .Container-Recover-Password .Message-Recover-Responsive{
        display: block;
    }
    .Container-Recover-Password .Input-Recover{
        width: auto;
    }
    .Container-Recover-Password .Input-Restore{
        width: auto;
    }
    .Container-Recover-Password .Form-Recover-Password{
        max-width: 280px;
    }
}
.Container-Footer{
    border-radius: 20px 20px 0px 0px;
    position: relative;
    height: 38px;
    width: 100%;
}
.Container-Footer .Item-Container-Footer{
    background-color: var(--blue);
    border-radius: 20px 20px 0px 0px;
    padding: 12px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: -38px;
    transition: bottom .5s;
}
.Container-Footer:hover .Item-Container-Footer{
    bottom: 0;
    transition: bottom .5s;
}
.Container-Footer .Text-Footer{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
}
.Container-Footer .Link-Footer{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 14px;
    color: var(--green);
    margin-bottom: 0;
    text-align: center;
    position: relative;
}
.Container-Footer .Link-Footer:hover{
    color: var(--white);
}
.Container-Footer .Link-Footer::before{
    content: '';
    position: absolute;
    background-color: var(--white);
    width: 0;
    height: 1px;
    bottom: 0;
    transition: width .5s;
}
.Container-Footer .Link-Footer:hover::before{
    width: 100%;
    transition: width .5s;
}
.ant-tooltip-inner{
    /* color: var(--blue) !important; */
    font-family: var(--font-family) !important;
}
.Text-Analytics{
    font-family: var(--font-family);
    text-align: center;
    color: var(--blue);
    line-height: var(--lineheight-regular);
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    font-size: 12px !important;
}
.Title-Analytics-Header{
    font-family: var(--font-family);
    text-align: center;
    color: var(--blue) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    font-weight: var(--weight-bold) !important;
    margin-bottom: 12px !important;
    margin-bottom: 5px !important;
}
.SubTitle-Analytics-Header{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    color: var(--green) !important;
    font-size: 16px !important;
    line-height: var(--lineheight-medium) !important;
    text-align: center;
    margin-top: 8px !important;
    margin-bottom: 2px !important;
}
.Card-Description-Status:hover{
    box-shadow: 3px 4px 10px rgba(217, 217, 217, 0.58) !important;
}
.Card-Description-Status-Not-Avaliable{
    opacity: 0.6 !important;
    cursor: not-allowed !important;
    -webkit-user-select: none !important;
            user-select: none !important;
}
.Card-Description-Status-Button-State{
    font-size: 10px !important;
    flex: 1;
    width: 110px !important;
    font-family: var(--font-family) !important;
}
.Card-Description-Status-Button-State-PopUp .ant-select-item-option{
    color: var(--blue) !important;
    font-weight: 500 !important;
}
.Card-Description-Status-Button-State-PopUp .ant-select-item-option:hover{
    background-color: #EDF2FF !important;
    color: var(--blue-elec) !important;
}
.Card-Description-Status-Button-State-PopUp .ant-select-item-option-selected{
    color: var(--blue-elec) !important;
    font-weight: var(--weight-regular) !important;
}
.Card-Description-Status-Button-State-PopUp .ant-select-item{
    align-items: center !important;
    font-size: 10px !important;
    border-radius: 0 !important;
    padding: 0 10px !important;
    min-height: 25px !important;
}
.Card-Description-Status-Button-State-PopUp .rc-virtual-list-holder-inner{
    padding: 10px 0 !important;
    font-family: var(--font-family) !important;
}
.Card-Description-Status-Button-State .ant-select-selector{
    height: 16px !important;
    border: 1px solid #3B72FF !important;
    border-radius: 8px !important;
    align-items: center;
}
.Card-Description-Status-Button-State.Error-State .ant-select-selector{
    border: 1px solid #D72B21 !important;
    background-color: #FFD9D7 !important;
    color: #D72B21 !important;
}
.Card-Description-Status-Button-State.Alert-State .ant-select-selector{
    border: 1px solid #FFC700 !important;
    background-color: #FCFFDE !important;
    color: #FFC700 !important;
}
.Card-Description-Status-Button-State.Success-State .ant-select-selector{
    border: 1px solid #2AD295 !important;
    background-color: #E4F9F1 !important;
    box-shadow: none !important;
    color: #2AD295 !important;
}
.Card-Description-Status-Button-State .ant-select-selection-search-input{
    height: 16px !important;
}
.Card-Description-Status-Button-State .ant-select-selection-item, .Card-Description-Status-Button-State .ant-select-selection-placeholder{
    line-height: 17px !important;
    font-size: 10px !important;
}
.Card-Description-Status-Button-State.Error-State .ant-select-selection-item,
.Card-Description-Status-Button-State.Alert-State .ant-select-selection-item,
.Card-Description-Status-Button-State.Success-State .ant-select-selection-item{
    font-weight: var(--weight-semibold) !important;
}
.Card-Description-Status{
    top: -24px;
    background-color: var(--cream);
    border-radius: 24px;
    border: 0;
    overflow: auto hidden;
    transition: 0.2s;
    padding-top: 20px;
}
.Card-Description-Status::-webkit-scrollbar{
    width: 10px;
    height: 8px;
}
.Card-Description-Status::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 24px;
}
.Card-Description-Status::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 0;
    margin-left: 50px;
    margin-right: 50px;
}
.Card-Description-Status .ant-card-body{
    padding: 7px 45px 14px 45px;
}
.Header-Description-Status .Title-Description-Status{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 14px;
    line-height: 16.94px;
    color: var(--blue);
    display: block;
    margin-bottom: 0 !important;
}
.Header-Description-Status .Container-Linear-Description-Status{
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
}
.Card-Description-Status .Container-Text-Description-Status{
    max-width: 200px;
    width: 200px;
}
.Card-Description-Status .Text-Responsible-Description-Status{
    font-family: var(--font-family);
    font-size: var(--size-small);
    line-height: 13px;
    color: var(--blue-elec);
    display: flex;
    align-items: center;
}
.Card-Description-Status .Avatar-Description-Status{
    background-color: var(--green);
    line-height: 14px !important;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Card-Description-Status .Text-Description-Status{
    font-family: var(--font-family);
    line-height: 17px;
    color: var(--blue);
    margin-bottom: 0;
}
.Header-Description-Status .Linear-Description-Status{
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.Header-Description-Status .Linear-Description-Status .ant-progress-text{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: 24.2px;
    color: var(--blue);
    margin-bottom: -4px;
    margin-inline-start: 17px;
}
.Card-Description-Status .Table-Description-Status{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 1000px;
}
.Card-Description-Status .Table-Description-Status th{
    padding-bottom: 3px;
}
.Card-Description-Status .Text-Head-Description-Status{
    font-family: var(--font-family);
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue-elec);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}
.Card-Description-Status th:last-child .Text-Head-Description-Status{
    display: flex;
    align-items: center;
}
.Card-Description-Status .Table-Description-Status td{
    font-family: var(--font-family);
    padding: 0 !important;
    color: var(--blue);
    text-align: center;
    font-size: 11px;
    line-height: 18px;
}
.Card-Description-Status .Table-Description-Status td:first-child{
    text-align: start;
}
.Card-Description-Status .Tag-Table-Description-Status{
    margin-inline-end: 0;
    border-radius: 8px;
    padding: 2px 10px;
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 11.062px;
    line-height: 13px;
    border: 0;
}
.Card-Description-Status .Tag-Table-Description-Status.Green-Description-Status{
    background-color: #D7FCD2;
    font-size: 10px;
    line-height: 12.1px;
    color: var(--green);
    width: 100px;
    text-align: center;
}
.Card-Description-Status .Tag-Table-Description-Status.Red-Description-Status{
    background-color: #FFD9D7;
    color: #D72B21;
    text-align: center;
    font-size: 10px;
    line-height: 12.1px;
    width: 100px;
}
.Card-Description-Status .Text-Status-Table{
    font-weight: var(--weight-semibold);
}
.Card-Description-Status .Text-Status-Table.Green-Status-Table{
    color: var(--green);
}
.Card-Description-Status .Text-Status-Table.Yellow-Status-Table{
    color: var(--yellow);
}
.Card-Description-Status .Text-Status-Table.Red-Status-Table{
    color: #D72B21;
}
.Header-Description-Status{
    display: flex;
    width: 100%;
    position: relative;
    background-color: #FFFFFF;
    z-index: 2;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 0 50px !important;
    box-shadow: 0px 2px 12px 0px rgba(180, 192, 234, 0.4);
}
.Header-Description-Status img{
    width: 40px !important;
    height: 40px !important;
}
.Table-Description-Status .Button-Show-More{
    cursor: pointer;
    margin-top: 5px;
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    border-radius: 10px !important;
    background-color: var(--blue-elec);
    color: white !important;
    font-family: var(--font-family);
    font-size: var(--size-small);
    font-weight: var(--weight-semibold);
    line-height: 13.39px;
    height: 25px;
    border: none !important;
    padding: 0 10px;
}
.Button-Show-More  span{
    z-index: 1;
}
.Table-Description-Status .Button-Show-More .Text-Span{
    transform: translate(0, 1px);
    transition: .7s;
    opacity: 1;
}
.Table-Description-Status .Button-Show-More:hover .Text-Span{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.Table-Description-Status .Button-Show-More .Text-Span-Table-Status{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    color: #FFFFFF;
    transition: .4s;
}
.Table-Description-Status .Button-Show-More:hover .Text-Span-Table-Status{
    transform: translate(0, 1px) !important;
    transition: .7s !important;
    opacity: 1 !important;
}
.Button-Show-More .Circle-Button-Table-Status{
    position: absolute;
    height: 360%;
    width: 100%;
    top: 100%;
    left: 0;
    border-radius: 100%;
    transition: top 0.8s;
}
.Button-Show-More .Circle-Button-Table-Status{
    background-color: var(--blue);
}
.Button-Show-More:hover .Circle-Button-Table-Status{
    top: -140%;
    z-index: 0;
}
.Header-Container-Title-Status{
    width: 20%;
    display: flex;
    align-items: center;
}
.Header-Container-Progress-Status{
    width: 100%;
    display: flex;
    align-items: start;
}
@media screen and (max-width: 991px) {
    .Card-Description-Status .ant-card-body{
        padding: 25px 35px;
    }
    .Card-Description-Status .Container-Linear-Description-Status{
        flex-direction: column-reverse;
        align-items: start;
    }
    .Header-Description-Status .Linear-Description-Status .ant-progress-outer{
        margin-inline-end: 0;
        padding-inline-end: 0;
    }

    .Header-Container-Title-Status{
        width: 40%;
    }
}
.Card-Circle-Status{
    border: 0;
    border-radius: 0 0 20px 20px;
}
.Card-Circle-Status-Not-Avaliable{
    opacity: 0.6 !important;
    cursor: not-allowed !important;
    -webkit-user-select: none !important;
            user-select: none !important;
}
.Card-Circle-Status .ant-card-body{
    padding: 26px 0 19px 0 !important;
}
.Card-Circle-Status .Container-Title-Circle-Status{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(176, 191, 236, 0.5);
    margin-bottom: 15px;
}
.Card-Circle-Status .Container-Title-Circle-Status p{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    line-height: 17px;
    color: var(--green);
    margin-bottom: 5px;
    text-align: center;
}
.Card-Circle-Status .Container-Title-Circle-Status p:first-child{
    width: 40%;
}
.Card-Circle-Status .Container-Title-Circle-Status p:last-child{
    width: 60%;
}
.Card-Circle-Status.ant-card .Container-Description-Item-Status{
    line-height: 0 !important;
}
.Card-Circle-Status.ant-card .Container-Date-Filter-Status{
    line-height: 1 !important;
}
.Card-Circle-Status .Container-Section-Status{
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px !important;
    padding: 7px 22px;
    gap: 15px;
    cursor: pointer;
    box-shadow: 0 2px 12px 0px rgb(23, 41, 104, .4);
}
.Card-Circle-Status .Container-Section-Status.Section-Minimun-Space{
    width: 100%;
}
.Card-Circle-Status .Container-Section-Status.Item-Selected{
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 3px 7px rgba(23, 41, 104, 0.4)
}
.Card-Circle-Status .Title-Item-Status{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue-elec);
    margin-bottom: 0;
}
.Card-Circle-Status .Responsible-Item-Status{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: var(--size-small);
    line-height: 13.39px;
    color: var(--blue);
}
.Card-Circle-Status .Percentage-Circle-Status .ant-progress-text{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: var(--size-regular);
    line-height: 17px;
    color: var(--blue);
}
.Card-Circle-Status .Percentage-Linear-Status{
    display: none;
    width: 60%;
    margin-inline-end: 0;
    flex-direction: column-reverse;
}
.Card-Circle-Status .Percentage-Linear-Status .ant-progress-outer{
    padding-inline-end: 0;
}
.Card-Circle-Status .Percentage-Linear-Status .ant-progress-text{
    margin-left: auto;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    line-height: 17px;
    color: var(--blue);
    margin-bottom: -7px;
}
.Container-Date-Filter-Status{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    top: 43%;
    width: 100%;
    position: absolute;
}
.Container-Date-Filter-Status .ant-picker{
    width: 60px !important;
}
.Container-Item-Header-Status-Date, .Container-Item-Header-Area{
    background-color: #F1F0FF;
    border-radius: 20px;
}
.Container-Item-Header-Area{
    height: 100%;
    padding: 0 11px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.Container-Item-Header-Status-Date{
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    font-family: var(--font-family);
    font-size: var(--size-regular) !important;
}
.Container-Date-Filter-Status span:first-child{
    font-weight: var(--weight-bold) !important;
    color: var(--blue-elec);
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
}
.Container-Date-Filter-Status span:last-child{
    color: var(--blue);
}
.Image-Indicator-Status-Top{
    width: 80%;
    transform: translate(10%, -40%);
}
.Container-Item-Area{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.Container-Section-Status .Container-Item-Status{
    position: relative;
}
.Container-Item-Status .Image-Indicator-Area-Top{
    position: absolute;
    width: 70px;
    top: -56px;
    left: 35%;
}
.Container-Description-Item-Status{
    padding-top: 10px;
}
.DatePicker-Filter-Status.ant-picker{
    padding: 2px 7px 2px 7px !important;
}
.DatePicker-Filter-Status.ant-picker-focused{
    border-color: none !important;
    box-shadow: none !important;
    border-inline-end-width: none !important;
}
.DatePicker-Filter-Status{
    border-color: none !important;
    border: none !important;
    background-color: #F1F0FF;
    margin: 7px 0 0 0 !important;
    width: 68px;
}
.DatePicker-Filter-Status:hover{
    background-color: #D5D2FF;
}
.DatePicker-Filter-Status .ant-picker-input input{
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    color: var(--blue) !important;
    font-family: var(--font-family);
    font-size: 11.06px;
    line-height: 13.39px;
}
.DatePicker-Filter-Status .ant-picker-input input:focus {
    outline: none !important;
}
.DatePicker-Filter-Status-PopUp .ant-picker-body{
    padding: 0 !important;
}
.DatePicker-Filter-Status-PopUp ,
.DatePicker-Filter-Status-PopUp .ant-picker-body,
.DatePicker-Filter-Status-PopUp .ant-picker-header{
    width: 250px !important;
}
.DatePicker-Filter-Status-PopUp .ant-picker-content td{
    width: 30px !important;
}
.DatePicker-Filter-Status-PopUp .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}
@media screen and (max-width: 991px) {
    .Image-Indicator-Area-Top{
        display: none !important;
    }
    .Container-Description-Item-Status{
        padding-top: 0px;
    }
    .Card-Circle-Status{
        box-shadow: none;
        border-radius: 0;
    }
    .Card-Circle-Status .ant-card-body{
        padding: 0;
    }
    .Card-Circle-Status .Container-Section-Status{
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .Card-Circle-Status .Container-Section-Status .Container-Item-Status{
        width: 40%;
    }
    .Card-Circle-Status .Percentage-Circle-Status{
        display: none;
    }
    .Card-Circle-Status .Percentage-Linear-Status{
        display: flex;
    }
}
@media screen and (min-width: 1707px) {
    .Image-Indicator-Status-Top{
        width: 60%;
        transform: translate(30%, -50%);
    }
}
@media screen and (min-width: 2049px) {
    .Image-Indicator-Status-Top{
        width: 50%;
        transform: translate(50%, -50%);
    }
}
.ant-dropdown{
    z-index: 100000001;
}
.Modal-Detail-Area-Status .Title-Modal-Detail-Area{
    color: var(--blue);
    font-weight: var(--weight-bold);
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 24.2px;
    display: flex;
    justify-content: center;
}
.DatePicker-Modal-Status{
    z-index: 1000000000 !important;
}
.Modal-Detail-Area-Status .Table-Pagination-Global{
    position: absolute;
    right: 10px;
    top: -36px;
}
.Modal-Detail-Area-Status .ant-modal-content{
    border-radius: 25px !important;
    padding: 20px 32px !important;
    /* overflow: hidden auto; */
}
.Modal-Detail-Area-Status .ant-table-thead .ant-table-cell::before{
    display: none !important;
}
.Modal-Detail-Area-Status .ant-modal-close{
    line-height: 0 !important;
}
.Modal-Detail-Area-Status .ant-modal-close:hover{
    background-color: #ffffff !important;
}
.Modal-Detail-Area-Status .Title-Table-Modal-Status{
    font-family: var(--font-family);
    font-size: 11px;
    font-weight: var(--weight-bold);
    line-height: 14.52px;
}
.Modal-Detail-Area-Status .ant-table-column-title{
    text-align: center !important;
}
.Button-Close-Modal-Detail-Area span{
    display: flex;
    align-items: center;
    position: relative;
    font-size: 26px;
    top: -2px;
}
.Button-Close-Modal-Detail-Area{
    display: flex;
    justify-content: center;
    color: var(--blue-elec);
    margin: 10px;
    left: -11px;
    top: -12px;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 95%;
    height: 25px;
    width: 25px;
    font-size: 30px;
    font-weight: 400;
    position: absolute;
    border: 1px solid var(--blue-elec);
    transform: rotate(45deg);
    font-family: var(--font-family);
}
.Button-Close-Modal-Detail-Area:hover{
    background-color: var(--blue-elec);
    color: #FFFFFF;
    transition: 0.4s;
}
.Modal-Detail-Area-Status .Text-Status-Table{
    font-weight: var(--weight-semibold);
    line-height: 24px;
}
.Modal-Detail-Area-Status .Text-Status-Table.Green-Status-Table{
    color: var(--green);
}
.Modal-Detail-Area-Status .Text-Status-Table.Red-Status-Table{
    color: #D72B21;
}
.Modal-Detail-Area-Status .Tag-Table-Description-Status{
    margin-inline-end: 0;
    border-radius: 8px;
    padding: 2px 0;
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 11.062px;
    line-height: 13px;
    border: 0;
}
.Modal-Detail-Area-Status .Tag-Table-Description-Status.Green-Description-Status{
    background-color: #D7FCD2;
    color: var(--green);
}
.Modal-Detail-Area-Status .Tag-Table-Description-Status.Red-Description-Status{
    background-color: #FFD9D7;
    color: #D72B21;
}
.Modal-Detail-Area-Status .Table-Global .ant-table-body{
    overflow-y: hidden !important;
}
.Modal-Detail-Area-Status .Table-Global .ant-table-body::-webkit-scrollbar{
    width: 7px !important;
    height: 7px !important;
}
.Modal-Detail-Area-Status .Table-Global .ant-table-body::-webkit-scrollbar-thumb{
    background-color: #013A81;
    border: 1px;
    border-radius: 50px;
}
.Modal-Detail-Area-Status .Table-Global .ant-table-body::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin: 10px 20px;
}
.Modal-Detail-Area-Status .Table-Global .ant-table-tbody .ant-table-cell{
    padding: 0 5px 0 0px !important;
    font-size: 10px;
    line-height: 13.31px;
    font-family: var(--font-family);
}
.Modal-Detail-Area-Status .Table-Global .ant-table-tbody .Table-Status-Days-Late{
    padding: 0 12px !important;
}
.Modal-Detail-Area-Status .Table-Global .ant-table-thead .ant-table-cell{
    padding: 0 5px 0px 5px !important;
    line-height: 14.52px !important;
    font-weight: var(--weight-bold) !important;
}
.Modal-Detail-Area-Status .Table-Global .ant-table-cell-scrollbar{
    /* display: none !important; */
}
.Modal-Detail-Area-Status .Table-Global .ant-table-thead .ant-table-cell{
    /* display: flex !important; */
    /* vertical-align: bottom !important;
    padding-bottom: 4px !important; */
    /* text-align: center !important;
    align-items: end !important; */
}
.Modal-Detail-Area-Status .Table-Container-Actions-Global{
    /* display: none !important; */
    /* padding-bottom: 0 !important; */
}
.Modal-Detail-Area-Status .Table-Global .ant-table{
    line-height: 1.1 !important;
}
.Container-Status{
    padding-top: 36px;
}
.Container-Button-Status{
    border-bottom: 1px solid #EFEBE6;
    display: flex;
    column-gap: 14px;
    background-color: #fff;
    padding-top: 11px;
    padding-bottom: 7px;
    min-height: 42px;
}
.Button-Status{
    background-color: #EEFFF9;
    color: var(--green);
    border-radius: 19px;
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 16.94px;
    font-weight: var(--weight-bold);
    border: 1px solid rgb(42, 210, 149, .4);
    cursor: pointer;
    padding: 2px 22px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: 0.5s;
}
.Button-Status.Active-Status-Button{
    background-color: var(--green);
    color: var(--white);
}
.Button-Status  span{
    z-index: 1;
}
.Button-Status .Text-Span{
    transform: translate(0, 0);
    transition: transform .7s, opacity .5s;
    opacity: 1;
}
.Button-Status:hover .Text-Span, .Button-Status.Active-Status-Button .Text-Span{
    transform: translate(0, -100%);
    transition: transform .7s, opacity .5s;
    opacity: 0;
}
.Button-Status .Text-Span-Table-Status{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    color: #FFFFFF;
    transition: transform .4s, opacity .5s;
}
.Button-Status:hover .Text-Span-Table-Status, .Button-Status.Active-Status-Button .Text-Span-Table-Status{
    transform: translate(0, 0) !important;
    transition: transform .7s, opacity .5s !important;
    opacity: 1 !important;
}
.Button-Status .Circle-Button-Table-Status{
    position: absolute;
    height: 360%;
    width: 100%;
    top: 100%;
    left: 0;
    border-radius: 100%;
    transition: top 0.8s;
}
.Button-Status .Circle-Button-Table-Status{
    background-color: var(--green);
}
.Button-Status:hover .Circle-Button-Table-Status, .Button-Status.Active-Status-Button .Circle-Button-Table-Status{
    top: -140%;
    z-index: 0;
}
.Create-Profile-Text-Main{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-weight: var(--weight-bold) !important;
    font-size: 20px !important;
    line-height: 24.2px;
    margin: 4px 0 !important;
}
.Create-Profile-Text-Description, .Create-Profile-Text-Title{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue) !important;
    margin-bottom: 12px !important;
    font-weight: var(--weight-regular) !important;
}
.Create-Profile-Text-Title{
    font-weight: var(--weight-bold) !important;
    margin-bottom: 4px !important;
}
.ant-form-vertical.Create-Profile-Form{
    margin-top: 22px !important;
}
.Create-Profile-Form .Search-Profile{
    margin-bottom: 12px;
}
.Create-Profile-Form .Search-Profile .ant-form-item-label{
    padding: 0 !important;
    display: flex;
}
.Create-Profile-Form label, .Form-Type-Profile label, .Collapse-Create-Profile label{
    color: var(--blue-elec) !important;
    font-family: var(--font-family) !important;
    font-size: 11px !important;
    line-height: 13.31px;
}
.Create-Profile-Form .Search-Profile label{
    padding-bottom: 8px;
    font-weight: var(--weight-regular);
}
.Create-Profile-Form .ant-form-item-label{
    padding: 8px 0  !important;
}
.Create-Profile-Form .Search-Profile .ant-input{
    padding: 8px 12px 7px 12px;
    border-color: rgba(176, 191, 236, .5);
    border-radius: 8px;
}
.Create-Profile-Form .Search-Profile .ant-input:hover, .Create-Profile-Form .Search-Profile .ant-input:focus{
    border-color: rgba(176, 191, 236, .5);
    box-shadow: none;
}
.Create-Profile-Form .Search-Profile .ant-input{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 22.54px;
    color: var(--blue);
}
.Create-Profile-Form .Group-Check-List{
    display: flex;
    column-gap: 30px;
}
.Create-Profile-Form .Group-Check-List .ant-checkbox-wrapper{
    margin-inline-start: 0 !important;
}
.Create-Profile-Container-Left{
    padding-right: 36px;
}
@media screen and (max-width: 991px) {
    .Create-Profile-Container-Left{
        padding-right: 0;
    }
}
.Edit-Profile-Text-Description-Users{
    color: #3B72FF;
    text-decoration: underline;
    text-underline-offset: 3px;
    font-size: 12px;
    font-family: var(--font-family);
}
.Edit-Profile-Container-Left{
    font-weight: 600 !important;
}
.Create-Profile-Text-Select{
    display: flex;
    margin-bottom: 20px !important;
}
.Create-Profile-Text-Select p{
    cursor: pointer;
    margin-bottom: 0 !important;
}
.Modal-Notification-List-Users-Profile .ant-modal-content{
    border-radius: 20px !important;
    padding: 15px 59px;
    display: flex;
    justify-content: center;
}
.Modal-Container-Text-List-Users-Profile .Modal-Container-Text-Main h1{
    display: flex;
    justify-content: center;
    color: var(--green) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 14.52px;
}
.Modal-Container-Text-List-Users-Profile .Modal-Container-Text-Main .ant-typography{
    margin-bottom: 0;
    margin-top: 0;
}
.Modal-Container-Text-List-Users-Profile .Modal-Container-Text-Description *{
    display: flex;
    justify-content: center;
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-regular) !important;
    font-weight: var(--weight-regular) !important;
}
.Modal-Container-Text-Description-Profile{
    display: flex;
    justify-content: center;
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-regular) !important;
    font-weight: var(--weight-regular) !important;
}
.Modal-Container-List-Users-Profile{
    margin-top: 4px;
    display: flex;
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    line-height: 14.52px;
    font-weight: var(--weight-regular) !important;
}
.User-Disabled-Profile{
    color: gray !important;
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
}
.User-Active-Profile{
    color: var(--blue) !important;
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
}
.Form-Item-Level-Countries{
    margin-bottom: 0 !important;
}
.Form-Item-Level-Countries .ant-form-item-label{
    padding-bottom: 0 !important;
    display: flex;
}
.Form-Item-Level-Countries .ant-form-item-label label{
    font-family: var(--font-family) !important;
    color: var(--blue-elec) !important;
    font-size: 11px !important;
    line-height: 13.31px;
}
.Container-Checkbox-Level-Countries .ant-checkbox .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.Container-Checkbox-Level-Countries{
    padding: 0 0 0 12px;
    position: relative;
}
.Container-Checkbox-Level-Countries .ant-checkbox-group-item span:nth-child(2){
    font-family: var(--font-family);
    font-size: 12px !important;
    line-height: 22.54px;
    color: var(--blue) !important;
    padding-left: 4px;
    padding-right: 0 !important;
}
.Container-Checkbox-Level-Countries .ant-checkbox-group-item .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
    background-color: var(--white) !important;
}
.Container-Checkbox-Level-Countries .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Container-Checkbox-Level-Countries .ant-checkbox-checked .ant-checkbox-inner::after{
    border-color: var(--white) !important;
}
.Container-Checkbox-Level-Countries .ant-checkbox-checked::after{
    border: 0 !important;
}
.Container-Checkbox-Level-Countries .ant-checkbox-wrapper-in-form-item{
    margin-right: 30px !important;
    margin-inline-start: 0 !important;
}
@media screen and (max-width: 575px) {
    .Container-Checkbox-Level-Countries .ant-checkbox-wrapper-in-form-item{
        margin-right: 10px !important;
    }
}
.Form-Item-Modules-Profile-Types{
    margin-bottom: 11px;
    position: relative;
}
.Header-Colapse-Select-Module .Text-Header-Collapse-Select-Module{
    font-family: var(--font-family) !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: var(--blue) !important;
    margin-left: 12px;
}
.Header-Colapse-Select-Module .ant-checkbox .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
}
.Header-Colapse-Select-Module .ant-checkbox-disabled .ant-checkbox-inner::after{
    border-color:  var(--white) !important;
}
.Header-Colapse-Select-Module .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Container-All-User-Modules .Checkbox-SelectAll-Select-Module{
    display: flex;
    align-items: center;
}
.Container-All-User-Modules .Checkbox-SelectAll-Select-Module .ant-checkbox-wrapper{
    top: 0;
}
.Container-All-User-Modules .Checkbox-SelectAll-Select-Module span:nth-child(2):not(.ant-checkbox span:nth-child(2)){
    margin-top: 5px;
}
.Checkbox-SelectAll-Select-Module .ant-checkbox .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.Checkbox-SelectAll-Select-Module{
    margin-left: 12px;
    margin-bottom: 5px;
}
.Checkbox-SelectAll-Select-Module .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: var(--white) !important;
}
.Checkbox-SelectAll-Select-Module :nth-child(2){
    font-family: var(--font-family) !important;
    font-size: 11px !important;
    line-height: 13.31px;
    color: var(--blue-elec) !important;
    font-weight: var(--weight-regular) !important;
    padding-inline-end: 0;
    padding-inline-start: 12px;
}
.Form-Type-Profile .Checkbox-SelectAll-Select-Module :nth-child(2){
    color: rgb(59, 114, 255, .5) !important;
}
.Checkbox-SelectAll-Select-Module .ant-checkbox .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
}
.Checkbox-SelectAll-Select-Module .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Checkbox-SelectAll-Select-Module .ant-checkbox-checked::after{
    border: 0 !important;
}
.Collapse-Select-Module-Profile-Type{
    margin-top: 8px !important;
    background-color: var(--white);
}
.Collapse-Select-Module-Profile-Type .ant-checkbox-checked .ant-checkbox-inner::after{
    border-color: var(--white) !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-expand-icon{
    padding-inline-end: 0 !important;
    padding: 15px 8px !important;
    border-radius: 100% !important;
    transition: 0.3s;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-header:hover .ant-collapse-expand-icon{
    background-color: rgba(237, 242, 255, 1) !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content{
    border-top: 0 !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content-box{
    margin-inline-start: 0 !important;
    padding: 2px 0 !important;
    display: flex !important;
    flex-direction: column !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content-box label{
    margin-inline-start: 0 !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content-box .ant-checkbox-wrapper-in-form-item:hover .ant-checkbox-wrapper :nth-child(2){
    color: #3B72FF !important;
    font-weight: 400 !important;
    padding-inline-start: 12px !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content-box .ant-checkbox-wrapper :nth-child(2){
    font-family: var(--font-family) !important;
    font-weight: 400 !important;
    color: var(--blue) !important;
    font-size: 14px !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content .ant-checkbox-wrapper-in-form-item:hover {
    background-color: #EDF2FF !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content .ant-checkbox-wrapper-in-form-item .ant-checkbox-inner {
    border: 2px solid rgba(42, 210, 149, 0.5) !important;

}
.Collapse-Select-Module-Profile-Type .ant-collapse-content .ant-checkbox-wrapper-in-form-item:hover .ant-checkbox-inner {
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content .ant-checkbox-wrapper-in-form-item .ant-checkbox-checked:after{
    border: none !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-header .ant-checkbox-checked:after{
    border: none !important;
}
.Collapse-Select-Module-Profile-Type  .ant-checkbox-inner:after {
    inset-inline-start: 16.5% !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content .ant-checkbox-wrapper-in-form-item{
    padding-left: 14px !important;
    padding-bottom: 4px;
    padding-top: 4px;
}
.Collapse-Select-Module-Profile-Type .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-header{
    padding: 7px 16px 7px 14px !important;
    align-items: center !important;
}
.Collapse-Select-Module-Profile-Type :is(.ant-collapse-item.ant-collapse-item-active) .ant-collapse-header{
    padding: 8px 16px 0 14px !important
}
.Collapse-Select-Module-Profile-Type :is(.ant-collapse-item.ant-collapse-item-active) .ant-collapse-expand-icon{
    transform: rotate(180deg) !important;
}
.Collapse-Select-Module-Profile-Type .ant-collapse-content-box .ant-checkbox-wrapper-in-form-item :not(:nth-child(1)){
    padding-inline-start: 12px !important
}
.Form-Item-Checkbox-Countries-Permissions{
    margin-bottom: 6px;
}
.Form-Item-Checkbox-Countries-Permissions .ant-form-item-label{
    padding-bottom: 4px !important;
    display: flex;
}
.Container-Row-Buttons-Countries-Permissions{
    display: flex;
}
.Container-Row-Buttons-Country-Permissions-Selected{
    border-bottom: 1px solid rgba(176, 191, 236, 0.5) !important;
}
.Container-Button-Country-Permissions{
    margin-right: 20px;
}
.Container-Button-Country-Permissions-Selected{
    border-bottom: 3px solid var(--blue-elec);
}
.Form-Item-Checkbox-Countries-Permissions .ant-form-item-label label{
    font-family: var(--font-family) !important;
    color: var(--blue-elec) !important;
    font-size: 11px !important;
    line-height: 13.31px;
}
.Button-Country-Permissions-Disabled{
    pointer-events: none;
}
.Button-Country-Permissions{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    border: 1px solid rgba(42, 210, 149, 0.4);
    width: 71px;
    height: 30px;
    -webkit-user-select: none;
            user-select: none;
    background-color: rgba(238, 255, 249, 1);
    color: rgba(42, 210, 149, 0.4);
    margin-bottom: 8px;
}
.Country-Permissions-Selected{
    background-color: var(--blue-elec) !important;
    color: var(--white) !important;
    margin-bottom: 5px;
}
.Country-Permissions-Added{
    background-color: var(--light-blue);
    border-color: var(--blue-elec);
    color: var(--blue-elec);
    transition: 0.2s background-color, 0.2s border-color, 0.2s color;
}
.Country-Permissions-Disabled{
    pointer-events: none;
}
@media screen and (max-width: 575px) {
    .Container-Button-Country-Permissions{
        margin-right: 15px;
    }    
}
.ProfileTypesFilterSelect-Container-Items .ant-checkbox-checked .ant-checkbox-inner{
    border: 2px solid #3B72FF !important;
}
.ProfileTypesFilterSelect-Container-Items .ant-checkbox-inner{
    border: 2px solid #013A81 !important;
}
.ProfileTypesFilterSelect-Container-Items.Theme-Green .ant-checkbox-checked .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
}
.ProfileTypesFilterSelect-Container-Items.Theme-Green .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
    width: 14px;
    height: 14px;
}
.ProfileTypesFilterSelect-Container-Items.Theme-Green:hover .ant-checkbox-checked .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
}
.Container-Items-Active .ant-checkbox-inner{
    border: 2px solid #3B72FF !important;
}
.ProfileTypesFilterSelect-Container-Items{
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-left: 5px !important;
}
.ProfileTypesFilterSelect-Container-Items-DropDown{
    display: flex;
    /* background-color: yellow; */
    align-items: center;
    border-radius: 8px;
    /* cursor: pointer; */
    position: relative;
    width:35px !important;
    height: 25px !important;
    margin: 2px 0;
}
.Simple-Table-Global .ant-table-thead .ant-table-selection-column .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
}
.Container-Items-Active{
    background-color: #EDF2FF;
}
.ProfileTypesFilterSelect-Container-Items:hover {
    /* background-color: #EDF2FF; */
}
.ProfileTypesFilterSelect-Container-Items:hover .ant-checkbox-inner{
    border: 2px solid #3B72FF !important;
}
.ProfileTypesFilterSelect-Container-Items.Theme-Green:hover .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
}
.ProfileTypesFilterSelect-Container-Items .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.ProfileTypesFilterSelect-Button-Hidden{
    display: none !important;
}
.ProfileTypesFilterSelect-Button{
    border-radius: 0 8px 8px 0;
    color: #2AD295;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    padding: 12px 2px 12px 0;
}
.ProfileTypesFilterSelect-Button:hover{
    /* background-color: #EDF2FF !important; */
}
.ProfileTypesFilterSelect-Option{
    padding: 0px 8px !important;
}
.ProfileTypesFilterSelect-Option:hover{
    background-color: var(--light-blue);
}
.ProfileTypesFilterSelect-Option{
    color: #013A81;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
    cursor: pointer;
}
.ProfileTypesFilterSelect-Option:hover{
    color: #3B72FF;
}
.ProfileTypesFilterSelect-Option .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #013A81 !important;
    border-color: #013A81 !important;
}
.ProfileTypesFilterSelect-Option:hover .ant-checkbox-checked .ant-checkbox-inner{
    border-color: #013A81 !important;
}
.ProfileTypesFilterSelect-PopOver {
    padding-top: 0 !important;
    left: 105px !important;
}
.ProfileTypesFilterSelect-PopOver .ant-popover-inner{
    padding: 10px 0 !important;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
}
.Container-Type-Profile{
    padding-top: 42px !important;
}
.ProfileTypes-Row-Actions{
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Title-Type-Profile{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    font-weight: var(--weight-bold) !important;
    margin: 0 !important;
}
@media screen and (max-width: 991px) {
    .Title-Type-Profile{
        padding-top: 20px;
    }
}
.Home-Administrator-Container-Card{
    padding: 36px 37px;
    background-color: var(--cream);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.Home-Administrator-Title-Card{
    font-size: 14px !important;
    line-height: 16.94px !important;
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-weight: var(--weight-bold) !important;
    margin-bottom: 4px !important;
}
.Home-Administrator-Container-Text-Card{
    width: 301px;
}
.Home-Administrator-Text-Card{
    color: var(--blue);
    font-family: var(--font-family);
    font-size: 11px;
    line-height: 13.31px;
    margin-bottom: 19px !important;
}
.Home-Administrator-Container-Link-Card{
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    cursor: pointer;
}
.Home-Administrator-Container-Arrow-Card{
    padding: 0 0 0 17px;
    display: block;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
}
.Home-Administrator-Link-Card span{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue-elec);
    margin-bottom: 0;
}
.Home-Administrator-Link-Card{
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
}
.Home-Administrator-Link-Card::after{
    width: calc(100% + 37px);
    content: '';
    height: 1px;
    background-color: var(--blue-elec);
    display: block;
    position: relative;
}
.Home-Administrator-Container-Arrow-Card img{
    position: relative;
    width: 18px;
    height: auto;
}
.Home-Administrator-Container-Link-Card:hover .Home-Administrator-Link-Card::after{
    animation-name: underline-animation;
    animation-duration: 0.7s;
    animation-timing-function: linear;
}
.Home-Administrator-Container-Link-Card:hover .Home-Administrator-Container-Arrow-Card img{
    animation-name: arrow-animation;
    animation-duration: 0.7s;
    animation-timing-function: linear;
}
@keyframes arrow-animation {
    0% {
        left: 0;
    }
    25%{
        left: 20%;
        width: 80%;
        opacity:1;
    }

      50%{
        width: 0px;
        left: 100%;
        opacity: 0;
      }

      60%{
        width: 0;
        left: 0;
      }
      75% {
        opacity: 1;
        width: 50%;
        left: 0;
      }
      90%{
        width: 75%;
        left: 0;
      }
      100% {
        width: 100%;
        left: 0;
      }
}
@keyframes underline-animation {
    0% {
        left: 0;
    }
    25%{
        left: 20%;
        width: 100%;
        opacity:1;
    }
    25%{
        left: 30%;
        width: 110%;
        opacity:1;
    }

      50%{
        width: 0px;
        left: 130%;
      }

      60%{
        width: 0;
        left: 0;
      }
      75% {
        width: 50%;
        left: 0;
      }
      85% {
        width: 70%;
        left: 0;
      }
      90%{
        width: 90%;
        left: 0;
      }
      100% {
        width: 130%;
        left: 0;
      }
}
@media screen and (max-width: 600px) {
    .Home-Administrator-Col-Container{
        padding: 10px 0;
    }
    .Home-Administrator-Container-Text-Card {
      width: 100%;
  }
}
.Container-Home-Administrador{
    padding-top: 42px;
    padding-bottom: 20px;
}
.Home-Administrator-Container-Title{
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}
.Home-Administrator-Title{
    font-family: var(--font-family);
    font-size: 20px !important;
    line-height: 24.2px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-bold) !important;
    margin-bottom: 0 !important;
}
.Container-Avatars-Dashboards div{
    width: 100%;
    margin-right: 0px;
    transition: margin-right 0.3s;
}
.ant-avatar-group:hover span{
    margin-right: 5px;
    transition: margin-right 0.3s;
}
.Container-Avatars-Dashboards div:hover{
    width: 100%;
    
}
.Container-Dashboards .Title-Metrics-Dashboards{
    font-family: var(--font-family);
    font-size: var(--size-medium);
    line-height: var(--lineheight-medium);
    font-weight: var(--weight-bold);
    color: var(--blue);
}
.Container-Dashboards .Paragraph-Metrics-Dashboards{
    font-family: var(--font-family);
    line-height: var(--lineheight-regular);
    color: var(--blue);
    margin-bottom: 0;
}
.Class-Power-Bi-Embebed{
    width: 100%;
    height: 1000px;
    position: relative;
}
.Disguise-Iframe-Footer{
    position: absolute;
    width: 100%;
    height: 40px;
    background-color: var(--white);
    bottom: 0;
}
.Container-Loading-Route-Dashboard{
    width: 35% !important;
}
.Container-Dashboards  .Text-Route-Dashboard{
    font-family: var(--font-family);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    font-weight: var(--weight-semibold);
    color: rgba(1, 58, 129, .4);
    margin: 0;
}
.Container-Dashboards .Current-Path-Item{
    color: rgba(1, 58, 129, .4);
    cursor: pointer;
}
.Container-Dashboards .Current-Path-Item:hover{
    border-bottom: 1px solid var(--blue-elec);
    color: var(--blue-elec);
}
.Container-Dashboards .Current-Path{
    color: var(--blue-elec);
    position: relative;
}
.Text-Route-Dashboard .Current-Path::after{
    content: "";
    position: absolute;
    width: 6px;
    height: 15px;
    background-color: var(--white);
    border-top-left-radius: 100%;
    border-bottom-right-radius: 100%;
    z-index: 1;
    left: 0px;
    animation: efecto-menu-dashboard 5s ease-in-out infinite !important;
}
#Iframe-Dashboard{
    width: 100% !important;
    /* max-height: 1000 !important; */
    height: 1180px !important;
    overflow-y: scroll;
}
#Iframe-Dashboard .displayArea {
    width: 100vw !important;
}
@keyframes efecto-menu-dashboard{
    0%{
        left: 0;
        opacity: 1;
    }
    100%{
      opacity: 1;
      left: 600px;
    }
}
.Container-Dashboards .Current-Path:hover{
    border-bottom: 1px solid var(--blue-elec);
}
.Create-Data-Control-Container-Button-Action{
    display: flex !important;
    flex-direction: row !important;
    margin-top: 5px !important;
}
.Create-Data-Control-Form-Container-Button{
    border-radius: 27px !important;
    padding: 15px 31px !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
    height: 38px !important;
    width: 83px !important;
}
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Cancel .Text-Span-Cancel-Create-Data-Control{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    transition: .7s;
}
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Cancel:hover .Text-Span-Cancel-Create-Data-Control{
    transform: translate(0, 0) !important;
    transition: .7s !important;
    opacity: 1 !important;
}
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Cancel{
    background-color: #ffffff !important;
    border: 2px solid rgba(42, 210, 149, 0.4);
    color: var(--green);
}
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Cancel:focus, 
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Cancel:hover{
    color: var(--green) !important;
}
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Cancel span:first-child{
    transform: translate(0, 0);
    transition: .7s;
    opacity: 1;
}
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Cancel:hover span:first-child{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.Create-Data-Control-Container-Button-Action .Create-Data-Control-Form-Button-Save{
    background-color: var(--green) !important;
    color: #ffffff;
    border: none;
    margin-left: 23px !important;
}
.Create-Data-Control-Form-Item-Text .ant-form-item-label label{
    font-size: var(--size-small) !important;
    font-family: var(--font-family) !important;
    color: rgba(59, 114, 255, 0.8) !important;
}
.Create-Data-Control-Form-Item-Text .ant-form-item-label{
    margin-bottom: -3px !important;
}
.Create-Data-Control-Form-Item-Text{
    width: 100% !important;
    margin-bottom: 12px !important;
}
.Container-Button-Create-Upload span{
    font-size: 12px !important;
}
.Create-Data-Control-Form-Item-Text .ant-input{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    border: 1px solid #D7DEF5 !important;
    height: 38px !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-select{
    width: 100% !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-select .ant-btn{
    font-family: var(--font-family) !important;
    font-family: 14px !important;
    font-weight: var(--weight-regular) !important;
    width: 100% !important;
    height: 45px !important;
    border: 1px dashed rgba(176, 191, 236, 0.5) !important;
    color:rgba(1, 58, 129, 0.4) !important;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.Create-Data-Control-Form-Item-Text .ant-upload-select .ant-btn:hover{
    border: 1px dashed var(--blue-elec) !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item{
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item:hover{
    background-color: #ffffff;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item-done .ant-upload-icon{
    display: none !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item-done .ant-upload-list-item-name{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    font-size: var(--size-regular) !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item-done .ant-upload-list-item-actions .ant-upload-list-item-action{
    opacity: 1 !important;
    
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item-done .ant-upload-list-item-actions .ant-upload-list-item-action{
    display: flex;
    align-items: center;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item-done .ant-upload-list-item-actions:hover .ant-upload-list-item-action{
    background-color: #ffffff !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item-done .ant-upload-list-item-actions .ant-upload-list-item-action span{
    content: url('../../Assets/Images/Global/cancel-icon-upload.svg') !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-select:hover .ant-btn span{
    color: var(--blue-elec) !important;
}
.Create-Data-Control-Form-Item-Text .ant-upload-list-text .ant-upload-list-item-error .ant-upload-icon{
    display: none !important;
}
.Container-Select-Country .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border: var(--green) !important;
}
.Container-Select-Country .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
}
.Container-Select-Country div {
    display: flex;
    column-gap: 5px;
    color: var(--blue) !important;
}
.Container-Select-Country {
    padding: 0px 10px !important;
}
.Select-Country-Items .ant-select-selection-item{
    background-color: var(--light-blue) !important;
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    height: 30px !important;
    display: flex;
    align-items: center;
}
.Select-Country-Items .ant-select-selector{
    height: 37px !important;
}
.Container-Option-Select-Country:hover .Title-Option-Select-Country{
    background-color: var(--light-blue) !important;

}
.Title-Option-Select-Country{
    /* width: 150px; */
    padding: 0 15px;
    border-radius: 5px;
    font-size: 12px !important;
    cursor: pointer;
}
.Create-Data-Control-Form-Item-Text .Button-Create-Upload .ant-btn{
    height: 116px !important;
    /* height: 37px !important; */
}
.Create-Data-Control-Form-Item-Text .Button-Create-Upload:hover .ant-btn span{
    color: var(--blue) !important;
}
.Collapse-Create-Data-Control .ant-collapse-content-box{
    padding: 5px !important;
}
.Header-Create-Data-Control .ant-collapse-content.ant-collapse-content-active{
    border-top: #FFFFFF 1px solid !important;
}
.Collapse-Create-Data-Control .Header-Create-Data-Control .ant-collapse-header{
    height: 37px !important;
    background-color: #FFFFFF !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    display: flex;
    align-items: center;
}
.Placeholder-Select-Country{
    color:  rgba(1, 58, 129, 0.4);
    font-family: var(--font-family) !important;
    font-size: 12px;
}
.Container-List-Countries-Selected{
    display: flex !important;
    column-gap: 5px;
}
.Box-Country-Selected{
    background-color: var(--light-blue) !important;
    padding: 1px 8px;
    border-radius: 4px;
    column-gap: 5px;
    display: flex !important;
    align-items: center !important;
    color: var(--blue) !important;
    font-size: 12px !important;
}
.Container-File-Upload-List{
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    margin-top: 5px !important;
    height: 20px !important;
    font-size: 12px;
}
.Button-Create-Upload .ant-upload-list{
    display: none !important;
}
.Create-Data-Control-Form-Item-Text .ant-form-item-explain-error{
    font-size: 12px !important;
}
.Modal-Create-Data-Control .ant-modal-body {
    display: flex !important;
    justify-content: center !important;
}
.Modal-Create-Data-Control .Modal-Description {
    color: var(--blue);
    font-size: 12px;
}
.Modal-Create-Data-Control .ant-modal-close {
    display: none !important;
}
.Modal-Create-Data-Control .ant-modal-content {
    border-radius: 20px;
}
.Modal-Create-Data-Control .ant-modal-header{
    display: flex;
    justify-content: center;
    margin-bottom: 3px !important;
}
.Modal-Create-Data-Control .ant-modal-title{
    font-size: 12px !important;
}
.Modal-Create-Data-Control .Text-Alert{
    color: red !important;
    font-size: 12px !important;
    
}
.Modal-Create-Data-Control .Text-Ok{
    color: var(--green) !important;
    font-size: 12px !important;
}
.Modal-Create-Data-Control .Button-Close{
    height: 38px !important;
    width: 49px !important;
    display: flex !important;
    color : var(--white) !important;
    font-family: var(--font-family) !important;
    border-radius: 20px !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
    font-weight: 600 !important;
}
.Modal-Create-Data-Control {
    top: 30vh !important;
}
.Modal-Create-Data-Control.Modal-Small{
    width: 250px !important;
}
.Modal-Create-Data-Control .Button-Ok{
    background-color: var(--green) !important;
    font-size: 12px !important;
}
.Modal-Create-Data-Control .Button-Alert{
    background-color: red !important;    
}
.Modal-Description{
    line-height: 1.2 !important;
    text-align: center !important;
}
.Text-Import-Data-Control{
    margin-right: 8px !important;
    color: var(--blue-elec) !important;
    text-decoration: underline;
    text-underline-offset: 3px !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-content{
    margin-block-start: 0 !important;
    line-height: 16px;
    font-family: var(--font-family) !important;
    color: var(--blue) !important;
}
.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title +.ant-modal-confirm-content{
    max-width: 100% !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-btns{
    display: flex;
    justify-content: center;
    column-gap: 15px !important;
    /* padding: 0 10px; */
}
.Modal-Confirm-Data-Control .ant-modal-confirm-btns .ant-btn{
    border-radius: 20px !important;
    font-family: var(--font-family) !important;
    height: 37px !important;
    font-size: 12px !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-btns .ant-btn{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 38px !important;
    padding: 12px !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-btns .ant-btn-default{
    color: var(--green) !important;
    border: 1px var(--green) solid !important;
    display: flex !important;
    text-align: center !important;
    justify-content: center !important;
}
.Modal-Confirm-Data-Control{
    display: flex;
    justify-content: center;
    top: 30vh !important;
}
.Modal-Confirm-Data-Control .ant-modal-content{
    border-radius: 20px !important;
    width: 264px !important;
    padding: 20px 20px !important;
    font-size: 12px !important;
}
.Modal-Confirm-Data-Control.Modal-Semi-large .ant-modal-confirm-title{
    font-size: 12px !important;
}
.Modal-Confirm-Data-Control.Modal-Semi-large .ant-modal-content{
    width: 280px !important;
}
.Modal-Confirm-Data-Control.Modal-Semi-large .ant-modal-confirm-btns{
    padding: 0 20px;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-body{
    display: flex !important;
    justify-content: center !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-title span{
    font-size: 12px !important;
    font-weight: 600 !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-content{
    text-align: center;
    font-size: 12px !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-title{
    color: var(--green) !important;
    font-size: 12px;
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    line-height: 1 !important;
    margin-bottom: 2px !important;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-content .Text-Message-Primary{
    font-size: 12px !important;
    color: var(--blue) !important;
    margin-bottom: 0;
}
.Modal-Confirm-Data-Control .ant-modal-confirm-content .Text-Message-Secondary{
    font-size: 11px !important;
    color: var(--blue) !important;
    margin-bottom: 0;
    font-weight: var(--weight-regular);
}
.Switch-Status-Data-Control .ant-switch-handle{
    width: 10px !important;
    height: 10px !important;
}
.Switch-Status-Data-Control{
    background-color: var(--white) !important;
    border: 1px solid var(--blue) !important;
    display: flex !important;
    align-items: center !important;
    height: 15px !important;
}
.Switch-Status-Data-Control .ant-switch-handle{
    top: 1px !important;
}
.Switch-Status-Data-Control .ant-switch-handle::before{
    background-color: var(--blue) !important;
}
.Container-Card-Download{
    background-image: url('../../Assets/Images/DownloadData/download-data.gif');
    width: 100%;
    height: 71vh;
    background-repeat: no-repeat;
    background-position: 27% 60%;
    background-size: 38% 110%;
    position: relative;
}
.Container-Card-Download .Container-Item-Download{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s;
    position: relative;
}
.Container-Overflow-Item-Download{
    position: absolute;
    width: 600px;
    height: 68vh;
    transform: translate(54vw, 4vh);
    overflow: hidden;
    top: -4vh;
}
.Container-Card-Item-Download{
    position: relative;
}
.Button-Scroll{
    position: absolute;
    background: var(--green);
    border-radius: 23px;
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    color: var(--white);
    padding: 5px 10px;
    height: auto;
    border-color: transparent;
    box-shadow: none;
    will-change: animation;
    z-index: 2;
}
.Button-Scroll:hover{
    color: #ffffff !important;
    border-color: transparent !important;
}
.Button-Scroll::before{
    content: '';
    position: absolute;
    display: flex;
    width: 90px;
    height: 32px;
    border: 1px solid var(--green);
    border-radius: 23px;
    top: -4px;
    left: -4px;
    animation: Pulsation-Border-Button 3.5s infinite;
}
@keyframes Pulsation-Border-Button{
    0%{
        transform: scale(1);
        border-color: var(--green);
    }
    20%{
        transform: scale(1.2);
        border-color: transparent;
    }
    21%{
        transform: scale(1);
        border-color: var(--green);
    }
    40%{
        transform: scale(1.2);
        border-color: transparent;
    }
    45%{
        transform: scale(1);
        border-color: transparent;
    }
    100%{
        transform: scale(1);
        border-color: transparent;
    }
}
.Dispel-Button{
    animation: dispel-button .7s forwards;
}
.Appear-Button{
    animation: appear-button .7s forwards;
}
@keyframes dispel-button{
    from{
        transform: translate(45vw, -5vh) scale(1);
    }
    to{
        transform: translate(45vw, -5vh) scale(0);
    }
}
@keyframes appear-button{
    from{
        transform: translate(45vw, -5vh) scale(0);
    }
    to{
        transform: translate(45vw, -5vh) scale(1);
    }
}
.Container-Card-Download .Container-Item-Download.Container-Active-Item-Download{
    transition: 1s;
}
.Container-Card-Download .Container-Circle-Download{
    border: 2px solid var(--blue-elec);
    border-radius: 50%;
    animation: Pulsation-Border 3.5s infinite;
    position: absolute;
    transform: translate(0, 0);
}
@keyframes Pulsation-Border{
    0%{
        padding: 0;
        border-color: var(--blue-elec);
    }
    20%{
        padding: 15px;
        border-color: transparent;
    }
    21%{
        padding: 0;
        border-color: var(--blue-elec);
    }
    40%{
        padding: 15px;
        border-color: transparent;
    }
    45%{
        padding: 0;
        border-color: transparent;
    }
    100%{
        padding: 0;
        border-color: transparent;
    }
}
.Container-Card-Download .Circle-Download{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid var(--blue-elec);
    background-color: var(--blue-elec);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: .5s;
}
.Container-Card-Download .Container-Circle-Download:hover .Circle-Download, .Container-Active-Item-Download .Container-Circle-Download .Circle-Download{
    transition: .5s;
    width: 80px;
    height: 80px;
    background-color: var(--green);
}
.Container-Card-Download .Text-Circle-Download{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: var(--white);
    opacity: 0;
    transition: .5s;
}
.Container-Card-Download .Icon-Circle-Download{
    color: var(--white);
    opacity: 0;
    transition: .5s;
    font-size: 18px;
}
.Container-Card-Download .Container-Circle-Download:hover .Icon-Circle-Download, .Container-Active-Item-Download .Container-Circle-Download .Icon-Circle-Download{
    transition: .5s;
    opacity: 1;
}
.Container-Card-Download .Container-Circle-Download:hover .Text-Circle-Download, .Container-Active-Item-Download .Container-Circle-Download .Text-Circle-Download{
    transition: 1.7s;
    opacity: 1;
}
.Container-Card-Download .Text-Download{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
    color: var(--blue);
    margin-bottom: 0;
    width: 200px;
    transition: 1s;
}
.Container-Text-Download{
    position: relative;
    transform: translate(135px, 0);
}
.Container-Text-Download span{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 30px;
    height: 100%;
    background-color: rgb(255, 255, 255, .7);
    animation: 1.5s text-download-data forwards;
    animation-delay: .7s;
}
@keyframes text-download-data{
    0%{
        left: 0;
        opacity: 1;
    }
    99%{
        left: 100%;
    }
    100%{
        left: calc(100% + 30px);
        opacity: 1;
    }
}
.Container-Card-Download .Text-Download.Text-Active-Download{
    color: var(--green);
    transform: translate(30px, 0);
    transition: 1s;
}
@media screen and (max-width: 781px) {
    .Container-Card-Download{
        background-position: 15% 60%;
    }
    .Container-Overflow-Item-Download{
        transform: translate(40vw, 4vh);
    }
  }
.Col-Button{
    display: flex;
    justify-content: space-between;
}
.Container-Button-Excel{
    display: flex;
    column-gap: 15px;
}
.Button-Excel{
    font-family: var(--font-family);
    padding: 12px 20px;
    font-size: var(--size-regular);
    background-color: rgb(42, 210, 149, .7);
    color: var(--white);
    outline: none;
    border: 0;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 var(--green);
    border-radius: 40px;
    transition: 1.2s;
}
.Button-Excel:hover{
    box-shadow: inset 200px 0 0 0 var(--green);
    transition: 1.2s;
}
.Button-Excel.Button-Excel-Active{
    box-shadow: inset 200px 0 0 0 var(--green);
}
.Button-Download{
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: var(--size-regular);
    padding-right: 12px;
    padding-left: 6px;
    height: 40.09px !important;
    border-radius: 40px;
    color: var(--green) !important;
    background-color: var(--white);
    border: 1px solid var(--green) !important;
    outline: none;
    cursor: pointer;
    transition: background-color .5s;
}
.Button-Download:hover{
    background-color: var(--green);
    color: var(--white) !important;
    transition: background-color .5s;
}
.Button-Download span:nth-child(2){
    transition: .5s;
    margin-left: 8px;
}
.Button-Download:hover span:nth-child(2){
    transform: translateX(-30px);
    transition: .5s;
}
.Button-Download .Icon-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: .5s;
    position: relative;
    z-index: 2;
}
.Button-Download .Icon-Download{
    color: var(--white);
}
.Button-Download:hover .Icon-Container{
    transform: translateX(84px);
    background-color: var(--white);
    transition: .5s;
}
.Button-Download:hover .Icon-Download{
    color: var(--green);
}
.DatePicker-Filter-Download-Data-Internal .ant-picker-body{
    padding: 0 !important;
}
.DatePicker-Filter-Download-Data-Internal ,
.DatePicker-Filter-Download-Data-Internal .ant-picker-body,
.DatePicker-Filter-Download-Data-Internal .ant-picker-header{
    width: 250px !important;
}
.DatePicker-Filter-Download-Data-Internal .ant-picker-content td{
    width: 30px !important;
}
.DatePicker-Filter-Download-Data-Internal .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}
@media screen and (max-width: 684px) {
    .Col-Filter-Date-Download-Data-Internal{
        margin-top: 15px;
    }
  }
.Disabled-Card-File{
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--white);
    opacity: .4;
}
.Card-File{
    background-color: var(--cream) !important;
    border-radius: 20px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Card-File .ant-card-cover{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0 9px 0;
}
.Card-File .Image-Card-File{
    width: 97px !important;
    height: 97px !important;
    border-radius: 50% !important;
}
.Card-File .ant-upload-list{
    display: none !important;
}
.Card-File .ant-card-meta-avatar{
    padding-inline-end: 7px;
}
.Card-File .ant-card-meta{
    align-items: center !important;
    margin: 0;
}
.Card-File .Container-Error-Card-File{
    width: 97px;
    height: 97px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
}
.Card-File .Container-Error-Border{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid red;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}
.Card-File .Container-Error-Body-Card-File{
    width: 63px;
    height: 63px;
    background-color: red;
    border-radius: 50%;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Card-File .Container-Icon-Card-File{
    border: 2px solid var(--blue-elec);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.Card-File .Title-Error, .Card-File .Text-Error{
    font-family: var(--font-family);
    text-align: center;
    color: red;
    font-size: 11.06px;
    line-height: 13.39px;
    padding: 0 8px;
}
.Card-File .Title-Error{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    margin-bottom: 5px;
}
.Card-File .Title-Loading-Card-File{
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
    color: var(--blue);
    font-weight: var(--weight-bold);
    font-size: 12px;
}
.Card-File .Text-Loading-Card-File{
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
    color: var(--blue);
    font-weight: var(--weight-regular);
    font-size: 12px;
}
.Card-File .Container-Icon-Card-File .Image-Icon-Card-File{
    position: absolute;
    z-index: 2;
    font-size: 12px;
    font-weight: bold;
    color: var(--blue-elec);
}
.Card-File .Container-Icon-Card-File:hover .Image-Icon-Card-File{
    color: var(--white);
}
.Card-File .Container-Icon-Card-File::after{
    content: '';
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    width: 24px;
    height: 24px;
    animation: quitarFondo .3s linear;
}
.Date-Card{
    padding: 0 !important;
}
.Date-Card .ant-picker-input{
    display: none !important;
}
.Card-File .Container-Icon-Card-File:hover::after{
    background-color: var(--blue-elec);
    animation: agregarFondo .3s linear;
}
.Card-File .ant-card-body{
    padding: 0px 14px 15px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.Card-File .ant-card-body::before, .Card-File .ant-card-body::after{
    display: none;
}
.Card-File.Sending-File .ant-card-body{
    display: block;
    padding-top: 25px;
}
.Card-File .ant-card-meta .ant-upload-wrapper{
    display: flex;
    align-items: center;
}
.Card-File .ant-card-meta-detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Card-File .ant-card-meta-title{
    font-family: var(--font-family);
    margin-bottom: 0 !important;
    font-size: 11.6px;
    line-height: 13.39px;
    font-weight: var(--weight-semibold);
    color: var(--blue);
    white-space: break-spaces;
}
.Card-File .ant-card-meta-description{
    font-family: var(--font-family);
    font-size: 11.6px;
    line-height: 13.39px;
    color: var(--blue);
}
.Link-Template-File-Upload:hover{
    text-decoration: underline !important;
}
.Link-Template-File-Upload{
    font-size: 10px;
    font-family: var(--font-family);
    color: var(--blue-elec) !important;
    text-align: center;
    text-decoration: underline !important;
    text-underline-offset: 0.2em !important;
    padding-top: 10px;
}
.Card-File .Container-Tag-Card-File{
    text-align: center;
    margin-top: 3px;
    padding: 0 6px;
    min-height: 42px;
}
.Card-File .Linear-Tag-Card-File{
    display: block;
    height: 1px;
    background-color: var(--lead);
}
.Card-File .Linear-Tag-Card-File-Hidden{
    display: hidden;
    height: 1px;
    background-color: var(--lead);
}
.Card-File .Tag-Card-File{
    display: flex;
    align-items: center !important;
    margin-inline-end: 0;
    padding-inline: 0;
    padding: 7px 0 7px 7px;
    background: transparent;
    border: 0;
    border-radius: 0;
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    font-weight: var(--weight-regular);
    color: var(--blue-elec) !important;
    position: relative;
    width: 100%;
}
.Card-File .Tag-Card-File.Tag-Inactive{
    display: none !important;
}
.Card-File .Container-Message-Error{
    margin-bottom: 10px;
}
.Container-SendFile-Success{
    font-family: var(--font-family);
    font-size: 11px;
    display: flex;
    justify-content: center;
    line-height: var(--lineheight-regular);
    font-weight: var(--weight-bold);
    color: var(--blue);
    padding: 12px 20px 28px 20px;
    text-align: center;
}
.Card-File .Tag-Card-File span{
    font-family: var(--font-family) !important;
    font-size: 11.06px;
    line-height: 13.39px;
    color: var(--blue-elec);
    overflow: auto;
    white-space: pre-line;
}
.Card-File .Tag-Card-File span:hover{
    color: var(--blue-elec);
}
.Card-File .Tag-Card-File span:nth-child(1){
    margin-right: 13px;
}
.Card-File .Tag-Card-File span:nth-child(2){
    position: absolute;
    top: 8px;
    right: 2px;
}
.Button-Card-File{
    height: auto;
    border-radius: 30px;
    border: 0;
    font-family: var(--font-family);
    padding: 7px 0;
    width: 40%;
    font-size: 12px;
    line-height: 14.52px;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--green);
    margin-bottom: 0;
    color: var(--white);
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    font-weight: 600;
}
.Button-Card-File.Send-Another:hover{
    border: 0.15rem solid var(--green);
}
.Button-Card-File.Send-Another{
    background-color: transparent;
    color: var(--green);
    border: 0.15rem solid var(--green) !important;
    font-weight: 600;
}
.Button-Card-File.Button-Error{
    width: 50%;
}
.Button-Card-File span{
    z-index: 1;
}
.Button-Card-File .Circle-Button-Card-File{
    position: absolute;
    height: 360%;
    width: 100%;
    top: 100%;
    border-radius: 100%;
    transition: top 0.8s;
}
.Button-Card-File .Circle-Button-Card-File{
    background-color: #1BA875;
}
.Button-Card-File.Send-Another .Circle-Button-Card-File{
    background-color: var(--green);
}
.Button-Card-File:hover .Circle-Button-Card-File{
    top: -140%;
    z-index: 0;
}
.Button-Card-File:hover:focus, 
.Button-Card-File:hover:hover{
    color: var(--white) !important;
}
.Button-Card-File{
    position: relative;
    cursor: pointer;
}
.Button-Card-File .Text-Span{
    transform: translate(0, 0);
    transition: .7s;
    opacity: 1;
}
.Button-Card-File:hover .Text-Span{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.Button-Card-File .Text-Span-Card-File{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    color: #FFFFFF;
    transition: .4s;
}
.Button-Card-File:hover .Text-Span-Card-File{
    transform: translate(0, 0) !important;
    transition: .7s !important;
    opacity: 1 !important;
}
.DatePicker-File-Upload .ant-picker-body{
    padding: 0 !important;
}
.DatePicker-File-Upload ,
.DatePicker-File-Upload .ant-picker-body,
.DatePicker-File-Upload .ant-picker-header{
    width: 250px !important;
}
.DatePicker-File-Upload .ant-picker-content td{
    width: 30px !important;
}
.DatePicker-File-Upload .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}
@keyframes AnimacionHover{
    from{ 
        opacity: 0;
    }
    to { 
        opacity : 10;
    }
}
@keyframes agregarFondo{
    from{ 
        transform: scale(0); 
    }
    to{ 
        transform: scale(1);
    }
}
@keyframes quitarFondo{
    from{
        transform: scale(1);
        background-color: var(--blue-elec);
    }to{
        transform: scale(0);
        background-color: var(--blue-elec);
    }
}
.Card-Notification-File{
    position: fixed;
    background-color: #EDF2FF;
    border-radius: 20px 20px 20px 20px;
    overflow: hidden auto;
    margin-right: 60px;
    width: 26.5%;
    max-width: 26.5%;
    right: 0;
    bottom: 0;
    top: 92px;
    height: 80vh;
    margin-bottom: 50px;
    transition: top 0.3s ease-in-out;
}
.Card-Notification-File .ant-card-body{
    height: 97%;
}
.Card-Notification-File::-webkit-scrollbar{
    width: 8px;
}
.Card-Notification-File .Container-Not-Notification{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100% !important;
}
.Card-Notification-File .Image-Not-Notification{
    width: 50px;
}
.Card-Notification-File .Text-Not-Notification{
    font-family: 'Inter';
    font-size: 14px;
    line-height: 19px;
    color: #ACAFB7;
}
.Card-Notification-File::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 6px;
}
.Card-Notification-File::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.Card-Notification-File .Title-Notificacion-File{
    font-family: var(--font-family);
    text-align: center;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: var(--weight-bold);
    color: var(--blue);
    margin-bottom: 15px;
}
.Card-Notification-File .ant-card-body{
    padding: 17px 29px 21px 28px;
}
.Container-Error-Notification .Button-Deploy-Notification-File{
    background-color: #FFF1F1 !important;
}
.Container-Error-Notification{
    background-color: #FFF1F1 !important;
}
.Container-Head-Notification-File-Upload img{
    width: 25px;
    height: 25px;
}
.Container-Head-Notification-File-Upload .Title-Notificacion-File{
    margin-bottom: 0;
}
.Container-Head-Notification-File-Upload{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3px;
    margin-bottom: 8px;
}
@media screen and (max-width: 1440px) {
    .Card-Notification-File{
        width: 26%;
        max-width: 26%;
    }
}
@media screen and (max-width: 1370px) {
    .Card-Notification-File{
        width: 25.7%;
        max-width: 25.7%;
    }
}
@media screen and (max-width: 1199px) {
    .Card-Notification-File{
        width: 33%;
        max-width: 33%;
    }
}
@media screen and (max-width: 1100px) {
    .Card-Notification-File{
        width: 32.5%;
        max-width: 32.5%;
    }
}
@media screen and (max-width: 1020px) {
    .Card-Notification-File{
        width: 32%;
        max-width: 32%;
    }
}
@media screen and (max-width: 991px) {
    .Card-Notification-File{
        position: relative;
        max-width: 100%;
        width: 100%;
        border-radius: 20px;
        margin-right: 0;
        top: 0;
    }
}
@media screen and (max-width: 575px) {
    .Card-Notification-File::-webkit-scrollbar{
        width: 8px;
    }
}
.Card-Item-Notification-File{
    border-radius: 20px;
    border: 2px solid var(--lead);
    margin-bottom: 8px;
    min-height: 71px;
}
.Card-Item-Notification-File.Unread-Notification{
    border: 2px solid var(--blue-elec);
}
.Card-Item-Notification-File .Notifications-Colums li::marker {
    background: var(--blue) !important;
    content: "•"; 
    color: var(--blue) !important;
}
.Card-Item-Notification-File .Notifications-Colums{
    font-size: 12px;
    font-family: var(--font-family);
    color: var(--blue);
    font-weight: 500;
    line-height: 17px;
}
.Card-Item-Notification-File .Title-Correcto-Upload{
    font-weight: bold;
    color: #004FB8;
    font-family: 'Inter';
}
.Card-Item-Notification-File .Title-Errors{
    font-family: 'Inter';
    color: #FF3742;
    font-weight: bold;
}
.Card-Item-Notification-File .Title-Correcto-Upload-Message, .Card-Item-Notification-File .Title-Errors-Message{
    font-size: 12px;
    font-weight: bold;
}
.Card-Item-Notification-File .Title-Errors-Message{
    color: #FF3742;
    font-size: 12px;
}
.Card-Item-Notification-File.Card-Effect-Remove{
    animation: RemoveCard 1.5s;
}
.Card-Item-Notification-File .ant-card-body{
    padding: 14px 24px 14px 18px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Card-Item-Notification-File .ant-card-body::after, .Card-Item-Notification-File .ant-card-body::before{
    display: none;
}
.Card-Item-Notification-File p{
    font-family: var(--font-family);
    font-size: 12px !important;
    line-height: 14.52px;
    font-weight: var(--weight-regular) !important;
    color: var(--blue);
    margin-right: 20px;
    margin-bottom: 10px;
}
.Card-Item-Notification-File .See-Notification-File{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    color: var(--blue-elec);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);   
    text-decoration: underline;
    cursor: pointer;
}
.Card-Item-Notification-File .Button-Deploy-Notification-File{
    position: absolute;
    top: 9px;
    right: 6px;
    height: auto !important;
    border: 0;
    padding: 3px 6.5px;
    border-radius: 50%;
    box-shadow: none;
}
/* .Container-Error-Notification.Container-Error-Notification .Button-Deploy-Notification-File:hover{
    background-color: #ff37413b !important;
}  */
.Card-Item-Notification-File .Button-Deploy-Notification-File:hover{
    background-color: #EDF2FF !important;
}
.Card-Item-Notification-File .Icon-Deploy-Notification-File{
    font-size: 15px;
    color: var(--blue);
}
.Card-Item-Notification-File .Icon-Deploy-Notification-File:hover{
    color: var(--blue);
}
.Menu-Notification-File .ant-dropdown-menu{
    padding: 4px 0;
    box-shadow: 0 4px 4px 0 #D9D9D9;
}
.Menu-Notification-File .ant-dropdown-menu-item{
    border-radius: 0 !important;
    padding: 0 8px !important;
}
.Menu-Notification-File .ant-dropdown-menu-item.ant-dropdown-menu-item-active{
    background-color: #EDF2FF !important;
}
.Menu-Notification-File .Delete-Notification-File{
    font-family: var(--font-family);
    line-height: 23.8px;
    font-size: 12px;
    color: var(--blue-elec);
    display: flex;
    align-items: center;
    column-gap: 7px;
}
.Container-Footer-Item-Notification .Container-Date-Notification-Upload-File{
    font-family: var(--font-family);
    font-weight: 600;
    display: flex;
    font-size: var(--size-small);
    justify-content: end;
    color: rgba(1, 58, 129, 0.5);
    margin-right: 10px;
}
.Container-Footer-Item-Notification{
    display: flex;
    justify-content: space-between;
}
.Card-Item-Notification-File .State-Notification-File{
    position: absolute;
    top: 19px;
    right: 15px;
    background-color: var(--blue-elec);
}
.Card-Item-Notification-File .Date-Notification-File{
    position: absolute;
    bottom: 14px;
    right: 24px;
    font-size: var(--size-small);
    font-weight: var(--weight-semibold);
    line-height: var(--lineheight-small);
    font-family: var(--font-family);
    color: rgba(1, 58, 129, .5);
}
@keyframes RemoveCard {
    0%{
        transform: translate(0,0);
    }
    100%{
        transform: translate(500px,0);
    }
}
.Container-File-Upload{
    padding-top: 42px;
}
.Container-File-Upload h2{
    margin-bottom: 0 !important;
}
.Title-Card-File-Upload{
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: var(--weight-bold);
    line-height: 19.36px;
    color: var(--blue);
    margin-bottom: 4px;
    margin-left: 10px;
}
.Barra-Home{
    position: absolute;
    bottom: 100vh;
    right: 20px;
    width: 30px;
    height: 100px;
}
.Barra-Home::before{
    content: '';
    display: block;
    width: 2px;
    height: 85px;
    background-color: var(--blue);
    position: absolute;
    right: calc(50% - .5px);
    bottom: 10px;
}
.Barra-Home::after{
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--blue);
    position: absolute;
    right: calc(50% - 4px);
    top: 20px;
    animation: animateBarra 1.8s infinite ease-in-out;
}
@keyframes animateBarra {
    0%{
        top: 10px;
        opacity: 1;
    }
    50%{
        opacity: 1;
    }
    65%{
        top: 80px;
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}
.Container-Item-Benefit-Home{
    position: relative;
    display: flex;
    color: #3B72FF;
    background-color: transparent;
    overflow: hidden;
}
.Container-Item-Benefit-Home .Title-Benefit-Home{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: var(--size-medium);
    line-height: var(--lineheight-medium);
    color: var(--blue);
}
.Container-Item-Benefit-Home .Container-Image-Benefit-Home{
    display: flex;
    flex-direction: column;
}
.Container-Item-Benefit-Home .Container-Image-Benefit-Home .Box-Image-Benefit-Home{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3B72FF;
    padding: 25px 40px;
    border-radius: 20px;
    min-width: 200px;
    min-height: 200px;
}
.Container-Item-Benefit-Home.Active-Item{
    color: #333;
    background-color: transparent;
}
.Container-Item-Benefit-Home.Active-Item .Title-Benefit-Home{
    color: #3B72FF;
}
.Container-Item-Benefit-Home p{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: var(--size-regular);
    line-height: var(--lineheight-regular);
    color: var(--blue);
    display: flex;
    padding: 5px 0 5px 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    position: absolute;
    opacity: 0;
    margin-top: 29px;
    min-height: 200px;
    height: 200px;
}
.Container-Item-Benefit-Home.Active-Item p{
    position: relative;
    transition: .5s;
    transition-delay: .5s;
    opacity: 1;
}
.Container-Card-Categories{
    border: 0;
    width: 120px;
    height: 120px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
}
.Container-Card-Categories:hover{
    border-radius: 50%;
}
.Container-Card-Categories .Container-Round{
    width: 145px;
    height: 145px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category 10s linear infinite;
}
.Container-Card-Categories:hover .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    position: relative;
}
.Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 15px;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Container-Card-Categories:hover .Card-Background{
    transform: scale3d(1,1,1) rotate(90deg);
    border-radius: 50%;
    transition: .5s;
}
.Container-Card-Categories.Shadow-Bluesky:hover .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Container-Card-Categories.Shadow-Yellow:hover .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Container-Card-Categories.Shadow-Purple:hover .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Container-Card-Categories .Image-Item-Categories{
    width: 72px;
    height: auto;
    top: 2px;
    position: absolute;
    z-index: 3;
}
.Container-Card-Categories .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--cream-light);
    margin-bottom: 0;
    position: absolute;
    z-index: 3;
    bottom: 17px;
    width: 96px;
}
.Carrousel-Home.swiper {
    width: 100%;
    height: 100%;
}
.Carrousel-Home .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}
.Carrousel-Home .swiper-pagination{
    left: -30px;
    text-align: end;
    color: var(--blue);
    font-weight: var(--weight-bold);
}
.Carrousel-Home .swiper-pagination .swiper-pagination-current{
    font-size: 20px;
}
.Container-Home .Title-Home{
    color: var(--white) !important;
    font-size: 70px !important;
    font-weight: var(--weight-bold) !important;
    line-height: var(--lineheight-big);
    font-family: var(--font-family-poppins);
    margin-top: 0 !important;
}
.Container-Home .Title-Home-Mobile{
    display: none;
    color: var(--blue) !important;
    font-size: 70px !important;
    font-weight: var(--weight-bold) !important;
    line-height: var(--lineheight-big);
    font-family: var(--font-family-poppins);
    text-align: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
.Consultar-Soporte-Intrologin{

    position: fixed;
    /* background-color: green;
    width: 20px;
    height: 20px; */
    bottom: 60px;
    right: 40px;
    display: flex;
    cursor: pointer;
    z-index: 3;
}
.Icono-Soporte{

    width: 60px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 52px;
    background: #000000 !important;
    transform: rotate(-1.79deg);
}
.Nube-Contactar-Soporte{
    margin-top: 13px;
    margin-right: 2px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: #004FB8;

    width: 88.15px;
    height: 35.44px;
    background: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 26px 26px 0px 26px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.Consultar-Soporte-Intrologin:hover{
    animation: reboteSoporte .8s ease;
}
.Container-Home .Card-Height-Home{
    background-color: transparent;
    border: 0 !important;
}
.Container-Text-Effect{
    display: inline-block;
    height: 80px;
    width: 380px;
    max-width: 380px;
    overflow: hidden;
    position: relative;
    transform: translate(15px, 23px);
    text-align: start;
}
.Text-Effect{
    -webkit-text-stroke: 1px var(--green);
    color: transparent !important;
    position: absolute;
}
.Text-Effect-Slider-1{
    animation: TextAnimationSlider 4s linear infinite;
}
.Text-Effect-Slider-2{
    animation: TextAnimationSlider2 4s linear infinite;
}
@keyframes TextAnimationSlider{
    0%{
        transform: translate(0, 0);
        visibility: visible;
    }
    25%{
        transform: translate(0, -70px);
        visibility: hidden;
    }
    50%{
        transform: translate(0, 65px);
        visibility: hidden;
    }
    75%{
        transform: translate(0, 0);
        visibility: visible;
    }
    100%{
        transform: translate(0, 0);
        visibility: visible;
    }
}
@keyframes TextAnimationSlider2{
    0%{
        transform: translate(0, 65px);
        visibility: visible;
    }
    25%{
        transform: translate(0, 0);
        visibility: visible;
    }
    50%{
        transform: translate(0, 0);
        visibility: visible;
    }
    75%{
        transform: translate(0, -70px);
        visibility: hidden;
    }
    100%{
        transform: translate(0, 65px);
        visibility: hidden;
    }
}
@keyframes titleUp{
    0%{
        transform-origin: left bottom;
        transform: rotate(45deg);
        opacity: 0;
    }
    0%,to{
        -webkit-transform-origin: left bottom;
    }
    40%{
        opacity: 0;
    }
    to{
        transform-origin: left bottom;
        transform: none;
        opacity: 1;
    }
}
.titleUp{
    animation-name: titleUp;
    animation-duration: 1s;
}
.Container-Home .Button-Home{
    font-family: var(--font-family);
    background-color: var(--green);
    border-radius: 37px;
    height: auto;
    width: 211px;
    border: 0;
    padding: 9px 7px 9px 20px;
    font-size: 12px;
    color: var(--white);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: var(--weight-semibold);
    line-height: 14.52px;
}
.Container-Home .Button-Home:focus, .Container-Home .Button-Home:hover{
    color: var(--white) !important;
}
.Container-Home .Icon-Button-Home{
    background-color: var(--white);
    border: 1px solid var(--green);
    color: var(--green);
    padding: 4px;
    font-size: 14px;
    border-radius: 50%;
    transition: .5s;
}
.Container-Home .Button-Home span:first-child{
    transition: .5s;
}
.Container-Home .Button-Home:hover span:first-child{
    transform: translate(41px, 0);
    transition: .5s;
}
.Container-Home .Button-Home:hover .Icon-Button-Home{
    transform: translate(-170px, 0);
    transition: .5s;
    background-color: var(--green);
    border: 1px solid var(--white);
    color: var(--white);
}
.Container-Row-Home{
    padding: 0 60px;
}
.Card-Container-Home{
    background-color: var(--cream);
    border-radius: 24px;
}
.Title-Card-Home{
    color: var(--blue) !important;
    font-family: var(--font-family);
    text-align: center;
    font-weight: var(--weight-bold) !important;
    font-size: var(--size-mediumbig) !important;
    line-height: var(--lineheight-mediumbig) !important;
    margin-bottom: 40px !important;
}
.Title-Card-Home.View-Title-Category span::after{
    content: "";
    position: absolute;
    width: 20px;
    height: 35px;
    /* background-color: red; */
    background-color: var(--white);
    opacity: 0.9 !important;
    z-index: 1;
    animation-name: efecto-menu-title-category;
    animation-duration: 3.2s !important;
    animation-delay: 4s !important;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.animated{
    animation-duration:1s;
    animation-fill-mode:both
}
.animated.infinite{
    animation-iteration-count:infinite
}
.animated.hinge{
    animation-duration:2s
}
.animated.bounceIn,.animated.bounceOut,.animated.flipOutX,.animated.flipOutY{
    animation-duration:.75s
}
@keyframes opacityCard{
    0%{
        opacity:0;
        transform:translate3d(0,10%,0);
    }
    50%{
        opacity: .7;
        transform:translate3d(0,0,0);
    }
    100%{
        opacity:1;
        transform:none;
    }
}
.opacityCard{
    animation-name:opacityCard;
    animation-duration: 1.2s;
}
.opacityCard0{
    animation-name:opacityCard;
    animation-duration: 1.2s;
}
.opacityCard1{
    animation-name:opacityCard;
    animation-duration: 1.2s;
    animation-delay: .2s;
}
.opacityCard2{
    animation-name:opacityCard;
    animation-duration: 1.2s;
    animation-delay: .4s;
}
.opacityCard3{
    animation-name:opacityCard;
    animation-duration: 1.2s;
    animation-delay: .6s;
}
@keyframes cardRight{
    0%,20%,100%{animation-timing-function:cubic-bezier(.215,.61,.355,1);
    }
    0%{
        opacity:0;
        transform:translate3d(3000px,0,0);
    }
    20%{
        opacity:0;
    }
    100%{
        transform:none;
    }
}
.cardRight1, .cardRight3, .cardRight5{
    animation-name:cardRight;
    animation-duration: 1.5s;
}
.cardRight3{
    animation-duration: 1.7s;
}
.cardRight5{
    animation-duration: 1.9s;
}
@keyframes cardLeft{
    0%,20%,100%{animation-timing-function:cubic-bezier(.215,.61,.355,1);
    }
    0%{
        opacity:0;
        transform:translate3d(-3000px,0,0);
    }
    20%{
        opacity:0;
    }
    100%{
        transform:none;
    }
}
.cardLeft0, .cardLeft2, .cardLeft4, .cardLeft6{
    animation-name:cardLeft;
    animation-duration: 1.5s;
}
.cardLeft2{
    animation-duration: 1.7s;
}
.cardLeft4{
    animation-duration: 1.9s;
}
.cardLeft6{
    animation-duration: 2.1s;
}
.Card-Container-Benefit-Home{
    display: flex;
    justify-content: start;
    column-gap: 15px;
    flex-wrap: wrap;
}
.Card-Container-Benefit-Home .Container-Item-Benefit{
    width: calc(20% - 15px);
    min-width: calc(20% - 15px);
    transition: all .5s ease-in;
}
.Card-Container-Benefit-Home .Container-Item-Benefit.Active-Container{
    width: calc(40% - 15px);
    min-width: calc(40% - 15px);
}
@media screen and (max-width: 991px) {
    .Container-Home .Title-Home{
        text-align: center;
        margin-bottom: 0 !important;
    }
    .Container-Home .Button-Home.Button-Responsive-Home{
        display: none;
    }
    .Container-Row-Home{
        padding: 0 32px;
    }
    .Container-Benefit-Cards-NotCard :nth-child(2n+1) .Card-Container-Benefit-Home{
        transform: translate(0px, 0px) !important;
    }
    .Card-Container-Benefit-Home{
        margin: 10px;
    }
}
@media screen and (max-width: 720px) {
    .Container-Home .Title-Home{
        padding: 0;
    }
}
@media screen and (max-width: 575px) {
    .Container-Home .Title-Home{
        display: none;
    }
    .Container-Home .Title-Home-Mobile{
        display: block !important;
    }
    .Card-Container-Home{
        border-radius: 51px;
    }
    .Container-Row-Home{
        padding: 0;
    }
    .Container-Home .Card-Height-Home{
        height: 535px;
    }
}
@media screen and (max-width: 527px) {
    .Container-Home .Title-Home-Mobile .Container-Text-Effect{
        transform: translate(0, 10px);
    }
    .Container-Home .Title-Home-Mobile .Container-Text-Effect .Text-Effect{
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
@media screen and (max-width: 425px) {
    .Container-Home .Image-Card-Home{
        width: 70px;
        margin-right: 10px;
    }
}
@media screen and (max-width: 360px) {
    .Container-Home .Image-Card-Home{
        margin-right: 5px;
    }
}
@media screen and (max-width: 300px) {
    .Container-Home .Image-Card-Home{
        width: 60px;
        margin-right: 5px;
        margin-left: 5px;
    }
}
@keyframes efecto-menu-title-category{
    0%{
      left: 120px;
    }
    50%{
      left: 850px;
      opacity: 0.4;
    }
    100%{
      left: 851px;
      opacity: 1;
    }
}
@keyframes reboteSoporte {
    0%{
        transform: translate(0, 0);
    }20%{
        transform: translate(0, -3.5vh);
    }40%{
        transform: translate(0, 0);
    }60%{
        transform: translate(0, -2vh);
    }75%{
        transform: translate(0, 0);
    }90%{
        transform: translate(0, -.8vh);
    }100%{
        transform: translate(0, 0);
    }
}
.Module-Tabs .ant-tabs-content-holder{
    margin-left: 0;
    border-left: 0;
}
.Module-Tabs .Title-Module{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 14px;
    line-height: 28.66px;
    color: var(--blue);
}
.Module-Tabs .Title-Module:hover{
    color: var(--green);
}
.Module-Tabs .ant-tabs-nav{
    align-items: flex-start;
    min-width: 200px !important;
}
.Module-Tabs .ant-tabs-extra-content{
    position: absolute;
    top: 102px;
}
.Module-Tabs .ant-tabs-nav-operations{
    display: none !important;
}
.Module-Tabs .ant-tabs-nav-list{
    margin-top: 131px;
}
.Module-Tabs .ant-tabs-ink-bar{
    display: none;
}
.Module-Tabs .ant-tabs-tabpane{
    padding-left: 161px !important;
}
.Module-Tabs .ant-tabs-tab{
    padding: 0 !important;
    margin-top: 0 !important;
}
.Module-Tabs .Title-Module-Tabs{
    font-family: var(--font-family);
    font-size: var(--size-small);
    font-weight: var(--weight-semibold);
    line-height: 28.82px;
    color: var(--blue);
    display: flex;
    align-items: center;
}
.Module-Tabs .ant-tabs-tab .ant-tabs-tab-btn{
    width: 100%;
}
.Module-Tabs .ant-tabs-tab-active .Title-Module-Tabs, .Module-Tabs .ant-tabs-tab:hover .Title-Module-Tabs{
    color: var(--green);
}
.Module-Tabs .ant-tabs-tab .Icon-Title-Module-Tabs{
    display: flex;
    width: 0px;
    height: 6px;
    border-radius: 1px;
    background-color: var(--green);
    margin-right: 0;
}
.Circle-Big-Categories{
    position: absolute;
    z-index: -2;
    border-radius: 50%;
    top: -200px;
    left: calc(50vw - 609px);
    width: 1200px;
    height: 1200px;
    border: 1px solid rgba(59, 114, 255, .3);
}
.Circle-Big-Categories-Spheres-X{
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: Animation-Rotate 25s linear infinite;
}
.Circle-Big-Categories-Spheres-X::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
.Circle-Big-Categories-Spheres-X::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    bottom: -5px;
    left: calc(50% - 5px);
}
.Circle-Big-Categories-Spheres-Y{
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: Animation-Rotate 25s linear infinite;
}
.Circle-Big-Categories-Spheres-Y::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    bottom: calc(50% - 5px);
    right: -5px;
}
.Circle-Big-Categories-Spheres-Y::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    bottom: calc(50% - 5px);
    left: -5px;
}
.Container-Categories{
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.Container-Categories .Item-Categories{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 750px;
    height: 750px;
    border-radius: 50%;
    border: 1px solid rgba(59, 114, 255, .3);
}
.Item-Categories-Round, .Subitem-Categories-Round{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: Animation-Rotate 20s linear infinite;
}
.Subitem-Categories-Round{
    animation-duration: 15s;
}
.Item-Categories-Round::before, .Subitem-Categories-Round::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
.Item-Categories-Round .Item-Item-Categories-Round, .Item-Categories-Round .Subitem-Subitem-Categories-Round{
    position: relative;
    width: 100%;
    height: 100%;
}
.Item-Categories-Round .Item-Item-Categories-Round::before, .Subitem-Subitem-Categories-Round::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    bottom: calc(50% - 5px);
    right: -5px;
}
.Item-Categories-Round .Item-Item-Categories-Round::after, .Subitem-Subitem-Categories-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    bottom: calc(50% - 5px);
    left: -5px;
}
.Item-Categories-Round::after, .Subitem-Categories-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    bottom: -5px;
    left: calc(50% - 5px);
}
@keyframes Animation-Rotate{
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.Container-Categories .Subitem-Categories{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 450px;
    border-radius: 50%;
    border: 1px solid rgba(59, 114, 255, .3);
}
.View-Title-Category{
    animation: View-Title-Category 1.5s forwards;
    animation-delay: 1.5s;
    opacity: 1;
    margin-bottom: 0 !important;
}
.Categories-18 .View-Title-Category, .Categories-17 .View-Title-Category, .Categories-16 .View-Title-Category, .Categories-15 .View-Title-Category, .Categories-14 .View-Title-Category, .Categories-13 .View-Title-Category, .Categories-12 .View-Title-Category, .Categories-11 .View-Title-Category, .Categories-10 .View-Title-Category, .Categories-9 .View-Title-Category, .Categories-8 .View-Title-Category, .Categories-7 .View-Title-Category, .Categories-6 .View-Title-Category, .Categories-5 .View-Title-Category, .Categories-4 .View-Title-Category, .Categories-3 .View-Title-Category, .Categories-2 .View-Title-Category, .Categories-1 .View-Title-Category{
    animation: View-Title-Category 1.5s forwards;
    animation-delay: 1.5s;
    opacity: 0;
    margin-bottom: 0 !important;
}
@keyframes View-Title-Category{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.Container-Item-0-Categories, .Container-Item-1-Categories, .Container-Item-2-Categories, .Container-Item-3-Categories, .Container-Item-4-Categories, .Container-Item-5-Categories, .Container-Item-6-Categories, .Container-Item-7-Categories{
    position: absolute;
    width: 95px;
    height: 95px;
    z-index: 2;
}
.Categories-18 .Container-Item-0-Categories{
        animation: Effect-Category-18-Item-0 1.5s forwards;
    }
.Categories-18 .Container-Item-1-Categories{
        animation: Effect-Category-18-Item-1 1.5s forwards;
    }
.Categories-18 .Container-Item-2-Categories{
        animation: Effect-Category-18-Item-2 1.5s forwards;
    }
.Categories-18 .Container-Item-3-Categories{
        animation: Effect-Category-18-Item-3 1.5s forwards;
    }
.Categories-18 .Container-Item-4-Categories{
        animation: Effect-Category-18-Item-4 1.5s forwards;
    }
.Categories-18 .Container-Item-5-Categories{
        animation: Effect-Category-18-Item-5 1.5s forwards;
    }
.Categories-18 .Container-Item-6-Categories{
        animation: Effect-Category-18-Item-6 1.5s forwards;
    }
.Categories-18 .Container-Item-7-Categories{
        animation: Effect-Category-18-Item-7 1.5s forwards;
    }
@keyframes Effect-Category-18-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, -230px);
    }
}
@keyframes Effect-Category-18-Item-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-300px, -230px);
    }
}
@keyframes Effect-Category-18-Item-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, -90px);
    }
}
@keyframes Effect-Category-18-Item-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-370px, -90px);
    }
}
@keyframes Effect-Category-18-Item-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, 90px);
    }
}
@keyframes Effect-Category-18-Item-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-370px, 90px);
    }
}
@keyframes Effect-Category-18-Item-6{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, 230px);
    }
}
@keyframes Effect-Category-18-Item-7{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-300px, 230px);
    }
}
.Categories-17 .Container-Item-0-Categories{
        animation: Effect-Category-17-Item-0 1.5s forwards;
    }
.Categories-17 .Container-Item-1-Categories{
        animation: Effect-Category-17-Item-1 1.5s forwards;
    }
.Categories-17 .Container-Item-2-Categories{
        animation: Effect-Category-17-Item-2 1.5s forwards;
    }
.Categories-17 .Container-Item-3-Categories{
        animation: Effect-Category-17-Item-3 1.5s forwards;
    }
.Categories-17 .Container-Item-4-Categories{
        animation: Effect-Category-17-Item-4 1.5s forwards;
    }
.Categories-17 .Container-Item-5-Categories{
        animation: Effect-Category-17-Item-5 1.5s forwards;
    }
.Categories-17 .Container-Item-6-Categories{
        animation: Effect-Category-17-Item-6 1.5s forwards;
    }
@keyframes Effect-Category-17-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, -230px);
    }
}
@keyframes Effect-Category-17-Item-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-300px, -230px);
    }
}
@keyframes Effect-Category-17-Item-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, -90px);
    }
}
@keyframes Effect-Category-17-Item-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-380px, 0);
    }
}
@keyframes Effect-Category-17-Item-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, 90px);
    }
}
@keyframes Effect-Category-17-Item-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, 230px);
    }
}
@keyframes Effect-Category-17-Item-6{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-300px, 230px);
    }
}
.Categories-16 .Container-Item-0-Categories{
        animation: Effect-Category-16-Item-0 1.5s forwards;
    }
.Categories-16 .Container-Item-1-Categories{
        animation: Effect-Category-16-Item-1 1.5s forwards;
    }
.Categories-16 .Container-Item-2-Categories{
        animation: Effect-Category-16-Item-2 1.5s forwards;
    }
.Categories-16 .Container-Item-3-Categories{
        animation: Effect-Category-16-Item-3 1.5s forwards;
    }
.Categories-16 .Container-Item-4-Categories{
        animation: Effect-Category-16-Item-4 1.5s forwards;
    }
.Categories-16 .Container-Item-5-Categories{
        animation: Effect-Category-16-Item-5 1.5s forwards;
    }
@keyframes Effect-Category-16-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, -230px);
    }
}
@keyframes Effect-Category-16-Item-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-300px, -230px);
    }
}
@keyframes Effect-Category-16-Item-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(380px, 0);
    }
}
@keyframes Effect-Category-16-Item-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-380px, 0);
    }
}
@keyframes Effect-Category-16-Item-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, 230px);
    }
}
@keyframes Effect-Category-16-Item-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-300px, 230px);
    }
}
.Categories-15 .Container-Item-0-Categories{
        animation: Effect-Category-15-Item-0 1.5s forwards;
    }
.Categories-15 .Container-Item-1-Categories{
        animation: Effect-Category-15-Item-1 1.5s forwards;
    }
.Categories-15 .Container-Item-2-Categories{
        animation: Effect-Category-15-Item-2 1.5s forwards;
    }
.Categories-15 .Container-Item-3-Categories{
        animation: Effect-Category-15-Item-3 1.5s forwards;
    }
.Categories-15 .Container-Item-4-Categories{
        animation: Effect-Category-15-Item-4 1.5s forwards;
    }
@keyframes Effect-Category-15-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, -230px);
    }
}
@keyframes Effect-Category-15-Item-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-370px, -90px);
    }
}
@keyframes Effect-Category-15-Item-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(380px, 0);
    }
}
@keyframes Effect-Category-15-Item-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-370px, 90px);
    }
}
@keyframes Effect-Category-15-Item-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(300px, 230px);
    }
}
.Categories-14 .Container-Item-0-Categories{
        animation: Effect-Category-14-Item-0 1.5s forwards;
    }
.Categories-14 .Container-Item-1-Categories{
        animation: Effect-Category-14-Item-1 1.5s forwards;
    }
.Categories-14 .Container-Item-2-Categories{
        animation: Effect-Category-14-Item-2 1.5s forwards;
    }
.Categories-14 .Container-Item-3-Categories{
        animation: Effect-Category-14-Item-3 1.5s forwards;
    }
@keyframes Effect-Category-14-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, -90px);
    }
}
@keyframes Effect-Category-14-Item-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-370px, -90px);
    }
}
@keyframes Effect-Category-14-Item-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, 90px);
    }
}
@keyframes Effect-Category-14-Item-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-370px, 90px);
    }
}
.Categories-13 .Container-Item-0-Categories{
        animation: Effect-Category-13-Item-0 1.5s forwards;
    }
.Categories-13 .Container-Item-1-Categories{
        animation: Effect-Category-13-Item-1 1.5s forwards;
    }
.Categories-13 .Container-Item-2-Categories{
        animation: Effect-Category-13-Item-2 1.5s forwards;
    }
@keyframes Effect-Category-13-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, -90px);
    }
}
@keyframes Effect-Category-13-Item-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-380px, 0);
    }
}
@keyframes Effect-Category-13-Item-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(370px, 90px);
    }
}
.Categories-12 .Container-Item-0-Categories{
        animation: Effect-Category-12-Item-0 1.5s forwards;
    }
.Categories-12 .Container-Item-1-Categories{
        animation: Effect-Category-12-Item-1 1.5s forwards;
    }
.Categories-12 .Container-Item-2-Categories{
        animation: Effect-Category-12-Item-2 1.5s forwards;
    }
@keyframes Effect-Category-12-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(380px, 0);
    }
}
@keyframes Effect-Category-12-Item-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-380px, 0);
    }
}
.Categories-11 .Container-Item-0-Categories{
    animation: Effect-Category-11-Item-0 1.5s forwards;
}
@keyframes Effect-Category-11-Item-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(380px, 0);
    }
}
.Container-Subitem-0-Categories, .Container-Subitem-1-Categories, .Container-Subitem-2-Categories, .Container-Subitem-3-Categories, .Container-Subitem-4-Categories, .Container-Subitem-5-Categories, .Container-Subitem-6-Categories, .Container-Subitem-7-Categories, .Container-Subitem-8-Categories, .Container-Subitem-9-Categories{
    position: absolute;
}
.Container-Subitem-0-Categories{
        animation: Effect-Category-Subitem-0 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-1-Categories{
        animation: Effect-Category-Subitem-1 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-2-Categories{
        animation: Effect-Category-Subitem-2 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-3-Categories{
        animation: Effect-Category-Subitem-3 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-4-Categories{
        animation: Effect-Category-Subitem-4 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-5-Categories{
        animation: Effect-Category-Subitem-5 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-6-Categories{
        animation: Effect-Category-Subitem-6 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-7-Categories{
        animation: Effect-Category-Subitem-7 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-8-Categories{
        animation: Effect-Category-Subitem-8 1.5s forwards;
        animation-delay: .5s;
    }
.Container-Subitem-9-Categories{
        animation: Effect-Category-Subitem-9 1.5s forwards;
        animation-delay: .5s;
    }
.Categories-10 .Container-Subitem-0-Categories{
        animation: Effect-Category-Subitem-0 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-1-Categories{
        animation: Effect-Category-Subitem-1 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-2-Categories{
        animation: Effect-Category-Subitem-2 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-3-Categories{
        animation: Effect-Category-Subitem-3 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-4-Categories{
        animation: Effect-Category-Subitem-4 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-5-Categories{
        animation: Effect-Category-Subitem-5 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-6-Categories{
        animation: Effect-Category-Subitem-6 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-7-Categories{
        animation: Effect-Category-Subitem-7 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-8-Categories{
        animation: Effect-Category-Subitem-8 1.5s forwards;
        animation-delay: 0;
    }
.Categories-10 .Container-Subitem-9-Categories{
        animation: Effect-Category-Subitem-9 1.5s forwards;
        animation-delay: 0;
    }
@keyframes Effect-Category-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -220px);
    }
}
@keyframes Effect-Category-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(140px, -180px);
    }
}
@keyframes Effect-Category-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(220px, -65px);
    }
}
@keyframes Effect-Category-Subitem-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(220px, 65px);
    }
}
@keyframes Effect-Category-Subitem-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(140px, 180px);
    }
}
@keyframes Effect-Category-Subitem-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 220px);
    }
}
@keyframes Effect-Category-Subitem-6{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-140px, 180px);
    }
}
@keyframes Effect-Category-Subitem-7{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-220px, 65px);
    }
}
@keyframes Effect-Category-Subitem-8{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-220px, -65px);
    }
}
@keyframes Effect-Category-Subitem-9{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-140px, -180px);
    }
}
.Categories-9 .Container-Subitem-0-Categories{
        animation: Effect-Category-9-Subitem-0 1.5s forwards;
    }
.Categories-9 .Container-Subitem-1-Categories{
        animation: Effect-Category-9-Subitem-1 1.5s forwards;
    }
.Categories-9 .Container-Subitem-2-Categories{
        animation: Effect-Category-9-Subitem-2 1.5s forwards;
    }
.Categories-9 .Container-Subitem-3-Categories{
        animation: Effect-Category-9-Subitem-3 1.5s forwards;
    }
.Categories-9 .Container-Subitem-4-Categories{
        animation: Effect-Category-9-Subitem-4 1.5s forwards;
    }
.Categories-9 .Container-Subitem-5-Categories{
        animation: Effect-Category-9-Subitem-5 1.5s forwards;
    }
.Categories-9 .Container-Subitem-6-Categories{
        animation: Effect-Category-9-Subitem-6 1.5s forwards;
    }
.Categories-9 .Container-Subitem-7-Categories{
        animation: Effect-Category-9-Subitem-7 1.5s forwards;
    }
.Categories-9 .Container-Subitem-8-Categories{
        animation: Effect-Category-9-Subitem-8 1.5s forwards;
    }
@keyframes Effect-Category-9-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -220px);
    }
}
@keyframes Effect-Category-9-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(140px, -180px);
    }
}
@keyframes Effect-Category-9-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(220px, -65px);
    }
}
@keyframes Effect-Category-9-Subitem-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(210px, 90px);
    }
}
@keyframes Effect-Category-9-Subitem-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(80px, 210px);
    }
}
@keyframes Effect-Category-9-Subitem-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-80px, 210px);
    }
}
@keyframes Effect-Category-9-Subitem-6{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-210px, 90px);
    }
}
@keyframes Effect-Category-9-Subitem-7{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-220px, -65px);
    }
}
@keyframes Effect-Category-9-Subitem-8{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-140px, -180px);
    }
}
.Categories-8 .Container-Subitem-0-Categories{
        animation: Effect-Category-8-Subitem-0 1.5s forwards;
    }
.Categories-8 .Container-Subitem-1-Categories{
        animation: Effect-Category-8-Subitem-1 1.5s forwards;
    }
.Categories-8 .Container-Subitem-2-Categories{
        animation: Effect-Category-8-Subitem-2 1.5s forwards;
    }
.Categories-8 .Container-Subitem-3-Categories{
        animation: Effect-Category-8-Subitem-3 1.5s forwards;
    }
.Categories-8 .Container-Subitem-4-Categories{
        animation: Effect-Category-8-Subitem-4 1.5s forwards;
    }
.Categories-8 .Container-Subitem-5-Categories{
        animation: Effect-Category-8-Subitem-5 1.5s forwards;
    }
.Categories-8 .Container-Subitem-6-Categories{
        animation: Effect-Category-8-Subitem-6 1.5s forwards;
    }
.Categories-8 .Container-Subitem-7-Categories{
        animation: Effect-Category-8-Subitem-7 1.5s forwards;
    }
@keyframes Effect-Category-8-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -210px);
    }
}
@keyframes Effect-Category-8-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(160px, -150px);
    }
}
@keyframes Effect-Category-8-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(210px, 0);
    }
}
@keyframes Effect-Category-8-Subitem-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(160px, 150px);
    }
}
@keyframes Effect-Category-8-Subitem-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 210px);
    }
}
@keyframes Effect-Category-8-Subitem-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-160px, 150px);
    }
}
@keyframes Effect-Category-8-Subitem-6{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-210px, 0);
    }
}
@keyframes Effect-Category-8-Subitem-7{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-160px, -150px);
    }
}
.Categories-7 .Container-Subitem-0-Categories{
        animation: Effect-Category-7-Subitem-0 1.5s forwards;
    }
.Categories-7 .Container-Subitem-1-Categories{
        animation: Effect-Category-7-Subitem-1 1.5s forwards;
    }
.Categories-7 .Container-Subitem-2-Categories{
        animation: Effect-Category-7-Subitem-2 1.5s forwards;
    }
.Categories-7 .Container-Subitem-3-Categories{
        animation: Effect-Category-7-Subitem-3 1.5s forwards;
    }
.Categories-7 .Container-Subitem-4-Categories{
        animation: Effect-Category-7-Subitem-4 1.5s forwards;
    }
.Categories-7 .Container-Subitem-5-Categories{
        animation: Effect-Category-7-Subitem-5 1.5s forwards;
    }
.Categories-7 .Container-Subitem-6-Categories{
        animation: Effect-Category-7-Subitem-6 1.5s forwards;
    }
@keyframes Effect-Category-7-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -210px);
    }
}
@keyframes Effect-Category-7-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(180px, -120px);
    }
}
@keyframes Effect-Category-7-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(220px, 40px);
    }
}
@keyframes Effect-Category-7-Subitem-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(110px, 200px);
    }
}
@keyframes Effect-Category-7-Subitem-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-110px, 200px);
    }
}
@keyframes Effect-Category-7-Subitem-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-220px, 40px);
    }
}
@keyframes Effect-Category-7-Subitem-6{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-180px, -120px);
    }
}
.Categories-6 .Container-Subitem-0-Categories{
        animation: Effect-Category-6-Subitem-0 1.5s forwards;
    }
.Categories-6 .Container-Subitem-1-Categories{
        animation: Effect-Category-6-Subitem-1 1.5s forwards;
    }
.Categories-6 .Container-Subitem-2-Categories{
        animation: Effect-Category-6-Subitem-2 1.5s forwards;
    }
.Categories-6 .Container-Subitem-3-Categories{
        animation: Effect-Category-6-Subitem-3 1.5s forwards;
    }
.Categories-6 .Container-Subitem-4-Categories{
        animation: Effect-Category-6-Subitem-4 1.5s forwards;
    }
.Categories-6 .Container-Subitem-5-Categories{
        animation: Effect-Category-6-Subitem-5 1.5s forwards;
    }
.Categories-6 .Container-Subitem-6-Categories{
        animation: Effect-Category-6-Subitem-6 1.5s forwards;
    }
@keyframes Effect-Category-6-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -210px);
    }
}
@keyframes Effect-Category-6-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(200px, -100px);
    }
}
@keyframes Effect-Category-6-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(200px, 100px);
    }
}
@keyframes Effect-Category-6-Subitem-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 210px);
    }
}
@keyframes Effect-Category-6-Subitem-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-200px, 100px);
    }
}
@keyframes Effect-Category-6-Subitem-5{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-200px, -100px);
    }
}
.Categories-5 .Container-Subitem-0-Categories{
        animation: Effect-Category-5-Subitem-0 1.5s forwards;
    }
.Categories-5 .Container-Subitem-1-Categories{
        animation: Effect-Category-5-Subitem-1 1.5s forwards;
    }
.Categories-5 .Container-Subitem-2-Categories{
        animation: Effect-Category-5-Subitem-2 1.5s forwards;
    }
.Categories-5 .Container-Subitem-3-Categories{
        animation: Effect-Category-5-Subitem-3 1.5s forwards;
    }
.Categories-5 .Container-Subitem-4-Categories{
        animation: Effect-Category-5-Subitem-4 1.5s forwards;
    }
.Categories-5 .Container-Subitem-5-Categories{
        animation: Effect-Category-5-Subitem-5 1.5s forwards;
    }
@keyframes Effect-Category-5-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -210px);
    }
}
@keyframes Effect-Category-5-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(200px, -75px);
    }
}
@keyframes Effect-Category-5-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(130px, 165px);
    }
}
@keyframes Effect-Category-5-Subitem-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-130px, 165px);
    }
}
@keyframes Effect-Category-5-Subitem-4{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-200px, -75px);
    }
}
.Categories-4 .Container-Subitem-0-Categories{
        animation: Effect-Category-4-Subitem-0 1.5s forwards;
    }
.Categories-4 .Container-Subitem-1-Categories{
        animation: Effect-Category-4-Subitem-1 1.5s forwards;
    }
.Categories-4 .Container-Subitem-2-Categories{
        animation: Effect-Category-4-Subitem-2 1.5s forwards;
    }
.Categories-4 .Container-Subitem-3-Categories{
        animation: Effect-Category-4-Subitem-3 1.5s forwards;
    }
.Categories-4 .Container-Subitem-4-Categories{
        animation: Effect-Category-4-Subitem-4 1.5s forwards;
    }
@keyframes Effect-Category-4-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -220px);
    }
}
@keyframes Effect-Category-4-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(220px, 0);
    }
}
@keyframes Effect-Category-4-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 220px);
    }
}
@keyframes Effect-Category-4-Subitem-3{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-220px, 0);
    }
}
.Categories-3 .Container-Subitem-0-Categories{
        animation: Effect-Category-3-Subitem-0 1.5s forwards;
    }
.Categories-3 .Container-Subitem-1-Categories{
        animation: Effect-Category-3-Subitem-1 1.5s forwards;
    }
.Categories-3 .Container-Subitem-2-Categories{
        animation: Effect-Category-3-Subitem-2 1.5s forwards;
    }
.Categories-3 .Container-Subitem-3-Categories{
        animation: Effect-Category-3-Subitem-3 1.5s forwards;
    }
.Categories-3 .Container-Subitem-4-Categories{
        animation: Effect-Category-3-Subitem-4 1.5s forwards;
    }
@keyframes Effect-Category-3-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -210px);
    }
}
@keyframes Effect-Category-3-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(215px, 80px);
    }
}
@keyframes Effect-Category-3-Subitem-2{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(-215px, 80px);
    }
}
.Categories-2 .Container-Subitem-0-Categories{
        animation: Effect-Category-2-Subitem-0 1.5s forwards;
    }
.Categories-2 .Container-Subitem-1-Categories{
        animation: Effect-Category-2-Subitem-1 1.5s forwards;
    }
.Categories-2 .Container-Subitem-2-Categories{
        animation: Effect-Category-2-Subitem-2 1.5s forwards;
    }
@keyframes Effect-Category-2-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -220px);
    }
}
@keyframes Effect-Category-2-Subitem-1{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 220px);
    }
}
.Categories-1 .Container-Subitem-0-Categories{
        animation: Effect-Category-1-Subitem-0 1.5s forwards;
    }
.Categories-1 .Container-Subitem-1-Categories{
        animation: Effect-Category-1-Subitem-1 1.5s forwards;
    }
@keyframes Effect-Category-1-Subitem-0{
    0%, 50%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, -220px);
    }
}
.Title-Modules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    font-size: 28.382px !important;
    line-height: 34px !important;
    color: var(--blue) !important;
    margin-bottom: 12px !important;
}
.Text-Modules{
    font-family: var(--font-family);
    line-height: 17px;
    color: var(--blue);
}
.Button-New-Modules{
    background-color: var(--green);
    border-radius: 27px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    line-height: 17px;
    padding: 14px 20px;
    border: 1px solid var(--green);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.Button-New-Modules .Icon-New-Modules{
    font-size: 20px;
    margin-right: 10px;
}
.Button-New-Modules .Text-New-Modules{
    margin-top: -3px;
}
.Card-Item-Modules{
    border: 1px solid var(--lead);
    border-radius: 20px;
    background-color: var(--white);
}
.Card-Item-Modules .ant-collapse-item{
    border-radius: 20px !important;
    border-bottom: 0;
}
.Card-Item-Modules .ant-collapse-item.ant-collapse-item-active{
    padding-bottom: 0 !important;
}
.Card-Item-Modules .ant-collapse-header{
    padding: 6px !important;
    border-radius: 20px !important;
    align-items: center !important;
}
.Card-Item-Modules .ant-collapse-header-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Card-Item-Modules .ant-collapse-expand-icon{
    order: 1;
    color: var(--blue);
    padding-inline-start: 1px !important;
}
.Card-Item-Modules .ant-collapse-extra{
    order: 2;
}
.Card-Item-Modules .Container-Text-Item-Modules, .Card-Item-Modules .Container-Icon-Item-Modules {
    display: flex;
    align-items: center;
}
.Card-Item-Modules .Icon-DragDrop-Item-Modules{
    padding: 8px 3px;
    font-size: 20px;
    color: rgba(1, 58, 129, 0.4);
}
.Card-Item-Modules .Icon-DragDrop-Item-Modules:hover{
    color: var(--blue-elec);
    background-color: #EDF2FF;
    border-radius: 8px;
}
.Card-Item-Modules .Text-Item-Modules{
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--weight-bold);
    line-height: 14.52px;
    margin-left: 3px;
    color: var(--blue);
}
.Card-Item-Modules .Container-Text-Item-Modules .Image-Item-Modules{
    margin-left: 9px;
    background-color: var(--cream);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Card-Item-Modules .Container-Icon-Item-Modules .Image-Item-Modules{
    background-color: #EDF2FF;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Card-Item-Modules .Container-Text-Item-Modules .Image-Item-Modules span{
    font-size: 20px;
    color: var(--blue);
}
.Card-Item-Modules .Container-Icon-Item-Modules .Image-Item-Modules span{
    font-size: 20px;
    color: var(--blue);
}
.Card-Item-Modules .Container-Text-Item-Modules .Image-Avatar-Data-Item-Modules{
    width: 40px;
    height: 40px;
}
.Card-Item-Modules .Container-Text-Item-Modules .Image-Avatar-Item-Modules{
    width: 35px;
    height: 35px;
}
.Card-Item-Modules .Container-Icon-Item-Modules .Image-Avatar-Item-Modules{
    width: 30px;
    height: 30px;
}
.Card-Item-Modules .Image-Avatar-Item-Home{
    width: 20px !important;
    height: 20px !important;
}
.Card-Item-Modules .Icon-View-Item-Modules, .Card-Item-Modules .Icon-View-Disabled-Item-Modules{
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 18px;
    cursor: pointer; 
}
.Card-Item-Modules .Icon-View-Item-Modules{
    background-size: 14px 11px;
    background-image: url('../../Assets/Images/Modules/EyeBlue.png');
}
.Card-Item-Modules .Icon-View-Disabled-Item-Modules{
    background-size: 14px 14px;
    background-image: url('../../Assets/Images/Modules/EyeDisable.png');
}
.Card-Item-Modules .Icon-View-Disabled-Item-Modules:hover{
    background-image: url('../../Assets/Images/Modules/EyeDisableSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Modules .Icon-View-Item-Modules:hover{
    background-image: url('../../Assets/Images/Modules/EyeSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Modules .ant-collapse-expand-icon .Icon-Expand-Item-Modules{
    background-image: url('../../Assets/Images/Modules/ArrowBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    margin-left: 7px;
    cursor: pointer;
}
.Card-Item-Modules .ant-collapse-expand-icon .Icon-Expand-Item-Modules:hover{
    background-image: url('../../Assets/Images/Modules/ArrowSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Modules .ant-collapse-expand-icon .Icon-Expand-Item-Modules.Expand-Rotate-Item-Modules{
    transform: rotate(180deg);
}
.Card-Item-Modules .Icon-Menu-Item-Modules{
    background-image: url('../../Assets/Images/Modules/MenuBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    margin-left: 6px;
    cursor: pointer;
}
.Card-Item-Modules .Icon-Menu-Item-Modules:hover{
    background-image: url('../../Assets/Images/Modules/MenuSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Modules .ant-collapse-content{
    border-top: 1px solid var(--lead);
    border-radius: 0 0 20px 20px !important;
}
.Card-Item-Modules .ant-collapse-content-box{
    padding: 0 !important;
}
.Card-Item-Modules.Disabled-Card-Item-Modules .Text-Item-Modules, .Card-Item-Modules.Disabled-Card-Item-Modules .Image-Item-Modules, .Card-Item-Modules.Disabled-Card-Item-Modules .Icon-Expand-Item-Modules, .Card-Item-Modules.Icon-Expand-Inabled-Modules .Icon-Expand-Item-Modules{
    opacity: .3;
}
.Card-Item-Modules.Disabled-Card-Item-Modules .ant-collapse-header .ant-collapse-expand-icon, .Card-Item-Modules.Icon-Expand-Inabled-Modules .ant-collapse-header .ant-collapse-expand-icon{
    cursor: default !important;
    pointer-events: none;
}
.Card-Item-Modules.Disabled-Card-Item-Modules .ant-collapse-content{
    display: none;
}
.Menu-Item-Modules .ant-dropdown-menu{
    padding: 7px 0;
    box-shadow: 0 4px 4px 0 #D9D9D9;
}
.Menu-Item-Modules .ant-dropdown-menu-item{
    padding: 0 8px !important;
    border-radius: 0 !important;
}
.Menu-Item-Modules .ant-dropdown-menu-item:hover{
    background-color: #EDF2FF !important;
}
.Menu-Item-Modules .Text-Edit-Menu, .Menu-Item-Modules .Text-Delete-Menu{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 23px;
    color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu-Item-Modules .ant-dropdown-menu-item:hover .Text-Edit-Menu, .Menu-Item-Modules .ant-dropdown-menu-item:hover .Text-Delete-Menu{
    color: var(--blue-elec);
}
.Menu-Item-Modules .Text-Edit-Menu .Icon-Edit-Menu, .Menu-Item-Modules .Text-Delete-Menu .Icon-Delete-Menu{
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 15px;
    cursor: pointer; 
}
.Menu-Item-Modules .Text-Edit-Menu .Icon-Edit-Menu{
    background-size: 14px 14px;
    background-image: url('../../Assets/Images/Modules/EditBlue.png');
}
.Menu-Item-Modules .Text-Delete-Menu .Icon-Delete-Menu{
    background-size: 14px 14px;
    background-image: url('../../Assets/Images/Modules/DeleteBlue.png');
}
.Menu-Item-Modules .ant-dropdown-menu-item:hover .Icon-Edit-Menu{
    background-image: url('../../Assets/Images/Modules/EditSkyBlue.png');
}
.Menu-Item-Modules .ant-dropdown-menu-item:hover .Icon-Delete-Menu{
    background-image: url('../../Assets/Images/Modules/DeleteSkyBlue.png');
}
.Menu-Item-Modules .Icon-Delete-Menu{
    margin-left: 10px;
}
.Modal-Delete-Module .ant-modal-content{
    border-radius: 20px;
    padding: 22px 16px;
}
.Modal-Delete-Module .ant-modal-confirm-title{
    font-family: var(--font-family);
    text-align: center;
    font-weight: var(--weight-bold) !important;
    line-height: var(--lineheight-regular) !important;
    color: var(--green) !important;
    margin-bottom: 2px;
}
.Modal-Delete-Module .ant-modal-confirm-content{
    font-family: var(--font-family);
    text-align: center;
    line-height: var(--lineheight-regular) !important;
    color: var(--blue) !important;
}
.Modal-Delete-Module .ant-modal-confirm-body{
    justify-content: center;
}
.Modal-Delete-Module .ant-modal-confirm-content{
    margin-top: 0 !important;
}
.Modal-Delete-Module .ant-modal-confirm-btns{
    text-align: center;
}
.Modal-Delete-Module .Confirm-Modal-Delete-Module{
    font-family: var(--font-family);
    line-height: 17px;
    color: #FFFFFF;
    padding: 10px;
    border: 1px solid var(--green);
    background-color: var(--green);
    border-radius: 20px;
    box-shadow: none;
    height: auto;
}
.Modal-Delete-Module .Confirm-Modal-Delete-Module:hover{
    background-color: var(--green);
}
.Modal-Delete-Module .Confirm-Modal-Delete-Module span:first-child{
    display: flex;
}
.Modal-Delete-Module .Confirm-Modal-Delete-Module .First-Confirm{
    transform: translate(0, 0);
    transition: .7s;
    opacity: 1;
}
.Modal-Delete-Module .Confirm-Modal-Delete-Module .Last-Confirm{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    transition: .7s;
}
.Modal-Delete-Module .Confirm-Modal-Delete-Module:hover .First-Confirm{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.Modal-Delete-Module .Confirm-Modal-Delete-Module:hover .Last-Confirm{
    transform: translate(0, 0) !important;
    transition: .7s !important;
    opacity: 1 !important;
}
.Modal-Delete-Module .Cancel-Modal-Delete-Module{
    font-family: var(--font-family);
    line-height: 17px;
    color: var(--green);
    border: 1px solid rgba(42, 210, 149, 0.4);
    border-radius: 20px;
    height: auto;
    padding: 10px;
}
.Modal-Delete-Module .Cancel-Modal-Delete-Module:hover{
    color: var(--green);
    border: 1px solid rgba(42, 210, 149, 0.4);
}
.Modal-Delete-Module .Cancel-Modal-Delete-Module span:first-child{
    display: flex;
}
.Modal-Delete-Module .Cancel-Modal-Delete-Module .First-Cancel{
    transform: translate(0, 0);
    transition: .7s;
    opacity: 1;
}
.Modal-Delete-Module .Cancel-Modal-Delete-Module .Last-Cancel{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    transition: .7s;
}
.Modal-Delete-Module .Cancel-Modal-Delete-Module:hover .First-Cancel{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.Modal-Delete-Module .Cancel-Modal-Delete-Module:hover .Last-Cancel{
    transform: translate(0, 0) !important;
    transition: .7s !important;
    opacity: 1 !important;
}
.Container-Collapse-Item-Sub-Modules{
    border-bottom: 1px solid var(--lead);
}
.Container-Collapse-Item-Sub-Modules .Card-Item-Sub-Modules{
    border: 0;
    border-radius: 0 !important;
}
.Container-Collapse-Item-Sub-Modules .Card-Item-Sub-Modules .ant-collapse-item{
    background-color: var(--white);
    padding-bottom: 0;
    border-radius: 0 !important;
}
.Container-Collapse-Item-Sub-Modules .Card-Item-Sub-Modules .ant-collapse-header, .Container-Item-Sub-Modules{
    padding: 2px 6px !important;
    border-radius: 0 !important;
}
.Container-Item-Sub-Modules{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--lead);
}
.Container-Collapse-Item-Sub-Modules .Container-Text-Item-Sub-Modules, .Container-Collapse-Item-Sub-Modules .Container-Icon-Item-Sub-Modules, .Container-Item-Sub-Modules .Container-Text-Item-Sub-Modules, .Container-Item-Sub-Modules .Container-Icon-Item-Sub-Modules{
    display: flex;
    align-items: center;
}
.Container-Collapse-Item-Sub-Modules .Container-Text-Item-Sub-Modules .Icon-DragDrop-Item-Sub-Modules, .Container-Item-Sub-Modules .Icon-DragDrop-Item-Sub-Modules{
    padding: 3px 2px;
    font-size: 20px;
    color: rgba(1, 58, 129, 0.4);
}
.Container-Collapse-Item-Sub-Modules .Container-Text-Item-Sub-Modules .Icon-DragDrop-Item-Sub-Modules:hover, .Container-Item-Sub-Modules .Icon-DragDrop-Item-Sub-Modules:hover{
    color: var(--blue-elec);
    background-color: #EDF2FF;
    border-radius: 8px;
}
.Container-Collapse-Item-Sub-Modules .Container-Text-Item-Sub-Modules .Text-Items-Sub-Modules, .Container-Item-Sub-Modules .Text-Items-Sub-Modules{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue);
    margin-left: 3px;
}
.Container-Collapse-Item-Sub-Modules .Container-Icon-Item-Sub-Modules .Icon-View-Item-Sub-Modules, .Container-Collapse-Item-Sub-Modules .Container-Icon-Item-Sub-Modules .Icon-View-Disabled-Item-Sub-Modules, .Container-Item-Sub-Modules .Icon-View-Item-Sub-Modules, .Container-Item-Sub-Modules .Icon-View-Disabled-Item-Sub-Modules
{
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 17px;
    cursor: pointer; 
}
.Container-Collapse-Item-Sub-Modules .Container-Icon-Item-Sub-Modules .Icon-View-Item-Sub-Modules, .Container-Item-Sub-Modules .Icon-View-Item-Sub-Modules{
    background-size: 14px 11px;
    background-image: url('../../Assets/Images/Modules/EyeBlue.png');
}
.Container-Collapse-Item-Sub-Modules .Container-Icon-Item-Sub-Modules .Icon-View-Disabled-Item-Sub-Modules, .Container-Item-Sub-Modules .Icon-View-Disabled-Item-Sub-Modules{
    background-size: 14px 14px;
    background-image: url('../../Assets/Images/Modules/EyeDisable.png');
}
.Container-Collapse-Item-Sub-Modules .Container-Icon-Item-Sub-Modules .Icon-View-Disabled-Item-Sub-Modules:hover, .Container-Item-Sub-Modules .Icon-View-Disabled-Item-Sub-Modules:hover{
    background-image: url('../../Assets/Images/Modules/EyeDisableSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Container-Collapse-Item-Sub-Modules .Container-Icon-Item-Sub-Modules .Icon-View-Item-Sub-Modules:hover, .Container-Item-Sub-Modules .Icon-View-Item-Sub-Modules:hover{
    background-image: url('../../Assets/Images/Modules/EyeSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Container-Collapse-Item-Sub-Modules .Icon-Expand-Item-Sub-Modules{
    background-image: url('../../Assets/Images/Modules/ArrowBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    margin-left: 6px;
    cursor: pointer;
}
.Container-Collapse-Item-Sub-Modules .Icon-Expand-Item-Sub-Modules:hover{
    background-image: url('../../Assets/Images/Modules/ArrowSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Container-Collapse-Item-Sub-Modules .Icon-Expand-Item-Sub-Modules.Expand-Rotate-Sub-Item-Modules{
    transform: rotate(180deg);
}
.Container-Collapse-Item-Sub-Modules .Icon-Menu-Item-Sub-Modules, .Container-Item-Sub-Modules .Icon-Menu-Item-Sub-Modules{
    background-image: url('../../Assets/Images/Modules/MenuBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    margin-left: 6px;
    cursor: pointer;
}
.Container-Collapse-Item-Sub-Modules .Icon-Menu-Item-Sub-Modules:hover, .Container-Item-Sub-Modules .Icon-Menu-Item-Sub-Modules:hover{
    background-image: url('../../Assets/Images/Modules/MenuSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Container-Collapse-Item-Sub-Modules.Div-Container, .Container-Item-Sub-Modules.Div-Container{
    border-bottom: 0;
    border-radius: 0 0 20px 20px !important;
}
.Container-Collapse-Item-Sub-Modules.Div-Container .Card-Item-Sub-Modules, .Container-Collapse-Item-Sub-Modules.Div-Container .ant-collapse-item, .Container-Collapse-Item-Sub-Modules.Div-Container .ant-collapse-header{
    border-radius: 0 0 20px 20px !important;
}
.Card-Item-Sub-Modules.Disabled-Card-Item-Sub-Modules .Text-Items-Sub-Modules, .Card-Item-Sub-Modules.Disabled-Card-Item-Sub-Modules .Icon-Expand-Item-Sub-Modules, .Container-Item-Sub-Modules.Disabled-Card-Item-Sub-Modules .Text-Items-Sub-Modules{
    opacity: .3;
}
.Card-Item-Sub-Modules.Disabled-Card-Item-Sub-Modules .ant-collapse-header .ant-collapse-expand-icon{
    cursor: default !important;
    pointer-events: none;
}
.Card-Item-Sub-Modules.Disabled-Card-Item-Sub-Modules .ant-collapse-content{
    display: none;
}
.Container-Items-Sub-Sub-Modules{
    padding: 2px 6px !important;
    border-bottom: 1px solid #EFEBE6;
    display: flex;
    justify-content: space-between;
}
.Container-Items-Sub-Sub-Modules .Container-Text-Item-Sub-Sub-Modules, .Container-Items-Sub-Sub-Modules .Container-Icon-Item-Sub-Sub-Modules{
    display: flex;
    align-items: center;
}
.Container-Items-Sub-Sub-Modules .Icon-DragDrop-Item-Sub-Sub-Modules{
    padding: 3px 2px;
    font-size: 20px;
    color: rgba(1, 58, 129, 0.4);
}
.Container-Items-Sub-Sub-Modules .Icon-DragDrop-Item-Sub-Sub-Modules:hover{
    color: var(--blue-elec);
    background-color: #EDF2FF;
    border-radius: 8px;
}
.Container-Items-Sub-Sub-Modules .Text-Items-Sub-Sub-Modules{
    font-family: var(--font-family);
    font-size: 11px;
    line-height: 13.52px;
    margin-left: 3px;
    color: var(--green);
}
.Card-Item-Modules .Icon-View-Item-Sub-Sub-Modules, .Card-Item-Modules .Icon-View-Disabled-Item-Sub-Sub-Modules{
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 17px;
    cursor: pointer; 
}
.Card-Item-Modules .Icon-View-Item-Sub-Sub-Modules{
    background-size: 14px 11px;
    background-image: url('../../Assets/Images/Modules/EyeBlue.png');
}
.Card-Item-Modules .Icon-View-Disabled-Item-Sub-Sub-Modules{
    background-size: 14px 14px;
    background-image: url('../../Assets/Images/Modules/EyeDisable.png');
}
.Card-Item-Modules .Icon-View-Disabled-Item-Sub-Sub-Modules:hover{
    background-image: url('../../Assets/Images/Modules/EyeDisableSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Modules .Icon-View-Item-Sub-Sub-Modules:hover{
    background-image: url('../../Assets/Images/Modules/EyeSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Container-Items-Sub-Sub-Modules .Icon-Menu-Item-Sub-Sub-Modules{
    background-image: url('../../Assets/Images/Modules/MenuBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    margin-left: 6px;
    cursor: pointer;
}
.Container-Items-Sub-Sub-Modules .Icon-Menu-Item-Sub-Sub-Modules:hover{
    background-image: url('../../Assets/Images/Modules/MenuSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Container-Items-Sub-Sub-Modules.Last-Sub-Sub-Modules{
    border-bottom: 0;
}
.Container-Items-Sub-Sub-Modules.Disabled-Card-Item-Sub-Sub-Modules .Text-Items-Sub-Sub-Modules{
    opacity: .3;
}
.Container-Modules{
    padding-top: 42px !important;
    padding-bottom: 20px !important;
}
.Container-Modules .Title-Modules{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    font-weight: var(--weight-bold) !important;
    margin: 0 !important;
}
.Container-New-Modules .Title-Modules{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    font-weight: var(--weight-bold) !important;
    margin-bottom: 4px !important;
}
.Container-New-Modules .Text-Modules{
    font-size: 11px;
    font-weight: var(--weight-regular);
    line-height: 13.31px;
    margin-bottom: 12px;
}
.Container-SubTitle{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EFEBE6;
}
.Contaimer-Image-Text{
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
}
.Container-Modules .Image-Subtitle{
    background-image: url('../../Assets/Images/Modules/HomeSkyBlue.png');
    width: 20px;
    background-size: 20px 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.Container-Modules .Image-Subtitle.Image-Menu{
    background-image: url('../../Assets/Images/Modules/BarSkyBlue.png');
}
.Container-Modules .Text-Subtitle{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    font-weight: var(--weight-regular);
    color: var(--blue-elec);
    margin-left: 2px;
    margin-bottom: -3px;
}
.Container-Modules .Text-Subtitle.Text-Menu{
    margin-bottom: -1px;
}
.Container-Modules .Text-Menu-Description{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 11px;
    line-height: 13.31px;
    color: var(--blue);
    margin-top: 7px;
    margin-bottom: 9px;
}
.Container-Modules .Container-Loading-Menu{
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Form-Modules .Form-Item-Modules{
    margin-bottom: 12px;
}
.Form-Modules .Form-Item-Modules .ant-form-item-label{
    display: flex;
    padding: 0 0 8px 0 !important;
}
.Form-Modules .Form-Item-Modules label{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 11px;
    line-height: 13.31px;
    color: var(--blue-elec);
}
.Form-Modules .Form-Item-Modules .ant-input{
    border: 1px solid rgb(176, 191, 236, .5);
    border-radius: 8px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--weight-regular);
    line-height: 22.54px;
    color: var(--blue);
}
.Form-Modules .Form-Item-Modules .ant-input::placeholder{
    color: rgb(1, 58, 129, .4);
}
.Form-Modules .Form-Item-Modules .ant-input:focus{
    box-shadow: none;
}
.Form-Modules .Form-Item-Modules .Container-Image-Modules{
    width: 51px;
    height: 38px;
    border: 1px solid rgba(176, 191, 236, 0.5);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Form-Modules .Form-Item-Modules .Input-File-Modules{
    display: none;
}
.Form-Modules .Form-Item-Button-Sub-Modules{
    text-align: end;
    margin-bottom: 40px;
}
.Form-Modules .Form-Item-Modules .ant-select-arrow{
    font-size: 16px;
    color: rgba(176, 191, 236, 0.5);
}
.Form-Modules .Form-Item-Modules .ant-select:hover .ant-select-selector{
    border-color: rgba(176, 191, 236, 0.5) !important;
    box-shadow: none !important;
}
.Form-Modules .Form-Item-Modules .ant-select-selector{
    height: 38px;
    border: 1px solid rgba(176, 191, 236, 0.5);
    box-shadow: none !important;
    border-color: rgba(176, 191, 236, 0.5) !important;
}
.Form-Modules .Form-Item-Modules .ant-select-selector input{
    height: 38px !important;
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    line-height: 17px;
    font-size: 12px;
    color: var(--blue) !important;
}
.Form-Modules .Form-Item-Modules .ant-select-selector .ant-select-selection-item{
    line-height: 36px !important;
    font-size: 12px;
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    color: var(--blue) !important;
}
.Form-Modules .Form-Item-Modules .ant-select-selection-placeholder{
    line-height: 38px !important;
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 12px;
    line-height: 17px;
    color: rgb(1, 58, 129, .4) !important;
}
.Item-Select-Country .ant-select-item-option-content{
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--weight-regular);
    line-height: 17px;
    color: var(--blue) !important;
}
.Item-Select-Country .ant-select-item{
    border-radius: 0 !important;
    align-items: center;
}
.Title-Submodules-Modules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: var(--blue) !important;
    margin: 0 !important;
}
.Form-Item-Modules.Input-Prefix .ant-input-affix-wrapper .ant-input-prefix{
    margin-inline-end: 0 !important;
    color: var(--blue) !important;
}
.Form-Item-Modules.Input-Prefix .ant-input-affix-wrapper{
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: 'Inter';
    padding: 0 !important;
    padding-left: 10px !important;
}
.Form-Item-Modules.Input-Prefix .ant-input{
    border: none;
}
.Card-Form-Submodules{
    background-color: var(--cream);
    border: 0;
    border-radius: 20px;
}
.Card-Form-Submodules.Error-Submodules{
    border: 1px solid red;
}
.Card-Form-Submodules .ant-card-body{
    padding: 20px;
}
.Card-Form-Submodules.Expandible-Submodules .ant-card-body{
    padding-top: 15px;
}
.Card-Form-Submodules .Title-Submodules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: var(--blue-elec) !important;
    margin-bottom: 0 !important;
}
.Card-Form-Submodules .Icon-Delete-Submodules{
    background-image: url('../../Assets/Images/Modules/DeleteBlue.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    cursor: pointer;
}
.Card-Form-Submodules .Icon-Min-Max-Submodules{
    color: #013A81;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
}
.Card-Form-Submodules .Icon-Min-Max-Submodules:hover{
    color: #3B72FF;
    background-color: #EDF2FF;
}
.Card-Form-Submodules .Icon-Delete-Submodules:hover{
    background-image: url('../../Assets/Images/Modules/DeleteSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Form-Submodules .Form-Submodules{
    margin-top: 0 !important;
}
.Card-Form-Submodules .Form-Item-Submodules{
    margin-bottom: 8px;
}
.Card-Form-Submodules .Form-Item-Submodules .ant-form-item-label{
    display: flex;
    padding-bottom: 4px;
}
.Card-Form-Submodules .Form-Item-Submodules label{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 11px;
    line-height: 13.31px;
    color: var(--blue-elec);
}
.Card-Form-Submodules .Form-Item-Submodules .ant-input{
    border: 1px solid rgb(176, 191, 236, .5);
    padding: 13px 8px 10px 8px !important;
    border-radius: 8px;
}
.Card-Form-Submodules .Form-Item-Submodules .ant-input{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue);
}
.Card-Form-Submodules .Form-Item-Submodules .ant-input::placeholder{
    color: rgb(1, 58, 129, .4);
}
.Card-Form-Submodules .Form-Item-Submodules .ant-input:focus{
    box-shadow: none;
}
.Card-Form-Submodules .Container-Create-SubSubmodules-Submodules{
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
}
.Card-Form-Submodules .Icon-Create-SubSubmodules-Submodules{
    color: var(--blue-elec);
}
.Card-Form-Submodules .Create-SubSubmodules-Submodules{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 11px;
    line-height: 13.31px;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    color: var(--blue-elec);
    margin-bottom: 0;
    margin-left: 4px;
}
.Card-Form-Submodules .Form-Item-Button-Submodules{
    margin-bottom: 0;
}
.Card-Form-Submodules .Form-Item-Button-Submodules .ant-form-item-control-input-content{
    display: flex;
    justify-content: end;
    column-gap: 20px;
    margin-top: 6px;
}
.Container-Button-SubModules{
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 12px;
    margin-top: 12px;
}
.Card-Form-Submodules .Button-Cancel-SubModules, .Card-Form-Submodules .Button-New-SubModules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 17px;
    border-radius: 27px;
    padding: 10px 15px;
    border: 1px solid var(--blue-elec);
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    overflow: hidden;
    position: relative;
}
.Card-Form-Submodules .Button-Cancel-SubModules{
    background-color: var(--white);
    color: var(--blue-elec);
}
.Card-Form-Submodules .Button-New-SubModules{
    background-color: var(--blue-elec);
    color: #FFFFFF;
}
.Card-Form-Submodules .Button-Cancel-SubModules .Text-Cancel, .Card-Form-Submodules .Button-New-SubModules .Text-New{
    transform: translate(0, 0);
    transition: .7s;
}
.Card-Form-Submodules .Button-Cancel-SubModules .Text-Cancel-Effect, .Card-Form-Submodules .Button-New-SubModules .Text-New-Effect{
    position: absolute;
    transform: translate(0, 170%);
    transition: .7s;
}
.Card-Form-Submodules .Button-Cancel-SubModules:hover .Text-Cancel, .Card-Form-Submodules .Button-New-SubModules:hover .Text-New{
    transform: translate(0, -170%);
    transition: .7s;
}
.Card-Form-Submodules .Button-Cancel-SubModules:hover .Text-Cancel-Effect, .Card-Form-Submodules .Button-New-SubModules:hover .Text-New-Effect{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Card-Form-SubSubmodules{
    background-color: #FFFDF9;
    border: 0;
    border-radius: 20px;
}
.Card-Form-SubSubmodules.Error-SubSubmodules{
    border: 1px solid red;
}
.Card-Form-SubSubmodules .ant-card-body{
    padding: 9px 20px 20px 20px !important;
}
.Card-Form-SubSubmodules .Icon-Delete-SubSubmodules{
    background-image: url('../../Assets/Images/Modules/DeleteBlue.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    cursor: pointer;
}
.Card-Form-SubSubmodules .Icon-Delete-SubSubmodules:hover{
    background-image: url('../../Assets/Images/Modules/DeleteSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Form-SubSubmodules .Title-SubSubmodules{
    position: absolute;
    left: 0;
    top: 5px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: var(--green) !important;
    margin-bottom: 0 !important;
}
.Card-Form-SubSubmodules .Form-SubSubmodules{
    margin-top: 0 !important;
}
.Card-Form-SubSubmodules .Form-Item-SubSubmodules{
    margin-bottom: 8px;
}
.Card-Form-SubSubmodules .ant-form-item-label{
    display: flex;
    padding-bottom: 4px;
}
.Card-Form-SubSubmodules .Form-Item-SubSubmodules label{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 11px;
    line-height: 13.31px;
    color: var(--green);
}
.Card-Form-SubSubmodules .Form-Item-SubSubmodules .ant-input{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue);
    border: 1px solid rgba(176, 191, 236, 0.5);
    padding: 13px 8px 10px 8px;
    border-radius: 8px;
}
.Card-Form-SubSubmodules .Form-Item-SubSubmodules .ant-input::placeholder{
    color: rgb(1, 58, 129, .4);
    font-size: 12px;
}
.Card-Form-SubSubmodules .Form-Item-SubSubmodules .ant-input:focus{
    box-shadow: none;
}
.Card-Item-SubModules{
    border-radius: 8px;
    border: 1px solid #EFEBE6;
    background-color: var(--white);
    margin-bottom: 4px;
}
.Card-Item-SubModules .ant-collapse-item{
    border-bottom: 0;
}
.Card-Item-SubModules .ant-collapse-item.ant-collapse-item-active{
    padding-bottom: 0 !important;
}
.Card-Item-SubModules .ant-collapse-header{
    padding: 10px 6px !important;
    align-items: center !important;
}
.Card-Item-SubModules.Disabled-Card-Item-SubModules .ant-collapse-content{
    display: none;
}
.Card-Item-SubModules .ant-collapse-content.ant-collapse-content-active{
    border-color: #EFEBE6;
}
.Card-Item-SubModules .ant-collapse-header-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Card-Item-SubModules .Container-Text-Item-SubModules{
    display: flex;
    align-items: center;
}
.Card-Item-SubModules .Icon-DragDrop-Item-SubModules{
    padding: 3px 2px;
    font-size: 14px;
    color: rgba(1, 58, 129, 0.4);
}
.Card-Item-SubModules .Icon-DragDrop-Item-SubModules:hover{
    color: var(--blue-elec);
    background-color: #EDF2FF;
    border-radius: 8px;
}
.Card-Item-SubModules .Text-Item-SubModules{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    font-weight: var(--weight-regular);
    color: var(--blue);
    margin-left: 5px;
}
.Card-Item-SubModules .Text-Item-SubModules.Text-Disable-SubModules{
    color: rgba(1, 58, 129, .3);
}
.Card-Item-SubModules .Icon-View-Item-SubModules, .Card-Item-SubModules .Icon-View-Disabled-Item-SubModules{
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 0;
    cursor: pointer; 
}
.Card-Item-SubModules .Icon-View-Item-SubModules{
    background-size: 14px 11px;
    background-image: url('../../Assets/Images/Modules/EyeBlue.png');
}
.Card-Item-SubModules .Icon-View-Item-SubModules:hover{
    background-image: url('../../Assets/Images/Modules/EyeSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-SubModules .Icon-View-Disabled-Item-SubModules{
    background-size: 14px 14px;
    background-image: url('../../Assets/Images/Modules/EyeDisable.png');
}
.Card-Item-SubModules .Icon-View-Disabled-Item-SubModules:hover{
    background-image: url('../../Assets/Images/Modules/EyeDisableSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-SubModules .Icon-Expand-Item-SubModules{
    background-image: url('../../Assets/Images/Modules/ArrowBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 19px 19px;
    margin-left: 3px;
    cursor: pointer;
}
.Card-Item-SubModules .Icon-Expand-Item-SubModules:hover{
    background-image: url('../../Assets/Images/Modules/ArrowSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-SubModules .Icon-Expand-Item-SubModules.Expand-Rotate-Item-SubModules{
    transform: rotate(180deg);
}
.Card-Item-SubModules .Icon-Menu-Item-SubModules{
    background-image: url('../../Assets/Images/Modules/MenuBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px 13px;
    margin-left: 3px;
    cursor: pointer;
}
.Card-Item-SubModules .Icon-Menu-Item-SubModules:hover{
    background-image: url('../../Assets/Images/Modules/MenuSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-SubModules.Disabled-Card-Item-SubModules .ant-collapse-expand-icon{
    cursor: default !important;
    pointer-events: none;
}
.Card-Item-SubModules.Disabled-Card-Item-SubModules .Icon-Expand-Item-SubModules{
    opacity: .3;
    cursor: default !important;
}
.Card-Item-SubModules .ant-collapse-expand-icon{
    padding-inline-start: 0 !important;
}
.Card-Item-SubModules .ant-collapse-extra{
    order: 2;
}
.Card-Item-SubModules .ant-collapse-content-box{
    padding: 0 !important;
}
.Menu-SubModules{
    box-shadow: 0px 4px 4px 0 #D9D9D9;
    border-radius: 8px;
}
.Menu-SubModules .ant-dropdown-menu{
    padding: 8px 0;
}
.Menu-SubModules .ant-dropdown-menu-item{
    padding: 0 8px 0 8px !important;
}
.Menu-SubModules .ant-dropdown-menu-item:hover{
    border-radius: 0;
    background-color: #EDF2FF !important;
}
.Menu-SubModules .Text-Edit-Submodules, .Menu-SubModules .Text-Delete-Submodules{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 23px;
    color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu-SubModules .ant-dropdown-menu-item:hover .Text-Edit-Submodules, .Menu-SubModules .ant-dropdown-menu-item:hover .Text-Delete-Submodules{
    color: var(--blue-elec);
}
.Menu-SubModules .Edit-Submodules, .Menu-SubModules .Delete-Submodules{
    color: var(--blue);
}
.Menu-SubModules .Text-Edit-Submodules .Icon-Edit-Menu, .Menu-SubModules .Text-Delete-Submodules .Icon-Delete-Menu{
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 15px;
    cursor: pointer; 
}
.Menu-SubModules .Text-Edit-Submodules .Icon-Edit-Menu{
    background-size: 13px 14px;
    background-image: url('../../Assets/Images/Modules/EditBlue.png');
}
.Menu-SubModules .Text-Delete-Submodules .Icon-Delete-Menu{
    background-size: 13px 14px;
    background-image: url('../../Assets/Images/Modules/DeleteBlue.png');
}
.Menu-SubModules .ant-dropdown-menu-item:hover .Icon-Edit-Menu{
    background-image: url('../../Assets/Images/Modules/EditSkyBlue.png');
}
.Menu-SubModules .ant-dropdown-menu-item:hover .Icon-Delete-Menu{
    background-image: url('../../Assets/Images/Modules/DeleteSkyBlue.png');
}
.Menu-SubModules .Icon-Delete-Submodules{
    margin-left: 15px;
}
.Modal-Delete .ant-modal-content{
    border-radius: 20px;
    padding: 22px 16px;
}
.Modal-Delete .ant-modal-confirm-title{
    font-family: var(--font-family);
    font-size: 12px !important;
    text-align: center;
    font-weight: var(--weight-bold) !important;
    line-height: 14.52px !important;
    color: var(--green) !important;
}
.Modal-Delete .ant-modal-confirm-content{
    font-family: var(--font-family);
    text-align: center;
    line-height: 16.94px !important;
    color: var(--blue) !important;
    margin-top: 0 !important;
}
.Modal-Delete .ant-modal-confirm-body{
    justify-content: center;
}
.Modal-Delete .ant-modal-confirm-btns{
    text-align: center;
    margin-top: 13px;
}
.Modal-Delete .Confirm-Modal-Delete{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    color: #FFFFFF;
    padding: 11px 10px;
    border: 1px solid var(--green);
    background-color: var(--green);
    border-radius: 20px;
    box-shadow: none;
    height: auto;
    overflow: hidden;
    position: relative;
}
.Modal-Delete .Confirm-Modal-Delete:hover{
    background-color: var(--green);
}
.Modal-Delete .Confirm-Modal-Delete span:first-child{
    display: flex;
}
.Modal-Delete .Confirm-Modal-Delete .First-Confirm{
    transform: translate(0, 0);
    transition: .7s;
}
.Modal-Delete .Confirm-Modal-Delete .Last-Confirm{
    position: absolute;
    transform: translate(0, 170%);
    transition: .7s;
}
.Modal-Delete .Confirm-Modal-Delete:hover .First-Confirm{
    transform: translate(0, -170%);
    transition: .7s;
}
.Modal-Delete .Confirm-Modal-Delete:hover .Last-Confirm{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Modal-Delete .Cancel-Modal-Delete{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--green);
    border: 1px solid rgba(42, 210, 149, 0.4);
    border-radius: 20px;
    height: auto;
    padding: 11px 10px;
    overflow: hidden;
    position: relative;
}
.Modal-Delete .Cancel-Modal-Delete:hover{
    color: var(--green);
    border: 1px solid rgba(42, 210, 149, 0.4);
}
.Modal-Delete .Cancel-Modal-Delete span:first-child{
    display: flex;
}
.Modal-Delete .Cancel-Modal-Delete .First-Cancel{
    transform: translate(0, 0);
    transition: .7s;
}
.Modal-Delete .Cancel-Modal-Delete .Last-Cancel{
    position: absolute;
    transform: translate(0, 170%);
    transition: .7s;
}
.Modal-Delete .Cancel-Modal-Delete:hover .First-Cancel{
    transform: translate(0, -170%);
    transition: .7s;
}
.Modal-Delete .Cancel-Modal-Delete:hover .Last-Cancel{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Card-Item-Subsubmodules{
    border-bottom: 1px solid var(--lead);
    padding: 2px 6px;
    display: flex;
    justify-content: space-between;
}
.Card-Item-Subsubmodules.Subsubmodules-Disabled-Border-Bottom{
    border-bottom: 0;
}
.Card-Item-Subsubmodules.Error-Subsubmodule{
    border: 1px solid red;
}
.Card-Item-Subsubmodules .Container-DrapDrop{
    display: flex;
    align-items: center;
}
.Card-Item-Subsubmodules .DragDrop-Subsubmodules{
    padding: 5px 2px;
    color: rgba(1, 58, 129, 0.4);
    font-size: 14px;
}
.Card-Item-Subsubmodules .DragDrop-Subsubmodules:hover{
    color: var(--blue-elec);
    background-color: #EDF2FF;
    border-radius: 8px;
}
.Card-Item-Subsubmodules .Text-Subsubmodules{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue);
    margin-bottom: -1px;
    margin-left: 6px;
}
.Card-Item-Subsubmodules .Text-Subsubmodules.Text-Disable-Subsubmodules{
    color: rgba(1, 58, 129, .3);
}
.Card-Item-Subsubmodules .Container-Options{
    display: flex;
    align-items: center;
}
.Card-Item-Subsubmodules .View-Subsubmodules, .Card-Item-Subsubmodules .View-Disable-Subsubmodules{
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 15px;
    cursor: pointer; 
}
.Card-Item-Subsubmodules .View-Subsubmodules{
    background-size: 14px 11px;
    background-image: url('../../Assets/Images/Modules/EyeBlue.png');
}
.Card-Item-Subsubmodules .View-Disable-Subsubmodules{
    background-size: 14px 13px;
    background-image: url('../../Assets/Images/Modules/EyeDisable.png');
}
.Card-Item-Subsubmodules .View-Subsubmodules:hover{
    background-image: url('../../Assets/Images/Modules/EyeSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Subsubmodules .View-Disable-Subsubmodules:hover{
    background-image: url('../../Assets/Images/Modules/EyeDisableSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Subsubmodules .Options-Subsubmodules{
    background-image: url('../../Assets/Images/Modules/MenuBlue.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    cursor: pointer;
}
.Card-Item-Subsubmodules .Options-Subsubmodules:hover{
    background-image: url('../../Assets/Images/Modules/MenuSkyBlue.png');
    border-radius: 50%;
    background-color: #EDF2FF;
}
.Card-Item-Subsubmodules .Options-Subsubmodules.Options-Disable-Subsubmodules{
    opacity: .3;
    cursor: default;
    background-color: #FFFFFF;
}
.Menu-Subsubmodules{
    box-shadow: 0px 4px 4px 0 #D9D9D9;
    border-radius: 8px;
}
.Menu-Subsubmodules .ant-dropdown-menu{
    padding: 8px 0;
}
.Menu-Subsubmodules .ant-dropdown-menu-item{
    padding: 0 8px 0 8px !important;
}
.Menu-Subsubmodules .ant-dropdown-menu-item:hover{
    border-radius: 0;
    background-color: #EDF2FF !important;
}
.Menu-Subsubmodules .Text-Edit-Subsubmodules, .Menu-Subsubmodules .Text-Delete-Subsubmodules{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 23px;
    color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu-Subsubmodules .ant-dropdown-menu-item:hover .Text-Edit-Subsubmodules, .Menu-Subsubmodules .ant-dropdown-menu-item:hover .Text-Delete-Subsubmodules{
    color: var(--blue-elec);
}
.Menu-Subsubmodules .Edit-Submodules, .Menu-Subsubmodules .Delete-Subsubmodules{
    color: var(--blue);
}
.Menu-Subsubmodules .Text-Edit-Subsubmodules .Icon-Edit-Menu, .Menu-Subsubmodules .Text-Delete-Subsubmodules .Icon-Delete-Menu{
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 15px;
    cursor: pointer; 
}
.Menu-Subsubmodules .Text-Edit-Subsubmodules .Icon-Edit-Menu{
    background-size: 13px 14px;
    background-image: url('../../Assets/Images/Modules/EditBlue.png');
}
.Menu-Subsubmodules .Text-Delete-Subsubmodules .Icon-Delete-Menu{
    background-size: 13px 14px;
    background-image: url('../../Assets/Images/Modules/DeleteBlue.png');
}
.Menu-Subsubmodules .ant-dropdown-menu-item:hover .Icon-Edit-Menu{
    background-image: url('../../Assets/Images/Modules/EditSkyBlue.png');
}
.Menu-Subsubmodules .ant-dropdown-menu-item:hover .Icon-Delete-Menu{
    background-image: url('../../Assets/Images/Modules/DeleteSkyBlue.png');
}
.Menu-Subsubmodules .Icon-Delete-Subsubmodules{
    margin-left: 15px;
}
.Modal-Delete .ant-modal-content{
    border-radius: 20px;
    padding: 22px 16px;
}
.Modal-Delete .ant-modal-confirm-title{
    font-family: var(--font-family);
    font-size: 12px !important;
    text-align: center;
    font-weight: var(--weight-bold) !important;
    line-height: 14.52px !important;
    color: var(--green) !important;
}
.Modal-Delete .ant-modal-confirm-content{
    font-family: var(--font-family);
    text-align: center;
    line-height: 16.94px !important;
    color: var(--blue) !important;
    margin-top: 0 !important;
}
.Modal-Delete .ant-modal-confirm-body{
    justify-content: center;
}
.Modal-Delete .ant-modal-confirm-btns{
    text-align: center;
    margin-top: 13px;
}
.Modal-Delete .Confirm-Modal-Delete{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    color: #FFFFFF;
    padding: 11px 10px;
    border: 1px solid var(--green);
    background-color: var(--green);
    border-radius: 20px;
    box-shadow: none;
    height: auto;
    overflow: hidden;
    position: relative;
}
.Modal-Delete .Confirm-Modal-Delete:hover{
    background-color: var(--green);
}
.Modal-Delete .Confirm-Modal-Delete span:first-child{
    display: flex;
}
.Modal-Delete .Confirm-Modal-Delete .First-Confirm{
    transform: translate(0, 0);
    transition: .7s;
}
.Modal-Delete .Confirm-Modal-Delete .Last-Confirm{
    position: absolute;
    transform: translate(0, 170%);
    transition: .7s;
}
.Modal-Delete .Confirm-Modal-Delete:hover .First-Confirm{
    transform: translate(0, -170%);
    transition: .7s;
}
.Modal-Delete .Confirm-Modal-Delete:hover .Last-Confirm{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Modal-Delete .Cancel-Modal-Delete{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--green);
    border: 1px solid rgba(42, 210, 149, 0.4);
    border-radius: 20px;
    height: auto;
    padding: 11px 10px;
    overflow: hidden;
    position: relative;
}
.Modal-Delete .Cancel-Modal-Delete:hover{
    color: var(--green);
    border: 1px solid rgba(42, 210, 149, 0.4);
}
.Modal-Delete .Cancel-Modal-Delete span:first-child{
    display: flex;
}
.Modal-Delete .Cancel-Modal-Delete .First-Cancel{
    transform: translate(0, 0);
    transition: .7s;
}
.Modal-Delete .Cancel-Modal-Delete .Last-Cancel{
    position: absolute;
    transform: translate(0, 170%);
    transition: .7s;
}
.Modal-Delete .Cancel-Modal-Delete:hover .First-Cancel{
    transform: translate(0, -170%);
    transition: .7s;
}
.Modal-Delete .Cancel-Modal-Delete:hover .Last-Cancel{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Title-Submodules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    font-size: 22.425px !important;
    line-height: 27px !important;
    color: var(--blue) !important;
    margin-bottom: 12px !important;
}
.Container-New-Modules{
    padding-bottom: 78px !important;
    position: relative;
}
.Container-New-Modules .Button-New-Sub-Modules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    line-height: 16.94px;
    height: auto;
    padding: 8px 16px;
    background-color: var(--green);
    border: 1px solid var(--green);
    border-radius: 27px;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: auto;
    box-shadow: none;
}
.Form-Modules .Form-Item-Button-Sub-Modules .Button-New-Sub-Modules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    line-height: 17px;
    height: auto;
    padding: 12px 20px;
    background-color: rgba(42, 210, 149, 0.5);
    border: 1px solid rgba(42, 210, 149, 0.1);
    border-radius: 27px;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: auto;
}
.Container-New-Modules .Button-New-Sub-Modules span:last-child{
    margin-top: 1px;
}
.Container-New-Modules .Button-New-Sub-Modules:hover, .Container-New-Modules .Button-New-Sub-Modules:focus{
    background-color: var(--green);
    border-color: var(--green);
}
.Container-New-Modules .Button-New-Sub-Modules .Icon-Button-New-Sub-Modules{
    font-size: 20px;
}
.Container-New-Modules .Col-Button-Modules{
    display: flex;
    justify-content: end;
    column-gap: 20px;
    position: absolute;
    right: 60px;
    bottom: 20px;
}
.Container-New-Modules .Button-Cancel-Modules, .Container-New-Modules .Button-New-Modules{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 17px;
    border-radius: 27px;
    padding: 10px 15px;
    border: 1px solid var(--green);
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    overflow: hidden;
    position: relative;
}
.Container-New-Modules .Button-Cancel-Modules{
    background-color: var(--white);
    color: var(--green);
}
.Container-New-Modules .Button-New-Modules{
    background-color: var(--green);
    color: #FFFFFF;
}
.Container-New-Modules .Button-Cancel-Modules .Text-Cancel, .Container-New-Modules .Button-New-Modules .Text-New{
    transform: translate(0, 0);
    transition: .7s;
}
.Container-New-Modules .Button-Cancel-Modules .Text-Cancel-Effect, .Container-New-Modules .Button-New-Modules .Text-New-Effect{
    position: absolute;
    transform: translate(0, 170%);
    transition: .7s;
}
.Container-New-Modules .Button-Cancel-Modules:hover .Text-Cancel, .Container-New-Modules .Button-New-Modules:hover .Text-New{
    transform: translate(0, -170%);
    transition: .7s;
}
.Container-New-Modules .Button-Cancel-Modules:hover .Text-Cancel-Effect, .Container-New-Modules .Button-New-Modules:hover .Text-New-Effect{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Container-New-Modules .Button-New-Modules .Spin-Loading{
    display: flex;
}
.ant-notification{
    z-index: 100000;
}
.Perfil-Container{
    padding-top: 41px;
}
.Perfil-Container .Perfil-Container-Form{
    /* margin-top: -25px !important; */
    margin-top: 0 !important;
}
.Perfil-Container-Form-Inputs{
    display: flex;
    justify-content: center;
}
.Perfil-Container-Form-Inputs label{
    color: rgba(59, 114, 255, 0.8) !important;
    font-family: var(--font-family), sans-serif;
    font-size: 11px;
}
.Perfil-Container-Form-Inputs .ant-input{
    padding: 11px 8px !important;
    color:var(--blue);
    font-style: normal;
    border-color: rgba(176, 191, 236, 0.5);
    background-color: var(--white);
    font-size: 12px;
    line-height: 14.52px;
    font-family: var(--font-family) !important;
}
.Perfil-Container-Form-Inputs .ant-input::placeholder{
    font-size: 12px;
    line-height: 14.52px;
}
.Perfil-Container-Form .ant-form-item{
    width: 100%;
    margin-bottom: 17px;
}
.Perfil-Container-Form .ant-picker{
    width: 100%;
}
.Perfil-Input-Date .ant-picker{
    padding: 11px 8px;
}
.Perfil-Container-Form .ant-picker-input input{
    color: var(--blue) !important;
    font-size: 12px;
    line-height: 14.52px;
}
.Perfil-Container-Form .ant-picker-input input::placeholder{
    font-size: 12px;
    line-height: 14.52px;
}
.Perfil-Container-Form-Inputs{
    display: flex;
    justify-content: center;
}
.Perfil-Container-Form-Inputs .Perfil-Form-Inputs .ant-form-item-label, .Perfil-Container-Form-Inputs .Perfil-Input-Date .ant-form-item-label{
    line-height: 11.06px;
}
.Perfil-Container-Form-Inputs .Perfil-Form-Inputs{
    font-family: var(--font-family) !important;
}
.Perfil-Container-Form-Inputs .Perfil-Form-Inputs .ant-form-item-label label, .Perfil-Container-Form-Inputs .Perfil-Input-Date .ant-form-item-label label{
    font-size: var(--size-small) !important;
    line-height: var(--lineheight-small);
}
.Perfil-Container-Text-Main{
    display: flex;
    justify-content: center;
}
.Perfil-Text-Main{
    font-family: var(--font-family), sans-serif;
    color: var(--blue);
    font-weight: bold;
    font-size: 20px;
    line-height: 24.2px;
    margin-bottom: 12px !important;
}
.ant-picker-date-panel span{
    background-color: var(--white);
}
.Perfil-Input-Date .ant-picker-input .ant-picker-clear .anticon-close-circle{
    background: var(--white) !important;
}
.Perfil-Input-Date .ant-picker{
    border-color: rgba(176, 191, 236, 0.5);
}
.Perfil-Input-Date .ant-picker-input span{
    background-color: var(--white) !important;
}
@media screen and (max-width: 600px) {
    .Perfil-Container{
        padding: 20px 28px;
    }    
}
@media screen and (min-width: 992px) {
    .Perfil-Container-Input-Right .ant-row{
        margin-right: 0;
        display: flex;
        justify-content: end;
    }    
}
@media screen and (max-width: 992px) {
    .Perfil-Container-Form-Inputs .ant-row{
        display: flex;
        justify-content: center;
    }    
}
.Container-Users-Create{
    padding-top: 49px !important;
    padding-bottom: 15px;
}
.Container-Users-Create .Title-Create-User-New{
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: var(--weight-bold);
    line-height: 24.2px;
    color: var(--blue);
    margin-top: 3px;
    margin-bottom: 12px !important;
}
.Container-Users-Create .Create-User-Form-Item-Text .ant-form-item-label{
    display: flex;
    padding-bottom: 8px !important;
    margin-bottom: 0 !important;
}
.ant-form-vertical.Form-Container-User{
    margin-top: 0 !important;
}
.Create-User-Form-Item-Text .ant-form-item-label label{
    font-size: 11px !important;
    font-family: 'Inter' !important;
    color: var(--blue-elec) !important;
    line-height: 13.31px;
}
.Create-User-Form-Item-Text{
    width: 100% !important;
    margin-bottom: 12px !important;
}
.Create-User-Form-Item-Text .ant-picker{
    width: 100%;
    padding: 8px 12px;
}
.Create-User-Form-Item-Text .ant-picker:hover{
    border: 1px solid #D7DEF5 !important;
}
.Create-User-Form-Item-Text .ant-picker-focused{
    border-color: #D7DEF5 !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input > input{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    line-height: 22.54px;
    color: var(--blue) !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input > input::placeholder{
    color: rgb(1, 58, 129, .4) !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-suffix, .Create-User-Form-Item-Text .ant-picker .ant-picker-clear{
    display: none;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input:hover > input{
    border: none !important;
}
.Create-User-Form-Item-Text .ant-input, .Create-User-Form-Item-Text .ant-input-number{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    line-height: 22.54px;
    color: var(--blue) !important;
    border: 1px solid rgb(215, 222, 245, .5) !important;
    padding: 8px 12px 7px 12px;
}
.Create-User-Form-Item-Text .ant-input::placeholder{
    color: rgb(1, 58, 129, .4) !important;
}
.Create-User-Form-Item-Text .ant-input:hover, .Create-User-Form-Item-Text .ant-input:focus{
    box-shadow: none !important;
}
.Create-User-Form-Item-Text .ant-input-number{
    width: 100%;
}
.Create-User-Form-Item-Text .ant-input-number .ant-input-number-input{
    color: var(--blue) !important;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile{
    background-color: var(--blue-elec);
    border-color: var(--blue-elec) !important;
    padding: 8px 15px 7px 17px;
    cursor: pointer;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile.Input-Active-Type-Profile{
    padding-left: 12px;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile .ant-input-prefix{
    margin-inline-end: 3px;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile input{
    border: 0 !important;
    background-color: var(--blue-elec);
    color: var(--white) !important;
    line-height: 22.54px;
    cursor: pointer;
}
.Create-User-Form-Item-Text .Form-Input-Type-Profile input::placeholder{
    color: var(--white) !important;
    line-height: 22.54px;
}
.Create-User-Form-Item-Text .Create-User-Input-Password{
    box-shadow: none !important;
    border-color: rgb(215, 222, 245, .5) !important;
}
.Create-User-Input-Password .ant-input{
    border: none !important;
}
.Create-User-Form-Item-Text .Create-User-Input-Password .ant-input-suffix .ant-input-password-icon:hover{
    color: var(--blue-elec) !important;
}
.Form-Create-User-Col-Left{
    padding-right: 10px;
}
.Form-Create-User-Col-Right{
    padding-left: 10px;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox .ant-checkbox-inner{
    border: 2px solid rgb(41, 209, 147, .5) !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-checked .ant-checkbox-inner::after{
    border-color:  #ffffff !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item .ant-checkbox-checked .ant-checkbox-inner{
    border: 2px solid #2AD295 !important;
    background-color: #2AD295 !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item .ant-checkbox-checked .ant-checkbox-inner::after{
    inset-inline-start: 16.5% !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-checked:after{
    border: none !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-wrapper-checked span{
    color: var(--blue);
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    margin-right: 30px !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-wrapper-disabled span:nth-child(2){
    font-size: 12px !important;
    color: rgba(1, 58, 129, 0.4) !important;
}
.Create-User-Container-Checkbox-Status{
    -webkit-user-select: none;
            user-select: none;
}
.Create-User-Container-Checkbox-Status label{
    margin-right: 20px !important;
}
.Create-User-Form-Gender .ant-select-selector{
    border-color: rgb(176, 191, 236, .5) !important;
    box-shadow: none !important;
    padding: 0 12px !important;
}
.Create-User-Form-Gender .ant-select-selection-item{
    font-family: var(--font-family);
    font-size: 12px !important;
    line-height: 22.54px !important;
    color: var(--blue);
    display: flex;
    align-items: center;
}
.Create-User-Form-Gender .ant-select-selection-placeholder{
    font-family: var(--font-family);
    font-size: 12px !important;
    line-height: 22.54px !important;
    color: rgb(1, 58, 129, .4);
    display: flex;
    align-items: center;
}
.Dropdown-User-Form-Gender .ant-select-item-option-content{
    font-family: var(--font-family) !important;
    color: var(--blue) !important;
    font-size: 12px !important;
    line-height: 22.72px !important;
    font-weight: var(--weight-regular) !important;
}
.Dropdown-User-Form-Gender .ant-select-item-option-selected, .Dropdown-User-Form-Gender .ant-select-item-option-active{
    background-color: #EDF2FF !important;
    border-radius: 0 !important;
}
.Dropdown-User-Form-Gender .ant-select-item-option-selected .ant-select-item-option-content, .Dropdown-User-Form-Gender .ant-select-item-option-active .ant-select-item-option-content{
    color: var(--blue-elec) !important;
}
.Create-User-Form-Gender:hover .ant-select-selector, .Create-User-Form-Gender:focus .ant-select-selector{
    border-color: rgb(176, 191, 236, .5) !important;
    box-shadow: none !important;
}
.Create-User-Form-Gender .ant-select-arrow{
    inset-inline-end: 12px;
}
.Create-User-Form-Gender .Icon-Gender-Form{
    font-size: 13px;
    color: var(--blue-elec);
}
.Create-User-Container-Checkbox-Countries label{
    margin-inline-start: 0 !important;
}
.Create-User-Container-Checkbox-Countries label span:nth-child(2){
    padding-inline-start: 4px;
    padding-inline-end: 0;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-disabled .ant-checkbox-inner{
    background-color: var(--white) !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-wrapper :last-child{
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-regular) !important;
    line-height: 14.52px;
    padding-inline-start: 4px;
    padding-inline-end: 0;
}
.Create-User-Container-Checkbox-Status .ant-radio-inner{
    border: 2px solid var(--blue) !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-checked .ant-radio-inner{
    border: 2px solid #2AD295 !important;
    background-color: #ffffff !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-checked .ant-radio-inner::after{
    background-color: #2AD295 !important;
}
.Create-User-Form-Container-Button{
    border-radius: 27px !important;
    padding: 11px 15px !important;
    font-family: var(--font-family) !important;
    font-weight: var(--weight-bold) !important;
    line-height: 14.52px;
    column-gap: 10px;
    font-size: 12px !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer;
    height: auto !important;
    overflow: hidden;
    position: relative;
}
.Create-User-Form-Container-Button .Spin-Loading{
    display: flex;
}
.Form-Button-Users{
    margin-bottom: 12px;
}
.Form-Button-Users .ant-form-item-control-input-content{
    margin-top: 52px;
}
.Create-User-Form-Button-Save{
    background-color: #2AD295 !important;
    color: #ffffff;
    border: 1px solid rgba(42, 210, 149, 0.4);
    margin-left: 20px !important;
    height: auto !important;
}
.Create-User-Form-Button-Cancel{
    background-color: #ffffff !important;
    border: 1px solid rgba(42, 210, 149, 0.4);
    color: #2AD295;
}
.Create-User-Form-Button-Cancel:focus, 
 .Create-User-Form-Button-Cancel:hover{
    color: #2AD295 !important;
}
.Create-User-Form-Button-Cancel span:first-child, .Create-User-Form-Button-Save .Text-Span-Save-User-First{
    transform: translate(0, 0);
    transition: .7s;
}
.Create-User-Form-Button-Cancel .Text-Span-Cancel-Create-User, .Create-User-Form-Button-Save .Text-Span-Save-Create-User{
    position: absolute;
    transform: translate(0, 190%);
    transition: .7s;
}
.Create-User-Form-Button-Cancel:hover span:first-child, .Create-User-Form-Button-Save:hover .Text-Span-Save-User-First{
    transform: translate(0, -190%);
    transition: .7s;
}
.Create-User-Form-Button-Cancel:hover .Text-Span-Cancel-Create-User, .Create-User-Form-Button-Save:hover .Text-Span-Save-Create-User{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Create-User-Form-Item-Text.Check-Form-Country .ant-form-item-control{
    display: flex;
    justify-content: center;
}
.Create-User-Form-Item-Text.Radio-Form-State .ant-form-item-control{
    display: flex;
    justify-content: center;
    height: 40px;
}
.Create-User-Form-Item-Text .Profile-Type-Select-Type-Profile .ant-select-selector{
    height: 37px !important;
}
.Create-User-Form-Item-Text .Profile-Type-Select-Type-Profile .ant-select-selection-placeholder{
    line-height: 35px !important;
}
.Create-User-Form-Item-Text .Profile-Type-Select-Type-Profile .ant-select-selection-search-input{
    height: 35px !important;
}
.Create-User-Form-Item-Text .ant-picker{
    border: 1px solid #D7DEF5 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}
.Create-User-Form-Item-Text .ant-picker-status-error.ant-picker{
    border: 1px solid #D7DEF5 !important;
}
.Form-Container-User .Permissions-User-Modules{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: var(--blue);
    margin-top: 14px;
    margin-bottom: 8px;
}
.Form-User-Checkbox-Countries{
    margin-bottom: 8px !important;
}
.Form-Button-Users .ant-form-item-control-input-content{
    display: flex;
    justify-content: end;
}
.Form-User-Checkbox-Countries .ant-form-item-label label{
    font-family: var(--font-family) !important;
    color: var(--blue-elec) !important;
    font-size: 11px !important;
    line-height: 13.31px;
}
.Form-User-Checkbox-Countries .ant-form-item-label{
    padding-bottom: 8px !important;
    display: flex;
}
.Container-User-Modules .Header-Colapse-Create-Profile .Text-Header-Collapse-Create-Profile{
    color: rgb(1, 58, 129, .5) !important;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper.Submodule-Item{
    display: flex;
    align-items: center;
    padding-left: 12px !important;
    padding-bottom: 4px;
    padding-top: 4px;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper-in-form-item .ant-checkbox-inner, .Collapse-Create-Profile .ant-collapse-content .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
    box-shadow: none !important;
}
.Container-User-Modules .Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper.Submodule-Item span:nth-child(2):not(.ant-checkbox-inner){
    color: rgb(1, 58, 129, .5);
}
.Container-User-Modules .Collapse-Create-Profile .ant-collapse-content-box .Collapse-Submodule-Profile .ant-checkbox-wrapper span:nth-child(2):not(.ant-checkbox-inner){
    color: rgb(1, 58, 129, .5);
}
.Header-Colapse-Create-Profile.Check-Active-Module .Text-Header-Collapse-Create-Profile, .Collapse-Submodule-Profile .Header-Colapse-Create-Profile.Check-Active-Submodule .Text-Header-Collapse-Create-Profile{
    color: var(--blue-elec) !important;
}
.DatePicker-Form-Executives .ant-picker-body{
    padding: 0 !important;
}
.DatePicker-Form-Executives ,
.DatePicker-Form-Executives .ant-picker-body,
.DatePicker-Form-Executives .ant-picker-header{
    width: 250px !important;
}
.DatePicker-Form-Executives .ant-picker-content td{
    width: 30px !important;
}
.DatePicker-Form-Executives .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}
.Modal-Edit-User .ant-modal-content{
    padding: 22px 16px !important;
    border-radius: 20px !important;
}
.Modal-Edit-User .ant-modal-confirm-title{
    font-family: var(--font-family);
    font-weight: var(--weight-regular) !important;
    font-size: 14px !important;
    color: var(--green) !important;
    text-align: center;
    line-height: 14.52px !important;
}
.Modal-Edit-User .ant-modal-confirm-content{
    font-family: var(--font-family);
    font-weight: var(--weight-regular) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    text-align: center;
    line-height: 16.94px !important;
}
.Modal-Edit-User .ant-modal-confirm-btns{
    display: flex;
    justify-content: center;
}
.Modal-Edit-User .Button-Cancel-Edit-User, .Modal-Edit-User .Button-Confirm-Edit-User{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    border-radius: 20px;
    height: auto !important;
    padding: 10px;
    box-shadow: none;
    display: flex !important;
    align-items: center !important;
    overflow: hidden;
    position: relative;
}
.Modal-Edit-User .Button-Cancel-Edit-User{
    font-weight: var(--weight-regular);
    border: 1px solid rgb(41, 209, 147, .4);
    color: var(--green);
}
.Modal-Edit-User .Button-Cancel-Edit-User:hover, .Modal-Edit-User .Button-Cancel-Edit-User:focus{
    border: 1px solid rgb(41, 209, 147, .4);
    color: var(--green);
}
.Modal-Edit-User .Button-Confirm-Edit-User{
    background-color: var(--green) !important;
    font-weight: var(--weight-bold);
    color: var(--white);
    margin-inline-start: 28px !important;
    border: 1px solid var(--green);
}
.Modal-Edit-User .Button-Confirm-Edit-User:hover, .Modal-Edit-User .Button-Confirm-Edit-User:focus{
    color: var(--white);
    border: 1px solid var(--green);
}
@media screen and (max-width: 991px) {
    .Form-Create-User-Col-Left{
        padding-right: 0;
    }
    .Form-Create-User-Col-Right{
        padding-left: 0;
    }
    .Create-User-Container-Checkbox-Countries .ant-checkbox-group-item{
        margin-right: 10px !important;
    }
}
.Container-Profile-Select{
    padding-top: 44px ;
}
.Container-Profile-Select .Form-Type-Profile{
    margin-top: 0 !important;
}
.Container-Disabled-Profile-Types{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: not-allowed;
}
.Profile-Type-Select-Text-Main{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    font-weight: var(--weight-bold) !important;
    margin-bottom: 4px !important;
}
.Profile-Type-Select-Text-Description{
    font-family: var(--font-family);
    color: var(--blue);
    font-size: 11px;
    line-height: 13.31px;
    font-weight: var(--weight-regular);
    margin-bottom: 12px;
}
.Container-Type-Profile-Select{
    margin-bottom: 8px !important;
}
.Profile-Type-Select-Text-Permissions{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-bold) !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: var(--blue) !important;
    margin-bottom: 4px !important;
}
.Container-Personalized-Actions-Select-Type-Profile{
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.Container-Personalized-Actions-Select-Type-Profile >:not(:first-child){
    margin-left: 8px !important;
}
.Profile-Type-Select-Type-Profile .ant-checkbox{
    display: none !important;
}
.Profile-Type-Select-Type-Profile .ant-select-selector{
    border: 1px solid rgb(176, 191, 236, .5) !important;
    padding: 0 12px !important;
    height: 38px !important;
}
.Profile-Type-Select-Type-Profile .ant-select-selector .ant-select-selection-item{
    line-height: 36px !important;
}
.Profile-Type-Select-Type-Profile .ant-select-selector .ant-select-selection-placeholder{
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 22.54px !important;
    color: rgb(1, 58, 129, .4);
    display: flex;
    align-items: center;
}
.Profile-Type-Select-Type-Profile .ant-select-arrow{
    inset-inline-end: 12px !important;
}
.Profile-Type-Select-Type-Profile .Icon-Select-Type-profile{
    font-size: 13px;
    color: var(--blue-elec);
}
.Container-Select-Dropdown-Type-Profile .rc-virtual-list-scrollbar{
    top: 5px !important;
}
.Container-Select-Dropdown-Type-Profile .rc-virtual-list-scrollbar-thumb{
    background-color: var(--blue) !important;
    max-height: 76px !important;
}
:is(.Select-Option-Type-Profile.ant-select-item-option-selected) .ant-select-item-option-content .ant-checkbox-wrapper span{
    color: var(--blue-elec) !important;
}
.Select-Option-Type-Profile:hover .ant-checkbox-checked .ant-checkbox-inner{
    border-color: var(--green) !important;
}
.Container-Select-Dropdown-Type-Profile .ant-checkbox-wrapper{
    display: flex;
    align-items: center;
}
.Container-Select-Dropdown-Type-Profile .ant-checkbox-wrapper .ant-checkbox{
    top: -1px;
}
.Select-Option-Type-Profile.Select-Option-Personalize label span:not(.ant-checkbox-inner):nth-child(2){
    display: flex;
    align-items: center;
    column-gap: 4px;
}
.Select-Option-Type-Profile.Select-Option-Personalize label span:not(.ant-checkbox-inner):nth-child(2)::before{
    content: url('../../Assets/Images/ProfileTypes/starMagenta.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    margin-top: 5px;
}
:is(.Profile-Type-Select-Type-Profile.Selected-Favorite-Type-Profile) .ant-select-selection-item::before{
    content: url('../../Assets/Images/ProfileTypes/starMagenta.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
}
.Profile-Type-Select-Type-Profile .ant-select-selection-item{
    align-items: center;
    display: flex;
}
.Profile-Type-Select-Type-Profile .ant-select-selection-item label{
    color: var(--blue) !important;
    font-size: 12px !important;
    line-height: 22.54px;
    font-weight: var(--weight-regular) !important;
}
.Profile-Type-Select-Type-Profile .ant-select-selection-item label span{
    padding-inline-start: 4px !important;
    padding-inline-end: 0 !important;
}
.Select-Option-Type-Profile .ant-select-item-option-content .ant-checkbox-inner:hover{
    border-color: var(--green) !important;
}
.Select-Option-Type-Profile .ant-select-item-option-content .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
}
.Select-Option-Type-Profile .ant-select-item-option-content .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
:is(.ant-select-dropdown .ant-select-dropdown-hidden){
    border-radius: 8px !important;
}
.ant-select-dropdown{
    padding: 0 !important;
}
.Select-Option-Type-Profile.ant-select-item-option-active, .Select-Option-Type-Profile.ant-select-item-option-selected{
    background-color: #EDF2FF !important;
}
.Select-Option-Type-Profile .ant-checkbox-wrapper{
    color: var(--blue) !important;
    font-size: 12px !important;
    line-height: 22.72px !important;
    font-weight: var(--weight-regular) !important;
    font-family: var(--font-family) !important;
}
.Select-Option-Type-Profile:hover .ant-checkbox-wrapper{
    color: var(--blue-elec) !important;
}
.Select-Option-Type-Profile{
    border-radius: 0 !important;
}
.Select-Option-Type-Profile .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Select-Option-Type-Profile .ant-select-item-option-content:hover .ant-checkbox-checked::after{
    border-color: var(--green) !important;
}
.Container-Profile-Select .Text-Personalized-Select-Type-Profile{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    font-weight: var(--weight-regular) !important;
    line-height: 14.52px;
    margin-left: 2px !important;
    margin-bottom: -3px !important;
}
.Container-Profile-Select .Text-Personalized-Select-Type-Profile.Selected-Text-Profile-Types{
    color: var(--magenta) !important;
}
:is(.ant-tooltip.ant-tooltip-placement-bottomLeft) .ant-tooltip-content .ant-tooltip-inner{
    background-color: var(--white) !important;
    color: var(--blue-elec) !important;
    font-size: 10px !important;
    line-height: 12.1px !important;
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
}
:is(.ant-tooltip.ant-tooltip-placement-bottomLeft){
    padding-top: 0 !important;
    width: 170px !important;
}
.Switch-Action-Personalized-Type-Profile .ant-switch-handle::before{
    background-color: var(--blue) !important;
    border: 1px solid var(--white);
}
.Switch-Action-Personalized-Type-Profile {
    min-width: 16px !important;
}
.Switch-Action-Personalized-Type-Profile .ant-switch-inner{
    background-color: var(--white) !important;
    border: 1.5px solid var(--blue) !important;
}
:is(.Switch-Action-Personalized-Type-Profile.ant-switch-checked)  .ant-switch-handle::before{
    background-color: var(--magenta) !important;
}
:is(.Switch-Action-Personalized-Type-Profile.ant-switch-checked)  .ant-switch-inner{
    border: 1.5px solid var(--magenta) !important;
}
/*  */
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Save{
    background-color: var(--green) !important;
    color: var(--white);
    border: none;
    margin-left: 23px !important;
    height: auto !important;
}
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Cancel{
    background-color: var(--white) !important;
    border: 2px solid rgba(42, 210, 149, 0.4);
    color: var(--green);
}
.Select-Type-Profile-Container-Button-Action{
    display: flex;
    justify-content: flex-end;
}
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Cancel:focus, 
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Cancel:hover{
    color: var(--green) !important;
}
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Cancel span:first-child, .Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Save span:first-child{
    transform: translate(0, 0);
    transition: .7s;
}
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Cancel .Text-Span-Cancel-Select-Type-Profile, .Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Save .Text-Span-Save-Select-Type-Profile{
    position: absolute;
    transform: translate(0, 190%);
    transition: .7s;
}
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Cancel:hover span:first-child, .Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Save:hover span:first-child{
    transform: translate(0, -190%);
    transition: .7s;
}
.Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Cancel:hover .Text-Span-Cancel-Select-Type-Profile, .Select-Type-Profile-Container-Button-Action .Select-Type-Profile-Button-Save:hover .Text-Span-Save-Select-Type-Profile{
    transform: translate(0, 0) !important;
    transition: .7s !important;
}
.Select-Type-Profile-Container-Button{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 14.52px;
    border-radius: 27px;
    padding: 11px 15px;
    border: 1px solid var(--green);
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    overflow: hidden;
    height: auto;
    position: relative;
}
.Container-Profile-Select .Star-Icon-Profile-Types{
    background-image: url('../../Assets/Images/ProfileTypes/starBlue.png');
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    margin-left: 9px !important;
}
.Container-Profile-Select .Star-Icon-Profile-Types.Star-Selected-Profile-Types{
    background-image: url('../../Assets/Images/ProfileTypes/starMagenta.png');
}
.Modal-Notification-User-Created .ant-modal-content{
    border-radius: 20px !important;
    padding: 22px 16px;
}
.Modal-Container-Text-User-Created .Modal-Container-Text-Main *{
    color: var(--green) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 16.94px;
}
.Modal-Container-Text-User-Created .Modal-Container-Text-Main-Alert *{
    color: #de444e !important;
    font-family: var(--font-family) !important;
    font-size: 14px !important;
    font-weight: var(--weight-bold) !important;
}
.Modal-Container-Text-User-Created .Modal-Container-Text-Main .ant-typography{
    margin-bottom: 0;
    margin-top: 0;
}
.Modal-Container-Text-User-Created .Modal-Container-Text-Description *{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    font-weight: var(--weight-regular) !important;
    line-height: 14.52px;
    margin-bottom: 13px;
}
.Modal-Container-Text-User-Created .Modal-Container-Text-Description .p{
    margin-bottom: 13px;
}
.Modal-Container-Text-User-Created *{
    display: flex !important;
    justify-content: center !important;
}
.Notification-Button-User{
    border-radius: 20px;
    padding: 12px 16px;
    color: var(--white);
    font-size: 12px;
    line-height: 14.52px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    justify-content: center;
    cursor: pointer;
}
.Notification-Button-User-Created{
    background-color: var(--green);
}
.Notification-Button-User-Alert{
    background-color: #de444e;
}
.Container-Users{
    padding-top: 42px !important;
}
.Users-Container-Actions{
    display: flex;
    justify-content: space-between;
    padding: 10px 0 10px 0;
    align-items: center;
}
.Container-Users .Title-Users{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    font-weight: var(--weight-bold) !important;
    margin: 0 !important;
}
@media screen and (max-width: 991px) {
    .Container-Users .Title-Users{
        padding-top: 20px;
    }
}
.Users-Container-Actions-Selects{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.Switch-Status-Users .ant-switch-handle{
    width: 10px !important;
    height: 10px !important;
}
.Switch-Status-Users{
    background-color: var(--white) !important;
    border: 1px solid var(--blue) !important;
    height: 15px !important;
}
.Switch-Status-Users .ant-switch-handle{
    top: 1px !important;
}
.Switch-Status-Users .ant-switch-handle::before{
    background-color: var(--blue) !important;
}
.Title-Item-Terms-Conditions{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: var(--blue) !important;
    margin-bottom: 6px !important;
}
.Text-Item-Terms-Conditions{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 12px;
    line-height: 14.52px;
    color: var(--blue);
    margin-bottom: 20px;
    text-align: justify;
}
.Container-Content-Terms-Conditions{
    display: flex;
    background-color: #EDF2FF;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px 7px 12px;
    border-radius: 20px;
    margin-bottom: 4px;
}
.Text-Content-Terms-Conditions{
    font-family: var(--font-family);
    color: var(--blue);
    font-size: 12px;
    line-height: 22.54px;
    font-weight: var(--weight-regular);
    margin-bottom: 0;
}
.Container-Content-Terms-Conditions:hover, .Container-Content-Terms-Conditions.Active-Content-Terms-Conditions{
    background-color: var(--blue-elec);
}
.Container-Content-Terms-Conditions:hover .Text-Content-Terms-Conditions, .Container-Content-Terms-Conditions .Text-Content-Terms-Conditions.Active-Content-Text-Terms-Conditions{
    color: var(--white);
    font-weight: var(--weight-bold);
}
.Container-Content-Terms-Conditions .Switch-Content-Terms-Conditions{
    min-width: 20px;
    height: 12px;
    line-height: 12px;
    border: 1px solid #B0BFEC;
}
.Container-Content-Terms-Conditions:hover .Switch-Content-Terms-Conditions, .Container-Content-Terms-Conditions .Switch-Content-Terms-Conditions.Active-Content-Switch-Terms-Conditions{
    border-color: var(--blue-elec);
}
.Container-Content-Terms-Conditions .Switch-Content-Terms-Conditions .ant-switch-handle{
    top: 1px;
    width: 8px;
    height: 8px;
}
.Switch-Content-Terms-Conditions.ant-switch{
    background-color: var(--white);
}
.Switch-Content-Terms-Conditions.ant-switch:hover{
    background-color: var(--white);
}
.Switch-Content-Terms-Conditions.ant-switch .ant-switch-handle::before{
    background-color: #B0BFEC;
    box-shadow: none;
}
.Switch-Content-Terms-Conditions.ant-switch.ant-switch-checked:hover{
    background-color: var(--white);
}
.Switch-Content-Terms-Conditions.ant-switch-checked .ant-switch-handle::before{
    background-color: var(--blue-elec);
}
.Switch-Content-Terms-Conditions.ant-switch-small.ant-switch-checked .ant-switch-handle{
    inset-inline-start: calc(100% - 10px);
}
.Container-Terms-Conditions{
    padding-top: 74px;
}
.Title-Terms-Conditions{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    color: var(--blue) !important;
    margin-bottom: 4px !important;
}
.Text-Terms-Conditions, .Text-Second-Terms-Conditions{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 0;
}
.Text-Terms-Conditions{
    color: var(--blue);
}
.Text-Second-Terms-Conditions{
    color: var(--magenta);
}
.Title-Content-Terms-Conditions{
    font-family: var(--font-family);
    font-size: 16px !important;
    line-height: 19.36px !important;
    font-weight: var(--weight-bold) !important;
    color: var(--blue) !important;
    margin-bottom: 2px !important;
}
.Button-Terms-Conditions{
    background-color: rgb(41, 209, 147, .4);
    border-radius: 27px;
    height: auto;    
    border: 0;
    font-family: var(--font-family);
    padding: 11.5px 18.5px;
    font-size: 12px;
    font-weight: var(--weight-bold);
    line-height: 14.52px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-right: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.Button-Terms-Conditions.Active-Button-Terms-Conditions{
    background-color: var(--green);
}
.Button-Terms-Conditions .Text-Span-First-Terms-Conditions:first-child{
    transform: translate(0, 0);
    transition: .7s;
}
.Button-Terms-Conditions .Text-Span-Terms-Conditions{
    position: absolute;
    transform: translate(0, 180%);
    transition: .7s;
}
.Button-Terms-Conditions:hover .Text-Span-First-Terms-Conditions:first-child{
    transform: translate(0, -180%);
    transition: .7s;
}
.Button-Terms-Conditions:hover .Text-Span-Terms-Conditions{
    transform: translate(0, 0);
    transition: .7s;
}
/* .Button-Terms-Conditions.Active-Button-Terms-Conditions{

} */
.Button-Up{
    background-color: #3B72FF;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px !important;
    height: 52px !important;
    right: 50px !important;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 50px;
    z-index: 10;
    cursor: pointer;
}
.Icon-Button-Up-First{
    font-size: 25px;
    color: #fff !important;
    position: absolute;
    top: 8px;
    animation: Icon-Effect 1s ease-in-out infinite;
    animation-direction: alternate;
}
.Icon-Button-Up-Second{
    font-size: 25px;
    color: #fff !important;
    position: absolute;
    bottom: 6px;
    animation: Icon-Effect 1s ease-in-out infinite;
    animation-direction: alternate;
    animation-delay: .25s;
}
.Button-Up:hover{
    background-color: #013A81;
}
@keyframes Icon-Effect {
    0%{
        opacity: 1;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 0;
    }
}
/*  */
.carousel-tablafiltro {
    overflow: hidden;
    height: 20px;
    line-height: 20px;
    margin: 0 auto;
    position: relative;
    width: 300px;
    text-align: right;
    right: 0;
    position: absolute;
  }
.carousel-tablafiltro ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    animation: scroll 10s linear infinite;
    
  }
.carousel-tablafiltro li {
    font-family: 'Inter';
    font-size: 12px;
    color: #013A81;
  }
@keyframes scroll {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-40px);
    }
    80% {
      transform: translateY(-40px);
    }
    90% {
      transform: translateY(-60px);
    }
    100% {
      transform: translateY(-60px);
    }
  }
.botonFiltro{
    font-size: 11px !important;
    border-radius: 35px !important;
    color: #013A81 !important;
    border: 1px solid #2AD295 !important;
    height: auto !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    line-height: normal !important;
    margin-bottom: 0 !important;
    width: 100%;
    font-family: 'Inter';
}
.botonFiltro:hover{
    letter-spacing: .20em;
    box-shadow: 0 0 8px #2AD295;
    font-weight: bold;
}
.botonFiltro.activo{
    color: #ffffff !important;
    background-color: #2AD295 !important;
}
.estadisticaGrafica{
    margin-bottom: 26px;
    font-family: 'Inter';
    border: 1px solid #EFEBE6;
    border-radius: 20px;
    overflow: auto hidden;
}
.estadisticaGrafica::-webkit-scrollbar:horizontal{
    height: 7px;
}
.estadisticaGrafica::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: #3B72FF;
    border: 0;
}
.estadisticaGrafica::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 20px;
    margin: 0 40px;
}
.estadisticaGrafica .recharts-wrapper .recharts-legend-item svg.recharts-surface{
    margin-top: 0;
}
.estadisticaGrafica .etiquetaNombre{
    font-size: 11px;
    color: #013A81;
    font-weight: 700;
}
.estadisticaGrafica .recharts-layer.recharts-label-list text{
    font-size: 10px !important;
}
.recharts-layer.recharts-label-list text tspan{
    fill: #000 !important;
}
.recharts-layer.recharts-yAxis .recharts-cartesian-axis-line, .recharts-layer.recharts-yAxis .recharts-cartesian-axis-tick-line, .recharts-layer.recharts-xAxis .recharts-cartesian-axis-line, .recharts-layer.recharts-xAxis .recharts-cartesian-axis-tick-line{
    display: none;
}
.estadisticaGrafica .recharts-layer.recharts-xAxis .recharts-layer.recharts-cartesian-axis-tick text{
    font-size: 11px;
}
.recharts-legend-wrapper .recharts-legend-item.legend-item-0 .recharts-surface, .recharts-legend-wrapper .recharts-legend-item .recharts-surface{
    width: 11px !important;
    height: 11px !important;
}
.recharts-legend-wrapper .recharts-legend-item.legend-item-0 .recharts-surface path{
    fill: #C8F3E3 !important;
}
.recharts-legend-wrapper .recharts-default-legend{
    display: flex !important;
}
.recharts-legend-wrapper .recharts-legend-item{
    display: flex !important;
    align-items: center;
}
.recharts-cartesian-grid .recharts-cartesian-grid-vertical{
    display: none;
}
.recharts-cartesian-grid .recharts-cartesian-grid-horizontal{
    display: inline;
}
.estadisticaGrafica  .recharts-layer.recharts-yAxis .recharts-layer.recharts-cartesian-axis-tick text{
    font-size: 11px;
}
.estadisticaGrafica  .custom-tooltip{
    position: relative;
    background-color: #000;
    text-align: end;
    color: white;
    padding: 10px 12px;
    width: 220px;
    height: auto;
    border-radius: 8px;
}
.custom-tooltip .tooltipContenedorIn{
    display: flex;
}
.estadisticaGrafica  .custom-tooltip .tooltipContenedorIn p:nth-child(1){
    font-size: 11px;
    margin-bottom: 0;
    width: 75%;
}
.estadisticaGrafica  .custom-tooltip .tooltipContenedorIn p:nth-child(2){
    font-size: 11px;
    margin-bottom: 0;
    width: 25%;
}
.contenedorZoom{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}
.containerTextTitle{
    position: absolute;
    left: 20px;
    top: -12px;
}
.containerButtonDownload{
    position: absolute;
    top: 5px;
    right: 270px;
    z-index: 2;
    display: flex;
}
.containerButtonDownload-Dos{
    position: absolute;
    top: 5px;
    right: 480px;
    z-index: 2;
    display: flex;
}
.downloadText{
    font-family: 'Inter';
    font-size: 11px !important;
    color: #013A81;
    display: flex;
    align-items: center;
    margin-right: 2px;
}
.botonDownload{
    font-family: 'Inter';
    font-size: 11px !important;
    line-height: normal !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 0 !important;
    height: 30px !important;
    color: #fff !important;
    border-radius: 32px !important;
    margin-bottom: 0 !important;
    box-shadow: none !important;
}
.botonDownload.excelboton{
    background-color: #FFF !important;
    border-color: #FFF !important;
    width: 70px;
    margin-right: 0 !important;
}
.botonDownload.excelboton.Email{
    background-color: #FFF !important;
    border-color: #FFF !important;
    width: 50px !important;
    margin-right: 0 !important;
}
.botonDownload.pdfboton{
    background-color: #FFF !important;
    border-color: #FFF !important;
}
.botonDownload.excelboton:hover{
    transform: scale(1.1);
}
.botonDownload.pdfboton:hover{
    transform: scale(1.1);
}
.contenedorPestañas{
    font-family: 'Inter';
}
.contenedorPestañas .ant-tabs-nav{
    margin-bottom: 10px !important;
}
.contenedorPestañas .ant-tabs-tab{
    padding-left: 12px;
    padding-right: 13px;
    padding-top: 11px;
}
.contenedorPestañas .ant-tabs-nav-wrap{
    justify-content: flex-end;
}
.contenedorPestañas .ant-tabs-nav-list{
    transform: translate(-50px, 0px) !important;
}
.contenedorPestañas .pestañaFiltro{
    display: flex;
    align-items: end;
    font-size: 11px;
}
.contenedorPestañas .pestañaFiltro .imagenIconoFiltro{
    background-image: url('../../Assets/Images/PowerBi/FiltroAzul.png');
    width: 20px;
    background-size: 14px 14px;
    height: 15px;
    background-repeat: no-repeat;
}
.contenedorPestañas .ant-tabs-tab-active .imagenIconoFiltro, .contenedorPestañas .ant-tabs-tab:hover .imagenIconoFiltro{
    background-image: url('../../Assets/Images/PowerBi/FiltroAzulClaro.png');
}
.contenedorPestañas .ant-tabs-tab .pestañaVisualizar{
    display: flex;
    align-items: center;
    color: #013A81;
    font-size: 11px;
}
.contenedorPestañas .pestañaVisualizar .imagenIconoOjo{
    background-image: url('../../Assets/Images/PowerBi/OjoAzul.png');
    width: 20px;
    background-size: 14px 14px;
    height: 15px;
    background-repeat: no-repeat;
}
.contenedorPestañas .ant-tabs-tab-active .imagenIconoOjo, .contenedorPestañas .ant-tabs-tab:hover .imagenIconoOjo{
    background-image: url('../../Assets/Images/PowerBi/OjoVerde.png');
}
.contenedorPestañas .ant-tabs-tab .pestañaVisualizar span{
    font-size: 18px;
    margin-right: 10px;
}
.contenedorPestañas .ant-tabs-tab .ant-tabs-tab-btn{
    color: #013A81;
}
.contenedorPestañas .ant-tabs-tab:hover .ant-tabs-tab-btn{
    color: #3B72FF;
}
.contenedorPestañas .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #3B72FF;
}
.contenedorPestañas .ant-tabs-nav-list .ant-tabs-tab-active ~ .ant-tabs-ink-bar{
    border: 1px solid #3B72FF;
}
.contenedorPestañas .ant-tabs-tab:nth-child(2):hover .pestañaVisualizar{
    color: #2AD295;
}
.contenedorPestañas .ant-tabs-tab.ant-tabs-tab-active .pestañaVisualizar{
    color: #2AD295;
}
.contenedorPestañas .ant-tabs-nav-list .ant-tabs-tab-active:nth-child(2) ~ .ant-tabs-ink-bar{
    border: 1px solid #2AD295;
}
.contenedorPestañas .filaContenedorPestañas{
    justify-content: space-evenly;
    margin-bottom: 10px;
    padding: 0 25px;
    row-gap: 10px !important;
}
.contenedorPestañas .cardFiltro{
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: #EFEBE6;
}
.contenedorPestañas .cardFiltro .ant-card-body{
    padding: 10px 15px;
}
.contenedorPestañas .cardFiltro .ant-card-body .rowFiltroSelect{
    row-gap: 10px !important;
    justify-content: space-between;
}
.contenedorPestañas .cardFiltro .ant-card-body .rowFiltroSelectOculto{
    margin-top: 10px;
}
@media screen and (max-width: 992px){
    .containerTextTitle{
        top: 5px;
    }
    .containerButtonDownload{
        left: 230px;
    }
    .containerButtonDownload-Dos{
        left: 430px;
    }
    .contenedorPestañas .ant-tabs-nav-list{
        transform: translate(0px, 0px) !important;
    }
    .contenedorPestañas .cardFiltro .ant-card-body .rowFiltroSelectOculto{
        margin-top: 0;
    }
}
@media screen and (max-width: 735px){
    .containerTextTitle{
        position: relative;
        top: 0;
        left: 0;
    }
    .containerButtonDownload{
        position: absolute;
        top: 0;
        left: calc(100% - 193px);
    }
    .containerButtonDownload-Dos{
        position: absolute;
        top: 0;
        left: calc(100% - 293px);
    }
}
@media screen and (max-width: 573){
    .contenedorPestañas .cardFiltro .ant-card-body .rowFiltroSelect{
        justify-content: center;
    }
}
@media screen and (max-width: 485px){
    .containerTextTitle{
        margin-bottom: 5px;
    }
    .containerButtonDownload{
        position: relative;
        margin-bottom: 5px;
    }
    .containerButtonDownload-Dos{
        position: relative;
        margin-bottom: 5px;
    }
}
@media screen and (max-width: 310px){
    .containerButtonDownload{
        left: 0;
        justify-content: center;
    }
    .containerButtonDownload-Dos{
        left: 0;
        justify-content: center;
    }
}
.ant-spin-nested-loading .iconoCheckBox span{
    top: 200% !important;
    z-index: 100 !important;
}
.ant-spin-nested-loading .iconoRadio span{
    display: none;
}
.ant-spin-nested-loading:first-child .iconoRadio span{
    display: block;
    top: 200% !important;
}
.contenedorSimpleFiltro{
    border: 1px solid #EFEBE6;
    background-color: #fff;
    padding: 0;
    position: relative;
    border-radius: 8px;
    padding: 1px 0 0 0;
    position: relative;
}
.contenedorSimpleFiltro .filtroTitulo{
    font-size: 10px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px 5px 0 0;
    border: 0;
    background-color: #fff;
    color: #3B72FF !important;
    font-weight: 400 !important;
    position: absolute;
    z-index: 2;
    top: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}
.contenedorSimpleFiltro .iconoFiltro{
    position: absolute;
    top: 15px;
    font-size: 11px;
    right: 31px;
    color: #3B72FF;
    z-index: 4;
}
.contenedorSimpleFiltro .imgIconoFlechaFiltro{
    position: absolute;
    top: 11px;
    width: 22px;
    right: 5px;
    z-index: 2;
}
.contenedorSimpleFiltro .imgIconoFlechaFiltro.flechaAbajo{
    transform: rotateX(180deg);
}
.contenedorSimpleFiltro .filtro{
    background-color: transparent;
    border-radius: 0 0 8px 8px;
}
.contenedorSimpleFiltro .filtro .picky__dropdown .option:hover, .contenedorSimpleFiltro .filtro .picky__dropdown li:hover{
    background-color: #EDF2FF;
}
.contenedorSimpleFiltro .filtro button span{
    color: #013A81;
    font-size: 11px;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    padding-right: 20px;
}
.contenedorSimpleFiltro .filtro .picky__input{
    cursor: pointer !important;
    position: relative;
    z-index: 3;
    background-image: none;
    padding-right: 19px;
    text-align: start;
    padding-top: 22px;
    padding-bottom: 2px;
}
.contenedorSimpleFiltro .filtro .picky__header{
    margin-bottom: 20px;
}
.contenedorSimpleFiltro .filtro .picky__placeholder{
    color: #013A81;
    font-size: 11px;
    margin-left: 11px;
}
.contenedorSimpleFiltro .filtro button::after{
    color: #3B72FF;
    right: 16px;
    top: 0;
    display: none;
}
.contenedorSimpleFiltro .filtro .picky__reset{
    color: transparent;
    width: 100%;
    background-image: url('../../Assets/Images/PowerBi/BorrarFiltro.png');
    background-size: 15px 13px;
    background-position: 15px 6px;
    background-repeat: no-repeat;
    margin-right: 0;
    padding: 4px 0;
    font-size: 0;
}
.contenedorSimpleFiltro .filtro .picky__reset::before{
    content: 'Filtro';
    color: #013A81;
    margin-left: 36px;
    font-size: 11px;
}
.contenedorSimpleFiltro .filtro .picky__reset:hover{
    background-image: url('../../Assets/Images/PowerBi/BorrarFiltroAzulClaro.png');
    background-color: #EDF2FF;
}
.contenedorSimpleFiltro .filtro .picky__reset:hover::before{
    color: #3B72FF;
}
.contenedorSimpleFiltro .filtro .picky__dropdown{
    padding: 5px 0;
    margin-right: 0;
    border: 0;
    width: 100%;
    z-index: 10;
    margin-top: 1px;
    border-radius: 8px;
    border: 1px solid #EFEBE6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.contenedorSimpleFiltro .filtro .picky__dropdown::-webkit-scrollbar{
    width: 6px;
}
.contenedorSimpleFiltro  .filtro .picky__dropdown::-webkit-scrollbar-thumb{
    background-color: #013A81;
    border: 0;
    border-radius: 25px;
}
.contenedorSimpleFiltro  .filtro .picky__dropdown::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.contenedorSimpleFiltro .filtro .option{
    display: flex;
    width: 100%;
    padding: 2px 15px;
    align-items: center;
    color: #013A81;
}
.contenedorSimpleFiltro .filtro .ant-checkbox-wrapper.option{
    padding: 2px 15px;
}
.contenedorSimpleFiltro .option input{
    margin-right: 10px;
}
.contenedorSimpleFiltro .filtro .picky__filter{
    padding: 10px;
}
.contenedorSimpleFiltro .filtro .picky__filter:before{
    content: '';
}
.contenedorSimpleFiltro .picky__filter input{
    padding-left: 10px;
    padding-right: 24px;
    border-radius: 10px;
    height: 26px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.contenedorSimpleFiltro  .picky__filter input:focus{
    border: 1px solid #3B72FF;
}
.contenedorSimpleFiltro .picky__filter .picky__clear{
    right: 6px;
    font-size: 11px;
    top: 18px;
    color: transparent;
    background-image: url('../../Assets/Images/PowerBi/EquisAzul.png');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position-y: 3px;
    background-position-x: 2px;
}
.contenedorSimpleFiltro .picky__filter__input{
    font-size: 11px;
    height: 30px;
}
.contenedorSimpleFiltro .picky__filter__input::placeholder{
    color: #3B72FF;
    font-size: 11px;
}
.contenedorSimpleFiltro .picky__filter__input, .contenedorSimpleFiltro .option.selected, .contenedorSimpleFiltro .option.ant-radio-wrapper-checked{
    outline: none !important;
    color: #038FDE;
}
.contenedorSimpleFiltro .picky__dropdown .option{
    font-size: 11px !important; 
    margin-left: 0;
}
.contenedorSimpleFiltro .picky__dropdown .option .ant-radio-inner{
    border-color: #374957;
    width: 14px;
    height: 14px;
}
.contenedorSimpleFiltro .picky__dropdown .option .ant-checkbox{
    background-color: #fff;
    margin-top: -6px !important;
}
.contenedorSimpleFiltro .picky__dropdown .option .ant-checkbox .ant-checkbox-input{
    width: 12px;
    height: 12px;
}
.contenedorSimpleFiltro .picky__dropdown .option .ant-checkbox-inner{
    background-color: #fff;
    border: 1px solid #013A81;
    border-radius: 3px !important;
    width: 12px;
    height: 12px;
}
.contenedorSimpleFiltro .picky__dropdown .option .ant-radio{
    top: 0;
}
.contenedorSimpleFiltro .picky__dropdown .option.ant-radio-wrapper-checked .ant-radio-inner{
    border-color: #3B72FF;
    background-color: #fff;
}
.contenedorSimpleFiltro .picky__dropdown .option.ant-radio-wrapper-checked .ant-radio-inner::after{
    width: 16px;
    height: 16px;
    top: 0;
    left: -2px;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #3B72FF;
}
.contenedorSimpleFiltro .ant-checkbox-wrapper:hover .ant-checkbox-inner{
    background-color: #fff !important;
}
.contenedorSimpleFiltro .ant-checkbox-wrapper:hover .ant-checkbox-checked:after{
    border: 1px solid #1677ff !important;
}
.contenedorSimpleFiltro .picky__dropdown .option.selected span:nth-child(2){
    color: #3B72FF;
}
.contenedorSimpleFiltro .picky__dropdown .option.selected .ant-checkbox-inner{
    border: 1px solid #3B72FF;
}
.contenedorSimpleFiltro .picky__dropdown .option .ant-checkbox-inner::after{
    border: 2px solid #3B72FF;
    border-top: 0;
    border-left: 0;
    width: 3.714286px;
    height: 8.142857px;
}
.contenedorSimpleFiltro .picky__dropdown .option:focus{
    outline: none !important;
}
.contenedorSimpleFiltro .picky__dropdown .option .ant-radio-wrapper span:nth-child(2), .contenedorSimpleFiltro .picky__dropdown .option span:nth-child(2){
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.contenedorSimpleFiltro .picky__dropdown .option input{
    margin: 0;
}
.subtituloTablaDinamica{
    margin-bottom: 0 !important;
    font-family: 'Inter';
    color: #013A81 !important;
    font-size: 14px !important;
}
.contenedorSubtitulo .subtituloIcono{
    font-size: 22px;
}
.colFechaCarousel{
    display: flex !important;
    align-items: center;
    justify-content: end;
    padding: 0 !important;
}
.botonesPrincipales{
    font-family: 'Inter';
    font-size: 11px !important;
    border-radius: 32px !important;
    color: #fff !important;
    border: 0 !important;
    height: auto !important;
    padding: 7px 34px 7px 34px !important;
    line-height: normal !important;
    margin-bottom: 0 !important;
    background-color: #3B72FF !important;
    box-shadow: 0 !important;
}
.botonesPrincipales span:first-child{
    transform: translate(0, 0);
    transition: .7s;
    opacity: 1;
}
.botonesPrincipales .botonNombreDos{
    position: absolute;
    transform: translate(-100%, 100%);
    opacity: 0;
    transition: .7s;
}
.botonesPrincipales:hover span:first-child{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}
.botonesPrincipales:hover .botonNombreDos{
    transform: translate(-100%, 0) !important;
    transition: .7s !important;
    opacity: 1 !important;
}
.botonesPrincipales.desactivado{
    background-color: rgba(59, 114, 255, .25) !important;
}
.contenedorTooltipFecha .ant-tooltip-inner{
    padding: 10px 20px;
}
.contenedorTooltipFecha .contenedorInternoFecha{
    font-family: 'Inter';
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
    text-transform: capitalize;
}
.contenedorFechas{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    position: relative;
}
.contenedorFechas div{
    font-family: 'Inter';
    font-size: 12px;
    line-height: 17px;
    color: #013A81;
    position: absolute;
}
.contenedorFechas div:nth-child(1){
    animation: changeTitle 8s infinite;
    transform: translate(0, 2px);
    animation-delay: 2s;
}
.contenedorFechas div:nth-child(2){
    animation: changeTitle2 8s infinite;
    transform: translate(0, 20px);
    animation-delay: 2s;
}
.contenedorFechas div:nth-child(3){
    animation: changeTitle3 8s infinite;
    transform: translate(0, 20px);
    animation-delay: 2s;
}
.contenedorFechas div:nth-child(4){
    animation: changeTitle4 8s infinite;
    transform: translate(0, 20px);
    animation-delay: 2s;
}
.contenedorFechas div span{
    text-transform: capitalize;
}
@keyframes changeTitle{
    0%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    12%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    24%{
        transform: translate(0, -20px);
        visibility: hidden;
    }
    88%{
        transform: translate(0, 20px);
        visibility: hidden;
    }
    100%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
}
@keyframes changeTitle2{
    0%{
        transform: translate(0, 20px);
        visibility: hidden;
    }
    12%{
        transform: translate(0, 20px);
        visibility: inherit;
    }
    24%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    36%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    48%{
        transform: translate(0, -20px);
        visibility: hidden;
    }
    100%{
        transform: translate(0, 20px);
        visibility: hidden;
    }
}
@keyframes changeTitle3{
    0%{
        transform: translate(0, 20px);
        visibility: hidden;
    }
    36%{
        transform: translate(0, 20px);
        visibility: inherit;
    }
    48%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    60%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    72%{
        transform: translate(0, -20px);
        visibility: hidden;
    }
    100%{
        transform: translate(0, 20px);
        visibility: hidden;
    }
}
@keyframes changeTitle4{
    0%{
        transform: translate(0, 20px);
        visibility: hidden;
    }
    60%{
        transform: translate(0, 20px);
        visibility: inherit;
    }
    72%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    88%{
        transform: translate(0, 2px);
        visibility: inherit;
    }
    100%{
        transform: translate(0, -20px);
        visibility: hidden;
    }
}
.filaTabla{
    font-family: 'Inter' !important;
    padding-bottom: 3px;
    margin-bottom: 7px;
    overflow-x: auto;
}
.filaTabla::-webkit-scrollbar-thumb {
    background-color: #3B72FF;
    border-radius: 25px;
}
.filaTabla::-webkit-scrollbar{
    height: 7px;
}
.filaTabla::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: #3B72FF;
    border: 0;
}
.filaTabla::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 20px;
    margin: 0 40px;
}
.filaTabla .ant-col{
    padding: 0 !important;
}
.filaTabla .iconoCargandoIzquierdo span{
    display: none;
}
.filaTabla .ant-spin-container{
    display: grid;
    grid-template-columns: 130px 1fr;
}
.filaTabla .iconoCargandoDerecha.iconoDerecha span{
    top: 9% !important;
    left: 97% !important;
}
.tablaDatos .ant-table{
    width: 100%;
}
.tablaDatos .ant-table-fixed-header{
    border-radius: 0 20px 20px 0;
    border: 1px solid #e8e8e8;
    border-left-width: 0;
}
.tablaDatos .ant-table-header{
    background-color: white;
    border-radius: 0 20px 0 0 !important;
}
.filaTabla .tablaDatos .ant-spin-container{
    display: inline;
}
.tablaDatos .ant-table-header colgroup col:last-child{
    display: none;
}
.tablaDatos thead{
    text-align: center;
}
.tablaDatos thead tr th{
    padding-left: 1px !important;
    padding-right: 3px !important;
}
.tablaDatos thead tr:nth-child(1) th:nth-child(1){
    border-start-start-radius: 0px !important;
}
.tablaDatos thead tr:nth-child(1) th:nth-child(1), .tablaDatos thead tr:nth-child(1) th:nth-child(2){
    background-color: #DCE2F0 !important;
    color: #013A81 !important;
    font-size: 11px;
    border-top-left-radius: 0 !important;
    font-weight: bold;
    border-right: 1px solid #EFEBE6;
    padding-top: 5px;
    padding-bottom: 5px;
}
.tablaDatos thead tr:nth-child(1) th:nth-child(3){
    border-bottom: 1px solid #EFEBE6;
}
.tablaDatos thead tr:nth-child(1) th:nth-child(3), .tablaDatos thead tr:nth-child(2) th{
    background-color: #D7E3FE !important;
    color: #013A81 !important;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}
.tablaDatos thead tr:nth-child(1) th:nth-child(3){
    font-size: 11px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    font-weight: bold;
}
.tablaDatos .tituloPrincipalTabla{
    font-weight: bold;
    font-size: 11px;
}
.tablaDatos thead tr:nth-child(2) th{
    font-size: 11px;
}
.tablaDatos thead tr:nth-child(1) .ant-table-cell.ant-table-cell-scrollbar{
    display: none;
}
.tablaDatos thead tr th::before{
    display: none;
}
.tablaDatos .ant-table-column-title{
    position: initial;
}
.tablaDatos .contenedorExpandirReducir{
    position: absolute;
    opacity: 0;
    right: 8px;
    top: 6px;
    display: flex;
    column-gap: 5px;
}
.tablaDatos:hover .contenedorExpandirReducir{
    opacity: 1;
}
.tablaDatos .iconoExpandirReducir{
    background-color: #fff;
    color: #3B72FF;
    padding: 3px;
}
.tablaDatos thead th .ant-table-column-sorter-full .anticon-caret-down,
.tablaDatos thead th .ant-table-column-sorter-full .anticon-caret-up{
    color: #013A81;
    font-size: 12px;
    opacity: 0;
}
.tablaDatos thead th:hover .ant-table-column-sorter-full .anticon-caret-down,
.tablaDatos thead th:hover .ant-table-column-sorter-full .anticon-caret-up{
    opacity: 1;
}
.tablaDatos thead th .ant-table-column-sorter-full .active{
    opacity: 1;
}
.tablaDatos thead tr th .ant-table-column-sorter-full .anticon-caret-up{
    display: none;
}
.tablaDatos .iconosTablaReducirExpandir{
    position: absolute;
    right: 12px;
    top: 4px;
}
.tablaDatos .iconosTablaReducirExpandir .iconoTablaReducir{
    margin-right: 5px;
}
.tablaDatos .iconosTablaReducirExpandir .iconoTablaReducir{
    width: 20px;
    height: 20px;
}
.tablaDatos .iconosTablaReducirExpandir .iconoTablaExpandir{
    width: 20px;
    height: 20px;
}
.tablaDatos .iconosTablaReducirExpandir .iconoTablaReducir, .tablaDatos .iconosTablaReducirExpandir .iconoTablaExpandir{
    padding: 4px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
}
.tablaDatos.ant-table-wrapper .ant-table-column-sorters{
    flex-direction: column;
    height: 100%;
    line-height: 14.3px;
}
.tablaDatos.ant-table-wrapper .ant-table-column-sorters .ant-table-column-sorter{
    align-self: flex-start;
    position: absolute;
    bottom: 5px;
    left: 3px;
    height: 8.41px;
}
.tablaDatos .ant-table-body::-webkit-scrollbar:vertical{
    width: 6.5px;
}
.tablaDatos .ant-table-body::-webkit-scrollbar:horizontal{
    height: 10px;
}
.tablaDatos .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #3B72FF;
    border-radius: 25px;
}
.tablaDatos .ant-table-body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f4f4f4;
}
.tablaDatos .ant-table-column-title{
    display: flex;
    align-items: center;
    font-size: 10px;
}
.tablaDatos tbody tr{
    position: relative;
}
.tablaDatos .celdaIconoSemaforo{
    width: 20px;
}
.tablaDatos tbody tr:not(tr:nth-child(1)) td{
    padding: 5px 2px 5px 2px !important;
    background-color: #EFEBE6;
    font-size: 11px;
    line-height: 14.3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 700;
    color: #013A81;
    border: 0;
    border-bottom: 1px solid #EFEBE6;
    text-align: center;
}
.tablaDatos tbody tr td.ant-table-column-sort{
    background-color: #EFEBE6;
}
.tablaDatos tbody tr td:nth-child(1){
    text-align: start;
}
.tablaDatos .ant-table-body tbody tr:hover{
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    z-index: 2;
}
.tablaDatos tbody tr td:nth-child(1), .tablaDatos tbody tr td:nth-child(2){
    border-right: 1px solid #EFEBE6;
}
.tablaDatos tbody .ant-table-row-level-0:hover td{
    background-color: #EFEBE6 !important;
}
.tablaDatos tbody .ant-table-row-level-0 td:first-child{
    padding-left: 7px !important;
}
.ant-table-wrapper .ant-table-row-level-1 td{
    background-color: #FBF8F2 !important;
    font-size: 10px !important;
    font-weight: 700 !important;
}
.ant-table-wrapper .ant-table-row-level-1:hover td{
    background-color: #FBF8F2 !important;
}
.ant-table-wrapper .ant-table-row-level-2 td{
    background-color: white !important;
    font-size: 10px !important;
    font-weight: 600 !important;
}
.ant-table-wrapper .ant-table-row-level-2:hover td{
    background-color: white !important;
}
.ant-table-wrapper .ant-table-row-level-3 td{
    background-color: #FFFFFF !important;
    font-size: 10px;
    font-weight: 400;
}
.ant-table-wrapper .ant-table-row-level-3:hover td{
    background-color: #FFFFFF !important;
}
.tablaDatos .ant-table-row-indent + .ant-table-row-expand-icon{
    margin-top: 0;
    width: 15px;
    height: 15px;
    color: #013A81;
    background-color: #fff;
    margin-right: 5px;
    border: 1px solid #013A81;
    border-radius: 3px;
}
.tablaDatos .ant-table-row-indent + .ant-table-row-expand-icon::before{
    top: 6px;
    right: 2px;
    left: 2px;
    height: 1px;
}
.tablaDatos .ant-table-row-indent + .ant-table-row-expand-icon::after{
    top: 2px;
    bottom: 2px;
    left: 6px;
    width: 1px;
}
.tablaDatos .ant-table-cell-with-append button{
    border: 1px solid #013A81;
    background-color: #FBF8F2 !important;
    border-radius: 0;
}
.tablaDatos .ant-table-cell-with-append button:hover, .tablaDatos .ant-table-cell-with-append button:focus{
    color: #013A81;
    background-color: #fff;
}
.tablaDatos tbody .ant-table-placeholder td{
    border-right: 0 !important;
}
.tablaDatos .ant-pagination{
    display: none;
}
.tablaDatos .ant-table-column-sorter-up{
    color: #013A81 !important;
}
.tablaDatos .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{
    color: #013A81 !important;
}
.tablaDatos thead th.zonaTablaascend .anticon-caret-up, .tablaDatos th.sellInTablaascend .anticon-caret-up, .tablaDatos th.sellOutTablaascend .anticon-caret-up, .tablaDatos th.sellOutLMSPTablaascend .anticon-caret-up, .tablaDatos th.sellOutLYSPTablaascend .anticon-caret-up, .tablaDatos th.sellOutPL4MSPTablaascend .anticon-caret-up, .tablaDatos th.proySellOutTablaascend .anticon-caret-up, .tablaDatos th.sellOutLMTablaascend .anticon-caret-up, .tablaDatos th.sellOutLYTablaascend .anticon-caret-up, .tablaDatos th.sellOutPL4MTablaascend .anticon-caret-up, .tablaDatos th.invTablaascend .anticon-caret-up, .tablaDatos th.doiTablaascend .anticon-caret-up, .tablaDatos th.inStockTablaascend .anticon-caret-up, .tablaDatos th.filRateTablaascend .anticon-caret-up, .tablaDatos th.filRateValorTablaascend .anticon-caret-up, .tablaDatos th.categoriaTabladescend .anticon-caret-down, .tablaDatos th.clienteHmlTabladescend .anticon-caret-down{
    display: block !important;
}
.tablaDatos thead th.zonaTablaascend .anticon-caret-down, .tablaDatos th.sellInTablaascend .anticon-caret-down, .tablaDatos th.sellOutTablaascend .anticon-caret-down, .tablaDatos th.sellOutLMSPTablaascend .anticon-caret-down, .tablaDatos th.sellOutLYSPTablaascend .anticon-caret-down, .tablaDatos th.sellOutPL4MSPTablaascend .anticon-caret-down, .tablaDatos th.proySellOutTablaascend .anticon-caret-down, .tablaDatos th.sellOutLMTablaascend .anticon-caret-down, .tablaDatos th.sellOutLYTablaascend .anticon-caret-down, .tablaDatos th.sellOutPL4MTablaascend .anticon-caret-down, .tablaDatos th.invTablaascend .anticon-caret-down, .tablaDatos th.doiTablaascend .anticon-caret-down, .tablaDatos th.inStockTablaascend .anticon-caret-down, .tablaDatos th.filRateTablaascend .anticon-caret-down, .tablaDatos th.filRateValorTablaascend .anticon-caret-down, .tablaDatos th.categoriaTabladescend .anticon-caret-up, .tablaDatos th.clienteHmlTabladescend .anticon-caret-up{
    display: none;
}
.tablaDatos .ant-table-body{
    overflow: auto auto !important;
}
.tablaDatos .ant-table-summary{
    background-color: transparent;
    border-radius: 0 0 20px 0;
}
.tablaDatos .ant-table-summary:hover{
    box-shadow: inset 1px 0 0 rgb(42, 210, 149, .2), inset -1px 0 0 rgb(42, 210, 149, .2), 0 1px 2px 0 rgb(42, 210, 149, .4), 0 1px 3px 1px rgb(42, 210, 149, .4);
}
.tablaDatosFooter td{
    padding: 5px 2px 5px 2px !important;
    font-size: 11px !important;
    white-space: nowrap;
    font-weight: 700;
    color: #013A81;
    background-color: rgb(42, 210, 149, .4);
    border: 0;
    text-align: center;
    border-bottom: 0 !important;
    transition: .5s;
}
.tablaDatos .ant-table-summary colgroup col:last-child{
    display: none;
}
.tablaDatosFooter td:first-child{
    border-radius: 0;
    padding-left: 11px !important;
    border-right: 1px solid ;
    text-align: start;
}
.tablaDatosFooter td:nth-child(1), .tablaDatosFooter td:nth-child(2){
    border-right: 1px solid #EFEBE6;
}
.tablaDatosFooter .celdaDatosFooter, .ant-table-body .celdaDatosBody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px;
}
.tarjetaTablaContenedor{
    border-radius: 20px 0 0 20px !important;
    width: 130px;
    margin-bottom: 0 !important;
}
.tarjetaTablaContenedor.ant-card-type-inner .ant-card-head{
    background-color: #D7E3FE !important;
    border-radius: 20px 0 0 0 !important;
    height: 54.8px;
    display: flex;
    justify-content: center;
}
.tarjetaTablaContenedor .ant-card-head .ant-card-head-title{
    color: #013A81;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    white-space: normal;
    text-overflow: initial;
}
.tarjetaTablaContenedor .ant-card-body{
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-bottom: 8px !important;
}
.tarjetaTablaContenedor .etiquetaTabla{
    padding: 3px 12px;
    border-radius: 32px;
    font-size: 11px;
    margin-bottom: 8px;
}
.tarjetaTablaContenedor .etiquetaTabla span{
    font-size: 11px;
    margin-left: 5px;
}
@media screen and (max-width: 1200px){
    .contenedorSubtitulo{
        margin-left: 0;
    }
}
@media screen and (max-width: 992px){
    .contenedorSubtitulo{
        display: flex;
        flex-wrap: wrap;
        align-items: start !important;
        row-gap: 10px !important;
    }
}
@media screen and (max-width: 768px){
    .contenedorBotonesPrincipales{
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px !important;
    }
}
@media screen and (max-width: 576px){
    .contenedorFechas{
        align-items: center;
    }
    .contenedorBotonesPrincipales{
        justify-content: space-evenly;
    }
    .contenedorBotonesPrincipales .botonesPrincipales{
        width: 160px;
    }
    @keyframes changeTitle{
        0%{
            transform: translate(0, 2px);
        }
        10%{
            transform: translate(0, -40px);
            visibility: hidden;
        }
        33%{
            transform: translate(0, -40px);
            visibility: hidden;
        }
        66%{
            transform: translate(0, 40px);
            visibility: hidden;
        }
        76%{
            transform: translate(0, 2px);
        }
        100%{
            transform: translate(0, 2px);
        }
    }
    @keyframes changeTitle2{
        0%{
            transform: translate(0, 40px);
            visibility: hidden;
        }
        10%{
            transform: translate(0, 2px);
            visibility: initial;
        }
        33%{
            transform: translate(0, 2px);
            visibility: initial;
        }
        43%{
            transform: translate(0, -40px);
            visibility: hidden;
        }
        66%{
            transform: translate(0, -40px);
            visibility: hidden;
        }
        100%{
            transform: translate(0, 40px);
            visibility: hidden;
        }
    }
}
.ant-table-cell{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    line-height: 14.3px;
}
.tituloEncabezado{
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    z-index: 2;
    position: relative;
    padding: 16px 24px;
}
.ant-page-header-heading-left{
    display: flex;
    align-items: center;
    margin: 4px 0;
    overflow: hidden;
}
.tituloEncabezado .iconoEncabezado{
    border-radius: 50%;
    background: #2AD295;
    border: 1px solid #2AD295;
    padding: 8px;
    font-size: 14px;
    color: #fff;
    opacity: 0;
}
.tituloEncabezado .ant-page-header-back-button:focus, .tituloEncabezado .ant-page-header-back-button:hover{
    color: #fff;
}
.tituloEncabezado .ant-page-header-back{
    margin-right: 0;
}
.tituloEncabezado .ant-page-header-heading-title{
    font-size: 16px;
    font-family: 'Inter';
    color: #013A81;
    font-weight: 700;
}
.tituloEncabezado .ant-page-header-heading-title span{
    position: relative;
    opacity: 0;
    z-index: 1;
}
.tituloEncabezado .ant-page-header-heading-title span.animateEffect{
    animation: textEffect 4s ease-out infinite;
}
.tituloEncabezado .ant-page-header-heading-title span.animateEffectInactive{
    animation: none;
    opacity: 1;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(1){
    animation-delay: .15s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(2){
    animation-delay: .2s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(3){
    animation-delay: .25s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(4){
    animation-delay: .3s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(5){
    animation-delay: .35s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(6){
    animation-delay: .4s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(7){
    animation-delay: .45s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(8){
    animation-delay: .5s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(9){
    animation-delay: .55s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(10){
    animation-delay: .6s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(11){
    animation-delay: .65s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(12){
    animation-delay: .7s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(13){
    animation-delay: .75s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(14){
    animation-delay: .8s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(15){
    animation-delay: .85s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(16){
    animation-delay: .9s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(17){
    animation-delay: .95s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(18){
    animation-delay: 1s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(19){
    animation-delay: 1.05s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(20){
    animation-delay: 1.1s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(21){
    animation-delay: 1.15s;
}
.tituloEncabezado .ant-page-header-heading-title span:nth-child(22){
    animation-delay: 1.2s;
}
@keyframes textEffect{
    0%{ 
        opacity: 0;
        bottom: -20px;
    }
    7%{
        opacity: .4;
        bottom: 0px;
    }
    20%, 50%{
        opacity: 1;
    }
    70%, 100%{
        opacity: 0;
    }
}
@media screen and (max-width: 992px){
    .tituloEncabezado .iconoEncabezado{
        display: none;
    }
    .tituloEncabezado .ant-page-header-heading-left{
        margin: 0;
    }
    .tituloEncabezado.ant-page-header{
        padding: 0;
    }
}
.Container-Image-Country{
    width: 120px;
    height: 70px;
    border: 1px solid rgba(176, 191, 236, 0.5);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Container-Image-Platform-Administration{
    width: 220px;
    height: 170px;
    border: 1px solid rgba(176, 191, 236, 0.5);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/*  Componentes Globales  */
.Container-Side{
    position: fixed;
    top: 38px;
    left: 0;
    width: 30px;
    height: 100vh;
    padding-top: 6px;
    padding-right: 5px;
    padding-bottom: 15px;
    padding-left: 5px;
    z-index: 3;
    background-color: transparent;
    transition: width 1s, background-color 0s .5s, padding-right 0s .5s, padding-top .3s 0s;
}
.Container-Side::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    background-color: var(--cream);
    transition: width .5s;
}
.Container-Side:hover::before{
    width: 30px;
    background-color: var(--cream);
    transition: width .5s;
}
.Container-Side.Active-Side::before, .Container-Side.Active-Side:hover::before{
    width: 0;
    background-color: transparent;
}
.Container-Side.Active-Side{
    width: 230px;
    background-color: var(--light-blue);
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.3);
    border-radius: 0 8px 8px 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 24px;
    padding-left: 24px;
    transition: width 1s;
}
.Container-Side .Item-Icon-Side{
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    background-color: var(--green);
    border-radius: 0 8px 8px 0;
    box-shadow: 0 1px 6px #B2B2B2;
    transition: background-color .3s, box-shadow .3s;
    transition-delay: .4s;
}
.Container-Side:hover .Item-Icon-Side{
    background-color: transparent;
    box-shadow: none;
    transition: background-color 0s, box-shadow 0s;
    transition-delay: 0s;
}
.Container-Side.Active-Side .Item-Icon-Side{
    background-color: transparent;
    box-shadow: none;
}
.Container-Title-Side{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    position: relative;
    height: 30px;
}
.Container-Side.Active-Side .Container-Title-Side{
    justify-content: space-between;
}
.Container-Title-Side .Title-Side{
    font-family: var(--font-family);
    color: var(--blue);
    font-size: var(--size-regular);
    font-weight: var(--weight-bold);
    line-height: var(--lineheight-regular);
    margin-bottom: 0;
    position: absolute;
    opacity: 0;
    transition: position .1s, opacity .1s;
}
.Container-Side.Active-Side .Title-Side{
    position: relative;
    opacity: 1;
    transition: position 1s .5s, opacity 1s .5s;
}
.Container-Side .Icon-Title-Side{
    color: var(--white);
    font-size: 18px;
    transform: rotateZ(90deg);
    position: absolute;
    right: 0;
    top: 0;
    transition: color 0s, transform 1s 0s, font-size .5s 0s;
}
.Container-Side.Active-Side .Icon-Title-Side{
    transition: color 0s, transform .5s 0s, font-size .5s .3s;
    top: 3px;
}
.Container-Side:hover .Icon-Title-Side.Active-Icon-Side{
    color: var(--blue-elec);
    transition: color 0s, transform .5s 0s, font-size .5s .3s;
}
.Container-Side .Icon-Title-Side.Inactive-Icon-Side{
    color: var(--white) !important;
    transition: color 0s, transform .5s 0s, font-size .5s .3s;
}
.Container-Side.Active-Side .Icon-Title-Side{
    transform: rotateZ(-90deg);
    font-size: 26px;
    color: var(--blue-elec);
}
.Container-Side:hover .Container-Hover-Side-User{
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0s;
    transition-delay: .5s;
}
.Container-Side.Active-Side .Container-Hover-Side-User, .Container-Side.Active-Side:hover .Container-Hover-Side-User{
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0s;
    transition-delay: 0s;
}
.Container-Hover-Side-User{
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    margin-top: 6px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0s;
    transition-delay: 0s;
}
.Container-Hover-Side-User .Icon-User-Subitem-Side{
    font-size: 12px;
}
.Container-Hover-Side-User .Container-Icon-Subitem-Side{
    background-color: var(--blue);
}
.Container-Hover-Side-User .Item-Icon-Side{
    position: absolute;
    bottom: -1px;
    right: -1px;
    top: auto !important;
    left: auto !important;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Container-Hover-Side-User .Circle-Icon-Side{
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.Container-Hover-Side-User .Active-User-Side{
    background-color: var(--green);
}
.Container-Hover-Side-User .Inactive-User-Side{
    background-color: #FFC700;
}
.Container-Hover-Side-User .Disable-User-Side{
    background-color: #C4C4C4;
}
.Container-List-User-Side{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
}
.Container-Side.Active-Side .Container-List-User-Side{
    display: none;
}
.View-User-Side .ant-tooltip-arrow::after{
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
}
.View-User-Side .ant-tooltip-inner{
    display: flex;
    column-gap: 5px;
    align-items: center;
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    color: var(--blue-elec);
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
}
.View-User-Side .View-Circle-User-Side{
    width: 16px;
    height: 16px;
    border: 3px solid var(--white);
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}
.View-User-Side .View-Circle-User-Side.View-Circle-Connected{
    border: 3px solid var(--green);
}
.View-User-Side .View-Circle-User-Side.View-Circle-Absent{
    border: 3px solid var(--yellow);
}
.View-User-Side .View-Circle-User-Side::before{
    content: '';
    width: 6px;
    height: 6px;
    background-color: var(--white);
    border-radius: 50%;
}
.View-User-Side .View-Circle-User-Side.View-Circle-Connected::before{
    background-color: var(--green);
}
.View-User-Side .View-Circle-User-Side.View-Circle-Absent::before{
    background-color: var(--yellow);
}
.Circle-Activity-User-Side.Circle-Connected .ant-badge-dot{
    background-color: var(--green) !important;
}
.Circle-Activity-User-Side.Circle-Absent .ant-badge-dot{
    background-color: var(--yellow) !important;
}
.Container-Icon-User-Side{
    background-color: var(--blue);
}
.Icon-User-Side{
    color: var(--white);
    vertical-align: 0;
}
.Container-Card-Side{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 15px;
    position: absolute;
    opacity: 0;
    transition: position .1s, opacity 0s;
    visibility: hidden;
}
.Container-Side.Active-Side .Container-Card-Side{
    position: relative;
    opacity: 1;
    visibility: visible;
    transition: position 1s .5s, opacity 1s .5s;
}
.Card-Item-Side{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
    background: var(--white);
    border-radius: 9px;
    padding: 7px 12px;
    position: relative;
    cursor: pointer;
}
.Card-Item-Side.Active-Hover{
    background-color: var(--blue-elec);
}
.Card-Item-Side.Inactive-Hover:hover::after, .Card-Item-Side.Inactive-Hover:hover::before{
    display: none;
}
.Card-Item-Side:hover::after, .Card-Item-Side.Active-Hover::after{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotateZ(45deg);
    background-color: var(--white);
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    right: -55px;
    border-radius: 5px;
    cursor: default;
    z-index: 1;
}
.Card-Item-Side:hover::before, .Card-Item-Side.Active-Hover::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotateZ(45deg);
    background-color: var(--white);
    right: -55px;
    border-radius: 5px;
    cursor: default;
    z-index: 3;
}
.Card-Item-Side .Text-Item-Side{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    color: var(--blue-elec);
    margin-bottom: 0;
}
.Card-Item-Side.Active-Hover .Text-Item-Side{
    color: var(--white);
}
.Icon-Card-Side{
    font-size: 12px;
    color: var(--blue-elec);
}
.Card-Item-Side.Active-Hover .Icon-Card-Side{
    color: var(--white);
}
.Container-Hover-Subitem-Side{
    position: fixed;
    left: 250px;
    width: 250px;
    top: 50px;
    height: 100vh;
    z-index: 2;
    display: none;
}
.Card-Hover-Side{
    position: absolute;
    width: 50px;
    height: 70px;
    right: -50px;
    z-index: 2;
    display: none;
    cursor: default;
}
.Container-Card-Subitem-Side{
    position: fixed;
    top: 38px;
    left: 250px;
    width: 265px;
    padding: 20px 20px 70px 20px;
    height: 100vh;
    background-color: var(--white);
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    z-index: 3;
    cursor: default;
    display: flex;
    flex-direction: column;
}
.Card-Item-Side:hover .Container-Hover-Subitem-Side, .Card-Item-Side:hover .Card-Hover-Side, .Card-Item-Side.Active-Hover .Container-Hover-Subitem-Side, .Card-Item-Side.Active-Hover .Card-Hover-Side{
    display: block;
}
.Card-Item-Side:hover .Container-Card-Subitem-Side, .Container-Hover-Subitem-Side:hover .Container-Card-Subitem-Side, .Card-Item-Side.Active-Hover .Container-Card-Subitem-Side{
    display: flex;
    flex-direction: column;
}
.Card-Item-Side.Inactive-Hover .Container-Card-Subitem-Side{
    display: none;
}
.Container-Title-Subitem-Side{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
}
.Container-Title-Subitem-Side .Title-Subitem-Side{
    font-family: var(--font-family);
    color: var(--blue);
    font-size: var(--size-regular);
    font-weight: var(--weight-bold);
    line-height: var(--lineheight-regular);
    margin-bottom: 0;
}
.Icon-Subitem-Side{
    color: var(--blue-elec);
    font-size: 26px;
    transform: rotateZ(-90deg);
}
.Container-AllUser-Subitem-Side{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
    padding: 7px 0;
    margin-top: 5px;
}
.Container-Search-AllUser-Subitem-Side{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
    padding: 2px 0;
    border-bottom: 1px solid var(--lead);
    margin-top: 5px;
}
.AllUser-Text-Subitem-Side, .Count-AllUser-Subitem-Side{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    color: rgba(1, 58, 129, 0.5);
}
.Count-AllUser-Subitem-Side .ant-input, .Count-AllUser-Subitem-Side input::placeholder{
    color: rgba(1, 58, 129, 0.5) !important;
}
.Container-Subitem-Side{
    overflow-y: scroll;
    overflow-x: hidden;
}
.Container-Subitem-Side::-webkit-scrollbar{
    width: 5px;
    height: 7px;
}
.Container-Subitem-Side::-webkit-scrollbar-thumb{
    background-color: var(--blue-elec);
    border: 0;
    border-radius: 50px;
}
.Container-Subitem-Side::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin: 50px 20px;
}
.Container-List-Subitem-Side{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
    margin-right: 8px;
}
.Card-Subitem-Side{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
}
.Container-Text-Subitem-Side{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.Container-List-Subitem-Side .Container-Icon-Subitem-Side{
    background-color: var(--blue-elec);
    min-width: 26px;
    min-height: 26px;
    max-width: 26px;
    min-width: 26px;
}
.Icon-User-Subitem-Side{
    vertical-align: 0;
}
.Container-Text-Subitem-Side p{
    font-family: var(--font-family);
    font-weight: var(--weight-regular);
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
    color: var(--blue);
    margin-bottom: 0;
}
.Icon-Circle-Subitem-Side{
    border: 3px solid var(--green);
    border-radius: 50%;
}
.Icon-Circle-Subitem-Side .Circle-Internal-Subitem-Side{
    width: 10px;
    height: 10px;
    background-color: var(--green);
    border-radius: 50%;
    margin: 3px;
}
.Button-Create-Global{
    background-color: rgba(42, 210, 149, 0.5);
    display: flex;
    align-items: center;
    transition: 0.5s;
}
.Button-Create-Global:hover{
    background-color:  var(--green) !important;
}
.Button-Create-Global span{
    font-size: var(--size-regular);
    font-family: var(--font-family);
    font-weight: bold;
}
.Button-Global{
    background-color: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 27px !important;
    padding: 4px 8px 4px 6px !important;
    column-gap: 10px;
    height: auto !important;
    border: 0;
}
.Button-Global:hover{
    background-color: var(--green) !important;
    border: 0;
}
.Button-Global span{
    font-size: 12px;
    line-height: 14.52px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    margin-inline-start: 0 !important;
}
.Float-Bottom-Top-Button{
    inset-inline-end: 3.5%;
    inset-block-end: 1%;
    transition: 1s;
    z-index: 1;
    position: fixed;
    width: 46px;
    cursor: pointer;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Float-Bottom-Top-Button.Last-Link{
    transform: rotate(-180deg);
}
.Float-Bottom-Top-Button .ant-float-btn-body{
    background-color: gray;
    display: block;
}
.Float-Bottom-Top-Button .ant-float-btn-icon{
    display: flex;
    justify-content: center;
}
.Float-Bottom-Top-Button .Arrow-Primary{
    width: 0;
    height: 0;
    border-right: 50px solid transparent;
    border-bottom: 100px solid yellow; /* Cambiar el color a amarillo */
    position: relative;
}
.Float-Bottom-Top-Button:hover .Arrow-Secondary{
    opacity: 1;
    transform: scale(1, 1);
    transition: .5s;
}
.Float-Bottom-Top-Button .Container-Bottom-Top{
    position: relative;
}
.Float-Bottom-Top-Button .Arrow-Primary, .Float-Bottom-Top-Button .Arrow-Secondary{
    position: absolute;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid yellow; /* Cambiar el color a amarillo */
    position: relative;
}
.Float-Bottom-Top-Button .Arrow-Primary{
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid yellow; /* Cambiar el color a amarillo */
    position: relative;
}
.Float-Bottom-Top-Button .Arrow-Secondary{
    border-color: transparent transparent transparent var(--white);
    transform: translate3d(0px, 0px, 0px) scale(0.5, 0.5);
    opacity: 0;
    transition: .5s;
}
.Float-Bottom-Top-Button .ant-float-btn-body{
    display: flex;
}
.Float-Bottom-Top-Button .First-Icon{
    position: absolute;
    z-index: 2;
    animation-name: efecto-first-button-up-down;
    animation-duration: 3s !important;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.Float-Bottom-Top-Button .Second-Icon{
    position: absolute;
    z-index: 2;
    animation-name: efecto-second-button-up-down;
    animation-duration: 3s !important;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    /* animation: 1.2s efecto-second-button-up-down 3s infinite ease-in-out; */
}
@keyframes efecto-first-button-up-down{
    0%{
        transform: translateY(-10px) scale(0);
        opacity: 1;
    }
    25%{
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1);
    }
}
@keyframes efecto-second-button-up-down{
    0%{
        transform: scale(1);
        opacity: 1;
    }
    15%{
        /* opacity: 1; */
        transform: translateY(10px) scale(0.2);
    }
    16%{
        transform: scale(0.1);
        opacity: 0;
    }
    17%{
        transform: scale(0);
        opacity: 0;
    }
    100%{
        /* transform: translateY(0px) scale(1); */
        opacity: 0;
    }
}
.Button-Menu-Container-PopOver-Global{
    display: flex !important;
    flex-direction: column !important;
    width: 150px !important;
}
.PopOver-Button-Menu-Points .ant-popover-content{
    margin-top: -5px !important;
}
.PopOver-Button-Menu-Points {
    /* margin-top: -20px !important; */
    padding-top: 0 !important;
}
.PopOver-Button-Menu-Points .ant-popover-inner{
    padding: 5px 0 !important;
}
/*  */
.Button-Menu-Option-PopOver-Global{
    display: flex !important;
    flex-direction: column !important;
    padding: 5px 0 !important;
}
.Button-Menu-PopOver-Global{
    width: 20px !important;
    height: 22px !important;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
}
.Hover-Menu-Points{
    background-color: var(--light-blue);
}
.Button-Menu-Option-PopOver-Global div {
    color: var(--blue) !important;
    padding: 3px 11px !important;
    font-weight: 500 !important;
    font-family: var(--font-family) !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    cursor: pointer;
}
.Button-Menu-Option-PopOver-Global div:hover{
    background-color: #EDF2FF !important;
    color: var(--blue-elec) !important;
}
.Url-Download-Menu:hover a{
    color: var(--blue-elec) !important;
}
.Url-Download-Menu a{
    color: var(--blue) !important;
}
.Download-Icon{
    background-image: url('../../Assets/Images/Global/DownloadBlue.png');
    width: 16px;
    background-size: 16px 16px;
    height: 16px;
    background-repeat: no-repeat;
}
.Download-Icon.Download-Hover{
    background-image: url('../../Assets/Images/Global/DownloadSkyBlue.png');
}
.Tooltip-Download-Button .ant-tooltip-inner{
    height: 26px !important;
    display: flex !important;
    align-items: center !important;
    min-height: 26px !important;
    font-size: 12px !important;
}
.Filter-Select-Container-PopOver-Global{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    max-width: 200px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.Filter-Select-Container-PopOver-Global::-webkit-scrollbar{
    width: 8px;
}
.Filter-Select-Container-PopOver-Global::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 6px;
}
.Filter-Select-Container-PopOver-Global::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.Filter-Select-Option-PopOver-Global span{
    font-size: 12px !important;
    font-family: var(--font-family) !important;
}
.Filter-Select-Option-PopOver-Global{
    padding: 0px 11px !important;
    min-width: 148px;
}
.Filter-Select-Option-PopOver-Global:hover{
    background-color: #EDF2FF !important;
}
.Filter-Select-Option-PopOver-Global .ant-checkbox-wrapper:last-child{
    color: var(--blue);
    font-size: var(--size-regular);
    font-weight: var(--weight-regular);
    font-family: var(--font-family);
}
.Filter-Select-Option-PopOver-Global .ant-checkbox-wrapper span:nth-child(2){
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 160px;
}
.Filter-Select-Option-PopOver-Global:hover .ant-checkbox-wrapper:last-child{
    color: var(--blue-elec);
}
.Filter-Select-Option-PopOver-Global .ant-checkbox-checked::after{
    border: 0 !important;
}
.Filter-Select-Option-PopOver-Global .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}
.Filter-Select-Option-PopOver-Global .ant-checkbox-checked .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.Filter-Select-Option-PopOver-Global .ant-checkbox-inner{
    border: 2px solid rgba(1, 58, 129, 0.4) !important;
    height: 14px !important;
    width: 14px !important;
}
.Filter-Select-Option-PopOver-Global:hover .ant-checkbox-checked .ant-checkbox-inner{
    border-color: var(--blue) !important;
}
.Pop-Over-Filter-Select-Global {
    padding-top: 0 !important;
    min-width: 130px !important;
    z-index: 3 !important;
}
.Pop-Over-Filter-Select-Global .ant-popover{
    padding-top: 0 !important;
}
.Pop-Over-Filter-Select-Global .ant-popover-inner{
    padding: 8px 0 !important;
}
.Pop-Over-Filter-Select-Global-DatePicker .ant-popover-inner{
    padding: 8px 8px !important;
}
.Pop-Over-Filter-Select-Global-DatePicker input{
    color: var(--blue) !important;
    font-size: var(--size-regular);
    font-family: var(--font-family);
}
.Filter-Select-Button-PopOver-Global{
    background-color: #EEFFF9;
    padding: 12px 10px;
    border-radius: 8px;
    color: var(--green);
    font-size: 12px !important;
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    width: max-content;
    cursor: pointer;
    z-index: 1;
    height: 28px !important;
    display: flex;
    align-items: center;
}
.Filter-Select-Button-PopOver-Global-Small{
    background-color: #EEFFF9;
    padding: 10px 10px;
    border-radius: 8px;
    color: var(--green);
    font-size: var(--size-regular);
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    width: max-content;
    cursor: pointer;
    z-index: 1;
}
.Filter-Container-PopOver-Global{
    background-color: #EEFFF9;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 7px 11px 7px 10px;
    border-radius: 8px;
}
.Filter-Container-PopOver-Global span{
    font-family: var(--font-family);
    color: var(--green);
    font-size: 12px;
    font-weight: var(--weight-semibold);
    line-height: 14.52px;
}
.Container-Radio-PopOver-Global{
    display: flex;
    row-gap: 5px;
    flex-direction: column;
    padding: 3px 10px;
}
.Container-Categories-Global{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Title-Categories-Dashboard{
    font-family: var(--font-family);
    color: var(--blue) !important;
    margin: 0 !important; 
    font-size: var(--size-mediumbig) !important;
    line-height: var(--lineheight-mediumbig) !important;
    font-weight: var(--weight-bold) !important;
}
.Container-Prev .Container-Button{
    margin-right: 11px;
}
.Container-Button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-radius .5s, transform .5s;
}
.Container-Button.Active-List .Button-Prev, .Container-Button.Active-List .Button-Next{
    transition: border-radius .5s;
    border-radius: 50%;
    background-color: var(--green);
    color: var(--white);
    padding: 6px;
}
.Border-Button{
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 50%;
    top: 0;
    left: 0;
}
.Container-Button.Active-List .Border-Button{
    transform: scale(1.2);
    transition: transform .5s;
    border-color: rgb(42, 210, 149, .5);
}
.Container-Categories-Global .Button-Prev, .Container-Categories-Global .Button-Next{
    font-family: var(--font-family);
    background-color: transparent;
    cursor: pointer;
    color: var(--green);
    height: auto;
    line-height: 17px;
    font-weight: 500;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--green);
    padding: 6px 7px 6px 6px;
}
.Container-Categories-Global .Button-Prev:hover, .Container-Categories-Global .Button-Next:hover, .Container-Categories-Global .Button-Prev:focus, .Container-Categories-Global .Button-Next:focus{
    border: 2px solid var(--green) !important;
    color: var(--white) !important;
    background-color: var(--green);
    border-radius: 50%;
}
.Container-Categories-Global .Button-Prev:hover .Icon-Button-Prev, .Container-Categories-Global .Button-Next:hover .Icon-Button-Next, .Container-Categories-Global .Button-Prev:focus .Icon-Button-Prev, .Container-Categories-Global .Button-Next:focus .Icon-Button-Next{
    color: var(--white) !important;
}
.Container-Categories-Global .Button-Prev:disabled, .Container-Categories-Global .Button-Next:disabled{
    background-color: transparent;
    color: var(--green);
}
.Container-Categories-Global .Icon-Button-Prev, .Container-Categories-Global .Icon-Button-Next{
    font-size: 10px;
}
.Dropdown-Categories-Dashboard{
    width: 170px;
    max-width: 170px;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu{
    padding: 8px 0;
    box-shadow: 0px 1px 7px 0 rgb(0, 0, 0, .3);
    border-radius: 8px;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-item{
    padding: 0 14px !important;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-item:hover{
    background-color: #EDF2FF !important;
    border-radius: 0;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-title-content{
    font-family: var(--font-family);
    line-height: 23.8px;
    color: var(--blue);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-item-selected{
    background-color: #EDF2FF !important;
    border-radius: 0;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-title-content{
    color: var(--blue) !important;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-item:hover .Text-Button-Dropdown span{
    color: var(--blue-elec) !important;
    font-weight: 500 !important;
}
.Dropdown-Categories-Dashboard .Text-Button-Dropdown{
    display: flex;
    align-items: center;
    color: var(--green) !important;
}
.Dropdown-Categories-Dashboard .Text-Button-Dropdown span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--blue) !important;
    font-weight: var(--weight-regular);
    margin-left: 4px;
}
.Dropdown-Categories-Dashboard .Text-Button-Dropdown.Item-Selected span{
    color: var(--green) !important;
    font-weight: var(--weight-semibold) !important;
}
.Dropdown-Categories-Dashboard .Round-Button{
    width: 4px;
    height: 4px;
    background-color: var(--blue-elec);
    border-radius: 50%;
    margin-top: 1px;
    display: none;
}
.Dropdown-Categories-Dashboard .Text-Button-Dropdown.Item-Selected .Round-Button-Selected{
    width: 4px;
    height: 4px;
    background-color: var(--green) !important;
    border-radius: 50%;
    margin-top: 1px;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-item:hover .Round-Button-Selected{
    background-color: var(--blue-elec) !important;
}
.Dropdown-Categories-Dashboard .ant-dropdown-menu-item:hover .Round-Button{
    display: block;
}
.Container-Buttons-Prev-Next{
    display: flex;   
}
@media screen and (max-width: 991px) {
    .Container-Categories-Global .Container-Prev{
        align-self: center;
    }
    .Container-Categories-Global .Button-Prev, .Container-Categories-Global .Button-Next{
        padding: 10px;
        border-radius: 50%;
        transform: scale(0.7) !important;
    }
    .Container-Categories-Global .Icon-Button-Prev, .Container-Categories-Global .Icon-Button-Next{
        font-size: 20px;
    }
    .Container-Categories-Global .Button-Next .Icon-Button-Next{
        margin: 0;
        margin-inline-start: 0 !important;
    }
    .Container-Buttons-Prev-Next{
        margin-top: -5px;
    }
}
@media screen and (max-width: 575px) {
    .Container-Categories-Global .Icon-Button-Prev, .Container-Categories-Global .Icon-Button-Next{
        font-size: 21px;
    }
    .Container-Categories-Global .Button-Prev, .Container-Categories-Global .Button-Next{
        padding: 11px;
    }
}
.Container-Menu-Navigation-Global{
    display: flex !important;
    font-family: var(--font-family);
    color: rgba(1, 58, 129, 0.4);
    position: relative;
    z-index: 1;
}
.Title-Menu-Navigation-Global{
    -webkit-user-select: none;
            user-select: none;
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
}
.Item-Menu-Navigation-Global{
    display: flex;
    margin-bottom: 0 !important;
    cursor: pointer;
    font-weight: 500;
    font-size: var(--size-small);
    line-height: var(--lineheight-small);
}
.Item-Menu-Navigation-Global:hover span{
    text-decoration: underline;
    text-underline-offset: 3px;
    color: var(--blue-elec);
}
.Item-Menu-Selected-Navigation-Global{
    color: var(--blue-elec);
}
.ant-notification-topRight{
    z-index: 100001 !important;
}
/* Row Actions */
/* Row Actions */
.Table-Container-Actions-Global .ant-col-12{
    flex: none !important;
}
.Table-Container-Actions-Global{
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px !important;
}
.Table-Col-Container-Actions-Global{
    display: flex;
    align-items: center;
}
.Button-Show-Component-MSL{
    background-color: var(--blue) !important;
    display: flex;
    align-items: center;
    color: #ffffff !important;
    justify-content: center;
    font-weight: var(--weight-regular);
}
.Button-Show-Component-MSL:hover{
    outline: none !important;
}
.Container-Button-New{
    background-color: #FFFFFF !important;
    padding: 8px 2px;
    border-radius: 50%;
    cursor: pointer;
}
.Button-Show-Component-MSL span{
    font-size: var(--size-regular);
    font-family: var(--font-family);
    font-weight: bold;
}
/* Row Actions */
/* Row Actions */
.Table-Column-Status-Global .ant-switch-handle{
    width: 10px !important;
    height: 10px !important;
    /* border: 1.5px solid blue; */
    border-radius: 100%;
}
.Table-Column-Status-Global .ant-switch{
    background-color: var(--white) !important;
    border: 1.5px solid var(--blue) !important;
}
.Table-Pagination-Global .ant-pagination-prev{
    margin-inline-end: 0 !important;
}
.Table-Pagination-Global .ant-table-selection-column{
    background-color: #EDF2FF !important;
}
.Table-Col-Container-Actions-Global .Buttons-Actions{
    height: 25px !important;
    padding-left: 3px !important;
    padding-right: 3px  !important;
    display: flex !important;
    align-items : center !important;
    justify-content: center !important;
    border-radius : 8px !important;
    color: white !important;
    font-size :13px !important;
    font-family: 'Inter' !important;
    cursor: pointer !important;
}
.Table-Col-Container-Actions-Global .Button-Save-Products{
    background: #FFC700 !important;
    border: 1px solid #FFC700 !important;
}
.Table-Col-Container-Actions-Global .Button-Download-Products{    
    background: #2AD295 !important;
    border: 1px solid #2AD295 !important;
    width: 71px !important;
}
.Table-Global{
    position: relative !important;
    top: -2px !important;
    max-height: 500px;
}
.Table-Global .ant-table-pagination-right{
    display: none;
}
.Table-Global .ant-table-thead .ant-table-cell,
.Table-Global .ant-table-thead{
    height: 36px !important;
}
.Container-Channels-Executives .Table-Global .ant-table-thead .ant-table-cell,
.Container-MSL .Table-Global .ant-table-thead .ant-table-cell,
.Container-MSL .Table-Global .ant-table-thead{
    height: 26px !important;
}
.Container-Channels-Executives .Table-Global .ant-table-thead .ant-table-cell,
.Container-Master-Clients .Table-Global .ant-table-thead .ant-table-cell,
.Container-Master-Clients .Table-Global .ant-table-thead,
.Container-Channels-Executives .Table-Global .ant-table-thead{
    height: 26px !important;
}
.Table-Global .ant-table-row-selected >td{
    background: #EDF2FF !important;
}
/* .Table-Global .ant-table-thead th{
    width: 130px !important;
} */
.Table-Global .ant-dropdown-trigger {
    margin-inline: 0 !important;
}
.Table-Global .ant-table-thead .ant-table-cell{
    color: var(--green) !important;
    font-family: var(--font-family);
    font-size: 12px !important;
    font-weight: 500 !important;
    background-color: var(--white);
    padding: 0px 8px 3px 3px !important;
    line-height: 16px !important;
}
.Table-Global .ant-table-tbody .ant-table-row-selected>td:first-child{
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
}
.Table-Global .Table-Column-Status-Global{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: end;
}
.Table-Global-Filter-PopUp-DatePicker .ant-picker-body{
    padding: 0 !important;
}
.Table-Global-Filter-PopUp-DatePicker ,
.Table-Global-Filter-PopUp-DatePicker .ant-picker-body,
.Table-Global-Filter-PopUp-DatePicker .ant-picker-header{
    width: 250px !important;
}
.Table-Global-Filter-PopUp-DatePicker .ant-picker-content td{
    width: 30px !important;
}
.Table-Global-Filter-PopUp-DatePicker .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}
.Table-Column-Status-Global .Table-Name-Status-Global{
    padding-right: 7px;
}
.Table-Row-Disabled-Global td .Table-Contain-Text-Global{
    opacity: 0.4;
}
.Table-Contain-Text-Global{
    max-width: 220px;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.Table-Contain-Text-Global .ant-input{
    color: var(--blue) !important;
    cursor: text !important;
}
.Table-Contain-Text-Global .ant-input-number-input{
    cursor: text !important;
    color: var(--blue) !important;
}
.Table-Contain-Text-Global .ant-input-number-handler-wrap{
    display: none !important;
}
.Table-Contain-Text-Global-Center{
    display: flex;
    justify-content: center;
    width: 100%;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.Table-Contain-Text-Global-Left{
    display: flex;
    justify-content: initial;
    margin-left: 5px;
    width: 100%;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.Table-Contain-Text-Center-Align{
    display: flex;
}
.Table-Global .ant-table-tbody .ant-checkbox-inner{
    border: 2px solid #2AD29580 !important;
    width: 14px;
    height: 14px;
}
.Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner:hover{
    border-color: var(--green) !important;
}
.Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
}
.Table-Global .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border: 1px solid var(--green);
}
.Table-Global .ant-table-thead th.ant-table-column-sort{
    background-color: none !important;
}
.Table-Global .ant-table-thead th.ant-table-column-sort{
    background-color: none !important;
}
.Table-Global .ant-table-thead th.ant-table-column-has-sorters:hover{
    background-color: #FFFFFF !important;
}
.Table-Global .ant-table-tbody .ant-checkbox-checked::after{
    border: 0 !important;
}
.Table-Row-Disabled-Global td .Table-Contain-Text{
    opacity: 0.4;
}
.Table-Row-Disabled-Global td .ant-switch{
    opacity: 1 !important;
}
.Table-Row-Disabled-Global:hover td{
    background-color: rgba(237, 242, 255, 0.4) !important;
    border-radius: 0 !important;
}
:is(.ant-table-row-selected.Table-Row-Disabled-Global) .ant-table-cell{
    background-color: rgba(237, 242, 255, 0.4) !important;
}
.Table-Row-Global :not(.ant-switch){
    cursor: pointer;
}
.Table-Row-Global-Disable td .Table-Contain-Text{
    opacity: 0.4;
}
.Table-Row-Global-Disable td .ant-switch{
    opacity: 1 !important;
}
.Table-Row-Global:hover td{
    background-color: #EDF2FF !important;
    border-radius: 0 !important;
}
.Table-Row-Global-Disable:hover td{
    background-color: rgba(237, 242, 255, 0.4) !important;
    border-radius: 0 !important;
}
:is(.ant-table-row-selected.Table-Row-Global-Disable) .ant-table-cell{
    background-color: rgba(237, 242, 255, 0.4) !important;
}
.Table-Global .ant-table-pagination-right{
    display: none;
}
.Table-Global .ant-table-row-selected >td{
    background: #EDF2FF !important;
}
.Table-Global .ant-table-row-selected >td{
    background: #EDF2FF !important;
}
.Table-Global-Filter-DatePicker{
    width: 100px !important;
    height: 24px !important;
}
.Table-Global-Filter-DatePicker .ant-picker-input input{
    color: var(--blue) !important;
    font-size: 13px !important;
}
.Table-Global-Filter-DatePicker .ant-picker{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: var(--size-regular) !important;
    border: 1px solid #D7DEF5 !important;
}
.ant-table-thead .ant-table-selection-column::before{
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: #f0f0f0;
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: "";
}
.Table-Global .ant-table-tbody .ant-table-cell{
    color: var(--blue) !important;
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 500;
    background-color: var(--white);
    padding: 0px 4px 0px 4px !important;
}
.Table-Global .ant-table-tbody .ant-table-row-selected>td:first-child{
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
}
.Table-Global .ant-table-tbody .ant-checkbox-inner{
    border: 2px solid #2AD29580 !important;
}
.Table-Global .ant-table-thead .ant-checkbox{
    /* display: none !important; */
}
.Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner:hover{
    border-color: var(--green) !important;
}
.Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
}
.Table-Global .ant-table-tbody .ant-checkbox-checked::after{
    border: 0 !important;
}
.ant-tooltip-content{
    margin-top: -10px !important;
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    align-items: center;
}
/* Pagination */
/* Pagination */
.Table-Pagination-Global .ant-pagination-item-link{
    border-radius: 100% !important;
    color: var(--blue) !important;
}
.Table-Pagination-Global .ant-pagination-total-text{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    align-items: center !important;
    margin-inline-end: 0 !important;
    font-size: 11px !important;
}
.Table-Pagination-Global .ant-pagination-item-link:hover{
    background: #EDF2FF !important;
    color: var(--blue-elec) !important;
}
.Table-Pagination-Global .ant-pagination-prev .ant-pagination-item-link span :first-child, .Table-Pagination-Global .ant-pagination-next .ant-pagination-item-link span :first-child{
    display: none !important;
}
.Table-Pagination-Global .ant-pagination-prev .ant-pagination-item-link span::after{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px !important;
    height: 10px !important;
}
.Table-Pagination-Global .ant-pagination-next .ant-pagination-item-link span::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px !important;
    height: 10px !important;
}
.Table-Pagination-Global .ant-pagination-prev .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination.svg') !important;
    transform: rotate(180deg);
}
.Table-Pagination-Global .ant-pagination-prev:hover .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination-hover.svg') !important;
}
.Table-Pagination-Global .ant-pagination-next .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination.svg') !important;
}
.Table-Pagination-Global .ant-pagination-next:hover .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination-hover.svg') !important;
}
.Table-Pagination-Global li:not(.ant-pagination-total-text):not(.ant-pagination-prev):not(.ant-pagination-next){
    display: none !important;
}
/* Pagination */
/* Pagination */
.Table-Global .ant-table-container {
    width: 100% !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.Table-Global .ant-table{
    width: 100% !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.Table-Global .ant-table-body{
    width: 100% !important;
    /* overflow: hidden !important; */
    /* overflow-x: scroll !important; */
    /* overflow-y: hidden !important; */
}
/* .Table-Global .ant-table-tbody::-webkit-scrollbar{
    width: 10px;
    background-color: #f5f5f5; 
}
.Table-Global .ant-table-tbody::-webkit-scrollbar-thumb{
    background-color: #888;
}
.Table-Global .ant-table-tbody::-webkit-scrollbar-track{
    background-color: transparent !important;
    border-radius: 10px !important;
    margin: 10px 20px !important;
} */
.Table-Global .ant-table-row.ant-table-row-selected>td:last-child{
    border-start-end-radius: 0 !important;
    border-end-end-radius : 0 !important;
}
.Table-Global .ant-table-body::-webkit-scrollbar{
    width: 10px;
    height: 1px !important;
    padding-top: 20px;
}
.Table-Global .ant-table-body::-webkit-scrollbar-thumb{
    background-color: var(--white);
    border-radius: 24px;
}
.Table-Global .ant-table-body::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 0;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
}
/* Pagination */
.Table-Pagination-Global .ant-pagination-jump-next,
.Table-Pagination-Global .ant-pagination-next,
.Table-Pagination-Global .ant-pagination-prev{
    min-width: 25px !important;
    height: 25px !important;
}
.Table-Global .Table-Contain-Text-Global .ant-input-number-input,
.Table-Global .Table-Contain-Text-Global .ant-input{
    font-size: 11px !important;
    text-align: center !important;
}
.Table-Global .Table-Contain-Text-Global .ant-input{
    line-height: 1 !important;
}
.Table-Global .ant-table-thead tr th:first-child{
    width: 40px !important;
}
.Table-Pagination-Global .ant-pagination-item-link{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.Table-Global .ant-table th.ant-table-selection-column {
    display: block !important;
    width: 32px !important;
}
.Table-Col-Container-Actions-Global .Button-Save-Products.Button-Disabled{
    background: rgba(128, 128, 128, 0.305) !important;
    cursor: not-allowed !important;
    border: none !important;
}
@media screen and (max-width: 1242px) {
    .Table-Global-Filter-DatePicker{
        width: 100px !important;
    }
}
.Container-Title-Categories-Global{
    display: grid;
    grid-template-columns: 140px auto 140px;
}
.Container-Title-Categories-Global .Button-Prev{
    padding: 11px 22px 11px 15px;
}
.Container-Title-Categories{
    margin: auto 0;
}
.Container-Title-Categories-Global .Title-Categories-Global{
    font-family: var(--font-family);
    font-size: 28.382px;
    font-weight: var(--weight-bold);
    line-height: 40px;
    margin-bottom: 0;
    color: var(--blue);
    margin: 0 !important;
}
.Container-Next{
    justify-self: end;
}
.Button-Next{
    padding: 11px 15px 11px 22px;
}
.Button-Prev, .Button-Next{
    font-family: var(--font-family);
    background-color: var(--green);
    border: 1px solid var(--green);
    color: var(--white);
    height: auto;
    line-height: 17px;
    font-weight: 500;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Button-Prev:hover, .Button-Next:hover, .Button-Prev:focus, .Button-Next:focus{
    border: 1px solid var(--green) !important;
    color: var(--white) !important;
}
.Button-Prev:hover .Text-Prev, .Button-Next:hover .Text-Next, .Button-Prev:focus .Text-Prev, .Button-Next:focus .Text-Next{
    color: var(--white) !important;
}
.Button-Prev:hover .Icon-Button-Prev, .Button-Next:hover .Icon-Button-Next, .Button-Prev:focus .Icon-Button-Prev, .Button-Next:focus .Icon-Button-Next{
    color: var(--white) !important;
}
.Icon-Button-Prev, .Icon-Button-Next{
    font-size: 18px;
}
.Dropdown-Categories .ant-dropdown-menu{
    padding: 8px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}
.Dropdown-Categories .ant-dropdown-menu-item:hover{
    background-color: #EDF2FF !important;
    border-radius: 0;
}
.Dropdown-Categories .ant-dropdown-menu-title-content{
    font-family: var(--font-family);
    line-height: 24px;
    color: var(--blue);
}
@media screen and (max-width: 991px) {
    .Container-Title-Categories-Global{
        grid-template-columns: 52px auto 52px;
    }
    .Container-Title-Categories-Global .Container-Prev{
        align-self: center;
    }
    .Container-Title-Categories-Global .Container-Next{
        align-self: center;
    }
    .Container-Title-Categories-Global .Button-Prev, .Container-Title-Categories-Global .Button-Next{
        padding: 10px;
        border-radius: 50%;
    }
    .Button-Prev .Text-Prev, .Button-Next .Text-Next{
        display: none;
    }
    .Icon-Button-Prev, .Icon-Button-Next{
        font-size: 20px;
    }
    .Button-Next .Icon-Button-Next{
        margin: 0;
        margin-inline-start: 0 !important;
    }
}
@media screen and (max-width: 575px) {
    .Container-Title-Categories-Global{
        grid-template-columns: auto 50px 50px;
    }
    .Container-Title-Categories-Global .Container-Title-Categories{
        grid-column-start: 1;
        grid-row-start: 1;
    }
    .Container-Title-Categories-Global .Container-Prev{
        justify-self: end;
        grid-column-start: 2;
        grid-row-start: 1;
    }
    .Container-Title-Categories-Global .Container-Next{
        grid-column-start: 3;
        grid-row-start: 1;
    }
}
.Title-Global{
    font-family: var(--font-family);
    text-align: center;
    color: var(--blue) !important;
    font-size: 20px !important;
    line-height: 24.2px !important;
    font-weight: var(--weight-bold) !important;
    margin-bottom: 12px !important;
}
.Sub-Title-Global{
    font-family: var(--font-family);
    font-weight: var(--weight-bold) !important;
    color: var(--green) !important;
    font-size: var(--size-medium) !important;
    line-height: var(--lineheight-medium) !important;
    text-align: center;
    margin-top: 0 !important;
}
.Paragraph-Global{
    font-family: var(--font-family);
    font-size: 12px;
    text-align: center;
    color: var(--blue);
    line-height: 14.52px;
    padding-left: 12px;
    padding-right: 12px;
}
.Title-Search-Text-Global{
    display: flex;
    font-family: var(--font-family);
    font-size: 20px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 12px !important;
    margin-top: 3px;
}
.SubTitle-Search-Text-Global{
    display: inline;
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-weight: var(--weight-bold);
    font-size: 12px !important;
    margin-bottom: 1em !important;
    margin-left: 10px;
}
.Title-Search-Input-Global{
    font-family: var(--font-family);
    font-size: var(--size-regular);
    color: var(--blue) !important;
    border: 1px solid #D7DEF5 !important;
    font-weight: var(--weight-regular) !important;
}
.Title-Search-Input-Global:hover{
    border: 1px solid #D7DEF5 !important;
}
.Title-Search-Input-Global:focus{
    outline: none !important;
}
.Title-Search-Input-Global:focus{
    border: 1px solid #B0BFEC80;
    outline: 0;
}
.Simple-Table-Global .ant-table-thead .ant-table-cell{
    background-color: var(--white) !important;
    color: var(--green) !important;
    font-weight: var(--weight-bold) !important;
    font-size: 12px !important;
}
.Simple-Table-Global .ant-table-thead .ant-table-cell.ant-table-cell-ellipsis{
    padding: 0 0 0 8px !important;
}
.Simple-Table-Global .ant-table-thead .ant-checkbox-indeterminate .ant-checkbox-inner:after{
    display: none !important;
}
.Simple-Table-Global tbody td {    
    white-space: nowrap !important;
    word-break:break-word !important;
    word-break: break-all !important;
}
.Simple-Table-Global .ant-table-thead .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
}
.Simple-Table-Global .ant-table-thead .ant-table-selection-column .ProfileTypesFilterSelect-Container-Items-DropDown{
    display: flex !important;
    align-items: center !important;
    position: relative !important;
    left: 20% !important;
    /* justify-content: center !important; */
}
.Simple-Table-Global .ant-table-thead .ant-table-selection-column .ant-table-selection-extra{
    top: 4px;
}
.Simple-Table-Global .ant-table-thead .ant-table-selection-column .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
    outline: none !important;
}
.Simple-Table-Global .ant-table-thead .ant-table-selection-column .ant-table-selection-extra .ant-dropdown-trigger span{
    content: url('../../Assets/Images/Global/iconDownGreen.png') !important;
}
.Simple-Table-Global .ant-table-thead .ant-table-selection-column .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.Table-Actions .Simple-Table-Global .ant-table-thead .ant-table-cell:nth-last-child(2)::before {
    display: none !important;
}
.Simple-Table-Global .ant-table-cell{
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.Simple-Table-Global .ant-table-row .ant-table-selection-column .ant-checkbox-wrapper{
    display: flex !important;
    position: relative !important;
    left: 20% !important;
}
.Simple-Table-Global .Row-Simple-Table-Global{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 11px !important;
    font-weight: 500 !important;
}
.Simple-Table-Global .Row-Simple-Table-Global-Row-Inactive{
    color: #013b8182 !important;
    font-family: var(--font-family) !important;
    font-size: 11px !important;
    font-weight: 500 !important;
}
.Row-Simple-Table-Global-Row-Inactive .ant-switch{
    border: 1px solid rgba(1, 58, 129, 0.4) !important;
}
.Row-Simple-Table-Global-Row-Inactive .ant-switch-handle::before{
    background-color: rgba(1, 58, 129, 0.4) !important;
}
.Simple-Table-Global .ant-table-selection-column{
    /* width: 65px !important; */
    padding-inline-start: 3px !important;
}
.Simple-Table-Global td{
    border-radius: 0 !important;
}
.Simple-Table-Global .Table-Column-Status-Global{
    display: flex !important;
    justify-content: space-between !;
}
.Simple-Table-Global .ant-table-tbody .ant-checkbox-inner{
    border: 2px solid #2AD29580 !important;
}
.Simple-Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner:focus{
    outline: none !important;
}
.Simple-Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
}
.Simple-Table-Global .ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner:after{
    inset-inline-start: 16.5% !important;
}
.Simple-Table-Global .ant-table-column-title{
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    width: 50px !important;
}
.Simple-Table-Contain-Text-Global.Text-Left{
    text-align: left !important;
}
.Simple-Table-Contain-Text-Global{
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    font-size: 11px !important;
}
.Simple-Table-Global .ant-table-thead .ant-table-filter-trigger{
    margin-inline: 0 !important;
}
.Simple-Table-Contain-Text-Global.Width-140{
    width: 140px !important;
}
.Simple-Table-Contain-Text-Global.Width-130{
    width: 130px !important;
}
.Simple-Table-Contain-Text-Global.Width-120{
    width: 120px !important;
}
.Simple-Table-Contain-Text-Global.Width-110{
    width: 110px !important;
}
.Simple-Table-Contain-Text-Global.Width-100{
    width: 100px !important;
}
.Simple-Table-Global .ant-table-cell {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
.Simple-Table-Global .Table-Contain-Text-Global-Left{
    display: flex;
    font-size: 11px !important;
    justify-content: initial;
    margin-left: 5px;
    width: 100%;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.Simple-Table-Global .Table-Contain-Text-Global{
    max-width: 200px;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    font-size: 11px !important;
}
.Simple-Table-Global .ant-table-content::-webkit-scrollbar{
    width: 8px;
    height: 6px;
}
.Simple-Table-Global .ant-table-content::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 6px;
}
.Simple-Table-Global .ant-table-content::-webkit-scrollbar-track{
    border-radius: 10px;
}
.Cell-Space-Items{
    display: flex !important;
    justify-content: space-between !important;
}
.Simple-Table-Pagination-Global {
    z-index: 1;
    display: flex;
    align-items: center;
}
.Simple-Table-Pagination-Global .ant-pagination-prev{
    margin-inline-end: 0 !important;
}
.Simple-Table-Pagination-Global .ant-table-selection-column{
    background-color: #EDF2FF !important;
}
.Simple-Table-Pagination-Global .ant-pagination-item-link{
    border-radius: 100% !important;
    color: var(--blue) !important;
}
.Simple-Table-Pagination-Global .ant-pagination-total-text{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    align-items: center !important;
    margin-inline-end: 0 !important;
    font-size: 11px !important;
    margin-right: 10px !important;
}
.Simple-Table-Pagination-Global .ant-pagination-item-link:hover{
    background: #EDF2FF !important;
    color: var(--blue-elec) !important;
}
.Simple-Table-Pagination-Global .ant-pagination-prev .ant-pagination-item-link span :first-child, .Simple-Table-Pagination-Global .ant-pagination-next .ant-pagination-item-link span :first-child{
    display: none !important;
}
.Simple-Table-Pagination-Global .ant-pagination-prev .ant-pagination-item-link span::after{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px !important;
    height: 10px !important;
}
.Simple-Table-Pagination-Global .ant-pagination-next .ant-pagination-item-link span::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px !important;
    height: 10px !important;
}
.Simple-Table-Pagination-Global .ant-pagination-prev .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination.svg') !important;
    transform: rotate(180deg);
}
.Simple-Table-Pagination-Global .ant-pagination-prev:hover .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination-hover.svg') !important;
}
.Simple-Table-Pagination-Global .ant-pagination-next .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination.svg') !important;
}
.Simple-Table-Pagination-Global .ant-pagination-next:hover .ant-pagination-item-link span::after{
    content: url('../../Assets/Images/Global/arrow-pagination-hover.svg') !important;
}
.Simple-Table-Pagination-Global li:not(.ant-pagination-total-text):not(.ant-pagination-prev):not(.ant-pagination-next){
    display: none !important;
}
.Simple-Table-Pagination-Global .ant-pagination-jump-next,
.Simple-Table-Pagination-Global .ant-pagination-next,
.Simple-Table-Pagination-Global .ant-pagination-prev{
    min-width: 25px !important;
    height: 25px !important;
}
.Row-Simple-Table-Global :not(.ant-switch),
.Row-Simple-Table-Global-Row-Inactive :not(.ant-switch){
    cursor: pointer;
}
.Row-Simple-Table-Global:hover td{
    background-color: #EDF2FF !important;
    border-radius: 0 !important;
}
.Row-Simple-Table-Global-Row-Inactive:hover td{
    background-color: rgba(237, 242, 255, 0.4) !important;
    border-radius: 0 !important;
}
.Row-Simple-Table-Global,
.Row-Simple-Table-Global-Row-Inactive{
    height: 22px !important;

}
/* .Simple-Table-Pagination-Global .ant-pagination-next{
    z-index: 1;
    position: absolute;
} */
.Simple-Table-Pagination-Global .ant-pagination-item-link{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.Submodule-1 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-1 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-1 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-1 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-1 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 0 !important;
}
.Submodule-1 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 14px;
}
.Submodule-1 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-1 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-1 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-1 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-1 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-1 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-1 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-1 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-1 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-1 10s linear infinite;
}
.Submodule-1 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-1 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-1 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-1{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-1 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    height: 160px;
}
.Submodule-1 .Container-Header-Submodule{
    display: flex;
    height: 102px;
    position: relative;
}
.Submodule-1 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    width: 100%;
    color: #00533F;
    position: absolute;
}
.Submodule-1 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-1 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-1 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-1 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-1 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-1 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-1 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text 1s forwards;
    animation-delay: 1s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-1 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-1 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-1 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-1 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-1 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .3s;
}
/* Tarjeta 1 */
.Submodule-1 .Card-Submodule-Item:nth-child(1){
    background-color: var(--green);
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-1 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-1 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-1 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 1 Activo */
.Submodule-1 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover{
    background-color: #00533F;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-1 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-1 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-1 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--green);
}
.Submodule-1 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-1 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-1 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Animacion Tarjeta 1 */
.Submodule-1 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub1active1-card1 .3s forwards;
}
@keyframes sub1active1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        width: 100%;
        height: 160px;
    }
    100%{
        height: 330px;
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-1 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: subinactive1-card1 .3s forwards;
    height: 330px;
    width: 100%;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
}
@keyframes subinactive1-card1{
    0%{
        height: 330px;
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        width: 100%;
        height: 160px;
    }
}
.Submodule-1 .Card-Submodule-Item:nth-child(1).active-sub-0 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-2 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-2 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-2 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-2 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-2 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 0 !important;
}
.Submodule-2 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 14px;
}
.Submodule-2 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-2 .Container-Card-Categories:hover{
    border-radius: 50%;
}
.Submodule-2 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-2 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-2 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-2 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-2 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-2 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-2 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-2 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-2 10s linear infinite;
}
.Submodule-2 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-2 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-2 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-2{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-2 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    height: 160px;
}
.Submodule-2 .Container-Header-Submodule{
    position: relative;
    height: 102px;
}
.Submodule-2 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    width: 100%;
    color: #00533F;
    position: absolute;
}
.Submodule-2 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-2 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-2 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-2 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-2 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-2 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-2 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text .5s forwards;
    animation-delay: 1s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-2 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-2 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-2 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-2 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-2 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .3s;
}
/* Tarjeta 1 */
.Submodule-2 .Card-Submodule-Item:nth-child(1){
    background-color: var(--green);
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-2 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-2 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-2 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-2 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-2 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-2 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-2 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 2 */
.Submodule-2  .Container-Submodule .Card-Submodule-Item:nth-child(2){
    background-color: #FFC700;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2) .Text-Submodule{
    color: #F90000;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-2 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2) .Text-SubSubmodule{
    color: var(--white);
}
/* Default Animacion */
.Submodule-2 .Card-Submodule-Item:nth-child(1).Default-Active{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2).Default-Active{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
/* Tarjeta 1 Activo */
.Submodule-2 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-2 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover, .Submodule-2 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-2 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-2 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-2 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-2 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Submodule{
    background-color: var(--green);
}
.Submodule-2 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-2 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-2 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 2 Activo */
.Submodule-2 .Card-Submodule-Item:nth-child(2).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover, .Submodule-2 .Card-Submodule-Item:nth-child(2).active-submodule-item{
    background-color: #F90000;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-2 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-2 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-2 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-2 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Animacion Tarjeta 1 */
.Submodule-2 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub2active1-card1 .3s forwards;
}
@keyframes sub2active1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: sub2inactive1-card1 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub2inactive1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(1).active-sub-0 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* Animacion Tarjeta 2 */
.Submodule-2 .Card-Submodule-Item:nth-child(2).active-sub-1{
    animation: sub2active2-card2 .3s forwards;
}
@keyframes sub2active2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(2).inactive-sub-1{
    animation: sub2inactive2-card2 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub2inactive2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(2).active-sub-1 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* --- Active Card 1 a Active Card 2 --- */
.Submodule-2 .Card-Submodule-Item:nth-child(1).active-sub-0.card-1{
    animation: sub2active1-sub1-card1-card2 .3s forwards;
}
@keyframes sub2active1-sub1-card1-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1{
    animation: sub2active1-sub1-card2-card2 .3s forwards;
}
@keyframes sub2active1-sub1-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* --- Active Card 1 a Active Card 2 --- */
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-2 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0{
    animation: sub2active2-sub2-card1-card1 .3s forwards;
}
@keyframes sub2active2-sub2-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(2).active-sub-1.card-0{
    animation: sub2active2-sub2-card2-card1 .3s forwards;
}
@keyframes sub2active2-sub2-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-2 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-3 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-3 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-3 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-3 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-3 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: 24.2px !important;
    line-height: var(--lineheight-mediumbig) !important;
    margin-bottom: 0 !important;
}
.Submodule-3 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
}
.Submodule-3 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-3 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-3 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-3 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-3 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-3 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-3 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-3 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-3 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-3 10s linear infinite;
}
.Submodule-3 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-3 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-3 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-3{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-3 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    width: 100%;
    height: 160px;
}
.Submodule-3 .Container-Header-Submodule{
    position: relative;
    height: 102px;
}
.Submodule-3 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    color: #00533F;
    width: 100%;
    position: absolute;
}
.Submodule-3 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-3 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-3 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-3 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-3 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-3 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-3 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text .5s forwards;
    animation-delay: 1s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-3 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-3 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-3 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-3 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-3 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .9s;
}
/* Tarjeta 1 */
.Submodule-3 .Card-Submodule-Item:nth-child(1){
    background-color: #2AD295;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-3 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-3 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-3 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 2 */
.Submodule-3 .Container-Submodule .Card-Submodule-Item:nth-child(2){
    background-color: #FFC700;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2) .Text-Submodule{
    color: #F90000;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-3 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 3 */
.Submodule-3 .Container-Submodule .Card-Submodule-Item:nth-child(3){
    grid-column: 1 / 3;
    background-color: var(--blue-elec);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3) .Text-Submodule{
    color: var(--blue);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Item{
    color: var(--blue);
    margin-top: 1px;
}
.Submodule-3 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Without-Item{
    color: var(--blue);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 1 Activo */
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover, .Submodule-3 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-3 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-3 .Card-Submodule-Item:nth-child(1).active-submodule-item:hover .Container-Icon-Submodule:hover{
    background-color: #2AD295;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-3 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-3 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 2 Activo */
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover, .Submodule-3 .Card-Submodule-Item:nth-child(2).active-submodule-item{
    background-color: #F90000;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-3 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-3 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-3 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Tarjeta 3 Activo */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-3 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover, .Submodule-3 .Card-Submodule-Item:nth-child(3).active-submodule-item{
    background-color: var(--blue);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--blue-elec);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-3 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--blue-elec);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue);
}
.Submodule-3 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-3 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--blue-elec);
}
/* Default Animacion */
.Submodule-3 .Card-Submodule-Item:nth-child(1).Default-Active{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).Default-Active{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).Default-Active{
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
/* Animacion Tarjeta 1 */
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub3active1-card1 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub3active1-card1{
    0%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: sub3inactive1-card1 .3s forwards;
}
@keyframes sub3inactive1-card1{
    0%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-sub-0 .Text-SubSubmodule{
    animation-delay: .9s;
}
/* Animacion Tarjeta 2 */
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-sub-1{
    animation: sub3active2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub3active2-card2{
    0%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).inactive-sub-1{
    animation: sub3inactive2-card2 .3s forwards;
}
@keyframes sub3inactive2-card2{
    0%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-sub-1 .Text-SubSubmodule{
    animation-delay: .6s;
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .6s;
}
/* Animacion Tarjeta 3 */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-2{
    animation: sub3active3-card3 .3s forwards;
}
@keyframes sub3active3-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).inactive-sub-2{
    animation: sub3inactive3-card3 .3s forwards;
}
@keyframes sub3inactive3-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-0{
    animation: sub3active1-card1-sub3 .3s forwards;
}
@keyframes sub3active1-card1-sub3{
    0%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    75%{
        width: 50%;
    }
    99.9%{
        transform: translateX(100%);
        width: 50%;
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-row: 2 / 3;
        grid-column: 2 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).inactive-sub-0{
    animation: sub3inactive1-card1-sub3 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub3inactive1-card1-sub3{
    0%{
        width: calc(50% + 7px);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(100%);
    }
    80%{
        transform: translateX(0);
    }
    99%{
        width: 100%;
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-1{
    animation: sub3active2-card2-sub3 .3s forwards;
}
@keyframes sub3active2-card2-sub3{
    0%{
        width: 100%;
        grid-column: 1 / 3;
    }
    99%{
        width: calc(50% + 5px);
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).inactive-sub-1{
    grid-column: 1 / 2;
    animation: sub3inactive2-sub2-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub3inactive2-sub2-card3{
    0%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    1%{
        width: calc(50% + 5px);
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-2 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* --- Active Card 1 a Active Card 3 --- */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2{
    animation: sub3active1-sub1-card3-card3 .6s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    height: 160px;
}
@keyframes sub3active1-sub1-card3-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        width: 50%;
        transform: translateX(calc(100% + 14px));
    }
    25%{
        transform: translateX(0);
    }
    49.9%{
        width: 100%;
    }
    50%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 160px;
        width: 100%;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        width: 100%;
        height: 334px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-sub-0.card-2{
    animation: sub3active1-sub1-card1-card3 .3s forwards;
}
@keyframes sub3active1-sub1-card1-card3{
    0%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2 .Text-SubSubmodule{
    animation-delay: .9s;
}
/* --- Active Card 1 a Active Card 3 --- */
/* --- Active Card 2 a Active Card 3 --- */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2{
    animation: sub3active2-sub2-card3-card3 .6s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub3active2-sub2-card3-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        width: calc(50% + 7px);
    }
    49%{
        width: 100%;
        grid-row: 2 / 3;
    }
    50%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-sub-1.card-2{
    animation: sub3active2-sub2-card2-card3 .3s forwards;
}
@keyframes sub3active2-sub2-card2-card3{
    0%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2 .Text-SubSubmodule{
    animation-delay: .9s;
}
/* --- Active Card 2 a Active Card 3 --- */
/* --- Active Card 1 a Active Card 2 --- */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-0.card-1{
    animation: sub3active2-sub2-card3-card2 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub3active2-sub2-card3-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 2;
        transform: translateX(0);
        grid-row: 2 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1{
    animation: sub3active2-sub2-card2-card2 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub3active2-sub2-card2-card2{
    0%{
        grid-column: 2 / 3;
        height: 160px;
        grid-row: 1 / 3;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-sub-0.card-1{
    animation: sub3active2-sub2-card1-card2 .3s forwards;
}
@keyframes sub3active2-sub2-card1-card2{
    0%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
/* --- Active Card 1 a Active Card 2 --- */
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-1.card-0{
    animation: sub3active2-sub2-card3-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub3active2-sub2-card3-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-sub-1.card-0{
    animation: sub3active2-sub2-card2-card1 .3s forwards;
}
@keyframes sub3active2-sub2-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0{
    animation: sub3active2-sub2-card1-card1 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub3active2-sub2-card1-card1{
    0%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    99%{
        height: 334px;
        grid-row: 1 / 3;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
/* --- Active Card 2 a Active Card 1 --- */
/* --- Active Card 3 a Active Card 1 --- */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-2.card-0{
    animation: sub3active3-sub3-card3-card1 .6s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub3active3-sub3-card3-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 334px;
        width: 100%;
    }
    49.9%{
        height: 160px;
        width: 100%;
        grid-row: 2 / 4;
    }
    50%{
        height: 160px;
        transform: translateX(0);
        width: 100%;
        grid-row: 2 / 3;
    }
    75%{
        width: 50%;
    }
    99%{
        transform: translateX(100%);
        width: 50%;
        grid-column: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        width: 100%;
        height: 160px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0{
    animation: sub3active3-sub3-card1-card1 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub3active3-sub3-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        height: 334px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0 .Text-SubSubmodule{
    animation-delay: .9s;
}
/* --- Active Card 3 a Active Card 1 --- */
/* --- Active Card 3 a Active Card 2 --- */
.Submodule-3 .Card-Submodule-Item:nth-child(3).active-sub-2.card-1{
    animation: sub3active3-sub3-card3-card2 .6s forwards;
}
@keyframes sub3active3-sub3-card3-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 334px;
        width: 100%;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        height: 160px;
        grid-row: 2 / 3;
        width: 100%;
    }
    99%{
        width: calc(50% + 7px);
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1{
    animation: sub3active3-sub3-card2-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row:  1 / 2;
}
@keyframes sub3active3-sub3-card2-card2{
    0%{
        grid-column: 2 / 3;
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-3 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1 .Text-SubSubmodule{
    animation-delay: .9s;
}
/* --- Active Card 3 a Active Card 2 --- */
.Submodule-4 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-4 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-4 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-4 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-4 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 0 !important;
}
.Submodule-4 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
}
.Submodule-4 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-4 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-4 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-4 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-4 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-4 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-4 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-4 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-4 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-4 10s linear infinite;
}
.Submodule-4 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-4 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-4 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-4{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-4 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    height: 160px;
}
.Submodule-4 .Container-Header-Submodule{
    position: relative;
    height: 102px;
}
.Submodule-4 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    color: #00533F;
    width: 100%;
    position: absolute;
}
.Submodule-4 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-4 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-4 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-4 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-4 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-4 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-4 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text .5s forwards;
    animation-delay: 1s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-4 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-4 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-4 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-4 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-4 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .3s;
}
/* Tarjeta 1 */
.Submodule-4 .Card-Submodule-Item:nth-child(1){
    background-color: var(--green);
    grid-row: span 1;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-4 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-4 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 2 */
.Submodule-4 .Container-Submodule .Card-Submodule-Item:nth-child(2){
    background-color: #FFC700;
    grid-row: span 1;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2) .Text-Submodule{
    color: #F90000;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 3 */
.Container-Submodule .Card-Submodule-Item:nth-child(3){
    background-color: var(--blue-elec);
    grid-row: 2;
    grid-column: span 1;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3) .Text-Submodule{
    color: var(--blue);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Item{
    color: var(--blue);
    margin-top: 1px;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Without-Item{
    color: var(--blue);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 4 */
.Submodule-4 .Card-Submodule-Item:nth-child(4){
    background-color: #C4D5FF;
    grid-row: 2;
    grid-column: span 1;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4) .Text-Submodule{
    color: #5270BC;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Item{
    color: #5270BC;
    margin-top: 1px;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Without-Item{
    color: #5270BC;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4) .Text-SubSubmodule{
    color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #C4D5FF;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #C4D5FF;
}
/* Default Animacion */
.Submodule-4 .Card-Submodule-Item:nth-child(1).Default-Active{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).Default-Active{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).Default-Active{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).Default-Active{
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
/* Tarjeta 1 Activo */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover, .Submodule-4 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--green);
}
.Submodule-4 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 2 Activo */
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover, .Submodule-4 .Card-Submodule-Item:nth-child(2).active-submodule-item{
    background-color: #F90000;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Tarjeta 3 Activo */
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover, .Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item{
    background-color: var(--blue);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--blue-elec);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--blue-elec);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue);
}
.Submodule-4 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--blue-elec);
}
/* Tarjeta 4 Activo */
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover, .Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item{
    background-color: #5270BC;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #C4D5FF;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-4 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #C4D5FF;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue-elec);
}
.Submodule-4 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #C4D5FF;
}
/* Animacion Tarjeta 1 */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub4active1-card1 .3s forwards;
}
@keyframes sub4active1-card1{
    0%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: sub4inactive1-card1 .3s forwards;
}
@keyframes sub4inactive1-card1{
    0%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    99%{
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-0{
    animation: sub4active1-card1-sub3 .3s forwards;
}
@keyframes sub4active1-card1-sub3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).inactive-sub-0{
    animation: sub4inactive1-card1-sub3 .3s forwards;
}
@keyframes sub4inactive1-card1-sub3{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-0 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* Animacion Tarjeta 2 */
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-1{
    animation: sub4active2-card2 .3s forwards;
}
@keyframes sub4active2-card2{
    0%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).inactive-sub-1{
    animation: sub4inactive2-card2 .3s forwards;
}
@keyframes sub4inactive2-card2{
    0%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-1{
    animation: sub4active2-card2-sub4 .3s forwards;
}
@keyframes sub4active2-card2-sub4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).inactive-sub-1{
    animation: sub4inactive2-card2-sub4 .3s forwards;
}
@keyframes sub4inactive2-card2-sub4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-1 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* Animacion Tarjeta 3 */
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-2{
    animation: sub4active3-card3 .3s forwards;
    animation-delay: .45s;
}
@keyframes sub4active3-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(100%);
    }
    50%{
        transform: translateY(0);
    }
    99%{
        transform: translateY(0);
        height: 334px;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).inactive-sub-2{
    animation: sub4inactive3-card3 .3s forwards;
}
@keyframes sub4inactive3-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-2{
    animation: sub4active3-card3-sub1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub4active3-card3-sub1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-2{
    animation: sub4active3-card3-sub2 .3s forwards;
}
@keyframes sub4active3-card3-sub2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        grid-row: 1 / 2;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-2{
    animation: sub4active3-card3-sub4 .3s forwards;
}
@keyframes sub4active3-card3-sub4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).inactive-sub-2{
    animation: sub4inactive3-card3-sub1 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
@keyframes sub4inactive3-card3-sub1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).inactive-sub-2{
    animation: sub4inactive3-card3-sub2 .3s forwards;
    animation-delay: .45s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub4inactive3-card3-sub2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).inactive-sub-2{
    animation: sub4inactive3-card3-sub4 .3s forwards;
    animation-delay: .45s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub4inactive3-card3-sub4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-2 .Text-SubSubmodule{
    animation-delay: .8s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .75s;
}
/* Animacion Tarjeta 4 */
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-3{
    animation: sub4active4-card4 .3s forwards;
    animation-delay: .45s;
}
@keyframes sub4active4-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).inactive-sub-3{
    animation: sub4inactive4-card4 .3s forwards;
}
@keyframes sub4inactive4-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    50%{
        grid-row: 1 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(100%);
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-3{
    animation: sub4active4-card4-sub1 .3s forwards;
}
@keyframes sub4active4-card4-sub1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        grid-row: 1 / 2;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-3{
    animation: sub4active4-card4-sub2 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub4active4-card4-sub2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-3{
    animation: sub4active4-card4-sub3 .3s forwards;
}
@keyframes sub4active4-card4-sub3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).inactive-sub-3{
    animation: sub4inactive4-card4-sub1 .3s forwards;
    animation-delay: .45s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub4inactive4-card4-sub1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).inactive-sub-3{
    animation: sub4inactive4-card4-sub2 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
@keyframes sub4inactive4-card4-sub2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).inactive-sub-3{
    animation: sub4inactive4-card4-sub3 .3s forwards;
    animation-delay: .45s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub4inactive4-card4-sub3{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-3 .Text-SubSubmodule{
    animation-delay: .8s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .75s;
}
/* --- Active Card 1 a Active Card 2 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-0.card-1{
    animation: sub4active1-sub1-card1-card2 .3s forwards;
}
@keyframes sub4active1-sub1-card1-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99.9%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1{
    animation: sub4active1-sub1-card2-card2 .3s forwards;
}
@keyframes sub4active1-sub1-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99.9%{
        height: 334px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-0.card-1{
    animation: sub4active1-sub1-card3-card2 .3s forwards;
}
@keyframes sub4active1-sub1-card3-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-0.card-1{
    animation: sub4active1-sub1-card4-card2 .3s forwards;
}
@keyframes sub4active1-sub1-card4-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* --- Active Card 1 a Active Card 2 --- */
/* --- Active Card 1 a Active Card 3 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-0.card-2{
    animation: sub4active1-sub1-card1-card3 .9s forwards;
}
@keyframes sub4active1-sub1-card1-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    32.9%{
        grid-row: 1 / 3;
        height: 160px;
    }
    33%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
    66%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-0.card-2{
    animation: sub4active1-sub1-card2-card3 .3s forwards;
}
@keyframes sub4active1-sub1-card2-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        grid-row: 1 / 2;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2{
    animation: sub4active1-sub1-card3-card3 1.2s forwards;
}
@keyframes sub4active1-sub1-card3-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
        height: 160px;
    }
    24.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    25%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    74.9%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    75%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    87.5%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-0.card-2{
    animation: sub4active1-sub1-card4-card3 .3s forwards;
}
@keyframes sub4active1-sub1-card4-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-0.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 1 a Active Card 3 --- */
/* --- Active Card 1 a Active Card 4 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-0.card-3{
    animation: sub4active1-sub1-card1-card4 .3s forwards;
}
@keyframes sub4active1-sub1-card1-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        transform: translateY(100%);
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-0.card-3{
    animation: sub4active1-sub1-card2-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub4active1-sub1-card2-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-0.card-3{
    animation: sub4active1-sub1-card3-card4 0s forwards;
}
@keyframes sub4active1-sub1-card3-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3{
    animation: sub4active1-sub1-card4-card4 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub4active1-sub1-card4-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-0.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 1 a Active Card 4 --- */
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0{
    animation: sub4active2-sub2-card1-card1 .3s forwards;
}
@keyframes sub4active2-sub2-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99.9%{
        height: 334px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-1.card-0{
    animation: sub4active2-sub2-card2-card1 .3s forwards;
}
@keyframes sub4active2-sub2-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99.9%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-1.card-0{
    animation: sub4active2-sub2-card3-card1 .3s forwards;
}
@keyframes sub4active2-sub2-card3-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row:  3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-1.card-0{
    animation: sub4active2-sub2-card4-card1 .3s forwards;
}
@keyframes sub4active2-sub2-card4-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0 .Text-SubSubmodule{
    animation-delay: .3s;
}
/* --- Active Card 2 a Active Card 1 --- */
/* --- Active Card 2 a Active Card 3 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-1.card-2{
    animation: sub4active2-sub2-card1-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub4active2-sub2-card1-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-1.card-2{
    animation: sub4active2-sub2-card2-card3 .3s forwards;
}
@keyframes sub4active2-sub2-card2-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2{
    animation: sub4active2-sub2-card3-card3 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub4active2-sub2-card3-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(100%);
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-1.card-2{
    animation: sub4active2-sub2-card4-card3 0s forwards;
}
@keyframes sub4active2-sub2-card4-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-1.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 2 a Active Card 3 --- */
/* --- Active Card 2 a Active Card 4 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-1.card-3{
    animation: sub4active2-sub2-card1-card4 .3s forwards;
}
@keyframes sub4active2-sub2-card1-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 2;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-1.card-3{
    animation: sub4active2-sub2-card2-card4 .6s forwards;
}
@keyframes sub4active2-sub2-card2-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    49%{
        grid-row: 1 / 3;
        height: 160px;
    }
    50%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 2;
        transform: translateX(0);
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-1.card-3{
    animation: sub4active2-sub2-card3-card4 .3s forwards;
}
@keyframes sub4active2-sub2-card3-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3{
    animation: sub4active2-sub2-card4-card4 1.2s forwards;
}
@keyframes sub4active2-sub2-card4-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
        height: 160px;
    }
    24.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    25%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    74.9%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    75%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    87.5%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-1.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 2 a Active Card 4 --- */
/* --- Active Card 3 a Active Card 1 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0{
    animation: sub4active3-sub3-card1-card1 .6s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
@keyframes sub4active3-sub3-card1-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    49%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
    50%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        transform: translateX(0);
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-2.card-0{
    animation: sub4active3-sub3-card2-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub4active3-sub3-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-2.card-0{
    animation: sub4active3-sub3-card3-card1 .9s forwards;
}
@keyframes sub4active3-sub3-card3-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    16.5%{
        height: 160px;
    }
    33%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 3;
    }
    33.3%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    66.6%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-2.card-0{
    animation: sub4active3-sub3-card4-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub4active3-sub3-card4-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-2.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 3 a Active Card 1 --- */
/* --- Active Card 3 a Active Card 2 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-2.card-1{
    animation: sub4active3-sub3-card1-card2 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
@keyframes sub4active3-sub3-card1-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1{
    animation: sub4active3-sub3-card2-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    height: 160px;
}
@keyframes sub4active3-sub3-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-2.card-1{
    animation: sub4active3-sub3-card3-card2 .3s forwards;
}
@keyframes sub4active3-sub3-card3-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-2.card-1{
    animation: sub4active3-sub3-card4-card2 0s forwards;
}
@keyframes sub4active3-sub3-card4-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-2.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 3 a Active Card 2 --- */
/* --- Active Card 3 a Active Card 4 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-2.card-3{
    animation: sub4active3-sub3-card1-card4 .6s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: 160px;
}
@keyframes sub4active3-sub3-card1-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translate(0, 0);
        height: 160px;
    }
    49%{
        grid-column: 2 / 3;
        transform: translate(calc(-100% - 14px), 0);
    }
    50%{
        transform: translate(0, 0);
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    99%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 1 / 2;
    }
    100%{
        height: 160px;
        transform: translate(0, 0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-2.card-3{
    animation: sub4active3-sub3-card2-card4 .6s forwards;
    animation-delay: .45s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub4active3-sub3-card2-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    49%{
        transform: translate(0, calc(-100% - 14px));
        grid-row: 2 / 3;
    }
    50%{
        transform: translate(0, 0);
        grid-row: 1 / 2;
    }
    99%{
        grid-column: 2 / 3;
        transform: translate(calc(-100% - 14px), 0);
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-2.card-3{
    animation: sub4active3-sub3-card3-card4 .6s forwards;
}
@keyframes sub4active3-sub3-card3-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    25%{
        height: 160px;
    }
    49%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 3;
    }
    50%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-2.card-3{
    animation: sub4active3-sub3-card4-card4 .9s forwards;
    animation-delay: .45s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub4active3-sub3-card4-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
        height: 160px;
    }
    32.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    33%{
        transform: translateY(0);
        grid-row: 2 / 3;
    }
    65.9%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    66%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 3;
        height: 160px;
    }
    90%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-2.card-3 .Text-SubSubmodule{
    animation-delay: 1.4s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-2.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.4s;
}
/* --- Active Card 3 a Active Card 4 --- */
/* --- Active Card 4 a Active Card 1 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0{
    animation: sub4active4-sub4-card1-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    height: 160px;
}
@keyframes sub4active4-sub4-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-3.card-0{
    animation: sub4active4-sub4-card2-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
@keyframes sub4active4-sub4-card2-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-3.card-0{
    animation: sub4active4-sub4-card3-card1 0s forwards;
}
@keyframes sub4active4-sub4-card3-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-3.card-0{
    animation: sub4active4-sub4-card4-card1 .3s forwards;
}
@keyframes sub4active4-sub4-card4-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        transform: translateY(0);
        height: 334px;
    }
    50%{
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-3.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 4 a Active Card 1 --- */
/* --- Active Card 4 a Active Card 2 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-3.card-1{
    animation: sub4active4-sub4-card1-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub4active4-sub4-card1-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-3.card-1{
    animation: sub4active4-sub4-card2-card2 .6s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
@keyframes sub4active4-sub4-card2-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    49%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    50%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-3.card-1{
    animation: sub4active4-sub4-card3-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub4active4-sub4-card3-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-3.card-1{
    animation: sub4active4-sub4-card4-card2 .3s forwards;
}
@keyframes sub4active4-sub4-card4-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        transform: translateY(calc(200% + 28px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-3.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 4 a Active Card 2 --- */
/* --- Active Card 4 a Active Card 3 --- */
.Submodule-4 .Card-Submodule-Item:nth-child(1).active-sub-3.card-2{
    animation: sub4active4-sub4-card1-card3 .6s forwards;
    animation-delay: .45s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub4active4-sub4-card1-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    49%{
        transform: translate(0, calc(-100% - 14px));
        grid-row: 2 / 3;
    }
    50%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 1 / 2;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(2).active-sub-3.card-2{
    animation: sub4active4-sub4-card2-card3 .6s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
@keyframes sub4active4-sub4-card2-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
    49%{
        grid-column: 1 / 2;
        transform: translate(calc(100% + 14px), 0);
    }
    50%{
        grid-column: 2 / 3;
        transform: translate(0, 0);
    }
    99%{
        grid-row: 1 / 2;
        transform: translate(0, calc(100% + 14px));
    }
    100%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2{
    animation: sub4active4-sub4-card3-card3 .9s forwards;
    animation-delay: .45s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub4active4-sub4-card3-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
        height: 160px;
    }
    32%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    33%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    65%{
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    66%{
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
        height: 160px;
    }
    90%{
        transform: translateY(0);
    }
    99%{
        grid-row: 1 / 3;
        height: 334px;
    }
    100%{
        grid-row: 1 / 3;
        grid-column: 1 / 2;
        height: 334px;
        transform: translateY(0);
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(4).active-sub-3.card-2{
    animation: sub4active4-sub4-card4-card3 .3s forwards;
}
@keyframes sub4active4-sub4-card4-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        transform: translateY(calc(200% + 28px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2 .Text-SubSubmodule{
    animation-delay: 1.4s;
}
.Submodule-4 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-3.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.4s;
}
/* --- Active Card 4 a Active Card 3 --- */
.Submodule-5 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-5 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-5 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-5 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-5 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 0 !important;
}
.Submodule-5 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 14px;
}
.Submodule-5 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-5 .Container-Card-Categories:hover{
    border-radius: 50%;
}
.Submodule-5 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-5 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-5 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-5 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-5 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-5 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-5 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-5 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-5 10s linear infinite;
}
.Submodule-5 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-5 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-5 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-5{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-5 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    height: 160px;
}
.Submodule-5 .Container-Header-Submodule{
    position: relative;
    height: 102px;
}
.Submodule-5 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    color: #00533F;
    width: 100%;
    position: absolute;
}
.Submodule-5 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-5 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-5 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-5 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-5 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-5 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-5 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text .5s forwards;
    animation-delay: 1s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-5 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-5 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-5 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-5 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-5 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .9s;
}
/* Tarjeta 1 */
.Submodule-5 .Card-Submodule-Item:nth-child(1){
    background-color: var(--green);
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-5 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-5 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 2 */
.Submodule-5 .Container-Submodule .Card-Submodule-Item:nth-child(2){
    background-color: #FFC700;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2) .Text-Submodule{
    color: #F90000;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 3 */
.Submodule-5 .Container-Submodule .Card-Submodule-Item:nth-child(3){
    background-color: var(--blue-elec);
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
.Submodule-5 .Card-Submodule-Item:nth-child(3) .Text-Submodule{
    color: var(--blue);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Item{
    color: var(--blue);
    margin-top: 1px;
}
.Submodule-5 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Without-Item{
    color: var(--blue);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 4 */
.Submodule-5 .Container-Submodule .Card-Submodule-Item:nth-child(4){
    background-color: #C4D5FF;
    grid-column: 1 / 4;
    grid-row: 2 / 3;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4) .Text-Submodule{
    color: #5270BC;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Item{
    color: #5270BC;
    margin-top: 1px;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Without-Item{
    color: #5270BC;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule, .Submodule-5 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #C4D5FF;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 5 */
.Submodule-5 .Container-Submodule .Card-Submodule-Item:nth-child(5){
    background-color: var(--magenta);
    grid-column: 4 / 7;
    grid-row: 2 / 3;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5) .Text-Submodule{
    color: #93007C;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Item{
    color: #93007C;
    margin-top: 1px;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Without-Item{
    color: #93007C;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-5 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-5 .Card-Submodule-Item:nth-child(5) .Text-SubSubmodule{
    color: var(--white);
}
/* Default Animacion */
.Submodule-5 .Card-Submodule-Item:nth-child(1).Default-Active{
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).Default-Active{
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).Default-Active{
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).Default-Active{
    grid-column: 1 / 4;
    grid-row: 2 / 3;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).Default-Active{
    grid-column: 4 / 7;
    grid-row: 2 / 3;
}
/* Tarjeta 1 Activo */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover, .Submodule-5 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--green);
}
.Submodule-5 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 2 Activo */
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover, .Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item{
    background-color: #F90000;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Tarjeta 3 Activo */
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-5 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover, .Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item{
    background-color: var(--blue);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--blue-elec);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--blue-elec);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue);
}
.Submodule-5 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--blue-elec);
}
/* Tarjeta 4 Activo */
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover, .Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item{
    background-color: #5270BC;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #C4D5FF;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #C4D5FF;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue-elec);
}
.Submodule-5 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #C4D5FF;
}
/* Tarjeta 5 Activo */
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover, .Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item{
    background-color: #93007C;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--magenta);
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-5 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--magenta);
}
.Submodule-5 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color:  #93007C;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--magenta);
}
/* Animacion Tarjeta 1 */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub5active1-card1 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub5active1-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: sub5inactive1-card1 .3s forwards;
}
@keyframes sub5inactive1-card1{
    0%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-0{
    animation: sub5active1-card1-sub4 .6s forwards;
}
@keyframes sub5active1-card1-sub4{
    0%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
    49%{
        width: 66.6%;
        grid-column: 1 / 4;
    }
    50%{
        width: 100%;
        grid-column: 1 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 3;
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-0{
    animation: sub5active1-card1-sub5 .6s forwards;
}
@keyframes sub5active1-card1-sub5{
    0%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        width: 100%;
    }
    49%{
        width: 66.6%;
        grid-column: 4 / 7;
    }
    50%{
        width: 100%;
        grid-column: 4 / 6;
        transform: translateX(0);
    }
    99%{
        grid-column: 4 / 6;
        transform: translateX(50%);
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).inactive-sub-0{
    animation: sub5inactive1-card1-sub4 .6s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5inactive1-card1-sub4{
    0%{
        width: 100%;
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    49%{
        width: 100%;
        grid-column: 3 / 5;
        transform: translateX(-100%);
    }
    50%{
        width: 66.6%;
        grid-column: 1 / 4;
        transform: translateX(0);
    }
    99%{
        width: 100%;
        grid-column: 1 / 4;
    }
    100%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).inactive-sub-0{
    animation: sub5inactive1-card1-sub5 .6s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5inactive1-card1-sub5{
    0%{
        transform: translateX(0);
        width: 100%;
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
    49%{
        transform: translateX(-50%);
        grid-column: 5 / 7;
        width: 100%;
    }
    50%{
        grid-column: 4 / 7;
        transform: translateX(0);
        width: 66.6%;
    }
    99%{
        grid-column: 4 / 7;
        width: 100%;
    }
    100%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        width: 100%;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-0 .Text-SubSubmodule{
    animation-delay: .9s;
}
/* Animacion Tarjeta 2 */
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-1{
    animation: sub5active2-card2 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub5active2-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 5;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).inactive-sub-1{
    animation: sub5inactive2-card2 .3s forwards;
    height: 334px;
}
@keyframes sub5inactive2-card2{
    0%{
        height: 334px;
        grid-column: 3 / 5;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-1{
    animation: sub5active2-card2-sub4 .3s forwards;
}
@keyframes sub5active2-card2-sub4{
    0%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
    99%{
        width: 66.6%;
        grid-column: 1 / 4;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-1{
    animation: sub5active2-card2-sub5 .6s forwards;
}
@keyframes sub5active2-card2-sub5{
    0%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        width: 100%;
    }
    49%{
        width: 66.6%;
        grid-column: 4 / 7;
    }
    50%{
        width: 100%;
        grid-column: 4 / 6;
        transform: translateX(0);
    }
    99%{
        grid-column: 4 / 6;
        transform: translateX(50%);
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).inactive-sub-1{
    animation: sub5inactive2-card2-sub4 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5inactive2-card2-sub4{
    0%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 66.6%;
    }
    99%{
        width: 100%;
        grid-column: 1 / 4;
    }
    100%{
        width: 100%;
        grid-column: 1 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).inactive-sub-1{
    animation: sub5inactive2-card2-sub5 .6s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5inactive2-card2-sub5{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        width: 100%;
        transform: translateX(0);
    }
    49%{
        grid-column: 5 / 7;
        width: 100%;
        transform: translateX(-50%);
    }
    50%{
        width: 66.6%;
        grid-column: 4 / 7;
        transform: translateX(0);
    }
    99%{
        width: 100%;
    }
    100%{
        width: 100%;
        grid-column: 4 / 7;
        grid-row: 2 / 3;
    }
}
/* Animacion Tarjeta 3 */
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-2{
    animation: sub5active3-card3 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub5active3-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 5 / 7;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).inactive-sub-2{
    animation: sub5inactive3-card3 .3s forwards;
    height: 334px;
}
@keyframes sub5inactive3-card3{
    0%{
        height: 334px;
        grid-column: 5 / 7;
        grid-row: 1 / 3;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-2{
    animation: sub5active3-card3-sub4 .3s forwards;
}
@keyframes sub5active3-card3-sub4{
    0%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
    99%{
        width: 66.6%;
        grid-column: 1 / 4;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-2{
    animation: sub5active3-card3-sub5 .6s forwards;
}
@keyframes sub5active3-card3-sub5{
    0%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        width: 100%;
    }
    49%{
        width: 66.6%;
        grid-column: 4 / 7;
    }
    50%{
        width: 100%;
        grid-column: 4 / 6;
        transform: translateX(0);
    }
    99%{
        grid-column: 4 / 6;
        transform: translateX(-50%);
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).inactive-sub-2{
    animation: sub5inactive3-card3-sub4 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5inactive3-card3-sub4{
    0%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 66.6%;
    }
    99%{
        width: 100%;
        grid-column: 1 / 4;
    }
    100%{
        width: 100%;
        grid-column: 1 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).inactive-sub-2{
    animation: sub5inactive3-card3-sub5 .6s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5inactive3-card3-sub5{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        width: 100%;
        transform: translateX(0);
    }
    49%{
        grid-column: 3 / 5;
        width: 100%;
        transform: translateX(50%);
    }
    50%{
        width: 66.6%;
        grid-column: 4 / 7;
        transform: translateX(0);
    }
    99%{
        width: 100%;
    }
    100%{
        width: 100%;
        grid-column: 4 / 7;
        grid-row: 2 / 3;
    }
}
/* Animacion Tarjeta 4 */
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-3{
    animation: sub5active4-card4 1.5s forwards;
}
@keyframes sub5active4-card4{
    0%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
    19.9%{
        width: 66.6%;
        grid-column: 1 / 4;
    }
    20%{
        grid-column: 1 / 3;
        width: 100%;
    }
    60%{
        grid-column: 1 / 3;
        width: 100%;
        transform: translateY(0);
    }
    79.9%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        width: 100%;
        transform: translateY(-100%);
    }
    80%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        width: 100%;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).inactive-sub-3{
    animation: sub5inactive4-card4 1.5s forwards;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5inactive4-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    15%{
        height: 160px;
    }
    24.99%{
        transform: translateY(100%);
        height: 160px;
        grid-row: 1 / 3;
    }
    25%{
        transform: translateY(0);
        grid-row: 2 / 3;
    }
    74.9%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        width: 100%;
    }
    75%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 66.6%;
    }
    99%{
        grid-row: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
    100%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-3{
    animation: sub5active4-card4-sub1 .3s forwards;
    animation-delay: .75s;
}
@keyframes sub5active4-card4-sub1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 3;
        transform: translateX(101%);
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-3{
    animation: sub5active4-card4-sub2 .3s forwards;
    animation-delay: .65s;
}
@keyframes sub5active4-card4-sub2{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 5;
        transform: translateX(101%);
    }
    100%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-3{
    animation: sub5active4-card4-sub3 .3s forwards;
    animation-delay: .45s;
}
@keyframes sub5active4-card4-sub3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 1 / 2;
        transform: translateY(101%);
    }
    100%{
        transform: translateY(0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-3{
    animation: sub5active4-card4-sub5 .6s forwards;
}
@keyframes sub5active4-card4-sub5{
    0%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        width: 100%;
        transform: translateX(0);
    }
    49%{
        width: 66.6%;
        grid-column: 4 / 7;
        transform: translateX(50%);
    }
    50%{
        width: 100%;
        grid-column: 5 / 7;
        transform: translateX(0);
    }
    99.99%{
        grid-column: 5 / 7;
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).inactive-sub-3{
    animation: sub5inactive4-card4-sub1 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5inactive4-card4-sub1{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 5;
        transform: translateX(-101%);
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).inactive-sub-3{
    animation: sub5inactive4-card4-sub2 .3s forwards;
    animation-delay: .4s;
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
@keyframes sub5inactive4-card4-sub2{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 5 / 7;
        transform: translateX(-101%);
    }
    100%{
        grid-column: 3 / 5;
        transform: translateX(0);
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).inactive-sub-3{
    animation: sub5inactive4-card4-sub3 .5s forwards;
    animation-delay: .55s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5inactive4-card4-sub3{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(-101%);
    }
    100%{
        transform: translateY(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).inactive-sub-3{
    animation: sub5inactive4-card4-sub5 .6s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5inactive4-card4-sub5{
    0%{
        width: 100%;
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    49%{
        width: 100%;
        grid-column: 3 / 5;
        transform: translateX(50%);
    }
    50%{
        width: 66.6%;
        grid-column: 4 / 7;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 4 / 7;
        width: 100%;
    }
    100%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        width: 100%;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-3 .Text-SubSubmodule{
    animation-delay: 1.5s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* Animacion Tarjeta 5 */
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-4{
    animation: sub5active5-card5 1.5s forwards;
}
@keyframes sub5active5-card5{
    0%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        width: 100%;
        transform: translateX(0);
    }
    19.9%{
        transform: translateX(50%);
        width: 66.6%;
        grid-column: 4 / 7;
    }
    20%{
        transform: translateX(0);
        width: 100%;
        grid-column: 5 / 7;
    }
    60%{
        transform: translateY(0);
        height: 160px;
        grid-row: 2 / 3;
    }
    79.9%{
        transform: translateY(-100%);
        grid-row: 2 / 3;
    }
    80%{
        transform: translateY(0);
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).inactive-sub-4{
    animation: sub5inactive5-card5 1.5s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5inactive5-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        width: 100%;
    }
    19.99%{
        height: 160px;
    }
    20%{
        transform: translateY(0);
    }
    39.9%{
        transform: translateY(100%);
        grid-row: 1 / 3;
        width: 100%;
    }
    40%{
        transform: translateY(0);
        grid-row: 2 / 3;
    }
    79.9%{
        grid-column: 5 / 7;
        width: 100%;
        transform: translateX(0);
    }
    80%{
        grid-column: 4 / 7;
        width: 66.6%;
        transform: translateX(50%);
    }
    99%{
        grid-column: 4 / 7;
        width: 100%;
        transform: translateX(0);
    }
    100%{
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-4{
    animation: sub5active5-card5-sub1 .3s forwards;
    animation-delay: .55s;
}
@keyframes sub5active5-card5-sub1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 1 / 2;
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-4{
    animation: sub5active5-card5-sub2 .3s forwards;
    animation-delay: .7s;
}
@keyframes sub5active5-card5-sub2{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 5;
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-4{
    animation: sub5active5-card5-sub3 .3s forwards;
    animation-delay: .8s;
}
@keyframes sub5active5-card5-sub3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 5 / 7;
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-4{
    animation: sub5active5-card5-sub4 .6s forwards;
}
@keyframes sub5active5-card5-sub4{
    0%{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
    49%{
        width: 66.6%;
        grid-column: 1 / 4;
    }
    50%{
        width: 100%;
        grid-column: 1 / 3;
        transform: translateX(0);
    }
    99.99%{
        grid-column: 1 / 3;
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0);
        width: 100%;
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).inactive-sub-4{
    animation: sub5inactive5-card5-sub1 .3s forwards;
    animation-delay: .85s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5inactive5-card5-sub1{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).inactive-sub-4{
    animation: sub5inactive5-card5-sub2 .3s forwards;
    animation-delay: .75s;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
@keyframes sub5inactive5-card5-sub2{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).inactive-sub-4{
    animation: sub5inactive5-card5-sub3 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5inactive5-card5-sub3{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 5;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).inactive-sub-4{
    animation: sub5inactive5-card5-sub4 .3s forwards;
    animation-delay: 1s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5inactive5-card5-sub4{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        width: 100%;
        transform: translateX(0);
    }
    1%{
        width: 66.6%;
        grid-column: 1 / 4;
        transform: translateX(calc(100% + 14px));
    }
    99%{
        width: 100%;
        grid-column: 1 / 4;
        transform: translateX(0);
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        width: 100%;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-4 .Text-SubSubmodule{
    animation-delay: 1.5s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 1 a Active Card 2 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-0.card-1{
    animation: sub5active1-sub1-card1-card2 .3s forwards;
}
@keyframes sub5active1-sub1-card1-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1{
    animation: sub5active1-sub1-card2-card2 .3s forwards;
    animation-delay: .55s;
}
@keyframes sub5active1-sub1-card2-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        grid-column: 3 / 5;
        height: 334px;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-0.card-1{
    animation: sub5active1-sub1-card3-card2 0s forwards;
}
@keyframes sub5active1-sub1-card3-card2{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-0.card-1{
    animation: sub5active1-sub1-card4-card2 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active1-sub1-card4-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-0.card-1{
    animation: sub5active1-sub1-card5-card2 0s forwards;
}
@keyframes sub5active1-sub1-card5-card2{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 1 a Active Card 2 --- */
/* --- Active Card 1 a Active Card 3 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-0.card-2{
    animation: sub5active1-sub1-card1-card3 .3s forwards;
}
@keyframes sub5active1-sub1-card1-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-0.card-2{
    animation: sub5active1-sub1-card2-card3 0s forwards;
}
@keyframes sub5active1-sub1-card2-card3{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2{
    animation: sub5active1-sub1-card3-card3 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub5active1-sub1-card3-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-0.card-2{
    animation: sub5active1-sub1-card4-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active1-sub1-card4-card3{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-0.card-2{
    animation: sub5active1-sub1-card5-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active1-sub1-card5-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
/* --- Active Card 1 a Active Card 3 --- */
/* --- Active Card 1 a Active Card 4 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-0.card-3{
    animation: sub5active1-sub1-card1-card4 .9s forwards;
}
@keyframes sub5active1-sub1-card1-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    32.9%{
        grid-row: 1 / 3;
        height: 160px;
    }
    33%{
        grid-row: 1 / 2;
        height: 160px;
    }
    66%{
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-0.card-3{
    animation: sub5active1-sub1-card2-card4 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub5active1-sub1-card2-card4{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-0.card-3{
    animation: sub5active1-sub1-card3-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub5active1-sub1-card3-card4{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        grid-row: 1 / 2;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3{
    animation: sub5active1-sub1-card4-card4 .9s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active1-sub1-card4-card4{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    32.9%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translate(calc(-100% - 14px), 0);
    }
    33%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
    65.9%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
    66%{
        transform: translate(0, 100%);
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    82%{
        transform: translate(0, 0);
    }
    99%{
        height: 334px;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-0.card-3{
    animation: sub5active1-sub1-card5-card4 .3s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active1-sub1-card5-card4{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-0.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 1 a Active Card 4 --- */
/* --- Active Card 1 a Active Card 5 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-0.card-4{
    animation: sub5active1-sub1-card1-card5 .3s forwards;
}
@keyframes sub5active1-sub1-card1-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-0.card-4{
    animation: sub5active1-sub1-card2-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub5active1-sub1-card2-card5{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-0.card-4{
    animation: sub5active1-sub1-card3-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub5active1-sub1-card3-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-0.card-4{
    animation: sub5active1-sub1-card4-card5 0s forwards;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active1-sub1-card4-card5{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-0.card-4{
    animation: sub5active1-sub1-card5-card5 .3s forwards;
    animation-delay: .6s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active1-sub1-card5-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(100%);
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        transform: translateY(0);
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
    }
}
/* --- Active Card 1 a Active Card 5 --- */
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0{
    animation: sub5active2-sub2-card1-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 160px;
}
@keyframes sub5active2-sub2-card1-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-1.card-0{
    animation: sub5active2-sub2-card2-card1 .3s forwards;
    height: 334px;
    grid-column: 3 / 5;
    grid-row: 1 / 3;
}
@keyframes sub5active2-sub2-card2-card1{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-1.card-0{
    animation: sub5active2-sub2-card3-card1 0s forwards;
}
@keyframes sub5active2-sub2-card3-card1{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-1.card-0{
    animation: sub5active2-sub2-card4-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active2-sub2-card4-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-1.card-0{
    animation: sub5active2-sub2-card5-card1 0s forwards;
}
@keyframes sub5active2-sub2-card5-card1{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 2 a Active Card 1 --- */
/* --- Active Card 2 a Active Card 3 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-1.card-2{
    animation: sub5active2-sub2-card1-card3 0s forwards;
}
@keyframes sub5active2-sub2-card1-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-1.card-2{
    animation: sub5active2-sub2-card2-card3 .3s forwards;
    height: 334px;
    grid-column: 3 / 5;
    grid-row: 1 / 3;
}
@keyframes sub5active2-sub2-card2-card3{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2{
    animation: sub5active2-sub2-card3-card3 .3s forwards;
    animation-delay: .5s;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 160px;
}
@keyframes sub5active2-sub2-card3-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-1.card-2{
    animation: sub5active2-sub2-card4-card3 0s forwards;
}
@keyframes sub5active2-sub2-card4-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-1.card-2{
    animation: sub5active2-sub2-card5-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active2-sub2-card5-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 5 / 7;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2 .Text-SubSubmodule{
    animation-delay: .8s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-1.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .8s;
}
/* --- Active Card 2 a Active Card 3 --- */
/* --- Active Card 2 a Active Card 4 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-1.card-3{
    animation: sub5active2-sub2-card1-card4 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub5active2-sub2-card1-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-1.card-3{
    animation: sub5active2-sub2-card2-card4 .9s forwards;
    height: 334px;
    grid-column: 3 / 5;
    grid-row: 1 / 3;
}
@keyframes sub5active2-sub2-card2-card4{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 334px;
    }
    32.9%{
        grid-row: 1 / 3;
        height: 160px;
    }
    33%{
        grid-row: 1 / 2;
        height: 160px;
        transform: translateX(0);
    }
    66%{
        grid-row: 1 / 2;
        height: 160px;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        height: 160px;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-1.card-3{
    animation: sub5active2-sub2-card3-card4 .3s forwards;
    animation-delay: .5s;
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
@keyframes sub5active2-sub2-card3-card4{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 2;
    }
    100%{
        transform: translateY(0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3{
    animation: sub5active2-sub2-card4-card4 .3s forwards;
    animation-delay: .75s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active2-sub2-card4-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
        height: 160px;
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-1.card-3{
    animation: sub5active2-sub2-card5-card4 .3s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active2-sub2-card5-card4{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3 .Text-SubSubmodule{
    animation-delay: 1.05s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-1.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.05s;
}
/* --- Active Card 2 a Active Card 4 --- */
/* --- Active Card 2 a Active Card 5 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-1.card-4{
    animation: sub5active2-sub2-card1-card5 .3s forwards;
    animation-delay: .55s;
}
@keyframes sub5active2-sub2-card1-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 2;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-1.card-4{
    animation: sub5active2-sub2-card2-card5 .9s forwards;
}
@keyframes sub5active2-sub2-card2-card5{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 334px;
    }
    32.9%{
        grid-row: 1 / 3;
        height: 160px;
    }
    33%{
        grid-row: 1 / 2;
        height: 160px;
        transform: translateX(0);
    }
    66%{
        grid-row: 1 / 2;
        height: 160px;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        height: 160px;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-1.card-4{
    animation: sub5active2-sub2-card3-card5 .6s forwards;
    animation-delay: .6s;
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
@keyframes sub5active2-sub2-card3-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 5 / 7;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-1.card-4{
    animation: sub5active2-sub2-card4-card5 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active2-sub2-card4-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-1.card-4{
    animation: sub5active2-sub2-card5-card5 .3s forwards;
    animation-delay: 1s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active2-sub2-card5-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        transform: translateY(100%);
        height: 160px;
    }
    50%{
        transform: translateY(0);
    }
    99%{
        height: 334px;
    }
    100%{
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-1.card-4 .Text-SubSubmodule{
    animation-delay: 1.3s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-1.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.3s;
}
/* --- Active Card 2 a Active Card 5 --- */
/* --- Active Card 3 a Active Card 1 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0{
    animation: sub5active3-sub3-card1-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
@keyframes sub5active3-sub3-card1-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
        grid-row: 1 / 3;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-2.card-0{
    animation: sub5active3-sub3-card2-card1 0s forwards;
}
@keyframes sub5active3-sub3-card2-card1{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-2.card-0{
    animation: sub5active3-sub3-card3-card1 .3s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active3-sub3-card3-card1{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        height: 160px;
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-2.card-0{
    animation: sub5active3-sub3-card4-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active3-sub3-card4-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-2.card-0{
    animation: sub5active3-sub3-card5-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active3-sub3-card5-card1{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
/* --- Active Card 3 a Active Card 1 --- */
/* --- Active Card 3 a Active Card 2 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-2.card-1{
    animation: sub5active3-sub3-card1-card2 0s forwards;
}
@keyframes sub5active3-sub3-card1-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1{
    animation: sub5active3-sub3-card2-card2 .3s forwards;
    animation-delay: .5s;
    height: 160px;
    grid-column: 3 / 5;
    grid-row: 1 / 3;
}
@keyframes sub5active3-sub3-card2-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        height: 334px;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-2.card-1{
    animation: sub5active3-sub3-card3-card2 .3s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active3-sub3-card3-card2{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-2.card-1{
    animation: sub5active3-sub3-card4-card2 0s forwards;
}
@keyframes sub5active3-sub3-card4-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-2.card-1{
    animation: sub5active3-sub3-card5-card2 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active3-sub3-card5-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 3 / 5;
    }
    100%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1 .Text-SubSubmodule{
    animation-delay: .8s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-2.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .8s;
}
/* --- Active Card 3 a Active Card 2 --- */
/* --- Active Card 3 a Active Card 4 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-2.card-3{
    animation: sub5active3-sub3-card1-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub5active3-sub3-card1-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-2.card-3{
    animation: sub5active3-sub3-card2-card4 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active3-sub3-card2-card4{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 3 / 5;
    }
    100%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-2.card-3{
    animation: sub5active3-sub3-card3-card4 .3s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active3-sub3-card3-card4{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    75%{
        height: 160px;
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-2.card-3{
    animation: sub5active3-sub3-card4-card4 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active3-sub3-card4-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 160px;
        transform: translateY(100%);
    }
    50%{
        transform: translateY(0);
    }
    100%{
        height: 334px;
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-2.card-3{
    animation: sub5active3-sub3-card5-card4 0s forwards;
}
@keyframes sub5active3-sub3-card5-card4{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-2.card-3 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
/* --- Active Card 3 a Active Card 4 --- */
/* --- Active Card 3 a Active Card 5 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-2.card-4{
    animation: sub5active3-sub3-card1-card5 .3s forwards;
    animation-delay: .4s;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
@keyframes sub5active3-sub3-card1-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 1 / 2;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-2.card-4{
    animation: sub5active3-sub3-card2-card5 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active3-sub3-card2-card5{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 5;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-2.card-4{
    animation: sub5active3-sub3-card3-card5 .9s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active3-sub3-card3-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
    }
    32.9%{
        height: 160px;
        grid-row: 1 / 3;
    }
    33%{
        height: 160px;
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    66%{
        transform: translateX(0);
        grid-column: 5 / 7;
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-2.card-4{
    animation: sub5active3-sub3-card4-card5 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active3-sub3-card4-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-2.card-4{
    animation: sub5active3-sub3-card5-card5 .9s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active3-sub3-card5-card5{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    32.9%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translate(calc(100% + 14px), 0);
    }
    33%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    65%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    66%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 160px;
        transform: translate(0, calc(100% + 14px));
    }
    82%{
        transform: translate(0, 0);
    }
    99%{
        grid-row: 1 / 3;
        height: 334px;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translate(0, 0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-2.card-4 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-2.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 3 a Active Card 5 --- */
/* --- Active Card 4 a Active Card 1 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0{
    animation: sub5active4-sub4-card1-card1 .9s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card1-card1{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    32.9%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(calc(-100% - 14px));
    }
    33%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    65.9%{
        grid-row: 1 / 2;
        grid-column: 1 / 3;
    }
    66%{
        grid-row: 1 / 3;
        grid-column: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-3.card-0{
    animation: sub5active4-sub4-card2-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card2-card1{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-3.card-0{
    animation: sub5active4-sub4-card3-card1 .3s forwards;
    animation-delay: .5s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active4-sub4-card3-card1{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-3.card-0{
    animation: sub5active4-sub4-card4-card1 .9s forwards;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active4-sub4-card4-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translate(0, 0);
    }
    15%{
        height: 160px;
    }
    32.9%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translate(0, calc(100% + 14px));
    }
    33%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
    66%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 1 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-3.card-0{
    animation: sub5active4-sub4-card5-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active4-sub4-card5-card1{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-3.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 4 a Active Card 1 --- */
/* --- Active Card 4 a Active Card 2 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-3.card-1{
    animation: sub5active4-sub4-card1-card2 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card1-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-3.card-1{
    animation: sub5active4-sub4-card2-card2 .9s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card2-card2{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    32.9%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    33%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    65%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
    66%{
        height: 160px;
        grid-column: 3 / 5;
        grid-row: 1 / 3;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 5;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-3.card-1{
    animation: sub5active4-sub4-card3-card2 .3s forwards;
    animation-delay: .5s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active4-sub4-card3-card2{
    0%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        grid-row: 2 / 3;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-3.card-1{
    animation: sub5active4-sub4-card4-card2 .3s forwards;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active4-sub4-card4-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-3.card-1{
    animation: sub5active4-sub4-card5-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active4-sub4-card5-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-3.card-1 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-3.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 4 a Active Card 2 --- */
/* --- Active Card 4 a Active Card 3 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-3.card-2{
    animation: sub5active4-sub4-card1-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card1-card3{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-3.card-2{
    animation: sub5active4-sub4-card2-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card2-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2{
    animation: sub5active4-sub4-card3-card3 .3s forwards;
    animation-delay: .5s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active4-sub4-card3-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
        height: 160px;
    }
    50%{
        transform: translateY(0);
    }
    99%{
        grid-row: 1 / 3;
        height: 334px;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-3.card-2{
    animation: sub5active4-sub4-card4-card3 .3s forwards;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active4-sub4-card4-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-3.card-2{
    animation: sub5active4-sub4-card5-card3 0s forwards;
}
@keyframes sub5active4-sub4-card5-card3{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2 .Text-SubSubmodule{
    animation-delay: .8s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-3.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .8s;
}
/* --- Active Card 4 a Active Card 3 --- */
/* --- Active Card 4 a Active Card 5 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-3.card-4{
    animation: sub5active4-sub4-card1-card5 .9s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card1-card5{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
    32.9%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translate(calc(-100% - 14px), 0);
    }
    33%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
    50%{
        grid-row: 1 / 2;
        grid-column: 1 / 3;
    }
    51%{
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        transform: translate(0, 0);
    }
    99%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 1 / 2;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-3.card-4{
    animation: sub5active4-sub4-card2-card5 .9s forwards;
    animation-delay: .3s;
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}
@keyframes sub5active4-sub4-card2-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    32.9%{
        grid-column: 5 / 7;
        transform: translateX(calc(-100% - 14px));
    }
    33%{
        grid-column: 3 / 5;
        transform: translateX(0);
    }
    66%{
        grid-column: 3 / 5;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-3.card-4{
    animation: sub5active4-sub4-card3-card5 .9s forwards;
    animation-delay: .5s;
    grid-column: 5 / 7;
    grid-row: 2 / 3;
}
@keyframes sub5active4-sub4-card3-card5{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        transform: translate(0, calc(100% + 14px));
    }
    32.9%{
        transform: translate(0, 0);
        grid-row: 1 / 3;
    }
    33%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    51%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    99%{
        transform: translate(calc(-100% - 14px), 0);
        grid-column: 5 / 7;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-3.card-4{
    animation: sub5active4-sub4-card4-card5 .9s forwards;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active4-sub4-card4-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
        transform: translate(0, 0);
    }
    15%{
        height: 160px;
    }
    32.9%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translate(0, 100%);
    }
    33%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
    66%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 1 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-3.card-4{
    animation: sub5active4-sub4-card5-card5 .9s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active4-sub4-card5-card5{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translate(0, 0);
        height: 160px;
    }
    32.9%{
        grid-column: 3 / 5;
        transform: translate(calc(100% + 14px), 0);
        grid-row: 2 / 3;
    }
    33%{
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        height: 160px;
        transform: translate(0, 0);
    }
    65%{
        transform: translate(0, 0);
        grid-row: 2 / 3;
    }
    66%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 160px;
        transform: translate(0, 100%);
    }
    82%{
        transform: translate(0, 0);
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translate(0, 0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-3.card-4 .Text-SubSubmodule{
    animation-delay: 1.5s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-3.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 4 a Active Card 5 --- */
/* --- Active Card 5 a Active Card 1 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-4.card-0{
    animation: sub5active5-sub5-card1-card1 .3s forwards;
    animation-delay: .45s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active5-sub5-card1-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
        height: 160px;
    }
    50%{
        transform: translateY(0);
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-4.card-0{
    animation: sub5active5-sub5-card2-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card2-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-4.card-0{
    animation: sub5active5-sub5-card3-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card3-card1{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 3 / 5;
    }
    100%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-4.card-0{
    animation: sub5active5-sub5-card4-card1 0s forwards;
}
@keyframes sub5active5-sub5-card4-card1{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-4.card-0{
    animation: sub5active5-sub5-card5-card1 .3s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active5-sub5-card5-card1{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-4.card-0 .Text-SubSubmodule{
    animation-delay: .75s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-4.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .75s;
}
/* --- Active Card 5 a Active Card 1 --- */
/* --- Active Card 5 a Active Card 2 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-4.card-1{
    animation: sub5active5-sub5-card1-card2 .3s forwards;
    animation-delay: .5s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active5-sub5-card1-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-4.card-1{
    animation: sub5active5-sub5-card2-card2 .9s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card2-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    32.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 3;
    }
    33%{
        transform: translateX(0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
    65%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        height: 160px;
    }
    66%{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-row: 1 / 3;
        grid-column: 3 / 5;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-4.card-1{
    animation: sub5active5-sub5-card3-card2 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card3-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 3 / 5;
    }
    100%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-4.card-1{
    animation: sub5active5-sub5-card4-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active5-sub5-card4-card2{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-4.card-1{
    animation: sub5active5-sub5-card5-card2 .3s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active5-sub5-card5-card2{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-row: 1 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-4.card-1 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-4.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 5 a Active Card 2 --- */
/* --- Active Card 5 a Active Card 3 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-4.card-2{
    animation: sub5active5-sub5-card1-card3 .3s forwards;
    animation-delay: .5s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active5-sub5-card1-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateY(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-4.card-2{
    animation: sub5active5-sub5-card2-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card2-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 3;
    }
    100%{
        grid-row: 1 / 3;
        grid-column: 3 / 5;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-4.card-2{
    animation: sub5active5-sub5-card3-card3 .9s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card3-card3{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    32.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 3 / 5;
    }
    33%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    65.9%{
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    66%{
        height: 160px;
        grid-row: 1 / 3;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 5 / 7;
        grid-row: 1 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-4.card-2{
    animation: sub5active5-sub5-card4-card3 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active5-sub5-card4-card3{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 5;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-4.card-2{
    animation: sub5active5-sub5-card5-card3 .9s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active5-sub5-card5-card3{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translate(0, 0);
    }
    16%{
        height: 160px;
    }
    32.9%{
        grid-row: 1 / 3;
        transform: translate(0, calc(100% + 14px));
    }
    33%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        height: 160px;
    }
    66%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
    }
    99%{
        transform: translate(calc(-100% - 14px), 0);
        grid-column: 5 / 7;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-4.card-2 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-4.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 5 a Active Card 3 --- */
/* --- Active Card 5 a Active Card 4 --- */
.Submodule-5 .Card-Submodule-Item:nth-child(1).active-sub-4.card-3{
    animation: sub5active5-sub5-card1-card4 .9s forwards;
    animation-delay: .5s;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}
@keyframes sub5active5-sub5-card1-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    32.9%{
        transform: translate(0, calc(-100% - 14px));
        grid-row: 2 / 3;
    }
    33%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
    66%{
        transform: translate(0, 0);
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 1 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(2).active-sub-4.card-3{
    animation: sub5active5-sub5-card2-card4 .9s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card2-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        transform: translateX(0);
    }
    32.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 3;
    }
    33%{
        grid-column: 3 / 5;
        transform: translateX(0);
    }
    66%{
        transform: translateX(0);
        grid-column: 3 / 5;
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 3 / 5;
    }
    100%{
        transform: translateX(0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(3).active-sub-4.card-3{
    animation: sub5active5-sub5-card3-card4 .9s forwards;
    animation-delay: .3s;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}
@keyframes sub5active5-sub5-card3-card4{
    0%{
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        transform: translate(0, 0);
    }
    32.9%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 3 / 5;
    }
    33%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
        grid-row: 1 / 2;
    }
    65.9%{
        grid-column: 5 / 7;
    }
    66%{
        transform: translate(0, 0);
    }
    99%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 1 / 2;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-4.card-3{
    animation: sub5active5-sub5-card4-card4 .9s forwards;
    animation-delay: .6s;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}
@keyframes sub5active5-sub5-card4-card4{
    0%{
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    32.9%{
        grid-column: 3 / 5;
        transform: translate(calc(-100% - 14px), 0);
    }
    33%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    65.9%{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    66%{
        transform: translate(0, 100%);
        grid-row: 1 / 3;
        height: 160px;
    }
    82%{
        transform: translate(0, 0);
    }
    99%{
        height: 334px;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(5).active-sub-4.card-3{
    animation: sub5active5-sub5-card5-card4 .9s forwards;
    grid-column: 5 / 7;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub5active5-sub5-card5-card4{
    0%{
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        height: 334px;
        transform: translate(0, 0);
    }
    16%{
        height: 160px;
    }
    32.9%{
        grid-row: 1 / 3;
        transform: translate(0, calc(100% + 14px));
    }
    33%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        height: 160px;
    }
    66%{
        transform: translate(0, 0);
        grid-column: 5 / 7;
    }
    99%{
        transform: translate(calc(-100% - 14px), 0);
        grid-column: 5 / 7;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 5;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-sub-4.card-3 .Text-SubSubmodule{
    animation-delay: 1.5s;
}
.Submodule-5 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-4.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 5 a Active Card 4 --- */
.Submodule-6 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-6 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-6 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-6 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-6 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 0 !important;
}
.Submodule-6 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
}
.Submodule-6 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-6 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-6 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-6 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-6 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-6 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-6 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-6 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-6 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-6 10s linear infinite;
}
.Submodule-6 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-6 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-6 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-6{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-6 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    height: 160px;
}
.Submodule-6 .Container-Header-Submodule{
    position: relative;
    height: 102px;
}
.Submodule-6 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    color: #00533F;
    width: 100%;
    position: absolute;
}
.Submodule-6 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-6 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-6 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-6 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-6 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-6 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-6 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text .5s forwards;
    animation-delay: .3s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-6 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-6 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-6 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-6 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-6 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .3s;
}
/* Tarjeta 1 */
.Submodule-6 .Card-Submodule-Item:nth-child(1){
    background-color: var(--green);
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-6 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-6 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 2 */
.Submodule-6 .Container-Submodule .Card-Submodule-Item:nth-child(2){
    background-color: #FFC700;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2) .Text-Submodule{
    color: #F90000;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 3 */
.Submodule-6 .Container-Submodule .Card-Submodule-Item:nth-child(3){
    background-color: var(--blue-elec);
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}
.Submodule-6 .Card-Submodule-Item:nth-child(3) .Text-Submodule{
    color: var(--blue);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Item{
    color: var(--blue);
    margin-top: 1px;
}
.Submodule-6 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Without-Item{
    color: var(--blue);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 4 */
.Submodule-6 .Container-Submodule .Card-Submodule-Item:nth-child(4){
    background-color: #C4D5FF;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4) .Text-Submodule{
    color: #5270BC;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Item{
    color: #5270BC;
    margin-top: 1px;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Without-Item{
    color: #5270BC;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #C4D5FF;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #C4D5FF;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 5 */
.Submodule-6 .Container-Submodule .Card-Submodule-Item:nth-child(5){
    background-color: var(--magenta);
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5) .Text-Submodule{
    color: #93007C;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Item{
    color: #93007C;
    margin-top: 1px;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Without-Item{
    color: #93007C;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-6 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-6 .Card-Submodule-Item:nth-child(5) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 6 */
.Submodule-6 .Container-Submodule .Card-Submodule-Item:nth-child(6){
    background-color: #FC8122;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6) .Text-Submodule{
    color: #AC4C02;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(6) .Icon-Submodule-Item{
    color: #AC4C02;
    margin-top: 1px;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6) .Icon-Submodule-Without-Item{
    color: #AC4C02;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FC8122;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FC8122;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6) .Text-SubSubmodule{
    color: var(--white);
}
/* Default Animacion */
.Submodule-6 .Card-Submodule-Item:nth-child(1).Default-Active{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2).Default-Active{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).Default-Active{
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).Default-Active{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).Default-Active{
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).Default-Active{
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
/* Tarjeta 1 Activo */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover, .Submodule-6 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--green);
}
.Submodule-6 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 2 Activo */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover, .Submodule-6 .Card-Submodule-Item:nth-child(2).active-submodule-item{
    background-color: #F90000;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Tarjeta 3 Activo */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-6 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover, .Submodule-6 .Card-Submodule-Item:nth-child(3).active-submodule-item{
    background-color: var(--blue);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--blue-elec);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--blue-elec);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue);
}
.Submodule-6 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--blue-elec);
}
/* Tarjeta 4 Activo */
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover, .Submodule-6 .Card-Submodule-Item:nth-child(4).active-submodule-item{
    background-color: #5270BC;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #C4D5FF;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #C4D5FF;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue-elec);
}
.Submodule-6 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #C4D5FF;
}
/* Tarjeta 5 Activo */
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover, .Submodule-6 .Card-Submodule-Item:nth-child(5).active-submodule-item{
    background-color: #93007C;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--magenta);
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--magenta);
}
.Submodule-6 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #93007C;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--magenta);
}
/* Tarjeta 6 Activo */
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover, .Submodule-6 .Card-Submodule-Item:nth-child(6).active-submodule-item{
    background-color: #AC4C02;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FC8122;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-6 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(6).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FC8122;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #AC4C02;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-6 .Card-Submodule-Item:nth-child(6).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FC8122;
}
/* Animacion Tarjeta 1 */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub6active1-card1 .3s forwards;
}
@keyframes sub6active1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: sub6inactive1-card1 .3s forwards;
}
@keyframes sub6inactive1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-0{
    animation: sub6active1-card1-sub4 .3s forwards;
}
@keyframes sub6active1-card1-sub4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).inactive-sub-0{
    animation: sub6inactive1-card1-sub4 .3s forwards;
}
@keyframes sub6inactive1-card1-sub4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* Animacion Tarjeta 2 */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-1{
    animation: sub6active2-card2 .3s forwards;
}
@keyframes sub6active2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(2).inactive-sub-1{
    animation: sub6inactive2-card2 .3s forwards;
}
@keyframes sub6inactive2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-1{
    animation: sub6active2-card2-sub5 .3s forwards;
}
@keyframes sub6active2-card2-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).inactive-sub-1{
    animation: sub6inactive2-card2-sub5 .3s forwards;
}
@keyframes sub6inactive2-card2-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* Animacion Tarjeta 3 */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-2{
    animation: sub6active3-card3 .3s forwards;
}
@keyframes sub6active3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).inactive-sub-2{
    animation: sub6inactive3-card3 .3s forwards;
}
@keyframes sub6inactive3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-2{
    animation: sub6active3-card3-sub6 .3s forwards;
}
@keyframes sub6active3-card3-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).inactive-sub-2{
    animation: sub6inactive3-card3-sub6 .3s forwards;
}
@keyframes sub6inactive3-card3-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* Animacion Tarjeta 4 */
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-3{
    animation: sub6active4-card4 .3s forwards;
}
@keyframes sub6active4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).inactive-sub-3{
    animation: sub6inactive4-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6inactive4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
/* Animacion Tarjeta 5 */
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-4{
    animation: sub6active5-card5 .3s forwards;
}
@keyframes sub6active5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).inactive-sub-4{
    animation: sub6inactive5-card5 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6inactive5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
/* Animacion Tarjeta 6 */
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-5{
    animation: sub6active6-card6 .3s forwards;
}
@keyframes sub6active6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).inactive-sub-5{
    animation: sub6inactive6-card6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6inactive6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
/* --- Active Card 1 a Active Card 2 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-0.card-1{
    animation: sub6active1-sub1-card1-card2 .3s forwards;
}
@keyframes sub6active1-sub1-card1-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1{
    animation: sub6active1-sub1-card2-card2 .3s forwards;
}
@keyframes sub6active1-sub1-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-0.card-1{
    animation: sub6active1-sub1-card4-card2 .3s forwards;
}
@keyframes sub6active1-sub1-card4-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-0.card-1{
    animation: sub6active1-sub1-card5-card2 .3s forwards;
}
@keyframes sub6active1-sub1-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 1 a Active Card 2 --- */
/* --- Active Card 1 a Active Card 3 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-0.card-2{
    animation: sub6active1-sub1-card1-card3 .3s forwards;
}
@keyframes sub6active1-sub1-card1-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2{
    animation: sub6active1-sub1-card3-card3 .3s forwards;
}
@keyframes sub6active1-sub1-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-0.card-2{
    animation: sub6active1-sub1-card4-card3 .3s forwards;
}
@keyframes sub6active1-sub1-card4-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-0.card-2{
    animation: sub6active1-sub1-card6-card3 .3s forwards;
}
@keyframes sub6active1-sub1-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 1 a Active Card 3 --- */
/* --- Active Card 1 a Active Card 4 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-0.card-3{
    animation: sub6active1-sub1-card1-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub6active1-sub1-card1-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3{
    animation: sub6active1-sub1-card4-card4 .6s forwards;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub6active1-sub1-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    49.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    50%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        transform: translateY(0);
        height: 160px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3 .Text-SubSubmodule{
    animation-delay: .6s;
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-0.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .6s;
}
/* --- Active Card 1 a Active Card 4 --- */
/* --- Active Card 1 a Active Card 5 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-0.card-4{
    animation: sub6active1-sub1-card1-card5 .3s forwards;
}
@keyframes sub6active1-sub1-card1-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-0.card-4{
    animation: sub6active1-sub1-card4-card5 .3s forwards;
}
@keyframes sub6active1-sub1-card4-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-0.card-4{
    animation: sub6active1-sub1-card5-card5 .3s forwards;
}
@keyframes sub6active1-sub1-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
/* --- Active Card 1 a Active Card 5 --- */
/* --- Active Card 1 a Active Card 6 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-0.card-5{
    animation: sub6active1-sub1-card1-card5 .3s forwards;
}
@keyframes sub6active1-sub1-card1-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-0.card-5{
    animation: sub6active1-sub1-card4-card5 .3s forwards;
}
@keyframes sub6active1-sub1-card4-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-0.card-5{
    animation: sub6active1-sub1-card5-card6 .3s forwards;
}
@keyframes sub6active1-sub1-card5-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
/* --- Active Card 1 a Active Card 6 --- */
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0{
    animation: sub6active2-sub2-card1-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-1.card-0{
    animation: sub6active2-sub2-card2-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-1.card-0{
    animation: sub6active2-sub2-card4-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-1.card-0{
    animation: sub6active2-sub2-card5-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* --- Active Card 2 a Active Card 1 --- */
/* --- Active Card 2 a Active Card 3 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-1.card-2{
    animation: sub6active2-sub2-card2-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2{
    animation: sub6active2-sub2-card3-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card3-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-1.card-2{
    animation: sub6active2-sub2-card5-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-1.card-2{
    animation: sub6active2-sub2-card6-card1 .3s forwards;
}
@keyframes sub6active2-sub2-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 2 a Active Card 3 --- */
/* --- Active Card 2 a Active Card 4 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-1.card-3{
    animation: sub6active2-sub2-card2-card4 .3s forwards;
}
@keyframes sub6active2-sub2-card2-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3{
    animation: sub6active2-sub2-card4-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
}
@keyframes sub6active2-sub2-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-1.card-3{
    animation: sub6active2-sub2-card5-card4 .3s forwards;
}
@keyframes sub6active2-sub2-card5-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* --- Active Card 2 a Active Card 4 --- */
/* --- Active Card 2 a Active Card 5 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-1.card-4{
    animation: sub6active2-sub2-card2-card5 .3s forwards;
}
@keyframes sub6active2-sub2-card2-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-1.card-4{
    animation: sub6active2-sub2-card5-card5 .6s forwards;
}
@keyframes sub6active2-sub2-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    49%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    50%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        transform: translateY(0);
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-1.card-4 .Text-SubSubmodule{
    animation-delay: .6s;
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-1.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .6s;
}
/* --- Active Card 2 a Active Card 5 --- */
/* --- Active Card 2 a Active Card 6 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-1.card-5{
    animation: sub6active2-sub2-card2-card6 .3s forwards;
}
@keyframes sub6active2-sub2-card2-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-1.card-5{
    animation: sub6active2-sub2-card5-card6 .3s forwards;
}
@keyframes sub6active2-sub2-card5-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-1.card-5{
    animation: sub6active2-sub2-card6-card6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
}
@keyframes sub6active2-sub2-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
/* --- Active Card 2 a Active Card 6 --- */
/* --- Active Card 3 a Active Card 1 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0{
    animation: sub6active3-sub3-card1-card1 .3s forwards;
}
@keyframes sub6active3-sub3-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-2.card-0{
    animation: sub6active3-sub3-card3-card1 .3s forwards;
}
@keyframes sub6active3-sub3-card3-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-2.card-0{
    animation: sub6active3-sub3-card4-card1 .3s forwards;
}
@keyframes sub6active3-sub3-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-2.card-0{
    animation: sub6active3-sub3-card6-card1 .3s forwards;
}
@keyframes sub6active3-sub3-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* --- Active Card 3 a Active Card 1 --- */
/* --- Active Card 3 a Active Card 2 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1{
    animation: sub6active3-sub3-card2-card2 .3s forwards;
}
@keyframes sub6active3-sub3-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-2.card-1{
    animation: sub6active3-sub3-card3-card2 .3s forwards;
}
@keyframes sub6active3-sub3-card3-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-2.card-1{
    animation: sub6active3-sub3-card5-card2 .3s forwards;
}
@keyframes sub6active3-sub3-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-2.card-1{
    animation: sub6active3-sub3-card6-card2 .3s forwards;
}
@keyframes sub6active3-sub3-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* --- Active Card 3 a Active Card 2 --- */
/* --- Active Card 3 a Active Card 4 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-2.card-3{
    animation: sub6active3-sub3-card3-card4 .3s forwards;
}
@keyframes sub6active3-sub3-card3-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-2.card-3{
    animation: sub6active3-sub3-card4-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
}
@keyframes sub6active3-sub3-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-2.card-3{
    animation: sub6active3-sub3-card6-card4 .3s forwards;
}
@keyframes sub6active3-sub3-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* --- Active Card 3 a Active Card 4 --- */
/* --- Active Card 3 a Active Card 5 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-2.card-4{
    animation: sub6active3-sub3-card3-card5 .3s forwards;
}
@keyframes sub6active3-sub3-card3-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-2.card-4{
    animation: sub6active3-sub3-card5-card5 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
}
@keyframes sub6active3-sub3-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-2.card-4{
    animation: sub6active3-sub3-card6-card5 .3s forwards;
}
@keyframes sub6active3-sub3-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
}
/* --- Active Card 3 a Active Card 5 --- */
/* --- Active Card 3 a Active Card 6 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-2.card-5{
    animation: sub6active3-sub3-card3-card6 .3s forwards;
}
@keyframes sub6active3-sub3-card3-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-2.card-5{
    animation: sub6active3-sub3-card6-card6 .6s forwards;
}
@keyframes sub6active3-sub3-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    49%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    50%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        transform: translateY(0);
        height: 160px;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-2.card-5 .Text-SubSubmodule{
    animation-delay: .6s;
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-2.card-5 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .6s;
}
/* --- Active Card 3 a Active Card 6 --- */
/* --- Active Card 4 a Active Card 1 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0{
    animation: sub6active4-sub4-card1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub6active4-sub4-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-3.card-0{
    animation: sub6active4-sub4-card4-card1 .6s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active4-sub4-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    49.9%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0 .Text-SubSubmodule{
    animation-delay: .6s;
}
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-3.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .6s;
}
/* --- Active Card 4 a Active Card 1 --- */
/* --- Active Card 4 a Active Card 2 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-3.card-1{
    animation: sub6active4-sub4-card2-card2 .3s forwards;
}
@keyframes sub6active4-sub4-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-3.card-1{
    animation: sub6active4-sub4-card4-card2 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active4-sub4-card4-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-3.card-1{
    animation: sub6active4-sub4-card5-card2 .3s forwards;
}
@keyframes sub6active4-sub4-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 4 a Active Card 2 --- */
/* --- Active Card 4 a Active Card 3 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2{
    animation: sub6active4-sub4-card3-card3 .3s forwards;
}
@keyframes sub6active4-sub4-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-3.card-2{
    animation: sub6active4-sub4-card4-card3 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active4-sub4-card4-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-3.card-2{
    animation: sub6active4-sub4-card6-card3 .3s forwards;
}
@keyframes sub6active4-sub4-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 4 a Active Card 3 --- */
/* --- Active Card 4 a Active Card 5 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-3.card-4{
    animation: sub6active4-sub4-card4-card5 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active4-sub4-card4-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-3.card-4{
    animation: sub6active4-sub4-card5-card5 .3s forwards;
}
@keyframes sub6active4-sub4-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
/* --- Active Card 4 a Active Card 5 --- */
/* --- Active Card 4 a Active Card 6 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-3.card-5{
    animation: sub6active4-sub4-card4-card6 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active4-sub4-card4-card6{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-3.card-5{
    animation: sub6active4-sub4-card6-card6 .3s forwards;
}
@keyframes sub6active4-sub4-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
/* --- Active Card 4 a Active Card 6 --- */
/* --- Active Card 5 a Active Card 1 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-4.card-0{
    animation: sub6active5-sub5-card1-card1 .3s forwards;
}
@keyframes sub6active5-sub5-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-4.card-0{
    animation: sub6active5-sub5-card4-card1 .3s forwards;
}
@keyframes sub6active5-sub5-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-4.card-0{
    animation: sub6active5-sub5-card5-card1 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active5-sub5-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 5 a Active Card 1 --- */
/* --- Active Card 5 a Active Card 2 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-4.card-1{
    animation: sub6active5-sub5-card2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub6active5-sub5-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-4.card-1{
    animation: sub6active5-sub5-card5-card2 .6s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active5-sub5-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-4.card-1 .Text-SubSubmodule{
    animation-delay: .6s;
}
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-4.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .6s;
}
/* --- Active Card 5 a Active Card 2 --- */
/* --- Active Card 5 a Active Card 3 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-4.card-2{
    animation: sub6active5-sub5-card3-card3 .3s forwards;
}
@keyframes sub6active5-sub5-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-4.card-2{
    animation: sub6active5-sub5-card5-card3 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active5-sub5-card5-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-4.card-2{
    animation: sub6active5-sub5-card6-card3 .3s forwards;
}
@keyframes sub6active5-sub5-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 5 a Active Card 3 --- */
/* --- Active Card 5 a Active Card 4 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-4.card-3{
    animation: sub6active5-sub5-card4-card4 .3s forwards;
}
@keyframes sub6active5-sub5-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-4.card-3{
    animation: sub6active5-sub5-card5-card4 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active5-sub5-card5-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 5 a Active Card 4 --- */
/* --- Active Card 5 a Active Card 6 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-4.card-5{
    animation: sub6active5-sub5-card5-card6 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active5-sub5-card5-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-4.card-5{
    animation: sub6active5-sub5-card6-card6 .3s forwards;
}
@keyframes sub6active5-sub5-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
/* --- Active Card 5 a Active Card 6 --- */
/* --- Active Card 6 a Active Card 1 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(1).active-sub-5.card-0{
    animation: sub6active6-sub6-card1-card1 .3s forwards;
}
@keyframes sub6active6-sub6-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-5.card-0{
    animation: sub6active6-sub6-card4-card1 .3s forwards;
}
@keyframes sub6active6-sub6-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-5.card-0{
    animation: sub6active6-sub6-card6-card1 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active6-sub6-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 6 a Active Card 1 --- */
/* --- Active Card 6 a Active Card 2 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(2).active-sub-5.card-1{
    animation: sub6active6-sub6-card2-card2 .3s forwards;
}
@keyframes sub6active6-sub6-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-5.card-1{
    animation: sub6active6-sub6-card5-card2 .3s forwards;
}
@keyframes sub6active6-sub6-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-5.card-1{
    animation: sub6active6-sub6-card6-card2 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active6-sub6-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 6 a Active Card 2 --- */
/* --- Active Card 6 a Active Card 3 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-5.card-2{
    animation: sub6active6-sub6-card3-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub6active6-sub6-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-5.card-2{
    animation: sub6active6-sub6-card6-card3 .6s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active6-sub6-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-sub-5.card-2 .Text-SubSubmodule{
    animation-delay: .6s;
}
.Submodule-6 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-5.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .6s;
}
/* --- Active Card 6 a Active Card 3 --- */
/* --- Active Card 6 a Active Card 4 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(4).active-sub-5.card-3{
    animation: sub6active6-sub6-card4-card4 .3s forwards;
}
@keyframes sub6active6-sub6-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-5.card-3{
    animation: sub6active6-sub6-card6-card4 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active6-sub6-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 6 a Active Card 4 --- */
/* --- Active Card 6 a Active Card 5 --- */
.Submodule-6 .Card-Submodule-Item:nth-child(5).active-sub-5.card-4{
    animation: sub6active6-sub6-card5-card5 .3s forwards;
}
@keyframes sub6active6-sub6-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-6 .Card-Submodule-Item:nth-child(6).active-sub-5.card-4{
    animation: sub6active6-sub6-card6-card5 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub6active6-sub6-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
/* --- Active Card 6 a Active Card 5 --- */
.Submodule-7 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-7 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-7 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-7 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-7 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 0 !important;
}
.Submodule-7 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
}
.Submodule-7 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-7 .Container-Card-Categories:hover{
    border-radius: 50%;
}
.Submodule-7 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-7 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-7 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-7 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-7 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-7 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-7 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-7 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-7 10s linear infinite;
}
.Submodule-7 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-7 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-7 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-7{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-7 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    height: 160px;
}
.Submodule-7 .Container-Header-Submodule{
    position: relative;
    height: 102px;
}
.Submodule-7 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    color: #00533F;
    width: 100%;
    position: absolute;
}
.Submodule-7 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-7 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-7 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-7 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-7 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-7 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-7 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text .5s forwards;
    animation-delay: .9s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-7 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-7 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-7 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-7 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-7 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .6s;
}
/* Tarjeta 1 */
.Submodule-7 .Card-Submodule-Item:nth-child(1){
    background-color: var(--green);
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 2 */
.Submodule-7 .Container-Submodule .Card-Submodule-Item:nth-child(2){
    background-color: #FFC700;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2) .Text-Submodule{
    color: #F90000;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 3 */
.Submodule-7 .Container-Submodule .Card-Submodule-Item:nth-child(3){
    background-color: var(--blue-elec);
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3) .Text-Submodule{
    color: var(--blue);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Item{
    color: var(--blue);
    margin-top: 1px;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Without-Item{
    color: var(--blue);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 4 */
.Submodule-7 .Container-Submodule .Card-Submodule-Item:nth-child(4){
    background-color: #C4D5FF;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4) .Text-Submodule{
    color: #5270BC;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Item{
    color: #5270BC;
    margin-top: 1px;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Without-Item{
    color: #5270BC;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #C4D5FF;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #C4D5FF;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 5 */
.Submodule-7 .Container-Submodule .Card-Submodule-Item:nth-child(5){
    background-color: var(--magenta);
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5) .Text-Submodule{
    color: #93007C;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Item{
    color: #93007C;
    margin-top: 1px;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Without-Item{
    color: #93007C;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-7 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-7 .Card-Submodule-Item:nth-child(5) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 6 */
.Submodule-7 .Container-Submodule .Card-Submodule-Item:nth-child(6){
    background-color: #FC8122;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6) .Text-Submodule{
    color: #AC4C02;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(6) .Icon-Submodule-Item{
    color: #AC4C02;
    margin-top: 1px;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6) .Icon-Submodule-Without-Item{
    color: #AC4C02;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FC8122;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FC8122;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 7 */
.Submodule-7 .Card-Submodule-Item:nth-child(7){
    background-color: var(--green);
    grid-column: 1 / 3;
    grid-row: 3 / 4;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7) .Text-Submodule{
    color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(7) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(7) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(7) .Text-SubSubmodule{
    color: var(--white);
}
/* Default Animacion */
.Submodule-7 .Card-Submodule-Item:nth-child(1).Default-Active{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).Default-Active{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).Default-Active{
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).Default-Active{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).Default-Active{
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).Default-Active{
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).Default-Active{
    grid-column: 1 / 3;
    grid-row: 3 / 4;
}
/* Tarjeta 1 Activo */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover, .Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 2 Activo */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover, .Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item{
    background-color: #F90000;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Tarjeta 3 Activo */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover, .Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item{
    background-color: var(--blue);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--blue-elec);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--blue-elec);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue);
}
.Submodule-7 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--blue-elec);
}
/* Tarjeta 4 Activo */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover, .Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item{
    background-color: #5270BC;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #C4D5FF;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #C4D5FF;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #5270BC;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #C4D5FF;
}
/* Tarjeta 5 Activo */
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover, .Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item{
    background-color: #93007C;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--magenta);
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--magenta);
}
.Submodule-7 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #93007C;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--magenta);
}
/* Tarjeta 6 Activo */
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover, .Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item{
    background-color: #AC4C02;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FC8122;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FC8122;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #AC4C02;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FC8122;
}
/* Tarjeta 7 Activo */
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover):hover, .Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item{
    background-color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item  .Text-Submodule{
    color: var(--white);
}
.Submodule-7 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--green);
}
.Submodule-7 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Animacion Tarjeta 1 */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub7active1-card1 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub7active1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: sub7inactive1-card1 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7inactive1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0{
    animation: sub7active1-card1-sub4 .3s forwards;
    animation-delay: .35s;
}
@keyframes sub7active1-card1-sub4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0{
    animation: sub7active1-card1-sub5 .3s forwards;
    animation-delay: .2s;
}
@keyframes sub7active1-card1-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-0{
    animation: sub7active1-card1-sub6 .3s forwards;
}
@keyframes sub7active1-card1-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).inactive-sub-0{
    animation: sub7inactive1-card1-sub4 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub7inactive1-card1-sub4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).inactive-sub-0{
    animation: sub7inactive1-card1-sub5 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7inactive1-card1-sub5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).inactive-sub-0{
    animation: sub7inactive1-card1-sub6 .3s forwards;
    animation-delay: .55s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7inactive1-card1-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* Animacion Tarjeta 2 */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1{
    animation: sub7active2-card2 .3s forwards;
    animation-delay: .35s;
}
@keyframes sub7active2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).inactive-sub-1{
    animation: sub7inactive2-card2 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7inactive2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1{
    animation: sub7active2-card2-sub5 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active2-card2-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1{
    animation: sub7active2-card2-sub6 .3s forwards;
}
@keyframes sub7active2-card2-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).inactive-sub-1{
    animation: sub7inactive2-card2-sub5 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7inactive2-card2-sub5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).inactive-sub-1{
    animation: sub7inactive2-card2-sub6 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7inactive2-card2-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1 .Text-SubSubmodule{
    animation-delay: .65s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .65s;
}
/* Animacion Tarjeta 3 */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2{
    animation: sub7active3-card3 .3s forwards;
}
@keyframes sub7active3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).inactive-sub-2{
    animation: sub7inactive3-card3 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7inactive3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2{
    animation: sub7active3-card3-sub6 .3s forwards;
}
@keyframes sub7active3-card3-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).inactive-sub-2{
    animation: sub7inactive3-card3-sub6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7inactive3-card3-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* Animacion Tarjeta 4 */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3{
    animation: sub7active4-card4 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).inactive-sub-3{
    animation: sub7inactive4-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7inactive4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3{
    animation: sub7active4-card4-sub7 .3s forwards;
}
@keyframes sub7active4-card4-sub7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(50%));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).inactive-sub-3{
    animation: sub7inactive4-card4-sub7 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7inactive4-card4-sub7{
    0%{
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-50%));
        grid-column: 2 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3 .Text-SubSubmodule{
    animation-delay: .45s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .45s;
}
/* Animacion Tarjeta 5 */
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4{
    animation: sub7active5-card5 .3s forwards;
    animation-delay: .25s;
}
@keyframes sub7active5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).inactive-sub-4{
    animation: sub7inactive5-card5 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7inactive5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-4{
    animation: sub7active5-card5-sub7 .3s forwards;
}
@keyframes sub7active5-card5-sub7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 100%;
    }
    99.9%{
        width: 50%;
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).inactive-sub-4{
    animation: sub7inactive5-card5-sub7 .3s forwards;
    animation-delay: .15s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub7inactive5-card5-sub7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 50%;
    }
    99.9%{
        width: 100%;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* Animacion Tarjeta 6 */
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5{
    animation: sub7active6-card6 .3s forwards;
}
@keyframes sub7active6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).inactive-sub-5{
    animation: sub7inactive6-card6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7inactive6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-5 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* Animacion Tarjeta 7 */
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6{
    animation: sub7active7-card7 .3s forwards;
}
@keyframes sub7active7-card7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).inactive-sub-6{
    animation: sub7inactive7-card7 .3s forwards;
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    height: 334px;
}
@keyframes sub7inactive7-card7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 3 / 5;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* --- Active Card 1 a Active Card 2 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-0.card-1{
    animation: sub7active1-sub1-card1-card2 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active1-sub1-card1-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1{
    animation: sub7active1-sub1-card2-card2 .3s forwards;
    animation-delay: .35s;
}
@keyframes sub7active1-sub1-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0.card-1{
    animation: sub7active1-sub1-card4-card2 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card4-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0.card-1{
    animation: sub7active1-sub1-card5-card2 0s forwards;
}
@keyframes sub7active1-sub1-card5-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-0.card-1{
    animation: sub7active1-sub1-card6-card2 0s forwards;
}
@keyframes sub7active1-sub1-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1 .Text-SubSubmodule{
    animation-delay: .65s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-0.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .65s;
}
/* --- Active Card 1 a Active Card 2 --- */
/* --- Active Card 1 a Active Card 3 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-0.card-2{
    animation: sub7active1-sub1-card1-card3 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active1-sub1-card1-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2{
    animation: sub7active1-sub1-card3-card3 .3s forwards;
    animation-delay: .55s;
}
@keyframes sub7active1-sub1-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0.card-2{
    animation: sub7active1-sub1-card4-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card4-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0.card-2{
    animation: sub7active1-sub1-card5-card3 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card5-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-0.card-2{
    animation: sub7active1-sub1-card6-card3 0s forwards;
}
@keyframes sub7active1-sub1-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-0.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .85s;
}
/* --- Active Card 1 a Active Card 3 --- */
/* --- Active Card 1 a Active Card 4 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-0.card-3{
    animation: sub7active1-sub1-card1-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active1-sub1-card1-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3{
    animation: sub7active1-sub1-card4-card4 1.2s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card4-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    24.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    25%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    74%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    75%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0.card-3{
    animation: sub7active1-sub1-card5-card4 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card5-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-0.card-3{
    animation: sub7active1-sub1-card6-card4 .3s forwards;
    animation-delay: .55s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active1-sub1-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-0.card-3{
    animation: sub7active1-sub1-card7-card4 .3s forwards;
    animation-delay: .75s;
}
@keyframes sub7active1-sub1-card7-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(50%));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3 .Text-SubSubmodule{
    animation-delay: 1.5s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-0.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 1 a Active Card 4 --- */
/* --- Active Card 1 a Active Card 5 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-0.card-4{
    animation: sub7active1-sub1-card1-card5 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active1-sub1-card1-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0.card-4{
    animation: sub7active1-sub1-card4-card5 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card4-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0.card-4{
    animation: sub7active1-sub1-card5-card5 .9s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card5-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    32.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    33%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    65%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    66%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-0.card-4{
    animation: sub7active1-sub1-card6-card5 .3s forwards;
    animation-delay: .55s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active1-sub1-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-0.card-4{
    animation: sub7active1-sub1-card7-card5 .3s forwards;
    animation-delay: .75s;
}
@keyframes sub7active1-sub1-card7-card5{
     0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 100%;
    }
    99.9%{
        width: 50%;
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0.card-4 .Text-SubSubmodule{
    animation-delay: 1.25s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-0.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.25s;
}
/* --- Active Card 1 a Active Card 5 --- */
/* --- Active Card 1 a Active Card 6 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-0.card-5{
    animation: sub7active1-sub1-card1-card6 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active1-sub1-card1-card6{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0.card-5{
    animation: sub7active1-sub1-card4-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card4-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0.card-5{
    animation: sub7active1-sub1-card5-card6 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card5-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-0.card-5{
    animation: sub7active1-sub1-card6-card6 .3s forwards;
    animation-delay: .55s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active1-sub1-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        transform: translateY(calc(100% + 14px));
        height: 160px;
    }
    50%{
        transform: translateY(0);
    }
    99.9%{
        height: 334px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-0.card-5 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .85s;
}
/* --- Active Card 1 a Active Card 6 --- */
/* --- Active Card 1 a Active Card 7 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-0.card-6{
    animation: sub7active1-sub1-card1-card7 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active1-sub1-card1-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-0.card-6{
    animation: sub7active1-sub1-card4-card7 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card4-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-0.card-6{
    animation: sub7active1-sub1-card5-card7 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active1-sub1-card5-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-0.card-6{
    animation: sub7active1-sub1-card6-card7 .3s forwards;
    animation-delay: .55s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active1-sub1-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-0.card-6{
    animation: sub7active1-sub1-card7-card7 .3s forwards;
    animation-delay: .55s;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub7active1-sub1-card7-card7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-0.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .85s;
}
/* --- Active Card 1 a Active Card 7 --- */
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0{
    animation: sub7active2-sub2-card1-card1 .3s forwards;
    animation-delay: .5s;
}
@keyframes sub7active2-sub2-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1.card-0{
    animation: sub7active2-sub2-card2-card1 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active2-sub2-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-1.card-0{
    animation: sub7active2-sub2-card4-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub7active2-sub2-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1.card-0{
    animation: sub7active2-sub2-card5-card1 0s forwards;
}
@keyframes sub7active2-sub2-card5-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1.card-0{
    animation: sub7active2-sub2-card6-card1 0s forwards;
}
@keyframes sub7active2-sub2-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-1.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .8s;
}
/* --- Active Card 2 a Active Card 1 --- */
/* --- Active Card 2 a Active Card 3 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1.card-2{
    animation: sub7active2-sub2-card2-card3 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active2-sub2-card2-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2{
    animation: sub7active2-sub2-card3-card3 .3s forwards;
    animation-delay: .5s;
}
@keyframes sub7active2-sub2-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-1.card-2{
    animation: sub7active2-sub2-card4-card3 0s forwards;
}
@keyframes sub7active2-sub2-card4-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1.card-2{
    animation: sub7active2-sub2-card5-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active2-sub2-card5-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1.card-2{
    animation: sub7active2-sub2-card6-card3 0s forwards;
}
@keyframes sub7active2-sub2-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-1.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .8s;
}
/* --- Active Card 2 a Active Card 3 --- */
/* --- Active Card 2 a Active Card 4 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1.card-3{
    animation: sub7active2-sub2-card2-card4 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active2-sub2-card2-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3{
    animation: sub7active2-sub2-card4-card4 .3s forwards;
    animation-delay: .9s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub7active2-sub2-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1.card-3{
    animation: sub7active2-sub2-card5-card4 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active2-sub2-card5-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1.card-3{
    animation: sub7active2-sub2-card6-card4 .3s forwards;
    animation-delay: .55s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active2-sub2-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-1.card-3{
    animation: sub7active2-sub2-card7-card4 .3s forwards;
    animation-delay: .7s;
}
@keyframes sub7active2-sub2-card7-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(50%));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-1.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 2 a Active Card 4 --- */
/* --- Active Card 2 a Active Card 5 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1.card-4{
    animation: sub7active2-sub2-card2-card5 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active2-sub2-card2-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1.card-4{
    animation: sub7active2-sub2-card5-card5 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active2-sub2-card5-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    32.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    33%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    65%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    66%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1.card-4{
    animation: sub7active2-sub2-card6-card5 .3s forwards;
    animation-delay: .55s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active2-sub2-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-1.card-4{
    animation: sub7active2-sub2-card7-card5 .3s forwards;
    animation-delay: .75s;
}
@keyframes sub7active2-sub2-card7-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 100%;
    }
    99.9%{
        width: 50%;
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1.card-4 .Text-SubSubmodule{
    animation-delay: 1.5s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-1.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 2 a Active Card 5 --- */
/* --- Active Card 2 a Active Card 6 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1.card-5{
    animation: sub7active2-sub2-card2-card6 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active2-sub2-card2-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1.card-5{
    animation: sub7active2-sub2-card5-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active2-sub2-card5-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1.card-5{
    animation: sub7active2-sub2-card6-card6 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active2-sub2-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        transform: translateY(calc(100% + 14px));
        height: 160px;
    }
    50%{
        transform: translateY(0);
    }
    99.9%{
        height: 334px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1.card-5 .Text-SubSubmodule{
    animation-delay: .65s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-1.card-5 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .65s;
}
/* --- Active Card 2 a Active Card 6 --- */
/* --- Active Card 2 a Active Card 7 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-1.card-6{
    animation: sub7active2-sub2-card2-card7 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active2-sub2-card2-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-1.card-6{
    animation: sub7active2-sub2-card5-card7 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub7active2-sub2-card5-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-1.card-6{
    animation: sub7active2-sub2-card6-card7 .3s forwards;
    animation-delay: .35s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active2-sub2-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-1.card-6{
    animation: sub7active2-sub2-card7-card7 .3s forwards;
    animation-delay: .55s;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub7active2-sub2-card7-card7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-1.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .65s;
}
/* --- Active Card 2 a Active Card 7 --- */
/* --- Active Card 3 a Active Card 1 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0{
    animation: sub7active3-sub3-card1-card1 .3s forwards;
    animation-delay: .55s;
}
@keyframes sub7active3-sub3-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2.card-0{
    animation: sub7active3-sub3-card3-card1 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active3-sub3-card3-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-2.card-0{
    animation: sub7active3-sub3-card4-card1 .3s forwards;
    animation-delay: .35s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub7active3-sub3-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-2.card-0{
    animation: sub7active3-sub3-card5-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active3-sub3-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2.card-0{
    animation: sub7active3-sub3-card6-card1 0s forwards;
}
@keyframes sub7active3-sub3-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-2.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .85s;
}
/* --- Active Card 3 a Active Card 1 --- */
/* --- Active Card 3 a Active Card 2 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1{
    animation: sub7active3-sub3-card1-card2 .3s forwards;
    animation-delay: .35s;
}
@keyframes sub7active3-sub3-card1-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2.card-1{
    animation: sub7active3-sub3-card3-card2 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active3-sub3-card3-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-2.card-1{
    animation: sub7active3-sub3-card4-card2 0s forwards;
}
@keyframes sub7active3-sub3-card4-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-2.card-1{
    animation: sub7active3-sub3-card5-card2 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active3-sub3-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2.card-1{
    animation: sub7active3-sub3-card6-card2 0s forwards;
}
@keyframes sub7active3-sub3-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1 .Text-SubSubmodule{
    animation-delay: .65s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-2.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .65s;
}
/* --- Active Card 3 a Active Card 2 --- */
/* --- Active Card 3 a Active Card 4 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2.card-3{
    animation: sub7active3-sub3-card3-card4 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active3-sub3-card3-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-2.card-3{
    animation: sub7active3-sub3-card4-card4 .3s forwards;
    animation-delay: .55s;
}
@keyframes sub7active3-sub3-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2.card-3{
    animation: sub7active3-sub3-card6-card4 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active3-sub3-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-2.card-3{
    animation: sub7active3-sub3-card7-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active3-sub3-card7-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(50%));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-2.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .85s;
}
/* --- Active Card 3 a Active Card 4 --- */
/* --- Active Card 3 a Active Card 5 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2.card-4{
    animation: sub7active3-sub3-card3-card5 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active3-sub3-card3-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-2.card-4{
    animation: sub7active3-sub3-card5-card5 .3s forwards;
    animation-delay: .55s;
}
@keyframes sub7active3-sub3-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2.card-4{
    animation: sub7active3-sub3-card6-card5 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active3-sub3-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-2.card-4{
    animation: sub7active3-sub3-card7-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active3-sub3-card7-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 100%;
    }
    99.9%{
        width: 50%;
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-2.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .85s;
}
/* --- Active Card 3 a Active Card 5 --- */
/* --- Active Card 3 a Active Card 6 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2.card-5{
    animation: sub7active3-sub3-card3-card6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active3-sub3-card3-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2.card-5{
    animation: sub7active3-sub3-card6-card6 .6s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active3-sub3-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        height: 160px;
        transform: translateY(0);
    }
    49%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    50%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
    50.1%{
        transform: translateY(0);
        height: 160px;
        grid-row: 2 / 4;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2.card-5 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* --- Active Card 3 a Active Card 6 --- */
/* --- Active Card 3 a Active Card 7 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-2.card-6{
    animation: sub7active3-sub3-card3-card7 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub7active3-sub3-card3-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-2.card-6{
    animation: sub7active3-sub3-card6-card7 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active3-sub3-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-2.card-6{
    animation: sub7active3-sub3-card7-card7 .3s forwards;
    animation-delay: .5s;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub7active3-sub3-card7-card7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-2.card-6 .Text-SubSubmodule{
    animation-delay: .8s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-2.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .8s;
}
/* --- Active Card 3 a Active Card 7 --- */
/* --- Active Card 4 a Active Card 1 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0{
    animation: sub7active4-sub4-card1-card1 .3s forwards;
    animation-delay: .8s;
}
@keyframes sub7active4-sub4-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3.card-0{
    animation: sub7active4-sub4-card4-card1 .9s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active4-sub4-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    32.3%{
        height: 160px;
        grid-row: 2 / 4;
    }
    33%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
    65.9%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
    66%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-3.card-0{
    animation: sub7active4-sub4-card5-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active4-sub4-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-3.card-0{
    animation: sub7active4-sub4-card6-card1 .3s forwards;
    animation-delay: .25s;
}
@keyframes sub7active4-sub4-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3.card-0{
    animation: sub7active4-sub4-card7-card1 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active4-sub4-card7-card1{
    0%{
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-50%));
        grid-column: 2 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0 .Text-SubSubmodule{
    animation-delay: 1.1s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-3.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.1s;
}
/* --- Active Card 4 a Active Card 1 --- */
/* --- Active Card 4 a Active Card 2 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-3.card-1{
    animation: sub7active4-sub4-card2-card2 .3s forwards;
    animation-delay: .5s;
}
@keyframes sub7active4-sub4-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3.card-1{
    animation: sub7active4-sub4-card4-card2 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active4-sub4-card4-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-3.card-1{
    animation: sub7active4-sub4-card5-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active4-sub4-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-3.card-1{
    animation: sub7active4-sub4-card6-card2 .3s forwards;
    animation-delay: .25s;
}
@keyframes sub7active4-sub4-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3.card-1{
    animation: sub7active4-sub4-card7-card2 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active4-sub4-card7-card2{
    0%{
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-50%));
        grid-column: 2 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-3.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .8s;
}
/* --- Active Card 4 a Active Card 2 --- */
/* --- Active Card 4 a Active Card 3 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2{
    animation: sub7active4-sub4-card3-card3 .3s forwards;
    animation-delay: .25s;
}
@keyframes sub7active4-sub4-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3.card-2{
    animation: sub7active4-sub4-card4-card3 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active4-sub4-card4-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-3.card-2{
    animation: sub7active4-sub4-card6-card3 .3s forwards;
    animation-delay: .25s;
}
@keyframes sub7active4-sub4-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3.card-2{
    animation: sub7active4-sub4-card7-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active4-sub4-card7-card3{
    0%{
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-50%));
        grid-column: 2 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* --- Active Card 4 a Active Card 3 --- */
/* --- Active Card 4 a Active Card 5 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3.card-4{
    animation: sub7active4-sub4-card4-card5 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active4-sub4-card4-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-3.card-4{
    animation: sub7active4-sub4-card5-card5 .3s forwards;
    animation-delay: .8s;
}
@keyframes sub7active4-sub4-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99.9%{
        height: 334px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3.card-4{
    animation: sub7active4-sub4-card7-card5 .6s forwards;
    animation-delay: .15s;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active4-sub4-card7-card5{
    0%{
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    49%{
        transform: translateX(calc(-50%));
        grid-column: 2 / 4;
    }
    50%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 100%;
    }
    99.9%{
        width: calc(50% + 7px);
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-3.card-4 .Text-SubSubmodule{
    animation-delay: 1.1s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-3.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.1s;
}
/* --- Active Card 4 a Active Card 5 --- */
/* --- Active Card 4 a Active Card 6 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3.card-5{
    animation: sub7active4-sub4-card4-card6 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active4-sub4-card4-card6{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-3.card-5{
    animation: sub7active4-sub4-card6-card6 .3s forwards;
    animation-delay: .4s;
}
@keyframes sub7active4-sub4-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    99.9%{
        height: 334px;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3.card-5{
    animation: sub7active4-sub4-card7-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active4-sub4-card7-card6{
    0%{
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-50%));
        grid-column: 2 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-3.card-5 .Text-SubSubmodule{
    animation-delay: .7s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-3.card-5 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .7s;
}
/* --- Active Card 4 a Active Card 6 --- */
/* --- Active Card 4 a Active Card 7 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-3.card-6{
    animation: sub7active4-sub4-card4-card7 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active4-sub4-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3.card-6{
    animation: sub7active4-sub4-card7-card7 .6s forwards;
    animation-delay: .15s;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}
@keyframes sub7active4-sub4-card7-card7{
    0%{
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
        height: 160px;
    }
    49.9%{
        transform: translateX(-50%);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }
    50%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-3.card-6 .Text-SubSubmodule{
    animation-delay: .75s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-3.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .75s;
}
/* --- Active Card 4 a Active Card 7 --- */
/* --- Active Card 5 a Active Card 1 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-4.card-0{
    animation: sub7active5-sub5-card1-card1 .3s forwards;
    animation-delay: .8s;
}
@keyframes sub7active5-sub5-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-4.card-0{
    animation: sub7active5-sub5-card4-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active5-sub5-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4.card-0{
    animation: sub7active5-sub5-card5-card1 .6s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active5-sub5-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-4.card-0{
    animation: sub7active5-sub5-card6-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active5-sub5-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-4.card-0{
    animation: sub7active5-sub5-card7-card1 .6s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub7active5-sub5-card7-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 50%;
    }
    99.9%{
        width: 100%;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-4.card-0 .Text-SubSubmodule{
    animation-delay: 1.1s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-4.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.1s;
}
/* --- Active Card 5 a Active Card 1 --- */
/* --- Active Card 5 a Active Card 2 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-4.card-1{
    animation: sub7active5-sub5-card2-card2 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub7active5-sub5-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4.card-1{
    animation: sub7active5-sub5-card5-card2 .6s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active5-sub5-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-4.card-1{
    animation: sub7active5-sub5-card6-card2 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active5-sub5-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-4.card-1{
    animation: sub7active5-sub5-card7-card2 .6s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub7active5-sub5-card7-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 50%;
    }
    99.9%{
        width: 100%;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-4.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 5 a Active Card 2 --- */
/* --- Active Card 5 a Active Card 3 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-4.card-2{
    animation: sub7active5-sub5-card3-card3 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active5-sub5-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4.card-2{
    animation: sub7active5-sub5-card5-card3 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active5-sub5-card5-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-4.card-2{
    animation: sub7active5-sub5-card6-card3 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active5-sub5-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-4.card-2{
    animation: sub7active5-sub5-card7-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub7active5-sub5-card7-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 50%;
    }
    99.9%{
        width: 100%;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-4.card-2 .Text-SubSubmodule{
    animation-delay: .45s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-4.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .45s;
}
/* --- Active Card 5 a Active Card 3 --- */
/* --- Active Card 5 a Active Card 4 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-4.card-3{
    animation: sub7active5-sub5-card4-card4 .3s forwards;
    animation-delay: .9s;
}
@keyframes sub7active5-sub5-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4.card-3{
    animation: sub7active5-sub5-card5-card4 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active5-sub5-card5-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-4.card-3{
    animation: sub7active5-sub5-card7-card4 .6s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub7active5-sub5-card7-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 50%;
    }
    49%{
        width: 100%;
    }
    50%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(50%));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-4.card-3 .Text-SubSubmodule{
    animation-delay: 1.2s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-4.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.2s;
}
/* --- Active Card 5 a Active Card 4 --- */
/* --- Active Card 5 a Active Card 6 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4.card-5{
    animation: sub7active5-sub5-card5-card6 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active5-sub5-card5-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-4.card-5{
    animation: sub7active5-sub5-card6-card6 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active5-sub5-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-4.card-5{
    animation: sub7active5-sub5-card7-card6 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub7active5-sub5-card7-card6{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 50%;
    }
    99%{
        width: 100%;
    }
    100%{
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-4.card-5 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* --- Active Card 5 a Active Card 6 --- */
/* --- Active Card 5 a Active Card 7 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-4.card-6{
    animation: sub7active5-sub5-card5-card7 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active5-sub5-card5-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-4.card-6{
    animation: sub7active5-sub5-card7-card7 .6s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub7active5-sub5-card7-card7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        height: 160px;
        width: calc(50% + 7px);
    }
    49%{
        width: 100%;
        grid-row: 3 / 4;
    }
    50%{
        height: 160px;
        grid-row: 3 / 5;
    }
    100%{
        width: 100%;
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-4.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 5 a Active Card 7 --- */
/* --- Active Card 6 a Active Card 1 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-5.card-0{
    animation: sub7active6-sub6-card1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active6-sub6-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-5.card-0{
    animation: sub7active6-sub6-card4-card1 .3s forwards;
    animation-delay: .2s;
}
@keyframes sub7active6-sub6-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-5.card-0{
    animation: sub7active6-sub6-card5-card1 .3s forwards;
    animation-delay: .2s;
}
@keyframes sub7active6-sub6-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5.card-0{
    animation: sub7active6-sub6-card6-card1 .3s forwards;
}
@keyframes sub7active6-sub6-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        transform: translateY(0);
        height: 334px;
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-5.card-0 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* --- Active Card 6 a Active Card 1 --- */
/* --- Active Card 6 a Active Card 2 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-5.card-1{
    animation: sub7active6-sub6-card2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active6-sub6-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-5.card-1{
    animation: sub7active6-sub6-card5-card2 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub7active6-sub6-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5.card-1{
    animation: sub7active6-sub6-card6-card2 .3s forwards;
}
@keyframes sub7active6-sub6-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        transform: translateY(0);
        height: 334px;
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-5.card-1 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* --- Active Card 6 a Active Card 2 --- */
/* --- Active Card 6 a Active Card 3 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-5.card-2{
    animation: sub7active6-sub6-card3-card3 .3s forwards;
}
@keyframes sub7active6-sub6-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5.card-2{
    animation: sub7active6-sub6-card6-card3 .3s forwards;
}
@keyframes sub7active6-sub6-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        transform: translateY(0);
        height: 334px;
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-5.card-2 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-5.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* --- Active Card 6 a Active Card 3 --- */
/* --- Active Card 6 a Active Card 4 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-5.card-3{
    animation: sub7active6-sub6-card4-card4 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub7active6-sub6-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5.card-3{
    animation: sub7active6-sub6-card6-card4 .3s forwards;
}
@keyframes sub7active6-sub6-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99.9%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-5.card-3{
    animation: sub7active6-sub6-card7-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active6-sub6-card7-card4{
    0%{
        transform: translateX(0);
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
    99%{
        transform: translateX(50%);
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-5.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 6 a Active Card 4 --- */
/* --- Active Card 6 a Active Card 5 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-5.card-4{
    animation: sub7active6-sub6-card5-card5 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub7active6-sub6-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5.card-4{
    animation: sub7active6-sub6-card6-card5 .3s forwards;
}
@keyframes sub7active6-sub6-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99.9%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-5.card-4{
    animation: sub7active6-sub6-card7-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub7active6-sub6-card7-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        width: 100%;
    }
    99.9%{
        width: 50%;
        grid-column: 1 / 3;
    }
    100%{
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-5.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 6 a Active Card 5 --- */
/* --- Active Card 6 a Active Card 7 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-5.card-6{
    animation: sub7active6-sub6-card6-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub7active6-sub6-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99.9%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        height: 160px;
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-5.card-6{
    animation: sub7active6-sub6-card7-card7 .3s forwards;
}
@keyframes sub7active6-sub6-card7-card7{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-5.card-6 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-5.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* --- Active Card 6 a Active Card 7 --- */
/* --- Active Card 7 a Active Card 1 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-6.card-0{
    animation: sub7active7-sub7-card1-card1 .3s forwards;
    animation-delay: .4s;
}
@keyframes sub7active7-sub7-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-6.card-0{
    animation: sub7active7-sub7-card4-card1 .3s forwards;
    animation-delay: .2s;
}
@keyframes sub7active7-sub7-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-6.card-0{
    animation: sub7active7-sub7-card5-card1 .3s forwards;
    animation-delay: .2s;
}
@keyframes sub7active7-sub7-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-6.card-0{
    animation: sub7active7-sub7-card6-card1 .3s forwards;
}
@keyframes sub7active7-sub7-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6.card-0{
    animation: sub7active7-sub7-card7-card1 .3s forwards;
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    height: 334px;
}
@keyframes sub7active7-sub7-card7-card1{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 334px;
    }
    99%{
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-sub-6.card-0 .Text-SubSubmodule{
    animation-delay: .7s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-6.card-0 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .7s;
}
/* --- Active Card 7 a Active Card 1 --- */
/* --- Active Card 7 a Active Card 2 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-6.card-1{
    animation: sub7active7-sub7-card2-card2 .3s forwards;
    animation-delay: .4s;
}
@keyframes sub7active7-sub7-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-6.card-1{
    animation: sub7active7-sub7-card5-card2 .3s forwards;
    animation-delay: .2s;
}
@keyframes sub7active7-sub7-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-6.card-1{
    animation: sub7active7-sub7-card6-card2 .3s forwards;
}
@keyframes sub7active7-sub7-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6.card-1{
    animation: sub7active7-sub7-card7-card2 .3s forwards;
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    height: 334px;
}
@keyframes sub7active7-sub7-card7-card2{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 334px;
    }
    99%{
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-sub-6.card-1 .Text-SubSubmodule{
    animation-delay: .7s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-6.card-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .7s;
}
/* --- Active Card 7 a Active Card 2 --- */
/* --- Active Card 7 a Active Card 3 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-6.card-2{
    animation: sub7active7-sub7-card3-card3 .3s forwards;
}
@keyframes sub7active7-sub7-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-6.card-2{
    animation: sub7active7-sub7-card6-card3 .3s forwards;
}
@keyframes sub7active7-sub7-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6.card-2{
    animation: sub7active7-sub7-card7-card3 .3s forwards;
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    height: 334px;
}
@keyframes sub7active7-sub7-card7-card3{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 334px;
    }
    99%{
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-sub-6.card-2 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-6.card-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* --- Active Card 7 a Active Card 3 --- */
/* --- Active Card 7 a Active Card 4 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-sub-6.card-3{
    animation: sub7active7-sub7-card4-card4 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub7active7-sub7-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6.card-3{
    animation: sub7active7-sub7-card7-card4 .6s forwards;
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    height: 334px;
}
@keyframes sub7active7-sub7-card7-card4{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 334px;
    }
    49.9%{
        height: 160px;
        grid-row: 3 / 5;
    }
    50%{
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(50%));
        grid-column: 1 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-6.card-3 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 7 a Active Card 4 --- */
/* --- Active Card 7 a Active Card 5 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-sub-6.card-4{
    animation: sub7active7-sub7-card5-card5 .3s forwards;
    animation-delay: .6s;
}
@keyframes sub7active7-sub7-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6.card-4{
    animation: sub7active7-sub7-card7-card5 .6s forwards;
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    height: 334px;
}
@keyframes sub7active7-sub7-card7-card5{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 334px;
        width: 100%;
    }
    49.9%{
        height: 160px;
        grid-row: 3 / 5;
    }
    50%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        height: 160px;
        width: 100%;
    }
    99%{
        grid-column: 1 / 3;
        width: calc(50% + 7px);
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        height: 160px;
        width: 100%;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-6.card-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 7 a Active Card 5 --- */
/* --- Active Card 7 a Active Card 6 --- */
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-6.card-5{
    animation: sub7active7-sub7-card6-card6 .3s forwards;
}
@keyframes sub7active7-sub7-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(7).active-sub-6.card-5{
    animation: sub7active7-sub7-card7-card6 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    height: 334px;
}
@keyframes sub7active7-sub7-card7-card6{
    0%{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
        height: 334px;
    }
    99%{
        grid-row: 3 / 5;
        height: 160px;
    }
    100%{
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-sub-6.card-5 .Text-SubSubmodule{
    animation-delay: .3s;
}
.Submodule-7 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-6.card-5 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* --- Active Card 7 a Active Card 6 --- */
.Submodule-8 .Container-Title-Module{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    column-gap: 12px;
}
.Submodule-8 .Image-Item-Categories{
    width: 51px;
    height: auto;
}
.Submodule-8 .Container-Card-Categories .Image-Item-Categories{
    position: relative;
    top: 1px;
}
.Submodule-8 .Container-Image-Module{
    width: 60px;
    height: 60px;
    background-color: var(--blue-elec);
    border-radius: 15px;
    padding: 5px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submodule-8 .Card-Title-Module{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 20px !important;
    font-weight: var(--weight-bold) !important;
    line-height: 24.2px !important;
    margin-bottom: 0 !important;
}
.Submodule-8 .Container-Submodule{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
}
.Submodule-8 .Container-Card-Categories{
    border: 0;
    width: 95px;
    height: 95px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
}
.Submodule-8 .Container-Card-Categories .ant-card-body{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    position: relative;
}
.Submodule-8 .Container-Card-Categories .Card-Background{
    position: absolute;
    background-color: var(--blue-elec);
    border-radius: 100%;
    width: 61px;
    height: 61px;
    z-index: 2;
    transition: .2s transform cubic-bezier(.44,0,0,1), border-radius 1s cubic-bezier(.44,0,0,1);
}
.Submodule-8 .Container-Card-Categories.Shadow-Bluesky .Card-Background{
    box-shadow: 0px 0px 17px #A9D2FE;
}
.Submodule-8 .Container-Card-Categories.Shadow-Yellow .Card-Background{
    box-shadow: 0px 0px 17px #FDCA0F;
}
.Submodule-8 .Container-Card-Categories.Shadow-Purple .Card-Background{
    box-shadow: 0px 0px 17px #AF88FD;
}
.Submodule-8 .Container-Image-Title{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Submodule-8 .Title-Item-Categories{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 20px !important;
    line-height: var(--lineheight-small);
    color: var(--blue);
    width: 45%;
    line-height: 24px;
    overflow-x: visible;
}
.Submodule-8 .Container-Card-Categories .Container-Round{
    width: 89px;
    height: 89px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #C4D5FF;
    opacity: 0;
    transition: .5s;
    animation: Rotate-Item-Category-Card-8 10s linear infinite;
}
.Submodule-8 .Container-Card-Categories .Container-Round{
    transition: .5s;
    opacity: 1;
}
.Submodule-8 .Container-Card-Categories .Container-Round::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.Submodule-8 .Container-Card-Categories .Container-Round::after{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C4D5FF;
    top: -5px;
    left: calc(50% - 5px);
}
@keyframes Rotate-Item-Category-Card-8{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.Submodule-8 .Card-Submodule-Item{
    padding: 29px;
    border-radius: 22px;
    position: relative;
    height: 160px;
}
.Submodule-8 .Container-Header-Submodule{
    position: relative;
    height: 102px;
}
.Submodule-8 .Text-Submodule{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19.36px;
    color: #00533F;
    position: absolute;
    width: 100%;
}
.Submodule-8 .Container-Icon-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
    opacity: 0;
    transition: all .3s;
    z-index: 3;
}
.Card-Submodule-Item:hover .Container-Icon-Submodule{
    opacity: 1;
    bottom: 20px;
}
.Submodule-8 .Icon-Submodule-Item{
    font-size: 10px;
}
.Submodule-8 .Container-Text-SubSubmodule{
    position: relative;
    display: flex;
    justify-content: start;
}
.Submodule-8 .Icon-SubSubmodule{
    position: absolute;
    width: 0;
    height: 15px;
    top: 1px;
    left: -29px;
    transition: width .5s;
}
.Submodule-8 .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    transition: width .5s;
    width: 17px;
}
.Submodule-8 .Text-SubSubmodule{
    font-family: var(--font-family);
    font-weight: var(--weight-semibold);
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 15px;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.Submodule-8 .Card-Submodule-Item.active-submodule-item .Text-SubSubmodule{
    animation: up-text 1s forwards;
    animation-delay: .7s;
}
@keyframes up-text{
    0%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Submodule-8 .Underline-SubSubmodule{
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width .5s;
}
.Submodule-8 .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    transition: width .5s;
    width: 100%;
}
.Submodule-8 .Container-Icon-Without-Submodule{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
}
.Submodule-8 .Container-Icon-Without-Submodule .Icon-Submodule-Without-Item{
    margin-left: 1px;
    font-size: 10px;
}
.Submodule-8 .Card-Submodule-Item.active-submodule-item .Container-Icon-Without-Submodule{
    opacity: 1;
    visibility: visible;
    transition: opacity .3s .6s;
}
/* Tarjeta 1 */
.Submodule-8 .Card-Submodule-Item:nth-child(1){
    background-color: var(--green);
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-8 .Card-Submodule-Item:nth-child(1) .Text-Submodule{
    color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(1) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(1) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-8 .Card-Submodule-Item:nth-child(1) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-8 .Card-Submodule-Item:nth-child(1) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 2 */
.Submodule-8 .Container-Submodule .Card-Submodule-Item:nth-child(2){
    background-color: #FFC700;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2) .Text-Submodule{
    color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 3 */
.Submodule-8 .Container-Submodule .Card-Submodule-Item:nth-child(3){
    background-color: var(--blue-elec);
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}
.Submodule-8 .Card-Submodule-Item:nth-child(3) .Text-Submodule{
    color: var(--blue);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Item{
    color: var(--blue);
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(3) .Icon-Submodule-Without-Item{
    color: var(--blue);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--blue-elec);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 4 */
.Submodule-8 .Container-Submodule .Card-Submodule-Item:nth-child(4){
    background-color: #C4D5FF;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4) .Text-Submodule{
    color: #5270BC;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Item{
    color: #5270BC;
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4) .Icon-Submodule-Without-Item{
    color: #5270BC;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule, .Submodule-8 .Card-Submodule-Item:nth-child(4) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #C4D5FF;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 5 */
.Submodule-8 .Container-Submodule .Card-Submodule-Item:nth-child(5){
    background-color: var(--magenta);
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5) .Text-Submodule{
    color: #93007C;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Item{
    color: #93007C;
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5) .Icon-Submodule-Without-Item{
    color: #93007C;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-8 .Card-Submodule-Item:nth-child(5) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--magenta);
}
.Submodule-8 .Card-Submodule-Item:nth-child(5) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 6 */
.Submodule-8 .Container-Submodule .Card-Submodule-Item:nth-child(6){
    background-color: #FC8122;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6) .Text-Submodule{
    color: #AC4C02;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(6) .Icon-Submodule-Item{
    color: #AC4C02;
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6) .Icon-Submodule-Without-Item{
    color: #AC4C02;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FC8122;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FC8122;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 7 */
.Submodule-8 .Card-Submodule-Item:nth-child(7){
    background-color: var(--green);
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7) .Text-Submodule{
    color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(7) .Icon-Submodule-Item{
    color: #00533F;
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7) .Icon-Submodule-Without-Item{
    color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: var(--green);
}
.Submodule-8 .Card-Submodule-Item:nth-child(7) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: var(--green);
}
.Submodule-8 .Card-Submodule-Item:nth-child(7) .Text-SubSubmodule{
    color: var(--white);
}
/* Tarjeta 8 */
.Submodule-8 .Container-Submodule .Card-Submodule-Item:nth-child(8){
    background-color: #FFC700;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8) .Text-Submodule{
    color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8) .Container-Icon-Submodule{
    background-color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(8) .Icon-Submodule-Item{
    color: #F90000;
    margin-top: 1px;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8) .Icon-Submodule-Without-Item{
    color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8) .Container-Text-SubSubmodule:hover .Icon-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8) .Container-Text-SubSubmodule:hover .Underline-SubSubmodule{
    background-color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8) .Text-SubSubmodule{
    color: var(--white);
}
/* Default Animacion */
.Submodule-8 .Card-Submodule-Item:nth-child(1).Default-Active{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).Default-Active{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).Default-Active{
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).Default-Active{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).Default-Active{
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).Default-Active{
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).Default-Active{
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).Default-Active{
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
/* Tarjeta 1 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(1).active-submodule-item{
    background-color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--green);
}
.Submodule-8 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(1):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(1).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 2 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item{
    background-color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Tarjeta 3 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(3).active-submodule-item{
    background-color: var(--blue);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--blue-elec);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--blue-elec);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: var(--blue);
}
.Submodule-8 .Card-Submodule-Item:nth-child(3):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(3).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--blue-elec);
}
/* Tarjeta 4 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item{
    background-color: #5270BC;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #C4D5FF;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #C4D5FF;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #5270BC;
}
.Submodule-8 .Card-Submodule-Item:nth-child(4):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #C4D5FF;
}
/* Tarjeta 5 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item{
    background-color: #93007C;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--magenta);
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: var(--magenta);
}
.Submodule-8 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #93007C;
}
.Submodule-8 .Card-Submodule-Item:nth-child(5):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--magenta);
}
/* Tarjeta 6 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(6).active-submodule-item{
    background-color: #AC4C02;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FC8122;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(6).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FC8122;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #AC4C02;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(6).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FC8122;
}
/* Tarjeta 7 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item{
    background-color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: var(--green);
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #2AD295;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #00533F;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: var(--green);
}
/* Tarjeta 8 Activo */
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item .Icon-Submodule-Item{
    margin-top: 0;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8):not(.active-submodule-item:hover):hover, .Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item{
    background-color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8):not(.active-submodule-item:hover):hover .Text-Submodule{
    color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item .Text-Submodule{
    color: var(--white);
}
.Submodule-8 .Card-Submodule-Item:nth-child(8):not(.active-submodule-item:hover) .Container-Icon-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item .Container-Icon-Submodule:hover{
    background-color: #FFC700;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8):not(.active-submodule-item:hover):hover .Icon-Submodule-Item{
    color: #F90000;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8):not(.active-submodule-item:hover) .Container-Icon-Without-Submodule:hover, .Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item .Container-Icon-Without-Submodule:hover{
    background-color: #FFC700;
}
/* Animacion Tarjeta 1 */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0{
    animation: sub8active1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(1).inactive-sub-0{
    animation: sub8inactive1-card1 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8inactive1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0{
    animation: sub8active1-card1-sub4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-card1-sub4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0{
    animation: sub8active1-card1-sub7 .3s forwards;
}
@keyframes sub8active1-card1-sub7{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0{
    animation: sub8active1-card1-sub8 .3s forwards;
}
@keyframes sub8active1-card1-sub8{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).inactive-sub-0{
    animation: sub8inactive1-card1-sub4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8inactive1-card1-sub4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).inactive-sub-0{
    animation: sub8inactive1-card1-sub7 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8inactive1-card1-sub7{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).inactive-sub-0{
    animation: sub8inactive1-card1-sub8 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8inactive1-card1-sub8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* Animacion Tarjeta 2 */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1{
    animation: sub8active2-card2 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).inactive-sub-1{
    animation: sub8inactive2-card2 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8inactive2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1{
    animation: sub8active2-card2-sub5 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active2-card2-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1{
    animation: sub8active2-card2-sub8 .3s forwards;
}
@keyframes sub8active2-card2-sub8{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).inactive-sub-1{
    animation: sub8inactive2-card2-sub5 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8inactive2-card2-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).inactive-sub-1{
    animation: sub8inactive2-card2-sub8 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8inactive2-card2-sub8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1 .Text-SubSubmodule{
    animation-delay: .5s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-1 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .45s;
}
/* Animacion Tarjeta 3 */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2{
    animation: sub8active3-card3 .3s forwards;
}
@keyframes sub8active3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).inactive-sub-2{
    animation: sub8inactive3-card3 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8inactive3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2{
    animation: sub8active3-card3-sub6 .3s forwards;
}
@keyframes sub8active3-card3-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).inactive-sub-2{
    animation: sub8inactive3-card3-sub6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8inactive3-card3-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2 .Text-SubSubmodule{
    animation-delay: .5s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-2 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* Animacion Tarjeta 4 */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3{
    animation: sub8active4-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).inactive-sub-3{
    animation: sub8inactive4-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8inactive4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3{
    animation: sub8active4-card4-sub7 .3s forwards;
}
@keyframes sub8active4-card4-sub7{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3{
    animation: sub8active4-card4-sub8 .3s forwards;
}
@keyframes sub8active4-card4-sub8{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).inactive-sub-3{
    animation: sub8inactive4-card4-sub7 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8inactive4-card4-sub7{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).inactive-sub-3{
    animation: sub8inactive4-card4-sub8 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8inactive4-card4-sub8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* Animacion Tarjeta 5 */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4{
    animation: sub8active5-card5 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).inactive-sub-4{
    animation: sub8inactive5-card5 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8inactive5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4{
    animation: sub8active5-card5-sub8 .3s forwards;
}
@keyframes sub8active5-card5-sub8{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).inactive-sub-4{
    animation: sub8inactive5-card5-sub8 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8inactive5-card5-sub8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-4 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .45s;
}
/* Animacion Tarjeta 6 */
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5{
    animation: sub8active6-card6 .3s forwards;
}
@keyframes sub8active6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).inactive-sub-5{
    animation: sub8inactive6-card6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8inactive6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5 .Text-SubSubmodule{
    animation-delay: .4s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-5 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .3s;
}
/* Animacion Tarjeta 7 */
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6{
    animation: sub8active7-card7 .3s forwards;
    animation-delay: .45s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub8active7-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).inactive-sub-6{
    animation: sub8inactive7-card7 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8inactive7-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        grid-row: 2 / 4;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6{
    animation: sub8active7-card7-sub4 .3s forwards;
    animation-delay: .15s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub8active7-card7-sub4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).inactive-sub-6{
    animation: sub8inactive7-card7-sub4 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8inactive7-card7-sub4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6{
    animation: sub8active7-card7-sub5 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-card7-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).inactive-sub-6{
    animation: sub8inactive7-card7-sub5 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8inactive7-card7-sub5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6{
    animation: sub8active7-card7-sub6 .3s forwards;
}
@keyframes sub8active7-card7-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).inactive-sub-6{
    animation: sub8inactive7-card7-sub6 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8inactive7-card7-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6 .Text-SubSubmodule{
    animation-delay: .4s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .75s;
}
/* Animacion Tarjeta 8 */
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7{
    animation: sub8active8-card8 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active8-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).inactive-sub-7{
    animation: sub8inactive8-card8 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8inactive8-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7{
    animation: sub8active8-card8-sub5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active8-card8-sub5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).inactive-sub-7{
    animation: sub8inactive8-card8-sub5 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8inactive8-card8-sub5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7{
    animation: sub8active8-card8-sub6 .3s forwards;
}
@keyframes sub8active8-card8-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).inactive-sub-7{
    animation: sub8inactive8-card8-sub6 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8inactive8-card8-sub6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item.active-sub-7 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 1 a Active Card 2 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0.card-1{
    animation: sub8active1-sub1-card1-card2 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active1-sub1-card1-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-0.card-1{
    animation: sub8active1-sub1-card2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-sub1-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0.card-1{
    animation: sub8active1-sub1-card4-card2 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card4-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-0.card-1{
    animation: sub8active1-sub1-card5-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-sub1-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0.card-1{
    animation: sub8active1-sub1-card7-card2 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card7-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0.card-1{
    animation: sub8active1-sub1-card8-card2 0s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card8-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 1 a Active Card 2 --- */
/* --- Active Card 1 a Active Card 3 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0.card-2{
    animation: sub8active1-sub1-card1-card3 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active1-sub1-card1-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-0.card-2{
    animation: sub8active1-sub1-card3-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-sub1-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0.card-2{
    animation: sub8active1-sub1-card4-card3 .3s forwards;
}
@keyframes sub8active1-sub1-card4-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-0.card-2{
    animation: sub8active1-sub1-card6-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-sub1-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0.card-2{
    animation: sub8active1-sub1-card7-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card7-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0.card-2{
    animation: sub8active1-sub1-card8-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card8-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 1 a Active Card 3 --- */
/* --- Active Card 1 a Active Card 4 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0.card-3{
    animation: sub8active1-sub1-card1-card4 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active1-sub1-card1-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0.card-3{
    animation: sub8active1-sub1-card4-card4 .6s forwards;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    49%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    50%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
    51%{
        grid-row: 2 / 4;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-row: 2 / 4;
        grid-column: 1 / 2;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0.card-3{
    animation: sub8active1-sub1-card7-card4 0s forwards;
}
@keyframes sub8active1-sub1-card7-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0.card-3{
    animation: sub8active1-sub1-card8-card4 0s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card8-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 1 a Active Card 4 --- */
/* --- Active Card 1 a Active Card 5 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0.card-4{
    animation: sub8active1-sub1-card1-card5 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active1-sub1-card1-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0.card-4{
    animation: sub8active1-sub1-card4-card5 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card4-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-0.card-4{
    animation: sub8active1-sub1-card5-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-sub1-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(0);
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0.card-4{
    animation: sub8active1-sub1-card7-card5 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card7-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0.card-4{
    animation: sub8active1-sub1-card8-card5 0s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card8-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 1 a Active Card 5 --- */
/* --- Active Card 1 a Active Card 6 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0.card-5{
    animation: sub8active1-sub1-card1-card6 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active1-sub1-card1-card6{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0.card-5{
    animation: sub8active1-sub1-card4-card6 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card4-card6{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-0.card-5{
    animation: sub8active1-sub1-card6-card6 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active1-sub1-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0.card-5{
    animation: sub8active1-sub1-card7-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card7-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0.card-5{
    animation: sub8active1-sub1-card8-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card8-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 1 a Active Card 6 --- */
/* --- Active Card 1 a Active Card 7 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0.card-6{
    animation: sub8active1-sub1-card1-card7 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active1-sub1-card1-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0.card-6{
    animation: sub8active1-sub1-card4-card7 1.2s forwards;
}
@keyframes sub8active1-sub1-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        transform: translate(0, calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    25%{
        transform: translate(0, 0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    75%{
        transform: translate(0, 0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-0.card-6{
    animation: sub8active1-sub1-card5-card7 .3s forwards;
    animation-delay: .9s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active1-sub1-card5-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-0.card-6{
    animation: sub8active1-sub1-card6-card7 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active1-sub1-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0.card-6{
    animation: sub8active1-sub1-card7-card7 1.2s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card7-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
        height: 160px;
    }
    24.9%{
        grid-column: 2 / 3;
        transform: translate(calc(-100% - 14px), 0);
    }
    25%{
        transform: translate(0, 0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    74%{
        grid-row: 3 / 4;
        transform: translate(0, 0);
        height: 160px;
    }
    75%{
        transform: translate(0, calc(100% + 14px));
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
    87%{
        transform: translate(0, 0);
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0.card-6{
    animation: sub8active1-sub1-card8-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card8-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-0.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 1 a Active Card 7 --- */
/* --- Active Card 1 a Active Card 8 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-0.card-7{
    animation: sub8active1-sub1-card1-card8 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active1-sub1-card1-card8{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-0.card-7{
    animation: sub8active1-sub1-card4-card8 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card4-card8{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-0.card-7{
    animation: sub8active1-sub1-card5-card8 .3s forwards;
    animation-delay: .9s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active1-sub1-card5-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-0.card-7{
    animation: sub8active1-sub1-card6-card8 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active1-sub1-card6-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-0.card-7{
    animation: sub8active1-sub1-card7-card8 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card7-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-0.card-7{
    animation: sub8active1-sub1-card8-card8 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active1-sub1-card8-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        grid-column: 3 / 4;
        transform: translate(calc(-100% - 14px), 0);
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    74.9%{
        transform: translate(0, 0);
        grid-row: 3 / 4;
    }
    75%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
        transform: translate(0, calc(100% + 14px));
    }
    87%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 0);
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item.active-sub-0.card-7 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 1 a Active Card 8 --- */
/* --- Active Card 2 a Active Card 1 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-1.card-0{
    animation: sub8active2-sub2-card1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active2-sub2-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    99%{
        height: 334px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1.card-0{
    animation: sub8active2-sub2-card2-card1 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active2-sub2-card2-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-1.card-0{
    animation: sub8active2-sub2-card4-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active2-sub2-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1.card-0{
    animation: sub8active2-sub2-card5-card1 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-1.card-0{
    animation: sub8active2-sub2-card7-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active2-sub2-card7-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-0{
    animation: sub8active2-sub2-card8-card1 0s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card8-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 2 a Active Card 1 --- */
/* --- Active Card 2 a Active Card 3 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1.card-2{
    animation: sub8active2-sub2-card2-card3 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active2-sub2-card2-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-1.card-2{
    animation: sub8active2-sub2-card3-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active2-sub2-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1.card-2{
    animation: sub8active2-sub2-card5-card3 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card5-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-1.card-2{
    animation: sub8active2-sub2-card6-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active2-sub2-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-2{
    animation: sub8active2-sub2-card8-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card8-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 2 a Active Card 3 --- */
/* --- Active Card 2 a Active Card 4 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1.card-3{
    animation: sub8active2-sub2-card2-card4 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active2-sub2-card2-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-1.card-3{
    animation: sub8active2-sub2-card4-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active2-sub2-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1.card-3{
    animation: sub8active2-sub2-card5-card4 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card5-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-1.card-3{
    animation: sub8active2-sub2-card7-card4 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active2-sub2-card7-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-3{
    animation: sub8active2-sub2-card8-card4 0s forwards;
}
@keyframes sub8active2-sub2-card8-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 2 a Active Card 4 --- */
/* --- Active Card 2 a Active Card 5 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1.card-4{
    animation: sub8active2-sub2-card2-card5 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active2-sub2-card2-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1.card-4{
    animation: sub8active2-sub2-card5-card5 .6s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    49.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    50%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    51%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
    99%{
        height: 334px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-4{
    animation: sub8active2-sub2-card8-card5 0s forwards;
}
@keyframes sub8active2-sub2-card8-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 2 a Active Card 5 --- */
/* --- Active Card 2 a Active Card 6 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1.card-5{
    animation: sub8active2-sub2-card2-card6 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active2-sub2-card2-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1.card-5{
    animation: sub8active2-sub2-card5-card6 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card5-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-1.card-5{
    animation: sub8active2-sub2-card6-card6 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active2-sub2-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-5{
    animation: sub8active2-sub2-card8-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card8-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 2 a Active Card 6 --- */
/* --- Active Card 2 a Active Card 7 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1.card-6{
    animation: sub8active2-sub2-card2-card7 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active2-sub2-card2-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-1.card-6{
    animation: sub8active2-sub2-card4-card7 .3s forwards;
    animation-delay: .9s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub8active2-sub2-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1.card-6{
    animation: sub8active2-sub2-card5-card7 1.2s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card5-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        transform: translate(0, calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    75%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-1.card-6{
    animation: sub8active2-sub2-card6-card7 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active2-sub2-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-1.card-6{
    animation: sub8active2-sub2-card7-card7 .3s forwards;
    animation-delay: 1.2s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub8active2-sub2-card7-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-6{
    animation: sub8active2-sub2-card8-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card8-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-1.card-6 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-1.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 2 a Active Card 7 --- */
/* --- Active Card 2 a Active Card 8 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-1.card-7{
    animation: sub8active2-sub2-card2-card8 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active2-sub2-card2-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-1.card-7{
    animation: sub8active2-sub2-card5-card8 1.2s forwards;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card5-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        transform: translate(0, calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    75%{
        transform: translate(0, 0);
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-1.card-7{
    animation: sub8active2-sub2-card6-card8 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active2-sub2-card6-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-7{
    animation: sub8active2-sub2-card8-card8 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active2-sub2-card8-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        grid-column: 3 / 4;
        transform: translate(calc(-100% - 14px), 0);
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    74.9%{
        transform: translate(0, 0);
        grid-row: 3 / 4;
    }
    75%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 4;
        height: 160px;
    }
    87%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-1.card-7 .Text-SubSubmodule{
    animation-delay: 1.5s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item.active-sub-1.card-7 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 2 a Active Card 8 --- */
/* --- Active Card 3 a Active Card 1 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-2.card-0{
    animation: sub8active3-sub3-card1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2.card-0{
    animation: sub8active3-sub3-card3-card1 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active3-sub3-card3-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-2.card-0{
    animation: sub8active3-sub3-card4-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2.card-0{
    animation: sub8active3-sub3-card6-card1 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active3-sub3-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-2.card-0{
    animation: sub8active3-sub3-card7-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active3-sub3-card7-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-2.card-0{
    animation: sub8active3-sub3-card8-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active3-sub3-card8-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 3 a Active Card 1 --- */
/* --- Active Card 3 a Active Card 2 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-2.card-1{
    animation: sub8active3-sub3-card2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2.card-1{
    animation: sub8active3-sub3-card3-card2 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active3-sub3-card3-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-2.card-1{
    animation: sub8active3-sub3-card5-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2.card-1{
    animation: sub8active3-sub3-card6-card2 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active3-sub3-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-2.card-1{
    animation: sub8active3-sub3-card8-card2 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active3-sub3-card8-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 3 a Active Card 2 --- */
/* --- Active Card 3 a Active Card 4 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2.card-3{
    animation: sub8active3-sub3-card3-card4 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active3-sub3-card3-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-2.card-3{
    animation: sub8active3-sub3-card4-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2.card-3{
    animation: sub8active3-sub3-card6-card4 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active3-sub3-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-2.card-3{
    animation: sub8active3-sub3-card7-card4 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active3-sub3-card7-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-2.card-3{
    animation: sub8active3-sub3-card8-card4 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active3-sub3-card8-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 3 a Active Card 4 --- */
/* --- Active Card 3 a Active Card 5 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2.card-4{
    animation: sub8active3-sub3-card3-card5 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active3-sub3-card3-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-2.card-4{
    animation: sub8active3-sub3-card5-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2.card-4{
    animation: sub8active3-sub3-card6-card5 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active3-sub3-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-2.card-4{
    animation: sub8active3-sub3-card8-card5 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active3-sub3-card8-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 3 a Active Card 5 --- */
/* --- Active Card 3 a Active Card 6 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2.card-5{
    animation: sub8active3-sub3-card3-card6 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active3-sub3-card3-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2.card-5{
    animation: sub8active3-sub3-card6-card6 .6s forwards;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active3-sub3-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    49.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    50%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
    51%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
/* --- Active Card 3 a Active Card 6 --- */
/* --- Active Card 3 a Active Card 7 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2.card-6{
    animation: sub8active3-sub3-card3-card7 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active3-sub3-card3-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-2.card-6{
    animation: sub8active3-sub3-card4-card7 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-2.card-6{
    animation: sub8active3-sub3-card5-card7 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active3-sub3-card5-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2.card-6{
    animation: sub8active3-sub3-card6-card7 0s forwards;
}
@keyframes sub8active3-sub3-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-2.card-6{
    animation: sub8active3-sub3-card7-card7 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active3-sub3-card7-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-2.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s .9s;
}
/* --- Active Card 3 a Active Card 7 --- */
/* --- Active Card 3 a Active Card 8 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-2.card-7{
    animation: sub8active3-sub3-card3-card8 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    height: 334px;
}
@keyframes sub8active3-sub3-card3-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 1 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-2.card-7{
    animation: sub8active3-sub3-card5-card8 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active3-sub3-card5-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-2.card-7{
    animation: sub8active3-sub3-card6-card8 0s forwards;
}
@keyframes sub8active3-sub3-card6-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-2.card-7{
    animation: sub8active3-sub3-card8-card8 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub8active3-sub3-card8-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
/* --- Active Card 3 a Active Card 8 --- */
/* --- Active Card 4 a Active Card 1 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-3.card-0{
    animation: sub8active4-sub4-card1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-sub4-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3.card-0{
    animation: sub8active4-sub4-card4-card1 .6s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active4-sub4-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3.card-0{
    animation: sub8active4-sub4-card7-card1 0s forwards;
}
@keyframes sub8active4-sub4-card7-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3.card-0{
    animation: sub8active4-sub4-card8-card1 0s forwards;
}
@keyframes sub8active4-sub4-card8-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 4 a Active Card 1 --- */
/* --- Active Card 4 a Active Card 2 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-3.card-1{
    animation: sub8active4-sub4-card2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-sub4-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3.card-1{
    animation: sub8active4-sub4-card4-card2 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active4-sub4-card4-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-3.card-1{
    animation: sub8active4-sub4-card5-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-sub4-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3.card-1{
    animation: sub8active4-sub4-card7-card2 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card7-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3.card-1{
    animation: sub8active4-sub4-card8-card2 0s forwards;
}
@keyframes sub8active4-sub4-card8-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 4 a Active Card 2 --- */
/* --- Active Card 4 a Active Card 3 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-3.card-2{
    animation: sub8active4-sub4-card3-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-sub4-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3.card-2{
    animation: sub8active4-sub4-card4-card3 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active4-sub4-card4-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-3.card-2{
    animation: sub8active4-sub4-card6-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-sub4-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3.card-2{
    animation: sub8active4-sub4-card7-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card7-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3.card-2{
    animation: sub8active4-sub4-card8-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card8-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 4 a Active Card 3 --- */
/* --- Active Card 4 a Active Card 5 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3.card-4{
    animation: sub8active4-sub4-card4-card5 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active4-sub4-card4-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-3.card-4{
    animation: sub8active4-sub4-card5-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-sub4-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3.card-4{
    animation: sub8active4-sub4-card7-card5 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card7-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3.card-4{
    animation: sub8active4-sub4-card8-card5 0s forwards;
}
@keyframes sub8active4-sub4-card8-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 4 a Active Card 5 --- */
/* --- Active Card 4 a Active Card 6 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3.card-5{
    animation: sub8active4-sub4-card4-card6 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active4-sub4-card4-card6{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-3.card-5{
    animation: sub8active4-sub4-card6-card6 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active4-sub4-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3.card-5{
    animation: sub8active4-sub4-card7-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card7-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3.card-5{
    animation: sub8active4-sub4-card8-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card8-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 4 a Active Card 6 --- */
/* --- Active Card 4 a Active Card 7 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3.card-6{
    animation: sub8active4-sub4-card4-card7 1.2s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active4-sub4-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    24%{
        height: 160px;
        grid-row: 2 / 4;
    }
    25%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
    75%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-3.card-6{
    animation: sub8active4-sub4-card5-card7 .3s forwards;
    animation-delay: .9s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active4-sub4-card5-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-3.card-6{
    animation: sub8active4-sub4-card6-card7 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active4-sub4-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3.card-6{
    animation: sub8active4-sub4-card7-card7 1.2s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card7-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        grid-column: 2 / 3;
        transform: translate(calc(-100% - 14px), 0);
    }
    25%{
        transform: translate(0, 0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    74%{
        transform: translate(0, 0);
        grid-row: 3 / 4;
    }
    75%{
        transform: translate(0, calc(100% + 14px));
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    87%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 0);
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3.card-6{
    animation: sub8active4-sub4-card8-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card8-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-3.card-6 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.5s;
}
/* --- Active Card 4 a Active Card 7 --- */
/* --- Active Card 4 a Active Card 8 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-3.card-7{
    animation: sub8active4-sub4-card4-card8 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active4-sub4-card4-card8{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-3.card-7{
    animation: sub8active4-sub4-card5-card8 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active4-sub4-card5-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-3.card-7{
    animation: sub8active4-sub4-card6-card8 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active4-sub4-card6-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-3.card-7{
    animation: sub8active4-sub4-card7-card8 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card7-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-3.card-7{
    animation: sub8active4-sub4-card8-card8 1.2s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active4-sub4-card8-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        grid-column: 3 / 4;
        transform: translate(calc(-100% - 14px), 0);
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    74.9%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    75%{
        transform: translate(0, calc(100% + 14px));
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    87%{
        transform: translate(0, 0);
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item.active-sub-3.card-7 .Container-Icon-Without-Submodule{
    opacity: 1;
    transition: opacity .3s 1.35s;
}
/* --- Active Card 4 a Active Card 8 --- */
/* --- Active Card 5 a Active Card 1 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-4.card-0{
    animation: sub8active5-sub5-card1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active5-sub5-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-4.card-0{
    animation: sub8active5-sub5-card4-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active5-sub5-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4.card-0{
    animation: sub8active5-sub5-card5-card1 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active5-sub5-card5-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-4.card-0{
    animation: sub8active5-sub5-card7-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active5-sub5-card7-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4.card-0{
    animation: sub8active5-sub5-card8-card1 0s forwards;
}
@keyframes sub8active5-sub5-card8-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 5 a Active Card 1 --- */
/* --- Active Card 5 a Active Card 2 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-4.card-1{
    animation: sub8active5-sub5-card2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active5-sub5-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4.card-1{
    animation: sub8active5-sub5-card5-card2 .6s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active5-sub5-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4.card-1{
    animation: sub8active5-sub5-card8-card2 0s forwards;
}
@keyframes sub8active5-sub5-card8-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 5 a Active Card 2 --- */
/* --- Active Card 5 a Active Card 3 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-4.card-2{
    animation: sub8active5-sub5-card3-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    height: 160px;
}
@keyframes sub8active5-sub5-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4.card-2{
    animation: sub8active5-sub5-card5-card3 .3s forwards;
}
@keyframes sub8active5-sub5-card5-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-4.card-2{
    animation: sub8active5-sub5-card6-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active5-sub5-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4.card-2{
    animation: sub8active5-sub5-card8-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active5-sub5-card8-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 5 a Active Card 3 --- */
/* --- Active Card 5 a Active Card 4 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-4.card-3{
    animation: sub8active5-sub5-card4-card4 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    height: 160px;
}
@keyframes sub8active5-sub5-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4.card-3{
    animation: sub8active5-sub5-card5-card4 .3s forwards;
}
@keyframes sub8active5-sub5-card5-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-4.card-3{
    animation: sub8active5-sub5-card7-card4 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active5-sub5-card7-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4.card-3{
    animation: sub8active5-sub5-card8-card4 0s forwards;
}
@keyframes sub8active5-sub5-card8-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 5 a Active Card 4 --- */
/* --- Active Card 5 a Active Card 6 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4.card-5{
    animation: sub8active5-sub5-card5-card6 .3s forwards;
}
@keyframes sub8active5-sub5-card5-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-4.card-5{
    animation: sub8active5-sub5-card6-card6 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    height: 160px;
}
@keyframes sub8active5-sub5-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4.card-5{
    animation: sub8active5-sub5-card8-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active5-sub5-card8-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 5 a Active Card 6 --- */
/* --- Active Card 5 a Active Card 7 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-4.card-6{
    animation: sub8active5-sub5-card4-card7 .3s forwards;
    animation-delay: .9s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub8active5-sub5-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4.card-6{
    animation: sub8active5-sub5-card5-card7 1.2s forwards;
}
@keyframes sub8active5-sub5-card5-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    24%{
        height: 160px;
        grid-row: 2 / 4;
    }
    25%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    75%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-4.card-6{
    animation: sub8active5-sub5-card6-card7 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active5-sub5-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 2 / 3;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-4.card-6{
    animation: sub8active5-sub5-card7-card7 .3s forwards;
    animation-delay: 1.2s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub8active5-sub5-card7-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4.card-6{
    animation: sub8active5-sub5-card8-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active5-sub5-card8-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-4.card-6 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.2s;
}
/* --- Active Card 5 a Active Card 7 --- */
/* --- Active Card 5 a Active Card 8 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-4.card-7{
    animation: sub8active5-sub5-card5-card8 1.2s forwards;
}
@keyframes sub8active5-sub5-card5-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
    24%{
        height: 160px;
        grid-row: 2 / 4;
    }
    25%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
    75%{
        transform: translateX(0);
        grid-column: 2 / 3;
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column:  3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-4.card-7{
    animation: sub8active5-sub5-card6-card8 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active5-sub5-card6-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-4.card-7{
    animation: sub8active5-sub5-card8-card8 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active5-sub5-card8-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    24.9%{
        grid-column: 3 / 4;
        transform: translate(calc(-100% - 14px), 0);
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    74.9%{
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    75%{
        transform: translate(0, calc(100% + 14px));
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    87%{
        transform: translate(0, 0);
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item.active-sub-4.card-7 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 5 a Active Card 8 --- */
/* --- Active Card 6 a Active Card 1 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-5.card-0{
    animation: sub8active6-sub6-card1-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-5.card-0{
    animation: sub8active6-sub6-card4-card1 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5.card-0{
    animation: sub8active6-sub6-card6-card1 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active6-sub6-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-5.card-0{
    animation: sub8active6-sub6-card7-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active6-sub6-card7-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-5.card-0{
    animation: sub8active6-sub6-card8-card1 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active6-sub6-card8-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 6 a Active Card 1 --- */
/* --- Active Card 6 a Active Card 2 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-5.card-1{
    animation: sub8active6-sub6-card2-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-5.card-1{
    animation: sub8active6-sub6-card5-card2 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card5-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5.card-1{
    animation: sub8active6-sub6-card6-card2 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active6-sub6-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-5.card-1{
    animation: sub8active6-sub6-card8-card2 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active6-sub6-card8-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 6 a Active Card 2 --- */
/* --- Active Card 6 a Active Card 3 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-5.card-2{
    animation: sub8active6-sub6-card3-card3 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5.card-2{
    animation: sub8active6-sub6-card6-card3 .6s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active6-sub6-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    49%{
        height: 160px;
        grid-row: 2 / 4;
    }
    50%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        height: 160px;
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 6 a Active Card 3 --- */
/* --- Active Card 6 a Active Card 4 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-5.card-3{
    animation: sub8active6-sub6-card4-card4 .3s forwards;
    animation-delay: .45s;
}
@keyframes sub8active6-sub6-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5.card-3{
    animation: sub8active6-sub6-card6-card4 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active6-sub6-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-5.card-3{
    animation: sub8active6-sub6-card7-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card7-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-5.card-3{
    animation: sub8active6-sub6-card8-card4 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card8-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-5.card-3 .Container-Icon-Without-Submodule{
    transition: opacity .3s .75s;
}
/* --- Active Card 6 a Active Card 4 --- */
/* --- Active Card 6 a Active Card 5 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-5.card-4{
    animation: sub8active6-sub6-card5-card5 .3s forwards;
    animation-delay: .3s;
}
@keyframes sub8active6-sub6-card5-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5.card-4{
    animation: sub8active6-sub6-card6-card5 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active6-sub6-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
    99%{
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-5.card-4{
    animation: sub8active6-sub6-card8-card5 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active6-sub6-card8-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 6 a Active Card 5 --- */
/* --- Active Card 6 a Active Card 7 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-5.card-6{
    animation: sub8active6-sub6-card4-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
@keyframes sub8active6-sub6-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-5.card-6{
    animation: sub8active6-sub6-card5-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active6-sub6-card5-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5.card-6{
    animation: sub8active6-sub6-card6-card7 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active6-sub6-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-5.card-6{
    animation: sub8active6-sub6-card7-card7 .3s forwards;
    animation-delay: .6s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub8active6-sub6-card7-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-submodule-item.active-sub-5.card-6 .Container-Icon-Without-Submodule{
    transition: opacity .3s .9s;
}
/* --- Active Card 6 a Active Card 7 --- */
/* --- Active Card 6 a Active Card 8 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-5.card-7{
    animation: sub8active6-sub6-card5-card8 .3s forwards;
    animation-delay: .15s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active6-sub6-card5-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-5.card-7{
    animation: sub8active6-sub6-card6-card8 .3s forwards;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active6-sub6-card6-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-5.card-7{
    animation: sub8active6-sub6-card8-card8 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active6-sub6-card8-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
/* --- Active Card 6 a Active Card 8 --- */
/* --- Active Card 7 a Active Card 1 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-6.card-0{
    animation: sub8active7-sub7-card1-card1 .3s forwards;
    animation-delay: .9s;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
@keyframes sub8active7-sub7-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6.card-0{
    animation: sub8active7-sub7-card4-card1 .9s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card4-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    32.9%{
        transform: translate(calc(-100% - 14px), 0);
        grid-column: 2 / 3;
    }
    33%{
        grid-column: 1 / 2;
        transform: translate(0, 0);
    }
    66%{
        grid-column: 1 / 2;
        transform: translate(0, 0);
    }
    99.9%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6.card-0{
    animation: sub8active7-sub7-card5-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    transform: translateY(0);
}
@keyframes sub8active7-sub7-card5-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-0{
    animation: sub8active7-sub7-card6-card1 .3s forwards;
    animation-delay: .45s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active7-sub7-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6.card-0{
    animation: sub8active7-sub7-card7-card1 .9s forwards;
}
@keyframes sub8active7-sub7-card7-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
        transform: translate(0, 0);
    }
    16%{
        height: 160px;
    }
    32.9%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 4;
    }
    33%{
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    66%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translate(calc(100% + 14px), 0);
    }
    100%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-6.card-0{
    animation: sub8active7-sub7-card8-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active7-sub7-card8-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-6.card-0 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.2s;
}
/* --- Active Card 7 a Active Card 1 --- */
/* --- Active Card 7 a Active Card 2 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-6.card-1{
    animation: sub8active7-sub7-card2-card2 .3s forwards;
    animation-delay: 1.2s;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
@keyframes sub8active7-sub7-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6.card-1{
    animation: sub8active7-sub7-card4-card2 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card4-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6.card-1{
    animation: sub8active7-sub7-card5-card2 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card5-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    24.9%{
        transform: translate(calc(-100% - 14px), 0);
        grid-column: 3 / 4;
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    75%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    99%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-1{
    animation: sub8active7-sub7-card6-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active7-sub7-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6.card-1{
    animation: sub8active7-sub7-card7-card2 .3s forwards;
}
@keyframes sub8active7-sub7-card7-card2{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        height: 160px;
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-6.card-1{
    animation: sub8active7-sub7-card8-card2 .3s forwards;
    animation-delay: .9s;
}
@keyframes sub8active7-sub7-card8-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-6.card-1 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 7 a Active Card 2 --- */
/* --- Active Card 7 a Active Card 3 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-6.card-2{
    animation: sub8active7-sub7-card3-card3 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}
@keyframes sub8active7-sub7-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6.card-2{
    animation: sub8active7-sub7-card4-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card4-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6.card-2{
    animation: sub8active7-sub7-card5-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card5-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-2{
    animation: sub8active7-sub7-card6-card3 0s forwards;
}
@keyframes sub8active7-sub7-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6.card-2{
    animation: sub8active7-sub7-card7-card3 .3s forwards;
}
@keyframes sub8active7-sub7-card7-card3{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-submodule-item.active-sub-6.card-2 .Container-Icon-Without-Submodule{
    transition: opacity .3s .9s;
}
/* --- Active Card 7 a Active Card 3 --- */
/* --- Active Card 7 a Active Card 4 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6.card-3{
    animation: sub8active7-sub7-card4-card4 1.2s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card4-card4{
    0%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        height: 160px;
    }
    24.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    25%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    74.9%{
        grid-row: 2 / 3;
    }
    75%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6.card-3{
    animation: sub8active7-sub7-card5-card4 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card5-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-3{
    animation: sub8active7-sub7-card6-card4 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active7-sub7-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6.card-3{
    animation: sub8active7-sub7-card7-card4 1.2s forwards;
}
@keyframes sub8active7-sub7-card7-card4{
    0%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        transform: translate(0, 0);
    }
    13%{
        height: 160px;
    }
    24.9%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 4;
    }
    25%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translate(0, 0);
        height: 160px;
    }
    75%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translate(0, 0);
        height: 160px;
    }
    99%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 1 / 2;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-6.card-3{
    animation: sub8active7-sub7-card8-card4 .3s forwards;
    animation-delay: .9s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active7-sub7-card8-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-6.card-3 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 7 a Active Card 4 --- */
/* --- Active Card 7 a Active Card 5 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6.card-4{
    animation: sub8active7-sub7-card4-card5 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card4-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6.card-4{
    animation: sub8active7-sub7-card5-card5 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card5-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    24.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    25%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    74%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    75%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-4{
    animation: sub8active7-sub7-card6-card5 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active7-sub7-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6.card-4{
    animation: sub8active7-sub7-card7-card5 .3s forwards;
}
@keyframes sub8active7-sub7-card7-card5{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-6.card-4{
    animation: sub8active7-sub7-card8-card5 .3s forwards;
    animation-delay: .9s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}
@keyframes sub8active7-sub7-card8-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(100% + 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-6.card-4 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 7 a Active Card 5 --- */
/* --- Active Card 7 a Active Card 6 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6.card-5{
    animation: sub8active7-sub7-card4-card6 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card4-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 2 / 3;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6.card-5{
    animation: sub8active7-sub7-card5-card6 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card5-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-5{
    animation: sub8active7-sub7-card6-card6 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub8active7-sub7-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 334px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6.card-5{
    animation: sub8active7-sub7-card7-card6 .3s forwards;
}
@keyframes sub8active7-sub7-card7-card6{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-5 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-submodule-item.active-sub-6.card-5 .Container-Icon-Without-Submodule{
    transition: opacity .3s .9s;
}
/* --- Active Card 7 a Active Card 6 --- */
/* --- Active Card 7 a Active Card 8 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-6.card-7{
    animation: sub8active7-sub7-card4-card8 .3s forwards;
    animation-delay: .3s;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
@keyframes sub8active7-sub7-card4-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 2 / 3;
    }
    100%{
        transform: translateX(0);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-6.card-7{
    animation: sub8active7-sub7-card5-card8 0s forwards;
}
@keyframes sub8active7-sub7-card5-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-6.card-7{
    animation: sub8active7-sub7-card6-card8 0s forwards;
}
@keyframes sub8active7-sub7-card6-card8{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-6.card-7{
    animation: sub8active7-sub7-card7-card8 .3s forwards;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active7-sub7-card7-card8{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-6.card-7{
    animation: sub8active7-sub7-card8-card8 .3s forwards;
    animation-delay: .6s;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    height: 160px;
}
@keyframes sub8active7-sub7-card8-card8{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-6.card-7 .Text-SubSubmodule{
    animation-delay: .9s;
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-submodule-item.active-sub-6.card-7 .Container-Icon-Without-Submodule{
    transition: opacity .3s .9s;
}
/* --- Active Card 7 a Active Card 8 --- */
/* --- Active Card 8 a Active Card 1 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-sub-7.card-0{
    animation: sub8active8-sub8-card1-card1 .3s forwards;
    animation-delay: 1.2s;
}
@keyframes sub8active8-sub8-card1-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-7.card-0{
    animation: sub8active8-sub8-card4-card1 .3s forwards;
    animation-delay: 1.2s;
}
@keyframes sub8active8-sub8-card4-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translateY(0);
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7.card-0{
    animation: sub8active8-sub8-card5-card1 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active8-sub8-card5-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-0{
    animation: sub8active8-sub8-card6-card1 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active8-sub8-card6-card1{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-7.card-0{
    animation: sub8active8-sub8-card7-card1 .3s forwards;
    animation-delay: .9s;
}
@keyframes sub8active8-sub8-card7-card1{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7.card-0{
    animation: sub8active8-sub8-card8-card1 1.2s forwards;
}
@keyframes sub8active8-sub8-card8-card1{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translate(0, 0);
    }
    13%{
        height: 160px;
    }
    24.9%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 4;
    }
    25%{
        transform: translate(0, 0);
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
    75%{
        transform: translate(0, 0);
    }
    99.9%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(1).active-submodule-item.active-sub-7.card-0 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 8 a Active Card 1 --- */
/* --- Active Card 8 a Active Card 2 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-sub-7.card-1{
    animation: sub8active8-sub8-card2-card2 .3s forwards;
    animation-delay: 1.2s;
}
@keyframes sub8active8-sub8-card2-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7.card-1{
    animation: sub8active8-sub8-card5-card2 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active8-sub8-card5-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translate(0, 0);
    }
    24.9%{
        transform: translate(calc(-100% - 14px), 0);
        grid-column: 3 / 4;
    }
    25%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    75%{
        transform: translate(0, 0);
    }
    99%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-1{
    animation: sub8active8-sub8-card6-card2 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active8-sub8-card6-card2{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99.9%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7.card-1{
    animation: sub8active8-sub8-card8-card2 1.2s forwards;
}
@keyframes sub8active8-sub8-card8-card2{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translate(0, 0);
    }
    13%{
        height: 160px;
    }
    24.9%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 4;
    }
    25%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    75%{
        transform: translate(0, 0);
    }
    99.9%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(2).active-submodule-item.active-sub-7.card-1 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 8 a Active Card 2 --- */
/* --- Active Card 8 a Active Card 3 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-7.card-2{
    animation: sub8active8-sub8-card3-card3 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    height: 160px;
}
@keyframes sub8active8-sub8-card3-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 1 / 3;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7.card-2{
    animation: sub8active8-sub8-card5-card3 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active8-sub8-card5-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-2{
    animation: sub8active8-sub8-card6-card3 0s forwards;
}
@keyframes sub8active8-sub8-card6-card3{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7.card-2{
    animation: sub8active8-sub8-card8-card3 .3s forwards;
}
@keyframes sub8active8-sub8-card8-card3{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(3).active-sub-7.card-2 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* --- Active Card 8 a Active Card 3 --- */
/* --- Active Card 8 a Active Card 4 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-7.card-3{
    animation: sub8active8-sub8-card4-card4 .3s forwards;
    animation-delay: 1.2s;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    height: 160px;
}
@keyframes sub8active8-sub8-card4-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7.card-3{
    animation: sub8active8-sub8-card5-card4 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active8-sub8-card5-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        grid-column: 3 / 4;
        transform: translateX(calc(-100% - 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-3{
    animation: sub8active8-sub8-card6-card4 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active8-sub8-card6-card4{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        grid-row: 3 / 4;
        transform: translateY(calc(-100% - 14px));
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-7.card-3{
    animation: sub8active8-sub8-card7-card4 .3s forwards;
    animation-delay: .9s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active8-sub8-card7-card4{
    0%{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        transform: translateX(0);
    }
    99.9%{
        grid-column: 1 / 2;
        transform: translateX(calc(100% + 14px));
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7.card-3{
    animation: sub8active8-sub8-card8-card4 1.2s forwards;
}
@keyframes sub8active8-sub8-card8-card4{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translate(0, 0);
    }
    13%{
        height: 160px;
    }
    24.9%{
        grid-row: 2 / 4;
        transform: translate(0, calc(100% + 14px));
    }
    25%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    75%{
        transform: translate(0, 0);
    }
    99.9%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-submodule-item.active-sub-7.card-3 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 8 a Active Card 4 --- */
/* --- Active Card 8 a Active Card 5 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7.card-4{
    animation: sub8active8-sub8-card5-card5 1.2s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    height: 160px;
}
@keyframes sub8active8-sub8-card5-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        height: 160px;
        transform: translateX(0);
    }
    24.9%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 4;
    }
    25%{
        transform: translateX(0);
        grid-column: 2 / 3;
    }
    74%{
        grid-row: 2 / 3;
    }
    75%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 160px;
    }
    100%{
        height: 334px;
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-4{
    animation: sub8active8-sub8-card6-card5 .3s forwards;
    animation-delay: .6s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active8-sub8-card6-card5{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        transform: translateY(0);
    }
    99%{
        transform: translateY(calc(-100% - 14px));
        grid-row: 3 / 4;
    }
    100%{
        transform: translateY(0);
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7.card-4{
    animation: sub8active8-sub8-card8-card5 1.2s forwards;
}
@keyframes sub8active8-sub8-card8-card5{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translate(0, 0);
    }
    12%{
        height: 160px;
    }
    24.9%{
        transform: translate(0, calc(100% + 14px));
        grid-row: 2 / 4;
    }
    25%{
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        transform: translate(0, 0);
    }
    75%{
        grid-column: 2 / 3;
        transform: translate(0, 0);
    }
    99.9%{
        transform: translate(calc(100% + 14px), 0);
        grid-column: 2 / 3;
    }
    100%{
        transform: translate(0, 0);
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        height: 160px;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-submodule-item.active-sub-7.card-4 .Container-Icon-Without-Submodule{
    transition: opacity .3s 1.5s;
}
/* --- Active Card 8 a Active Card 5 --- */
/* --- Active Card 8 a Active Card 6 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7.card-5{
    animation: sub8active8-sub8-card5-card6 .3s forwards;
    animation-delay: .15s;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}
@keyframes sub8active8-sub8-card5-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(-100% - 14px));
        grid-column: 3 / 4;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-5{
    animation: sub8active8-sub8-card6-card6 .3s forwards;
    animation-delay: .3s;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}
@keyframes sub8active8-sub8-card6-card6{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        height: 334px;
        grid-column: 3 / 4;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7.card-5{
    animation: sub8active8-sub8-card8-card6 .3s forwards;
}
@keyframes sub8active8-sub8-card8-card6{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99.9%{
        transform: translateY(calc(100% + 14px));
        grid-row: 2 / 4;
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-5 .Text-SubSubmodule{
    animation-delay: .6s;
}
/* --- Active Card 8 a Active Card 6 --- */
/* --- Active Card 8 a Active Card 7 --- */
.Submodule-8 .Card-Submodule-Item:nth-child(4).active-sub-7.card-6{
    animation: sub8active8-sub8-card4-card7 .3s forwards;
    animation-delay: .15s;
}
@keyframes sub8active8-sub8-card4-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        transform: translateX(0);
    }
    99%{
        transform: translateX(calc(100% + 14px));
        grid-column: 1 / 2;
    }
    100%{
        transform: translateX(0);
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(5).active-sub-7.card-6{
    animation: sub8active8-sub8-card5-card7 0s forwards;
}
@keyframes sub8active8-sub8-card5-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(6).active-sub-7.card-6{
    animation: sub8active8-sub8-card6-card7 0s forwards;
}
@keyframes sub8active8-sub8-card6-card7{
    0%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
    100%{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(7).active-sub-7.card-6{
    animation: sub8active8-sub8-card7-card7 .3s forwards;
    animation-delay: .3s;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}
@keyframes sub8active8-sub8-card7-card7{
    0%{
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        height: 160px;
        transform: translateY(calc(100% + 14px));
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
        height: 334px;
        grid-column: 1 / 2;
        grid-row: 2 / 4;
    }
}
.Submodule-8 .Card-Submodule-Item:nth-child(8).active-sub-7.card-6{
    animation: sub8active8-sub8-card8-card7 .3s forwards;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 334px;
}
@keyframes sub8active8-sub8-card8-card7{
    0%{
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        height: 334px;
        transform: translateY(0);
    }
    50%{
        height: 160px;
    }
    99.9%{
        grid-row: 2 / 4;
        transform: translateY(calc(100% + 14px));
    }
    100%{
        transform: translateY(0);
        height: 160px;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
/* --- Active Card 8 a Active Card 7 --- */
.Container-Presentation + div{
    overflow: hidden;
}
.Container-Presentation{
    position: fixed;
    z-index: 1000000;
    width: 100%;
    height: 110vh;
    border-radius: 0 0 50px 50px;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Container-Presentation.Presentation-Green{
    background-color: var(--green);
}
.Container-Presentation.Presentation-Up{
    animation: View-Presentation 1s ease-in;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}
.Container-Presentation .Container-Item-Top-Presentation, .Container-Presentation .Container-Item-Bottom-Presentation{
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 100px;
    position: relative;
}
.Container-Presentation .Container-Item-Top-Presentation{
    margin-left: -205px;
}
.Container-Presentation .Container-Item-Bottom-Presentation{
    margin-right: -205px;
    justify-content: end;
}
.Container-Presentation .Container-Item-Bottom-Presentation .Title-Presentacion{
    -webkit-text-stroke: 1px var(--white);
    color: transparent !important;
    animation: View-Title-Bottom 1s;
    animation-fill-mode: forwards;
}
.Container-Presentation .Title-Presentacion{
    color: var(--white) !important;
    font-size: var(--size-exbig) !important;
    font-weight: var(--weight-bold) !important;
    font-family: var(--font-family-poppins);
    margin-bottom: 0 !important;
    margin-top: 0;
    text-transform: capitalize;
    animation: View-Title 1s;
    animation-fill-mode: forwards;
}
.Container-Presentation .Subtitle-Presentacion{
    color: var(--green) !important;
    font-size: var(--size-medium) !important;
    font-weight: var(--weight-regular) !important;
    line-height: 25.56px;
    font-family: var(--font-family-poppins);
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    margin-left: 10px;
}
.Container-Presentation .Subtitle-Presentacion.Height-Regular{
    animation: View-Sub-Title 1s;
    animation-fill-mode: forwards;
}
.Container-Presentation .Subtitle-Presentacion.Height-Big{
    animation: View-Sub-Title-Big 1s;
    animation-fill-mode: forwards;
}
.Container-Presentation.Presentation-Green .Subtitle-Presentacion{
    color: var(--blue) !important;
}
@keyframes View-Title{
    0%{
        transform: translateY(80px);
    }
    100%{
        transform: translateY(0);
    }
}
@keyframes View-Sub-Title{
    0%{
        transform: translateY(80px);
    }
    100%{
        transform: translateY(0);
    }
}
@keyframes View-Sub-Title-Big{
    0%{
        transform: translateY(80px);
    }
    100%{
        transform: translateY(-17px);
    }
}
@keyframes View-Title-Bottom{
    0%{
        transform: translateY(80px);
    }
    100%{
        transform: translateY(-10px);
    }
}
@keyframes View-Presentation{
    0%{
        top: 0;
    }
    90%{
        top: -120vh;
    }
    100%{
        top: -120vh;
        display: none;
    }
}
@media screen and (max-width: 1108px) {
    .Container-Presentation .Container-Item-Top-Presentation, .Container-Presentation .Container-Item-Bottom-Presentation{
        height: 80px;
    }
    .Container-Presentation .Container-Item-Top-Presentation{
        margin-left: -145px;
    }
    .Container-Presentation .Container-Item-Bottom-Presentation{
        margin-right: -145px;
    }
    .Container-Presentation .Title-Presentacion{
        font-size: 57px !important;
        line-height: 60px;
    }
    .Container-Presentation .Subtitle-Presentacion{
        font-size: 20px !important;
        line-height: 23px;
        margin-top: 9px !important;
    }
}
@media screen and (max-width: 769px) {
    .Container-Presentation .Container-Item-Top-Presentation, .Container-Presentation .Container-Item-Bottom-Presentation{
        height: 68px;
    }
    .Container-Presentation .Container-Item-Top-Presentation{
        margin-left: -108px;
    }
    .Container-Presentation .Container-Item-Bottom-Presentation{
        margin-right: -108px;
    }
    .Container-Presentation .Title-Presentacion{
        font-size: 42px !important;
        line-height: 45px;
    }
    .Container-Presentation .Subtitle-Presentacion{
        font-size: 16px !important;
        line-height: 18px;
        margin-top: 3px !important;
    }
}
@media screen and (max-width: 575px) {
    .Container-Presentation .Container-Item-Top-Presentation, .Container-Presentation .Container-Item-Bottom-Presentation{
        justify-content: center;
        margin-right: 0 !important;
        margin-left: 0 !important;
        flex-direction: column;
    }
    .Container-Presentation .Container-Item-Bottom-Presentation{
        height: 60px;
    }
    .Container-Presentation .Container-Item-Top-Presentation{
        height: 85px;
    }
    .Container-Presentation .Title-Presentacion{
        font-size: 37px !important;
        line-height: 40px;
    }
    .Container-Presentation .Subtitle-Presentacion{
        font-size: 18px !important;
        line-height: 20px;
        margin-top: 0 !important;
        text-align: center;
    }
}
.Container-Preload-Login{
    background-color: var(--white);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.Container-Button-Preload-Login{
    display: flex;
    column-gap: 20px;
    margin-bottom: 63px;
}
.Container-Button-Preload-Login .Unmute-Preload-Login, .Container-Button-Preload-Login .Skip-Preload-Login{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16.94px;
    width: 142px;
    height: auto;
    border-radius: 30px;
    z-index: 4;
    border: 0;
    padding: 20px 7px 20px 9px;
    display: flex;
    align-items: center;
    font-weight: var(--weight-bold);
}
.Container-Button-Preload-Login .Skip-Preload-Login{
    padding: 20px 10px 20px 15px;
}
.Container-Button-Preload-Login .Unmute-Preload-Login-Active{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16.94px;
    width: 142px;
    height: auto;
    border-radius: 30px;
    z-index: 4;
    border: 0;
    display: flex;
    align-items: center;
    padding: 20px 10px 20px 12px;
    font-weight: var(--weight-bold);
}
.Container-Button-Preload-Login .Unmute-Preload-Login, .Container-Button-Preload-Login .Unmute-Preload-Login-Active{
    display: block;
    background-color: var(--white);
    color: var(--black);
}
.Container-Button-Preload-Login .Unmute-Preload-Login span, .Container-Button-Preload-Login .Unmute-Preload-Login-Active span{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Container-Button-Preload-Login .Unmute-Preload-Login:hover, .Container-Button-Preload-Login .Unmute-Preload-Login-Active:hover{
    color: #FC8122;
}
.Container-Button-Preload-Login .Skip-Preload-Login{
    background-color: var(--black);
    color: var(--white);
    justify-content: space-between;
    padding-right: 16.11px;
}
.Container-Button-Preload-Login .Skip-Preload-Login:hover{
    color: #FC8122;
}
.Container-Preload-Login.Active-Hide-Preload-Login{
    animation: Hide-Preload-Login .8s ease-in;
    animation-fill-mode: forwards;
}
@keyframes Hide-Preload-Login{
    0%{
        height: 100vh;
    }
    100%{
        height: 0px;
    }
}
.Form-Create-Profile-Text-Main{
    font-family: var(--font-family) !important;
    font-size: 16px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-bold) !important;
    line-height: 19.36px !important;
    margin-bottom: 4px !important;
}
.Container-Checkbox-Create-Profile .ant-checkbox .ant-checkbox-inner::after{
    inset-inline-start: 18.5% !important;
}
:is(.ant-collapse-item.ant-collapse-item-active){
    padding-bottom: 5px !important;
}
.Container-Checkbox-Create-Profile{
    padding: 0;
}
.Container-Checkbox-Create-Profile .ant-checkbox{
    padding-inline-start: 0 !important;
}
.Container-Checkbox-Create-Profile .ant-checkbox-group-item span{
    font-size: 12px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-regular);
    line-height: 22.54px;
    padding-inline-start: 4px;
    padding-inline-end: 0;
}
.Container-Checkbox-Create-Profile .ant-checkbox-group-item .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
}
.Button-Country-Create-Profile{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    border: 1px solid rgba(42, 210, 149, 0.4);
    width: 71px;
    height: 30px;
    background-color: rgba(238, 255, 249, 1);
    color: rgba(42, 210, 149, 0.4);
    margin-bottom: 8px;
}
.Collapse-Create-Profile .ant-collapse-expand-icon, .Collapse-Submodule-Profile .ant-collapse-expand-icon{
    padding-inline-end: 0 !important;
    padding-inline-start: 0 !important;
    margin-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px !important;
    border-radius: 100% !important;
    transition: 0.3s;
}
.Collapse-Submodule-Profile .ant-collapse-expand-icon{
    width: 20px;
    height: 20px !important;
    background-color: transparent;
}
.Collapse-Create-Profile .ant-collapse-expand-icon:hover{
    background-color: #EDF2FF !important;
}
.Collapse-Create-Profile .Icon-Arrow-Profile, .Collapse-Submodule-Profile .Icon-Arrow-Profile{
    background-image: url('../../Assets/Images/CreateProfile/arrowBlue.png');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-top: 1px;
}
.Collapse-Submodule-Profile .Icon-Arrow-Profile{
    width: 20px;
    height: 20px;
}
.Collapse-Create-Profile .Icon-Arrow-Profile:hover, .Collapse-Submodule-Profile .ant-collapse-header:hover .Icon-Arrow-Profile{
    background-image: url('../../Assets/Images/CreateProfile/arrowSkyBlue.png');
}
.Collapse-Create-Profile .Icon-Arrow-Profile.Module-Active-Icon-Arrow{
    background-image: url('../../Assets/Images/CreateProfile/arrowBlueOpacity.png');
}
.Form-Item-Checkbox-Create-Profile{
    margin-bottom: 6px;
}
.Container-Row-Buttons-CountrySelected{
    border-bottom: 1px solid rgb(176, 191, 236, .5);
}
.Country-Added{
    background-color: var(--light-blue);
    color: var(--blue-elec);
    transition: 0.2s;
    border-color: var(--blue-elec);
}
.Container-Row-Buttons-Countries{
    display: flex;
    column-gap: 20px;
}
.Country-Selected{
    background-color: var(--blue-elec);
    color: var(--white);
}
.Country-Disabled{
    pointer-events: none;
}
.Container-Button-Country-Selected{
    position: relative;
}
.Container-Button-Country-Selected::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: var(--blue-elec);
}
.Disabled-Modules-Profile{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: not-allowed;
}
.Checkbox-SelectAll-Create-Profile{
    margin-left: 12px;
}
.Form-Item-Modules-Profile-Types-Create .ant-checkbox-wrapper-disabled .ant-checkbox-inner{
    background-color: var(--white) !important;
}
.Form-Item-Modules-Profile-Types-Create .ant-checkbox-wrapper-disabled span:nth-child(2){
    color: rgb(59, 114, 255, .5) !important;
}
.Form-Item-Modules-Profile-Types-Create .Collapse-Disabled-Profile .Header-Colapse-Create-Profile .Text-Header-Collapse-Create-Profile{
    color: rgb(1, 58, 129, .5) !important;
}
.Collapse-Create-Profile .Icon-Arrow-Profile.Disabled-Icon-Arrow{
    background-image: url('../../Assets/Images/CreateProfile/arrowBlueOpacity.png');
}
.Checkbox-SelectAll-Create-Profile :nth-child(2){
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    color: var(--blue-elec) !important;
    font-weight: var(--weight-regular) !important;
}
.Checkbox-SelectAll-Create-Profile .ant-checkbox .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
}
.Form-Item-Level-Country-Create-Profile{
    margin-bottom: 0 !important;
}
.Form-Item-Level-Country-Create-Profile .ant-form-item-label{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    margin-bottom: 8px;
}
.Form-Item-Level-Country-Create-Profile label{
    font-weight: var(--weight-regular);
}
.Form-Item-Checkbox-Create-Profile .ant-form-item-label{
    padding-bottom: 4px !important;
    padding-top: 0 !important;
    display: flex;
}
.Collapse-Create-Profile{
    margin-bottom: 4px !important;
    background-color: var(--white);
    margin-top: 0 !important;
}
.Collapse-Disabled-Profile .Collapse-Create-Profile{
    cursor: default !important;
    pointer-events: none;
}
.Collapse-Disabled-Profile .Collapse-Create-Profile .ant-checkbox-wrapper{
    cursor: default !important;
    pointer-events: none;
}
.Collapse-Create-Profile.Icon-Hide-Profile{
    cursor: default !important;
    pointer-events: none;
}
.Collapse-Create-Profile.Icon-Hide-Profile .ant-collapse-expand-icon{
    visibility: hidden;
}
.Collapse-Disabled-Profile .Collapse-Create-Profile.Icon-Hide-Profile .ant-checkbox-wrapper{
    cursor: default !important;
    pointer-events: none;
}
.Collapse-Create-Profile.Icon-Hide-Profile .ant-checkbox-wrapper{
    cursor: pointer !important;
    pointer-events: all;
}
.Collapse-Create-Profile .ant-collapse-item-active .ant-collapse-header{
    border-bottom: 1px solid rgb(176, 191, 236, .5);
    border-radius: 0 !important;
}
.Collapse-Create-Profile .ant-collapse-item-active .Collapse-Submodule-Profile .ant-collapse-header, 
.Collapse-Submodule-Profile .ant-collapse-item-active .ant-collapse-header{
    border-bottom: 0;
}
.Collapse-Create-Profile .ant-collapse-item{
    border-color: rgb(176, 191, 236, .5);
}
.Collapse-Create-Profile .ant-collapse-content{
    border-top: 0 !important;
}
.Collapse-Create-Profile .ant-collapse-content-box{
    margin-inline-start: 0 !important;
    padding: 0 0 !important;
    display: flex !important;
    flex-direction: column !important;
}
.Collapse-Create-Profile .ant-collapse-content-box label{
    margin-inline-start: 0 !important;
}
.Collapse-Create-Profile .Collapse-Submodule-Profile .ant-collapse-content-box label{
    padding: 4px 15px 4px 12px;
    display: flex;
    align-items: center;
}
.Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper-in-form-item:hover .ant-checkbox-wrapper :nth-child(2){
    color: var(--blue-elec) !important;
    font-weight: var(--weight-regular) !important;
    padding-inline-start: 12px !important;
}
.Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper.Submodule-Item .ant-checkbox, .Header-Colapse-Create-Profile, .Subsubmodule-Item .ant-checkbox{
    top: 0 !important;
}
.Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper.Submodule-Item span:nth-child(2):not(.ant-checkbox-inner){
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    color: var(--blue);
    font-size: 11px !important;
    line-height: 13.31px;
    margin-bottom: -2px;
}
.Form-Item-Modules-Profile-Types .Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper.Submodule-Item span:nth-child(2):not(.ant-checkbox-inner), .Container-User-Modules .Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper.Submodule-Item span:nth-child(2):not(.ant-checkbox-inner){
    color: rgb(1, 58, 129, .5);
}
.Collapse-Create-Profile .ant-collapse-content-box .Collapse-Submodule-Profile .ant-checkbox-wrapper span:nth-child(2):not(.ant-checkbox-inner){
    font-family: var(--font-family);
    font-size: 10px !important;
    line-height: 12.31px;
    color: var(--blue);
    margin-top: 0;
}
.Form-Item-Modules-Profile-Types .Collapse-Create-Profile .ant-collapse-content-box .Collapse-Submodule-Profile .ant-checkbox-wrapper span:nth-child(2):not(.ant-checkbox-inner), .Container-User-Modules .Collapse-Create-Profile .ant-collapse-content-box .Collapse-Submodule-Profile .ant-checkbox-wrapper span:nth-child(2):not(.ant-checkbox-inner){
    color: rgb(1, 58, 129, .5);
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper-in-form-item:hover {
    background-color: var(--light-blue) !important;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper-in-form-item .ant-checkbox-inner, .Collapse-Create-Profile .ant-collapse-content .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
    box-shadow: none !important;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper-in-form-item:hover .ant-checkbox-inner, .Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper:hover .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
    box-shadow: none !important;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper-in-form-item .ant-checkbox-checked::after, .Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper .ant-checkbox-checked::after{
    border: none !important;
}
.Collapse-Create-Profile .ant-collapse-header .ant-checkbox-checked::after{
    border: none !important;
}
.Collapse-Create-Profile .ant-collapse-content .ant-checkbox-wrapper.Submodule-Item{
    display: flex;
    align-items: center;
    padding-left: 12px !important;
    padding-bottom: 4px;
    padding-top: 4px;
}
.Collapse-Create-Profile .Submodule-Item{
    display: flex;
    align-items: center;
}
.Collapse-Create-Profile .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Collapse-Create-Profile .ant-collapse-header{
    padding: 6px 14px 6px 12px !important;
    align-items: center !important;
}
.Collapse-Create-Profile .ant-collapse-header .Header-Colapse-Create-Profile{
    display: flex;
    align-items: center;
}
.Collapse-Create-Profile .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{
    padding: 6px 12px !important;
}
.Collapse-Create-Profile .ant-collapse-item.ant-collapse-item-active .ant-collapse-expand-icon{
    transform: rotate(180deg) !important;
}
.Header-Colapse-Create-Profile .Text-Header-Collapse-Create-Profile{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-semibold) !important;
    font-size: 12px !important;
    color: var(--blue) !important;
    line-height: 14.52px;
    margin-left: 12px;
    margin-top: 6px;
}
.Form-Item-Modules-Profile-Types .Header-Colapse-Create-Profile .Text-Header-Collapse-Create-Profile, .Container-User-Modules .Header-Colapse-Create-Profile .Text-Header-Collapse-Create-Profile{
    color: rgb(1, 58, 129, .5) !important;
}
.Collapse-Submodule-Profile{
    border: 0 !important;
    background-color: var(--white);
}
.Collapse-Submodule-Profile .ant-collapse-item{
    border: 0;
    padding-bottom: 0 !important;
}
.Collapse-Submodule-Profile.Submodule-Profile .ant-collapse-item .ant-collapse-header{
    border-radius: 0 !important;
    padding: 4px 15px 4px 12px !important;
}
.Collapse-Submodule-Profile .ant-collapse-header:hover{
    background-color: #EDF2FF;
}
.Collapse-Submodule-Profile .Text-Header-Collapse-Create-Profile{
    color: var(--blue);
    font-weight: var(--weight-regular) !important;
    font-size: 11px !important;
    line-height: 13.31px;
    margin-top: 4px;
}
.Header-Colapse-Create-Profile.Check-Active-Module .Text-Header-Collapse-Create-Profile, .Collapse-Submodule-Profile .Header-Colapse-Create-Profile.Check-Active-Submodule .Text-Header-Collapse-Create-Profile{
    color: var(--blue-elec) !important;
}
.Collapse-Submodule-Profile .ant-collapse-content-box{
    padding: 0 !important;
}
.Collapse-Create-Profile .ant-checkbox-wrapper.Submodule-Item.Check-Active-Submodule span:nth-child(2), .Collapse-Submodule-Profile .ant-checkbox-wrapper.Subsubmodule-Item.Check-Active-Subsubmodule span:nth-child(2){
    color: var(--blue-elec) !important;
}
.Collapse-Submodule-Profile.Submodule-Profile .ant-collapse-item .ant-collapse-expand-icon{
    transform: rotate(0) !important;
}
.Collapse-Submodule-Profile.Submodule-Profile .ant-collapse-item.ant-collapse-item-active .ant-collapse-expand-icon{
    transform: rotate(180deg) !important;
}
.Header-Colapse-Create-Profile .ant-checkbox .ant-checkbox-inner{
    border: 2px solid rgba(42, 210, 149, 0.5) !important;
    
}
.Container-Checkbox-Create-Profile .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Header-Colapse-Create-Profile .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Header-Colapse-Create-Profile .ant-checkbox-checked .ant-checkbox-inner::after{
    inset-inline-start: 18.5% !important;
}
.Checkbox-SelectAll-Create-Profile .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
.Checkbox-SelectAll-Create-Profile .ant-checkbox-checked .ant-checkbox-inner::after{
    inset-inline-start: 18.5% !important;
}
.Checkbox-SelectAll-Create-Profile .ant-checkbox-checked::after{
    border: 0 !important;
}
.Container-Checkbox-Create-Profile .ant-checkbox-checked::after{
    border: 0 !important;
}
.Collapse-Create-Profile .ant-collapse-content-box .ant-checkbox-wrapper-in-form-item :not(:nth-child(1)){
    padding-inline-start: 12px !important;
    margin-top: -1px;
}
.Form-Create-Profile-Container-Button-Save{
    display: flex;
    justify-content: end;
    column-gap: 20px;
}
.Form-Create-Profile-Container-Button-Save .Form-Create-Profile-Button-Save{
    padding: 15px 31px;
    width: 118px;
    height: 48px;
    background-color: var(--green);
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 27px;
    align-items: center;
    font-family: var(--font-family);
    font-size: var(--size-regular);
    font-weight: var(--weight-bold);
    color: var(--white);
    outline: 0;
    border: 0;
    cursor: pointer;
}
.Create-Profile-Container-Right{
    padding-left: 36px;
}
.Form-Create-Profile-Container-Button-Save .Button-Cancel-Profile, .Form-Create-Profile-Container-Button-Save .Button-New-Profile{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 14.52px;
    border-radius: 27px;
    padding: 11px 15px;
    border: 1px solid var(--green);
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    margin: 0;
    overflow: hidden;
    height: auto;
    position: relative;
}
.Form-Create-Profile-Container-Button-Save .Button-Cancel-Profile{
    background-color: var(--white);
    color: var(--green);
}
.Form-Create-Profile-Container-Button-Save .Button-New-Profile{
    background-color: var(--green);
    color: var(--white);
}
.Form-Create-Profile-Container-Button-Save .Button-New-Profile.Button-Disabled{
    opacity: 0.5 !important;
    cursor: not-allowed  !important;
    pointer-events: none !important;
}
.Form-Create-Profile-Container-Button-Save .Button-Cancel-Profile .Text-Cancel, .Form-Create-Profile-Container-Button-Save .Button-New-Profile .Text-New{
    transform: translate(0, 5%);
    transition: .7s;
}
.Form-Create-Profile-Container-Button-Save .Button-Cancel-Profile .Text-Cancel-Effect, .Form-Create-Profile-Container-Button-Save .Button-New-Profile .Text-New-Effect{
    position: absolute;
    transform: translate(0, 190%);
    transition: .7s;
}
.Form-Create-Profile-Container-Button-Save .Button-Cancel-Profile:hover .Text-Cancel, .Form-Create-Profile-Container-Button-Save .Button-New-Profile:hover .Text-New{
    transform: translate(0, -190%);
    transition: .7s;
}
.Form-Create-Profile-Container-Button-Save .Button-Cancel-Profile:hover .Text-Cancel-Effect, .Form-Create-Profile-Container-Button-Save .Button-New-Profile:hover .Text-New-Effect{
    transform: translate(0, 5%) !important;
    transition: .7s !important;
}
.Form-Create-Profile-Container-Button-Save .Button-New-Profile .Spin-Loading{
    display: flex;
}
.Modal-Create-Success .ant-modal-content{
    padding: 22px 16px;
    border-radius: 20px;
}
.Modal-Edit-Profile .ant-modal-content{
    padding: 15px;
    border-radius: 20px;
}
.Modal-Create-Success .ant-modal-confirm-title, .Modal-Edit-Profile .ant-modal-confirm-title{
    font-family: var(--font-family);
    color: var(--green) !important;
    line-height: 16.94px;
    text-align: center;
    font-size: 12px !important;
}
.Modal-Create-Success .ant-modal-confirm-content, .Modal-Edit-Profile .ant-modal-confirm-content{
    font-family: var(--font-family);
    color: var(--blue) !important;
    font-size: 12px !important;
    line-height: 14.52px;
    text-align: center;
    margin-block-start: 0 !important;
    max-width: 100% !important;
}
.Modal-Create-Success .ant-modal-confirm-btns{
    margin-top: 13px !important;
    text-align: center;
}
.Modal-Create-Success .ant-modal-confirm-btns .ant-btn, .Modal-Edit-Profile .ant-modal-confirm-btns .ant-btn{
    border-radius: 20px;
    padding: 11px 16px !important;
    height: auto !important;
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14.52px;
    font-weight: var(--weight-bold);
    box-shadow: none !important;
}
.Modal-Edit-Profile .ant-modal-confirm-btns .ant-btn{
    padding: 11px 14px !important;
}
.Modal-Edit-Profile .ant-modal-confirm-btns{
    margin-top: 4px !important;
    text-align: center;
}
.Modal-Edit-Profile .Cancel-Button-Modal{
    border-color: rgb(42, 210, 149, .4);
    color: var(--green);
    margin-right: 22px;
}
.Modal-Edit-Profile .Cancel-Button-Modal:hover, .Modal-Edit-Profile .Cancel-Button-Modal:focus{
    border-color: rgb(42, 210, 149, .4);
    color: var(--green);
}
@media screen and (max-width: 991px) {
    .Create-Profile-Container-Right{
        padding-left: 0;
    }
}
@media screen and (max-width: 575px) {
    .Container-Checkbox-Create-Profile .ant-checkbox-wrapper-in-form-item{
        margin-right: 10px !important;
    }
}
.Modal-Terms-Conditions .ant-modal-content{
    border-radius: 20px !important;
    padding: 20px 20px;
}
.Modal-Terms-Conditions .Container-Button-Close{
    display: flex !important;
    justify-content: center !important;
}
.Modal-Terms-Conditions .Modal-Container-Text-Main *{
    color: var(--green) !important;
    font-family: 14px !important;
    font-size: var(--size-regular) !important;
    display: flex;
    font-weight: var(--weight-bold) !important;
}
.Modal-Terms-Conditions .Modal-Container-Text-Main{
    display: flex;
    justify-content: center;
    padding: 2px 0 0 0;
}
.Modal-Terms-Conditions .Modal-Container-Text-Description *{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}
.Modal-Terms-Conditions .Modal-Container-Text-Description{
    line-height: 14px;
    margin: 0 0 13px 0px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: initial !important;
    text-align: center;
}
.Modal-Terms-Conditions .Notification-Button-Ok{
    border-radius: 20px;
    background-color: var(--green);
    padding: 10px 12px;
    width: -moz-fit-content;
    width: fit-content;
    color: #ffffff;
    font-size: 11px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    justify-content: center;
    cursor: pointer;
}