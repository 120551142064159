.Modal-Notification-Global .ant-modal-content{
    border-radius: 20px !important;
    font-family: var(--font-family) !important;
    padding: 20px 20px !important;
}
.Modal-Notification-Global .Modal-Container-Text-Main *{
    display: flex !important;
    justify-content: center !important;
    font-size: 12px !important;
    color: var(--green) !important;
}

.Modal-Notification-Global .Modal-Container-Text-Description{
    margin-bottom: 10px !important;
    font-size: 12px !important;
    font-family: var(--font-family) !important;
}
.Modal-Notification-Global .Modal-Container-Text-Description div{
    display: flex !important;
    justify-content: center !important;
    text-align: center;
    line-height: 14px !important;
    color: var(--blue) !important;
}

.Modal-Notification-Global .Container-Button-Close{
    display: flex !important;
    justify-content: center !important;
    column-gap: 15px;
}

.Modal-Container-Text-Main h1{
    margin-bottom: 0px !important;
}

.Modal-Notification-Global .Notification-Button-Global{
    border-radius: 20px !important;
    padding: 10px 12px;
    width: fit-content;
    color: #ffffff;
    font-size: 11px;
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    justify-content: center;
    cursor: pointer;
}

.Modal-Notification-Global .Notification-Button-Global.Button-Cancel{
    border: 1px solid var(--green) !important;
    color: var(--green) !important;
}
.Modal-Notification-Global .Notification-Button-Global.Button-Ok{
    background-color: var(--green);
}
