.Modal-Item-Notification-Upload-File .ant-modal-close{
    display: none;
}
.Modal-Item-Notification-Upload-File .ant-modal-content{
    background-color: var(--light-blue) !important;
    border-radius: 20px !important;
    padding: 20px 30px;
}

.Modal-Item-Notification-Upload-File .Button-Action-Notification{
    width: 31px;
    height: 31px;
    border: 2px solid rgba(42, 210, 149);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    position: absolute;
    transition: all 0.2s ease-in-out;
}

.Modal-Item-Notification-Upload-File .Button-Action-Notification.Select-Prev{
    top: 0;
    left: 0;
}

.Modal-Item-Notification-Upload-File .Button-Action-Notification.Select-Next{
    top: 0;
    right: 0;
}

.Modal-Item-Notification-Upload-File .Button-Action-Notification:not(.Disable-Action):hover{
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    width: 43px;
    height: 43px;
    border-color: rgba(42, 210, 149);
}

.Modal-Item-Notification-Upload-File .Button-Action-Notification.Select-Prev:not(.Disable-Action):hover{
    top: -5px;
    left: -5px;
}

.Modal-Item-Notification-Upload-File .Button-Action-Notification.Select-Next:not(.Disable-Action):hover{
    top: -5px;
    right: -5px;
}

.Modal-Item-Notification-Upload-File .Button-Action-Notification.Disable-Action{
    border: 2px solid #9aebce;
    cursor: not-allowed;
}

.Button-Action-Notification.Select-Prev .Container-Icon{
    content: url('../../../Assets//Images/UploadFile/arrow-next-prev.svg') !important;
    width: 5px;
    height: 9px;
    transform: rotate(180deg);
}
.Button-Action-Notification.Select-Prev.Disable-Action .Container-Icon{
    content: url('../../../Assets//Images/UploadFile/arrow-next-prev-disable.svg') !important;
    transform: rotate(180deg);
}

.Button-Action-Notification.Select-Next:not(.Disable-Action):hover .Container-Icon{
    content: url('../../../Assets//Images/UploadFile/arrow-next-prev-hover.svg') !important;
}
.Button-Action-Notification.Select-Prev:not(.Disable-Action):hover .Container-Icon{
    content: url('../../../Assets//Images/UploadFile/arrow-next-prev-hover.svg') !important;
    transform: rotate(180deg);
}

.Button-Action-Notification div{
    transition: all 0.2s ease-in-out;
}
.Button-Action-Notification:not(.Disable-Action):hover div{
    background-color: var(--green);
}
.Button-Action-Notification div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.Button-Action-Notification.Select-Next .Container-Icon{
    width: 5px;
    height: 9px;
    content: url('../../../Assets//Images/UploadFile/arrow-next-prev.svg') !important;
}
.Button-Action-Notification.Select-Next.Disable-Action .Container-Icon{
    content: url('../../../Assets//Images/UploadFile/arrow-next-prev-disable.svg') !important;
}
.Modal-Item-Notification-Upload-File .Title-Notificacion-File{
    font-family: var(--font-family);
    text-align: center;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: var(--weight-bold);
    color: var(--blue);
    margin-top: 12px;
}
.Container-Header-Notification-Upload-File .Container-Header-Title-Notification{
    width: 100%;
    justify-content: center;
}

.Container-Header-Title-Notification .Container-Image-Header{
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -200%;
}
.Container-Header-Title-Notification .Container-Image-Header img{
    position: relative !important;
    top: 42px !important;
    height: 45px;
    width: 45px;
}
.Container-Header-Notification-Upload-File{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.Container-Body-Notification-Upload-File{
    background-color: #FFFFFF;
    padding: 19px 0px;
    border-radius: 20px;
    max-height: 70vh;
    overflow-y: scroll;
    position: relative;
}

.Container-Text-Notification-Upload-File{
    font-family: var(--font-family);
    color: var(--blue);
    font-size: 12px;
    line-height: 14.52px;
    font-weight: var(--weight-regular);
    margin-bottom: 4px;
}

.Container-Text-Notification-Upload-File li{
    text-align: justify;
}

.Container-Body-Notification-Upload-File .Button-Deploy-Notification-File{
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: none;
    position: absolute;
    top: 10px;
    right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Container-Body-Notification-Upload-File .Button-Deploy-Notification-File:hover{
    background-color: #EDF2FF;
}
.Dropdown-Menu-Notification .ant-dropdown-menu-title-content{
    line-height: 23.8px;
}

.Dropdown-Menu-Notification .Delete-Notification-File{
    color: var(--blue-elec);
    padding: 0;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 7px;
}

.Container-Text-Notification-Upload-File .List-Description-Error{
    text-align: justify;
    list-style-type: none !important;
    margin-left: 20px;
    padding: 0;
}
.Container-Text-Notification-Upload-File .List-Description-Error li::marker {
    background: var(--blue) !important;
    content: "•  "; 
    color: var(--blue) !important;
    margin-left: 0;
}

.Dropdown-Menu-Notification .ant-dropdown{
    padding: 8px 0;
    z-index: 100000000 !important;
    padding: 10px !important;
}
.Dropdown-Menu-Notification .ant-dropdown-menu{
    padding: 4px 0;
    z-index: 100000000 !important;
    box-shadow: 0 4px 4px 0 #D9D9D9 !important;
}
.Dropdown-Menu-Notification .ant-dropdown-menu-item{
    padding: 0 8px !important;
}

.Modal-Item-Notification-Upload-File .Container-Date-Notification-Upload-File{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: var(--size-small);
    display: flex;
    padding-right: 30px;
    justify-content: end;
    color: rgba(1, 58, 129, 0.5);
}

.Container-Body-Notification-Upload-File::-webkit-scrollbar{
    width: 8px;
    height: 5px !important;
    padding-top: 20px;
}
.Container-Body-Notification-Upload-File::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 24px;
}
.Container-Body-Notification-Upload-File::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 0;
    margin-top: 30px;
    margin-bottom: 30px;
}