.Modal-Notification-Description-Table .ant-modal-content{
    border-radius: 20px !important;
    padding: 22px 24px;
    position: absolute;
    width: 100%;
}

.Form-Create-Msl-Select{
    width: 100%;
    z-index: 100000000 !important;
}

.Form-Create-Msl-DatePicker{
    z-index: 100000000 !important;
}

.Form-Create-Msl-Buttons-Action{
    display: flex;
    justify-content: center;
}
.Form-Create-Msl-Col-Datepicker{
    display: flex;
    justify-content: center;
}
.Modal-Container-Text-Create-MSL .Modal-Container-Text-Main *{
    color: var(--green) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-regular) !important;
    display: flex;
    font-weight: var(--weight-bold) !important;
}

.Modal-Container-Text-Create-MSL .Modal-Container-Text-Main{
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
}

.Modal-Create-MSL .Modal-Container-Form-Create-MSL{
    padding: 10px 0;
}
.Modal-Create-MSL .Form-Create-MSL-Button-Create{
    background-color: #2AD295;
}
.Modal-Create-MSL .Form-Create-MSL-Button-Create:hover{
    background-color: #2AD295;
}

.Form-Create-MSL-Item .ant-form-item-label label{
    font-size: var(--size-regular) !important;
    font-family: var(--font-family) !important;
    color: rgba(59, 114, 255, 0.8) !important;
}

.Form-Create-MSL-Item .ant-input,
.Form-Create-MSL-Item .ant-select-selector,
.Form-Create-MSL-Item .ant-picker
{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: var(--size-regular) !important;
    color: var(--blue) !important;
    border: 1px solid #D7DEF5 !important;
}
.Form-Create-MSL-Item .ant-picker-input input,
.Form-Create-Msl-Select .ant-select-item .ant-select-item-option-content{
    color: var(--blue) !important;
}
