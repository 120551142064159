.Modal-Notifications-Uploaded-Files{
    border-radius: 20px !important;
    padding: 22px 24px;
}
.Modal-Notifications-Uploaded-Files .Modal-Container-Text-Success{
    display: flex !important;
    justify-content: center !important;
    color: var(--green) !important;
    font-weight: var(--weight-bold) !important;
}

.Modal-Container-Text-Uploaded-Files .Modal-Container-Text-Main *{
    display: flex;
    justify-content: center;
    color: var(--green) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-medium) !important;
}
.Modal-Container-Error-Notification{
    margin-bottom: 10px;
}
.Modal-Container-Text-Uploaded-Files{
    margin-bottom: 10px;
}

.Modal-Container-Index-Error-Notification{
    color: red;
    font-weight: var(--weight-bold);
}

.Modal-Container-Detail-Error-Notification{
    margin-left: 30px;
    color: rgb(143, 6, 6);
}