

.Container-Master-Clients-Selected .Table-Global .ant-table-tbody .Row-Client-Manual .ant-table-cell{
    background-color: #c4d4ff !important;
    color: var(--blue) !important;
}

.Container-Master-Clients-Selected .Table-Global .ant-table-tbody .Row-Client-Manual:hover .ant-table-cell{
    background-color: #d7e2ff !important;
}
.Container-Master-Clients-Selected .Table-Global .ant-table-tbody .Row-Client-Manual.ant-table-row-selected .ant-table-cell{
    background-color: #EDF2FF !important;
}

.Container-Actions-Show-Clients{
    display: flex;
}

.Container-Master-Clients .Table-Global{
    max-height: 100%;
}

.Button-Show-Clients,
.Button-Show-Clients-Manual{
    padding: 5px;
    width: 24px;
    height: 24px;
    margin: 0 2px;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Button-Show-Clients{
    border: 1px solid var(--green);
}

.Button-Show-Clients-Manual{
    border: 1px solid var(--yellow);
}

.Button-Show-Clients.Cursor-Blocked,
.Button-Show-Clients-Manual.Cursor-Blocked{
    cursor: not-allowed;
}

.Button-Show-Clients-Manual .Content-Button-Show-Clients-Manual.Selected{
    background-color: var(--yellow);
}
.Button-Show-Clients .Content-Button-Show-Clients.Selected{
    background-color: var(--green);
}
.Button-Show-Clients .Content-Button-Show-Clients{
    display: flex;
    content: '';
    width: 12px;
    border-radius: 3px;
    height: 12px;
    background-color: #2ad2944f;
}
.Button-Show-Clients-Manual .Content-Button-Show-Clients-Manual{
    display: flex;
    content: '';
    width: 12px;
    border-radius: 3px;
    height: 12px;
    background-color: #ffc80041;
}