



.Row-File-No-Process .Cell-Action-Process-File{
    display: none !important;
    /* background-color: yellow !important; */
}
.Row-File-No-Process:hover .Cell-Action-Process-File{
    display: block !important;
    /* background-color: red; */
}
.Row-File-No-Process:hover .Cell-Information-No-Process-Uploaded-File{
    display: none !important;
}

.Uploaded-Files .Icon-Notification-Blue{
    color: blue;
}
.Uploaded-Files .Icon-Notification-Red{
    color: red;
}

.PopOver-Confirm-Delete-Uploaded-Files .ant-popover-inner{
    padding: 8px 8px !important;
}
.ant-popconfirm-buttons .ant-btn-primary{
    background-color: var(--green) !important;
}
.Uploaded-Files{
    transition: all .1s ease-in-out;
}
.Uploaded-Files:hover{
    transform: scale(1.1);
}
