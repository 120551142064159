.Modal-Notification-Alert-MSL .ant-modal-content{
    border-radius: 20px !important;
    padding: 22px 24px;
    position: absolute;
    width: 100%;
}

.Modal-Container-Alert-MSL .Modal-Container-Button-Main{
    display: flex !important;
    justify-content: center !important;
}

.Modal-Notification-Alert-MSL .Modal-Close-Alert-MSL{
    border-radius: 100%;
    padding: 8px 10px;
    width: fit-content;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 700;
    justify-content: center;
    cursor: pointer;
}

.Modal-Notification-Alert-MSL .Modal-Close-Alert-MSL{
    background-color: var(--yellow);
}

.Modal-Container-Alert-MSL .Modal-Container-Text-Description *{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-regular) !important;
    font-weight: var(--weight-regular) !important;
}

.Modal-Container-Alert-MSL .Modal-Container-Text-Description{
    margin: 13px 0px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: initial !important;
}
.Modal-Container-Alert-MSL .Modal-Container-Text-Description p:not(:first-child){
    margin-bottom: 0;
}

.Modal-Container-Alert-MSL .Modal-Container-Text-Main .ant-typography{
    margin-bottom: 0;
}
.Modal-Container-Alert-MSL .Modal-Container-Text-Main{
    display: flex;
    justify-content: center;
}

.Modal-Container-Alert-MSL .Modal-Container-Text-Main *{
    color: var(--green) !important;
    font-family: var(--font-family) !important;
    font-size: var(--size-regular) !important;
    display: flex;
    font-weight: var(--weight-bold) !important;
}