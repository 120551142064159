.Create-User-Form-Item-Text .ant-form-item-label label{
    font-size: var(--size-regular) !important;
    font-family: var(--font-family) !important;
    color: rgba(59, 114, 255, 0.8) !important;
}

.Create-User-Form-Item-Text{
    width: 100% !important;
    margin-bottom: 14px !important;
}

.Create-User-Form-Item-Text .ant-picker{
    width: 100%;
}

.Create-User-Form-Item-Text .ant-picker:hover{
    border: 1px solid #D7DEF5 !important;
}

.Create-User-Form-Item-Text .ant-picker-focused{
    border-color: #D7DEF5 !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input > input{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: var(--size-regular) !important;
    color: var(--blue) !important;
}
.Create-User-Form-Item-Text .ant-picker .ant-picker-input:hover > input{
    border: none !important;
}
.Create-User-Form-Item-Text .ant-input,
.Create-User-Form-Item-Text .ant-input-number{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: var(--size-regular) !important;
    color: var(--blue) !important;
    border: 1px solid #D7DEF5 !important;
}

.Create-User-Form-Item-Text .ant-input-number .ant-input-number-input{
    color: var(--blue) !important;
}
.Create-User-Form-Item-Text .ant-input-number{
    width: 100%;
}

.Create-User-Input-Password .ant-input{
    border: none !important;
}


.Form-Create-User-Col-Left{
    padding-right: 10px;
}
.Form-Create-User-Col-Right{
    padding-left: 10px;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox .ant-checkbox-inner{
    border: 2px solid rgba(1, 58, 129, 0.4) !important;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox-checked .ant-checkbox-inner::after{
    border-color:  #ffffff !important;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item .ant-checkbox-checked .ant-checkbox-inner{
    border: 2px solid var(--green) !important;
    background-color: var(--green) !important;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item .ant-checkbox-checked .ant-checkbox-inner::after{
    inset-inline-start: 16.5% !important;
}
.Create-User-Container-Checkbox-Countries .ant-checkbox-checked:after{
    border: none !important;
}

.Create-User-Container-Checkbox-Countries .ant-checkbox-group-item{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: var(--size-regular) !important;
    color: rgba(1, 58, 129, 0.4) !important;
    margin-right: 30px !important;
}

.Create-User-Container-Checkbox-Status{
    user-select: none;
}

.Create-User-Container-Checkbox-Status .ant-radio-wrapper :last-child{
    font-family: var(--font-family) !important;
    font-weight: var(--weight-regular) !important;
    font-size: var(--size-regular) !important;
    color: var(--blue) !important;
}

.Create-User-Container-Checkbox-Status .ant-radio-inner{
    border: 2px solid var(--blue) !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-checked .ant-radio-inner{
    border: 2px solid var(--green) !important;
    background-color: #ffffff !important;
}
.Create-User-Container-Checkbox-Status .ant-radio-checked .ant-radio-inner::after{
    background-color: var(--green) !important;
}
.Create-User-Form-Container-Button{
    border-radius: 27px !important;
    padding: 15px 31px !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    font-size: var(--size-regular) !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer;
    height: 50px !important;
}

.Create-User-Container-Button-Action .Create-User-Form-Button-Save{
    background-color: var(--green) !important;
    color: #ffffff;
    border: none;
    margin-left: 23px !important;
    height: auto !important;
}

.Create-User-Container-Button-Action .Create-User-Form-Button-Cancel{
    background-color: #ffffff !important;
    border: 2px solid rgba(42, 210, 149, 0.4);
    color: var(--green);
}
.Create-User-Container-Button-Action{
    display: flex !important;
    flex-direction: row !important;
}


.Create-User-Container-Button-Action .Create-User-Form-Button-Cancel:focus, 
.Create-User-Container-Button-Action .Create-User-Form-Button-Cancel:hover{
    color: var(--green) !important;
}

.Create-User-Container-Button-Action .Create-User-Form-Button-Cancel span:first-child{
    transform: translate(0, 0);
    transition: .7s;
    opacity: 1;
}

.Create-User-Container-Button-Action .Create-User-Form-Button-Cancel .Text-Span-Cancel-Create-User{
    position: absolute;
    transform: translate(0, 100%);
    opacity: 0;
    transition: .7s;
}

.Create-User-Container-Button-Action .Create-User-Form-Button-Cancel:hover span:first-child{
    transform: translate(0, -100%);
    transition: .7s;
    opacity: 0;
}

.Create-User-Container-Button-Action .Create-User-Form-Button-Cancel:hover .Text-Span-Cancel-Create-User{
    transform: translate(0, 0) !important;
    transition: .7s !important;
    opacity: 1 !important;
}

.Header-Form-User .ant-collapse-content.ant-collapse-content-active{
    border-top: #FFFFFF 1px solid !important;
}

.Header-Form-User.ant-collapse-item.ant-collapse-item-active{
    padding-bottom: 0 !important;
}

.Collapse-Form-User .Header-Form-User .ant-collapse-header{
    background: #FFFFFF !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    display: flex;
    font-size: 12px !important;
    height: 39px !important;
    align-items: center;
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
}

.Collapse-Form-User .Container-Option-Select-Country{
    color: var(--blue) !important;
    font-family: var(--font-family) !important;
    font-size: 12px !important;
    padding-left: 12px !important;
    padding-top: 1px;
    padding-bottom: 1px;
    cursor: pointer;
}
.Collapse-Form-User .ant-collapse-content-box{
    padding: 0 !important;
}

.Collapse-Form-User .ant-collapse-content::-webkit-scrollbar{
    width: 8px;
    background-color: transparent !important;
    margin: 20px 0 !important;
    border-radius: 100% !important;
}
.Collapse-Form-User .ant-collapse-content::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 10px !important;
    padding: 10px 0 !important;
}

.Collapse-Form-User .ant-collapse-content::-webkit-scrollbar-track{
    background-color: transparent !important;
    border-radius: 10px !important;
    margin: 10px 20px !important;
}

.Collapse-Form-User .ant-collapse-content{
    margin: 0 !important;
    max-height: 80px !important;
    overflow-y: scroll !important;
}

.Collapse-Form-User .Container-Option-Select-Country:hover{
    background-color: var(--light-blue) !important;
    color: var(--blue-elec) !important;
}
.Image-Country-Select.Country-Select{
    margin-right: 25px !important;
}
.Image-Country-Select{
    width: 20px !important;
    height: auto !important;
}

.Collapse-Form-User .Input-Phone-Select.ant-input{
    border: none !important;
}

@media screen and (max-width: 991px) {
    .Form-Create-User-Col-Left{
        padding-right: 0;
    }
    .Form-Create-User-Col-Right{
        padding-left: 0;
    }
    .Create-User-Container-Checkbox-Countries .ant-checkbox-group-item{
        margin-right: 10px !important;
    }
}