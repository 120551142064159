
.Collapse-Table-Incentiv-Program.ant-collapse{
    border-radius: 0 0 8px 8px !important;
}

.Collapse-Table-Container-Header .Column-Secondary-Table-Collapse:not(:last-child){
    border-right: 1px solid #d9d9d9 !important;
}

.Row-Data-Table-Collapse .ant-collapse-content-box .Row-Data-Table-Collapse:last-child .Cell-Data-Table-Collapse{
    border-bottom: none !important;
}
.Row-Data-Table-Collapse .Cell-Data-Table-Collapse:not(:last-child){
    border-right: 1px solid #d9d9d9 !important;
}

.Input-Cell-Data-Edit-Left{
    width: 30px;
    font-size: 11px;
    color: #013A81;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    outline: none !important;
    height: 19px;
}
.Input-Cell-Data-Edit-Left .ant-input-number-input{
    color: var(--blue) !important;
    height: 17px !important;
    padding: 0 2px !important;
}
.Input-Cell-Data-Edit-Right .ant-input-number-input{
    color: var(--blue) !important;
    height: 17px !important;
    padding: 0 2px !important;
}
.Input-Cell-Data-Edit-Left .ant-input-number-handler-wrap{
    display: none !important;
}

.Input-Cell-Data-Edit-Right .ant-input-number-handler-wrap{
    display: none !important;
}
.Input-Cell-Data-Edit-Revenue .ant-input-number-input{
    color: var(--blue) !important;
    display: flex;
    text-align: right;
    height: 17px !important;
}
.Input-Cell-Data-Edit-Revenue .ant-input-number-handler-wrap{
    display: none !important;
}

.Cell-Data-Table-Collapse.Cell-Left .Separation-Input{
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    height: 19px;
}
.Input-Cell-Data-Edit-Right{
    height: 19px;
    width: 30px;
    font-size: 11px;
    color: #013A81;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 0 2px !important;
}
.Cell-Data-Table-Collapse.Cell-Left{
    display: flex !important;
    justify-content: flex-start !important;
}
.Cell-Data-Table-Collapse.Cell-Right{
    display: flex !important;
    justify-content: flex-end !important;
}

.Cell-Data-Table-Collapse.Container-Actions{
    padding: 3px 2px !important;
}

.Title-Collapse-Incentiv-Program{
    font-size: 11px !important;
    background-color: var(--blue-elec);
    color: #FFFFFF !important;
    padding: 6px 10px;
    cursor: pointer;
    border-left: 1px solid #3B72FF;
    border-top: 1px solid #3B72FF;
    border-right: 1px solid #3B72FF;
    font-weight: var(--weight-bold);
    border-radius: 8px 8px 0 0 !important;
    font-family: var(--font-family);
    display: flex;
    justify-content: space-between;
    transition: 0.3s !important;
}

.Title-Collapse-Incentiv-Program.Item-Active,
.Title-Collapse-Incentiv-Program:hover{
    background-color: var(--blue) !important;
    border-left: 1px solid #013A81;
    border-top: 1px solid #013A81;
    border-right: 1px solid #013A81;
}

.Collapse-Table-Incentiv-Program .ant-collapse-header{
    padding: 2px 0 !important;
    background-color: #FFFFFF;
    border-radius: 0 0 8px 8px !important;
}
.Collapse-Table-Incentiv-Program{
    margin: 0 0 10px 0 !important;
}

.Collapse-Table-Incentiv-Program .ant-collapse-header{
    cursor: context-menu !important;
}

.Collapse-Table-Incentiv-Program .Column-Secondary-Table-Collapse{
    color: var(--green);
    font-weight: var(--weight-bold);
    display: flex;
    justify-content: center;
    padding: 0 5px;
}

.Collapse-Table-Incentiv-Program .ant-collapse-item.ant-collapse-item-active{
    padding-bottom: 0 !important;
}
.Collapse-Table-Incentiv-Program .ant-collapse-content-box{
    padding: 0 !important;
}

.ant-row .Collapse-Table-Container-Header:not(:last-child){
    border-right: 1px solid red !important;
}

.Collapse-Table-Incentiv-Program .Collapse-Table-Container-Header{
    display: flex;
    justify-content: space-around;
}

.Collapse-Table-Incentiv-Program .Cell-Data-Table-Collapse{
    display: flex !important;
    justify-content: center;
    font-size: 9px;
    padding: 3px 5px;
    border-bottom: 1px solid #f0f0f0;
    font-family: var(--font-family) !important;
    font-weight: var(--weight-semibold);
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
}

.Row-Data-Table-Collapse{
    color: var(--blue); 
    font-weight: 500;
    display: flex;
    justify-content: space-around;
}

.Cell-Column-Secondary-Title{
    font-family: var(--font-family);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 10px !important;
}

.Info-Tooltip-Cell-Data-Table-Collapse .ant-tooltip-inner{
    padding: 4px 8px !important;
    display: flex;
    align-items: center;
}
.Info-Tooltip-Cell-Data-Table-Collapse{
    font-size: 11px;
    font-family: var(--font-family);
    max-width: 350px;
}

.Confirm-Action-Incentive-Program .ant-modal-content{
    padding: 18px 22px !important;
}
.Confirm-Action-Incentive-Program .ant-btn span{
    font-size: 13px !important;
}
.Confirm-Action-Incentive-Program .ant-modal-confirm-content{
    font-size: 13px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-semibold);
}
.Confirm-Action-Incentive-Program .ant-modal-confirm-title{
    font-size: 14px !important;
    color: var(--green) !important;
}