.Title-Box-Form-Channel-Executive{
    border: 0 !important;
    font-size: 14px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-semibold) !important;
    margin-bottom: 5px !important;
    text-align: center;
    padding: 3px 8px;
    border-radius: 5px;
    font-family: var(--font-family);
}

.Button-Show-Filter-Channel-Executive{
    height: 22px !important;
    width: 22px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 0 !important;
    cursor: pointer;
}

.Select-Executive-Form .ant-select-item-option-content{
    font-size: 11px !important;
}


.Select-Simple-Executive-Form .ant-select-selector .ant-select-selection-placeholder{
    font-size: 13px !important;
}

.Select-Simple-Executive-Form.Select-Executive{
    width: 180px !important;
}
.Select-Executive-Form.ant-select-dropdown .ant-select-item{
    padding: 2px 12px !important;
}

.Select-Executive-Form .ant-select-item{
    /* padding: 1px 10px !important; */
    min-height: 22px !important;
}

.Select-Executive-Form *{
    color : var(--blue) !important;
}

.Box-Form-Channel-Executive .ant-table-tbody .ant-table-cell{
    padding: 0 10px !important;
    color: var(--blue) !important;
    font-size: 11px !important;
}
.Box-Form-Channel-Executive .ant-table-thead .ant-table-cell{
    padding: 0 10px !important;
    color: var(--green) !important;
    font-size: 12px !important;
    background-color: #FFFFFF !important;
}

.Box-Form-Channel-Executive{
    border: 1px solid #3b72ff7b !important;
    border-radius: 10px 10px 0 0 !important;
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    position: relative;
    padding-bottom: 15px;
}


.Box-Form-Channel-Executive.Table-Selected{
    padding-bottom: 5px;
}

.Container-Box-Form-Channels{
    display: flex !important;
    flex-direction: column;
}

.Container-Info-Channels-Executives{
    max-height: 65vh;
    overflow-y: scroll;
    padding: 0 10px;
    margin-top: 12px !important;
    padding-bottom: 10px !important;
}

.Container-Info-Channels-Executives .ant-table-body::-webkit-scrollbar,
.Container-Info-Channels-Executives.Selection-Rows::-webkit-scrollbar{
    width: 7px !important;
    height: 7px !important;
    background-color: #FFFFFF;
    margin: 10px 0 !important;
}

.Container-Info-Channels-Executives .ant-table-body::-webkit-scrollbar-thumb,
.Container-Info-Channels-Executives.Selection-Rows::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 10px !important;
    padding: 10px 0 !important;
}
.Container-Info-Channels-Executives .ant-table-body::-webkit-scrollbar-track,
.Container-Info-Channels-Executives.Selection-Rows::-webkit-scrollbar-track{
    background-color: transparent !important;
    border-radius: 10px !important;
    margin: 10px 20px !important;
}

.Container-Box-Form-Channels .Container-Info-Channels-Executives.Selection-Rows::-webkit-scrollbar{
    width: 10px;
    background-color: #FFFFFF;
    margin: 20px 0 !important;
    border-radius: 10px !important;
}
.Container-Box-Form-Channels .Container-Info-Channels-Executives.Selection-Rows::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 10px !important;
    padding: 10px 0 !important;
}

.Container-Box-Form-Channels .Container-Info-Channels-Executives.Selection-Rows::-webkit-scrollbar-track{
    background-color: transparent !important;
    border-radius: 10px !important;
    margin: 10px 20px !important;
}

.Container-Box-Form-Channels .Container-Info-Channels-Executives.Table-Selected{
    overflow-y:hidden;
}


.Box-Form-Channel-Executive .ant-table-thead th.ant-table-column-sort{
    background-color: none !important;
}
.Box-Form-Channel-Executive .ant-table-thead th.ant-table-column-has-sorters:hover{
    background-color: #FFFFFF !important;
}

.Box-Form-Channel-Executive .ant-table-column-title{
    font-size: 11px !important;
}
.Box-Form-Channel-Executive .Table-Contain-Text-Global{
    font-size: 10px !important;
    font-family: var(--font-family);
}
.Box-Form-Channel-Executive .Table-Contain-Text-Global-Form-Channel-Executives{
    font-size: 10px !important;
    font-family: var(--font-family);
}

.DropDown-Filter-Table-Channels-Executives input{
    font-size: 14px !important;
}
.DropDown-Filter-Table-Channels-Executives{
    padding: 5px !important;
}

.Button-Save-Form-Channels-Executive{
    background-color: var(--green) !important;
    color: #FFFFFF !important;
    border: none !important;
    border-radius: 5px !important;
    font-size: 12px !important;
}

.Button-Reset-Form-Channels-Executive{
    background-color: var(--yellow) !important;
    color: #FFFFFF !important;
    border: none !important;
    border-radius: 5px !important;
    font-size: 12px !important;
}

.DatePicker-Executive-Form .ant-picker-input input{
    font-size: 13px !important;
    color: var(--blue);
}
.DatePicker-Executive-Form{
    height: 24px !important;
    width: 120px !important;
}

.Input-Filter-Executive-Form{
    font-size: 12px !important;
}

.Row-Table-Clients-Selected:hover{
    content: "Presione para elimianr";
    width: 100%;
}

.Container-Info-Channels-Executives .ant-table-tbody >tr.ant-table-row-selected >td{
    background: #FFFFFF !important;
}

.Container-Info-Channels-Executives .ant-table-row-selected>td:first-child{
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
}
.Container-Info-Channels-Executives .ant-table-row-selected>td:last-child{
    border-start-end-radius: 0 !important;
    border-end-end-radius: 0 !important;
}

.Container-Info-Channels-Executives .ant-checkbox-wrapper {
    font-size: 12px !important;
}

.Container-Info-Channels-Executives .ant-checkbox-inner {
    width: 13px !important;
    height: 13px !important;
    border-radius: 3px !important;
}

.Container-Info-Channels-Executives .ant-checkbox-inner::after {
    font-size: 10px !important;
}

.Container-Info-Channels-Executives .ant-checkbox-checked .ant-checkbox-inner {
    width: 13px !important;
    height: 13px !important;
    border-radius: 3px !important;
}

.Confirm-Action-Channel-Executive .ant-modal-content{
    padding: 18px 22px !important;
}
.Confirm-Action-Channel-Executive .ant-btn span{
    font-size: 13px !important;
}
.Confirm-Action-Channel-Executive .ant-modal-confirm-content{
    font-size: 13px !important;
    color: var(--blue) !important;
    font-weight: var(--weight-semibold);
}
.Confirm-Action-Channel-Executive .ant-modal-confirm-title{
    font-size: 14px !important;
    color: var(--green) !important;
}

.DatePicker-Form-Channel-Executives .ant-picker-body{
    padding: 0 !important;
}
.DatePicker-Form-Channel-Executives ,
.DatePicker-Form-Channel-Executives .ant-picker-body,
.DatePicker-Form-Channel-Executives .ant-picker-header{
    width: 250px !important;
}

.DatePicker-Form-Channel-Executives .ant-picker-content td{
    width: 30px !important;
}
.DatePicker-Form-Channel-Executives .ant-picker-content {
    margin: 2px !important;
    height: 5px !important;
}

.Row-Table-Clients-Selected.Client-Default .ant-table-cell{
    color : #ffae00 !important;
}