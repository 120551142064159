.Container-Card-Analytics{
    border: 1px solid var(--lead);
    border-radius: 20px;
    margin-bottom: 20px;
}
.Container-Card-Analytics .ant-card-body{
    padding: 0 !important;
}
.Container-Card-Analytics .Title-Analytics{
    font-family: var(--font-family);
    font-size: 16px !important;
    line-height: 27px;
    font-weight: var(--weight-bold);
    color: var(--green);
    padding-top: 10px;
    padding-left: 28px;
    padding-right: 28px;
    margin-bottom: 4px !important;
}
.Container-Card-Analytics .Container-Table-Analytics{
    width: 100%;
}
.Container-Card-Analytics .Container-Table-Analytics thead th:first-child{
    width: 15%;
}
.Container-Card-Analytics .Container-Table-Analytics thead th{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    line-height: 17px;
    color: var(--blue);
    padding-bottom: 8px;
    width: 20%;
}
.Container-Card-Analytics .Container-Table-Analytics thead th:nth-child(2){
    width: 45%;
}
.Container-Card-Analytics .Container-Table-Analytics tbody tr{
    border-top: 1px solid var(--lead);
}
.Container-Card-Analytics .Container-Table-Analytics tbody .Last-Row:last-child{
    border-bottom: 1px solid var(--lead);
}
.Container-Card-Analytics .Container-Table-Analytics tbody td{
    font-family: var(--font-family);
    text-align: center;
    line-height: 17px;
    color: var(--blue);
    padding: 8px 0;
}
.Container-Card-Analytics .Container-Table-Analytics tbody td:nth-child(2){
    text-align: start;
}

.Container-Card-Analytics .Container-Table-Analytics *{
    font-size: 12px !important;
}
.Container-Card-Analytics .Text-See-More{
    font-family: var(--font-family);
    font-weight: var(--weight-bold);
    line-height: 17px;
    text-decoration: underline;
    color: var(--blue-elec);
    margin: 12px 0 12px 30px;
    cursor: pointer;
    font-size: 12px !important;
}