.Divider-Global.ant-divider-horizontal.ant-divider-with-text{
    margin-bottom: 6px !important;
    margin-top: 1px !important;
}
.Divider-Global .ant-divider-inner-text{
    color: var(--blue);
    font-size: 15px;
}


.ant-form-vertical{
    margin-top: 5px !important;
}

.Create-User-Form-Item-Text .ant-form-item-label{
    margin-bottom: -7px !important;   
}