
.Container-Filter-Modal-Download-Data{
    border: 2px #000000a1 solid !important;
    border-radius: 10px;
    padding-bottom: 20px;
    height: 60vh;
    /* height: 100%; */
}

.Panel-Collapse-Select-Date .ant-collapse-content .ant-collapse-content-box{
    padding: 0 !important;
}
.Panel-Collapse-Select-Date.ant-collapse-item.ant-collapse-item-active{
    padding-bottom: 0 !important;
}

.Panel-Collapse-Select-Date .ant-collapse{
    border-radius: 0 !important;
}

.Panel-Collapse-Select-Date{
    padding-bottom: 0 !important;
}

:is(.ant-collapse-item.ant-collapse-item-active.Panel-Collapse-Select-Date-Year),
:is(.ant-collapse-item.ant-collapse-item-active.Panel-Collapse-Select-Date-Month){
    padding-bottom: 0 !important;
}


.Panel-Collapse-Select-Date .ant-collapse-content-box .ant-collapse,
.Panel-Collapse-Select-Date-Year .ant-collapse-content-box .ant-collapse,
.Panel-Collapse-Select-Date-Month .ant-collapse-content-box .ant-collapse{
    border: none !important;
}
.Panel-Collapse-Select-Date-Year .ant-collapse-content,
.Panel-Collapse-Select-Date-Month .ant-collapse-content{
    padding: 8px 10px !important;
}

.Modal-Download-Data{
    top: 70px !important;
}

.Container-Filter-Modal-Download-Data .ant-collapse{
    border: none !important;
}

.ant-collapse>.Panel-Collapse-Select-Date.ant-collapse-item:last-child{
    border-radius: 0 !important;
}

.Container-Filters-Scroll-Download-Data{
    max-height: 40vh;
    overflow-y: scroll;
}

/* .Container-Filters-Scroll-Download-Data::-webkit-scrollbar{
    width: 8px;
    height: 1px !important;
}
.Container-Filters-Scroll-Download-Data::-webkit-scrollbar-thumb{
    background-color: var(--blue);
    border-radius: 24px;
} */
/* .Container-Filters-Scroll-Download-Data::-webkit-scrollbar-track{
    border-radius: 0;
    margin-left: 40px;
    margin-right: 40px;
} */

.Container-Button-Arrow{
    display: flex;
    justify-content: center;
    border: 2px solid #013A81;
    border-radius: 5px;
    margin: 2px 0;
    cursor: pointer;
}

.Panel-Collapse-Select-Date .ant-collapse-header{
    padding: 5px 10px !important;
    background-color: var(--blue-elec) !important;
    color: #FFFFFF !important;
    border-radius: 0 !important;
    font-size: 13px;
    font-weight: var(--weight-semibold) !important;
}

.Container-Column-Filter-Text{
    padding-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
    margin-bottom: 20px;
}

.Panel-Collapse-Select-Date-Year .ant-collapse-header,
.Panel-Collapse-Select-Date-Month .ant-collapse-header{
    font-size: 12px;
    padding: 5px 15px !important;
    background-color: #FFFFFF !important;
    color: var(--blue-elec) !important;
    /* color: #000000 !important; */
    font-family: var(--font-family) !important;
}

.Panel-Collapse-Select-Date-Date .ant-collapse,
.Panel-Collapse-Select-Date-Year .ant-collapse,
.Panel-Collapse-Select-Date-Month .ant-collapse{
    border: none !important;
}

.Panel-Collapse-Select-Date-Year,
.Panel-Collapse-Select-Date-Month{
    border-radius: 0 !important;
    border-bottom: 0 !important;
    border-left:  0 !important;
    border-right: 0 !important;
}

.Panel-Collapse-Select-Date-Year .ant-collapse-content span,
.Panel-Collapse-Select-Date-Month .ant-collapse-content span{
    font-size: 11px !important;
    font-family: var(--font-family) !important;
}
.Panel-Collapse-Select-Date-Year .ant-radio-wrapper,
.Panel-Collapse-Select-Date-Month .ant-radio-wrapper{
    align-items: center !important;
}

.Panel-Collapse-Select-Date-Year .ant-collapse-content,
.Panel-Collapse-Select-Date-Month .ant-collapse-content{
    padding-left: 20px !important;
}

.ant-collapse-item .Panel-Collapse-Select-Date{
    border-radius: 0 !important;
}

.Title-Modal-Download-Data{
    font-family: var(--font-family) !important;
    display: flex;
    justify-content: center;
    color: #000000;
    margin-bottom: 20px !important;
}