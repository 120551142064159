.Container-Section-Slider{
    padding: 20px 10px;
}
.Container-Item-Slider{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--cream);
    cursor: pointer;
    border-radius: 20px;
    padding: 15px 0;
}
.Container-Section-Slider:hover .Container-Item-Slider{
    box-shadow: 2px 4px 4px rgba(126, 107, 69, 0.25);
}
.Container-Section-Slider .Container-Item-Slider.Active-Shadow{
    box-shadow: 2px 4px 4px rgba(126, 107, 69, 0.25);
}
.Container-Section-Slider .Title-Slider{
    font-family: var(--font-family);
    text-align: center;
    color: var(--blue);
    font-size: 12px !important;
    font-weight: var(--weight-bold);
    line-height: var(--lineheight-regular);
    margin-bottom: 5px;
}
.Container-Section-Slider .Numeric-Slider{
    font-family: var(--font-family);
    text-align: center;
    color: var(--green) !important;
    font-size: 20px !important;
    line-height: var(--lineheight-regular) !important;
    font-weight: var(--weight-bold);
    margin-bottom: 4px !important;
}
.Container-Section-Slider .Text-Slider{
    font-family: var(--font-family);
    text-align: center;
    color: var(--blue);
    line-height: var(--lineheight-regular);
    margin-bottom: 5px;
    font-size: 12px !important;
}
.Container-Section-Slider .Bar-Bottom{
    width: 70px !important;
    margin-top: 8px;
    background-color: var(--blue-elec);
    margin-left: 35%;
    border-radius: 12px;
    height: 6px;
    opacity: 0;
}
.Container-Section-Slider .Bar-Bottom.Active-Bar{
    opacity: 1;
}
.Container-Section-Slider .Bar-Item-Bottom{
    width: 22px;
    height: 5px;
    background-color: rgba(59, 114, 255, .3);
    border-radius: 12px;
    display: none;
}
.Container-Section-Slider .Bar-Item-Bottom.Active-Item-Bar{
    background-color: var(--blue-elec);
}
@media screen and (max-width: 991px) {
    .Container-Section-Slider .Bar-Bottom{
        display: flex;
        column-gap: 10px;
        justify-content: center;
        background-color: var(--white);
        margin-left: auto;
        opacity: 1;
        width: auto;
    }
    .Container-Section-Slider .Bar-Item-Bottom{
        display: block;
    }
}