.ant-modal-confirm-btns .ant-btn-primary{
    background-color: var(--green) !important;
}

.Button-Show-MSL,
.Button-Show-Manuals-Products,
.Button-Show-Products{
    padding: 5px;
    width: 20px;
    height: 20px;
    margin: 0 2px;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Button-Show-MSL{
    border: 1px solid rgba(255, 0, 0, 0.145);
}

.Button-Show-MSL-Selected{
    /* border: 1px solid red; */
}

.Button-Show-Manuals-Products{
    border: 1px solid var(--yellow);
}

.Button-Show-Products{
    border: 1px solid var(--green);
}

.Button-Show-Manuals-Products .Content-Button-Show-Manuals-Products{
    display: flex;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 3px;
    background-color: #ffc80041;
}
.Button-Show-Manuals-Products .Content-Button-Show-Manuals-Products.Selected{
    background-color: var(--yellow);
}

.Button-Show-Products .Content-Button-Show-Products{
    display: flex;
    content: '';
    width: 10px;
    border-radius: 3px;
    height: 10px;
    background-color: #2ad2944f;
}
.Button-Show-Products .Content-Button-Show-Products.Selected{
    background-color: var(--green);
}


.Button-Show-MSL .Content-Button-Show-MSL{
    display: flex;
    content: '';
    width: 10px;
    border-radius: 3px;
    height: 10px;
    background-color: rgba(255, 0, 0, 0.145);
}
.Button-Show-MSL .Content-Button-Show-MSL.Selected{
    background-color: red;
}

.Button-Show-Products.Cursor-Blocked,
.Button-Show-Manuals-Products.Cursor-Blocked,
.Button-Show-MSL.Cursor-Blocked{
    cursor: not-allowed;
}

.Container-Actions-Show-MSL{
    display: flex;
}

.Table-Global .ant-table-thead th{
    padding-bottom: 0 !important;   
}

.Container-View-Products .Table-Global{
    max-height: 100%;
}

.Container-View-Products .Table-Global .ant-table-tbody .Row-Product-Manual .ant-table-cell{
    color: var(--yellow) !important;
}

.Container-View-Products .Table-Global .ant-table-tbody .Row-Product-Manual:hover .ant-table-cell{
    background-color: #d7e2ff !important;
}
.Container-View-Products .Table-Global .ant-table-tbody .Row-Product-Manual.ant-table-row-selected .ant-table-cell{
    background-color: #EDF2FF !important;
}

.Container-Actions-Table-Products{
    display: flex;
    justify-content: center;
    column-gap: 10px;
    align-items: center;
}
.Actions-Table-Products-Editing{
    display: flex !important;
    align-items: center !important;
    column-gap: 5px;
}
.Actions-Table-Products-Editing div{
    display: flex !important;
    align-items: center !important;
}